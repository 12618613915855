import { useState } from "react";
import { useStateContext } from "../../../V3/StateProvider";
import { SVG_IA } from "../../../V3/svgs";
import { sbUpdate } from "../../../V3/DbFunction";
import { gpt, imageGenerator } from "../../../utilities/helper/text";
import { addDalleImg } from "../../../V3/pages/AdminCards";
import { app } from "../../../config";
import { getStorage } from "firebase/storage";




const Hint = ({quit, updateProfileCard, profile_card, updateCurrentCard, card}) => {

    const {setLearnedCards, setAlert, setView, profile} = useStateContext()
    const storage = getStorage(app);
    const [hint, setHint] = useState(profile_card?.hint || {})
    const [addHint, setAddHint] = useState(false)
    const [mode, setMode] = useState("Créer")
    const [showExemple, setShowExemple] = useState(false)
    const [loading, setLoading] = useState([])

    const exemples = [
        {word: "Cane (chien en italien)", hint: "Un CHIEN qui se déplace avec une CANNE"},
        {word: "Gato (chat en espagnol)", hint: "Un CHAT qui mange un GATEAU"},
        {word: "Carro (voiture en portugais)", hint: "Une VOITURE qui transporte des CAROTTES"},
        {word: "Wolke (nuage en allemand)", hint: "Un NUAGE en forme de VOLCAN"},
        {word: "Hochzeit (mariage en allemand)", hint: 'Un MARIAGE où tout le monde crie "OH ZUT !'},



    ]

    const modes = ["Choisir", "Créer"]

    const IA_correctErrors = async(text) => {

            const text_request = ` Donne juste la réponse. Corrige les fautes d'orthographe pour : ${text}. Laisse les Majuscules`
            const request = [{ "role": "user", "content": text_request}]
            const requestData = await gpt(request, true, null)
            return requestData.choices[0]?.message?.content
        
    }

    const getImageFromDalle = async() => {
        const input = document.querySelector('#input-hint').value
        setLoading(prev => [...prev, "img"])
        const dataImg = await imageGenerator({
            "model": "dall-e-3",
            "prompt": "Donne une illustration moderne et jolie SANS TEXTE, pour réprésenter le liens mnémotechnique suivant :" + input,
            "n": 1,
            "size": "1024x1024"
          })
        const url = dataImg.data[0].url
        const url_fire = await addDalleImg(url, null, storage, {id: card?.id+"--"+profile?.id})

        console.log('url_fire', url_fire)

        setHint(prev => ({...prev, img: url_fire}))
        setLoading(prev => prev.filter(e => e != "img"))
        
    }

    const correctErrors = async() => {

        setLoading(prev => [...prev, "fautes"])
        const input = document.querySelector('#input-hint')
        const corrected = await IA_correctErrors(input.value)
        console.log('corrected', corrected)
        setLoading(prev => prev.filter(e => e != "fautes"))



        input.value = corrected


    }


    console.log('loading', loading)
    const [admin, setAdmin] = useState(profile.role == "admin" || profile.role == "editor")
    
    return <div onClick={() => setView()} className="fixed z-[999] top-0 bottom-0 left-0 right-0 backdrop-blur flex items-center jusitfy-center  bg-[#05043e99]">
        <div onClick={(e) => e.stopPropagation()} style={{width: "calc(100vw - 20px)"}} className="p-4 text-white relative rounded-xl pt-[100px] pb-[80px] h-[70vh] bg-[#9D2DE7] min-w-[300px] mx-auto max-w-[500px]">
            <div className="absolute rounded-t-xl text-center  text-white px-4 gap-4 flex flex-col justify-end  bg-[#3F2261] top-0 left-0 h-[80px] right-0">
                <div className="text-sm">Ajouter un lien mnémotechnique</div>
                <div className="flex items-end">
                    {modes.map(m => <div onClick={() => setMode(m)} className={`px-3 ${mode == m ? "bg-[#9D2DE7]" : ""} py-1 rounded-t-xl text-white `}>{m}</div>)}

                </div>
            </div>

            {mode == "Créer" && <div className="h-full overflow-scroll pt-6 ">
                {hint.img && <div className="flex justify-center"><div className="relative mx-auto">
                    <img src={hint.img} className="h-[86px] w-[86px] mx-auto rounded-md mb-2" />
                    <svg onClick={() => {setHint(p => ({...p, img: null}))}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 absolute top-2 p-1 rounded bg-black/80 right-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                    </div></div>
                }
            <textarea id="input-hint" placeholder={"Ecris ton astuce ici pour retenir le mot “" + card.term+ "”"} defaultValue={profile_card?.hint?.text_fr} className="rounded-xl p-2 bg-white/20 text-white placeholder:text-white/40 w-full border border-white/50" />
            {admin && <div className="flex gap-2">
                <div className="px-1 text-xs bg-white/10 rounded-md" onClick={() => correctErrors()}>{loading.includes("fautes") ? "Correction en cours..." : <>Corriger mes fautes <SVG_IA /></>}</div>
                {/* <div className="px-1 text-xs bg-white/10 rounded-md">Trouver une idée <SVG_IA /></div> */}
                <div className="px-1 text-xs bg-white/10 rounded-md" onClick={() => getImageFromDalle()}>{loading.includes("img") ? "Génération en cours..." : <>Générer une image <SVG_IA /></>}</div>
                <div></div>
            </div>}

            <div className="bg-white/10 rounded-xl p-2 mt-2">
            <div className="flex text-sm text-white justify-between ">Partager avec la communauté <div onClick={() => setHint(prev => ({...prev, shared: !prev.shared}))} className={`p-1 items-center ${!hint.shared ? "justify-start bg-black/10": "justify-end bg-white"} flex  w-[36px]  rounded-xl`}><div className={`h-3 w-3 ${!hint.shared ? "bg-white": "bg-[#9D2DE7]"} rounded-xl`}></div></div></div>
            <div className="text-xs italic">D'autres joueurs pourront voir ton astuce</div>
            </div>
            {admin && profile_card.profile_id == 1483 && <div className="flex "><div className="flex bg-white text-purple-500 rounded-xl px-3 text-sm text-white justify-between mt-2" onClick={() => {
                const text = document.querySelector('#input-hint')?.value
                sbUpdate('cards', profile_card.card_id, {hint: {...hint, text_fr: text}, data: {...card.data, hint_count: (card?.data?.hint_count || 0) + 1}})
            }}>Envoyer en DB</div></div>}

            <div onClick={() => setShowExemple(e=>!e)} className="mt-4 tex-sm flex justify-between items-center">
                Voir des exemples
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 ${!showExemple ? "" : "rotate-[90deg]"} transform-all `}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
            </div>
            {showExemple && exemples.map(e => <div className="text-sm mt-2 pl-2 "><span className="italic text-white/80">{e.word}</span><div><span className="text">“{e.hint}”</span></div></div>)}

            </div>}

            {mode == "Choisir" && <div className="flex flex-col text-white items-center">
               
                <div className="italic text-sm">Aucune astuce n'est disponible pour cette carte.</div>
                <div className="mt-4 text-[#9D2DE7] px-3 py-2 bg-white rounded-xl" onClick={() => setMode("Créer")}>Créer ma propre astuce</div>
                </div>}


            <div className="absolute p-4 flex items-center gap-8 enter text-center justify-between bottom-0 left-0 h-[80px] right-0">
                <div className="px-3 py-2 rounded-xl  text-white bg-white/10"  onClick={() => {setView()}}>Annuler</div>
                {mode == "Créer" && <div className="px-3 py-2 rounded-xl  grow text-[#9D2DE7] bg-white" 
                onClick={() => {
                    
                    const text = document.querySelector('#input-hint')?.value
                    updateProfileCard(profile_card.id, {hint: {...hint, text_fr: text}})
                    updateCurrentCard(prev => ({...prev, hint: {...hint, text_fr: text}}))
                    setView()
                    }}>Valider</div>}
            </div>
        </div>
       
    </div>
}


export {Hint}