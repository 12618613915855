import { supabase } from "../V2/screens/testScreen";
import { images } from "../utilities/helper/helper";
import { sbCreate, sbUpdate } from "./DbFunction";
import { islands, totemsExtension } from "./data/forestData";



const handleCollision = ({x, y, element, profileWorkspace, setAlert, objs}) => {

    const elixirs = profileWorkspace?.tt_elixirs - profileWorkspace.elixirs_spend
    const xpCoin = profileWorkspace?.tt_xp - profileWorkspace.xp_spend
    const allElementsWithData = objs
    .map(ud => ({...ud, element: images?.find(i => i.name == ud.name)}))
    .map(e => ({...e, spreadPos: e.element?.spread?.map(arr => ({x: e.x - arr[0], y: e.y - arr[1]}))}))
    .filter(e => e?.name != "target_yellow")

    const elementWithData = {...element, 
        element: images?.find(i => i.name == element.name),
        spreadPos: element?.spread?.map(arr => ({x: x - arr[0], y: y - arr[1]}))
    }
    const price = elementWithData?.element?.price

   
    let elementOnPos = allElementsWithData?.filter(e => (
        (e.x == x && e.y == y) || 
        (e.spreadPos?.find(s => s.x == x && s.y == y)) || 
        elementWithData.spreadPos?.find(spread => spread.x == e.x && spread.y == e.y)
    ) && !e.avatar_id && e.element ).sort((a,b) => (b.z||1) - (a.z||1))

    let baseGround = elementOnPos?.find(e => e.element?.ground)
    let deco = elementOnPos?.find(e => !e.element.ground ) 

    



    console.log('__app elementWithData', {
        baseGround, elementWithData, elementOnPos, deco
    })

    if (deco){
        setAlert({title: "Cette case n'est pas vide" , content: ""})
        return false
    }


    if (price && price > elixirs) {
        setAlert({title: "Tu n'as pas assez d'elixirs" , content: ""})
        return false
    }

    if (!baseGround && !element.ground) {
        setAlert({title: "Cette case n'est pas disponible" , content: ""})
        return false
    }

   
   

    return true
    // si on 
}


const decoToMap = (deco) => {
    const map = deco?.map(e => {
        const base = [e.x, e.y, images?.find(i => i.name == e.name)?.id];
        
        if (e.name === "bourse_l") {
            return [...base, {n: e.n, bc: e.bc}];
        }
        
        return base;
    })?.filter(e => e[2] !== undefined);
    
    console.log('deco maped', map);
    console.log('deco maped 10', map.slice(0, 10));
    return map;
}

const addToMap = async(decoToAdd, profileWorkspace_id) => {

    const newDeco = decoToMap(decoToAdd.filter(e => e.name != "totem_extension"));

    console.log('__app addToMap', newDeco)
    
    const { data, error } = await supabase
        .rpc('append_to_map_deco', { 
            p_workspace_id: profileWorkspace_id,
            new_deco: newDeco
        });

    console.log('data', data)
    if (error)console.log('error', error)

    
    // window.location.reload();
    return data
}

const dowloadIsland = async (lvl, profileWorkspace_id, setProfileDecoration, setProfileWorkspace) => {
    const totem = totemsExtension?.[lvl-1 || 0];
    const base_deco = islands.slice(lvl-1 || 0, lvl).flat()
        ?.concat([totem] || [])
        ?.filter(item => item?.x !== undefined && item?.y !== undefined)
        ?.map((e,i) => ({...e, id: i, x: e.x , y: e.y }))
    

  
     await addToMap(base_deco, profileWorkspace_id)
     setProfileDecoration(prev => prev.concat(base_deco))
     if (lvl > 1) await sbCreate('events', {
        profile_workspace_id: profileWorkspace_id,
        type: "extension",
        date: new Date(),
        data: {lvl: lvl, base_deco_nb: base_deco?.length}

     })
     await sbUpdate('profiles_workspaces', profileWorkspace_id, {extension_lvl: lvl})

     setProfileWorkspace(prev => ({...prev, extension_lvl: lvl}))
    window.location.reload();
}

const mapToDeco = (map) => {
    console.log('debug mapToDeco', map);
    
    // Première étape : conversion des données
    const converted = map?.map(([x, y, imageId, opt]) => ({
        x: x,
        y: y,
        name: images?.find(img => img.id === imageId)?.name,
        bc: opt?.bc,
        n: opt?.n
    })).filter(e => e.name !== undefined).filter(e => e.name);

    // Deuxième étape : élimination des doublons
    const uniqueMap = new Map();
    
    converted?.forEach(item => {
        const key = `${item.x},${item.y},${item.name}`;
        // Ne garde que la première occurrence
        if (!uniqueMap.has(key)) {
            uniqueMap.set(key, item);
        } else {
            // on clean en db
        }
    });

    // Conversion de la Map en array
    return Array.from(uniqueMap.values());
}

export {mapToDeco, dowloadIsland, addToMap, decoToMap, handleCollision}