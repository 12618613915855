import { useStateContext } from "../StateProvider"

const ShowInfoPop = (props) => {
    

    const {setShowInfo, showInfo} = useStateContext()

    const content = showInfo?.content
    const title = showInfo?.title
    const icon = showInfo?.icon
   
  


  
  return (
    <div id="showInfoPop" onClick={() => setShowInfo()} className={`fixed z-[999] fredoka top-0 flex flex-col items-center justify-center z-[150] left-0 right-0 backdrop-blur-md	 bottom-0 bg-black/40`}>
      
      <div className='bg-black/90 rounded-xl border-4 border-black w-[320px] mx-auto'>
        <div className=' px-4 py-2 text-white flex justify-between'>
           <div className='flex gap-2 items-center'>{icon} {title}</div>
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

            </div>
        <div className={`p-4 rounded-xl ${showInfo?.classContent}`}>
            {content}
        </div>
      </div>
    </div>
  );
};

export {ShowInfoPop};