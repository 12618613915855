
import { getMessaging, getToken } from "firebase/messaging";
import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';

import { GoogleAuthProvider, getAuth, signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
import { db, app} from "../config.js";

import {TestScreen} from './screens/testScreen'
import {DeckScreen} from './screens/💰deckScreen'
import {LandingScreen} from './screens/landingScreen'
import {HomeScreen} from './screens/🏠homeScreen'
import {ForestScreen} from './screens/🌳forestScreen'
import {LearnContainer} from './components/learn/learnContainer'
import {UserScreen} from './screens/🧑‍🌾userScreen'
import { LeaderScreen } from './screens/🏆leaderScreen';
import { LearnDialog } from './components/learn/learnDialog';
import { LearnMindCard } from './components/learn/learnMindCard';
import {ChatScreen} from './screens/🐈chatScreen'
import {AdminScreen} from './screens/👑adminScreen'
import {ManagerScreen} from './screens/👮‍♀️managerScreen'
import { VerificationScreen } from './screens/verificationScreen';
import { AdminSchool } from './screens/AdminSchool';
import {fire} from './🔥db'
import {Alert} from './components/alert'

import { Chronos } from './components/chronos';
// import { CodeView } from '../components/codeView';
import { checkAuth } from '../utilities/auth';
import { NotificationScreen } from './screens/NotificationScreen';
import { coachs } from './data/notification';
import { GoalScreen } from './screens/GoalsScreen';
import { TeacherScreen } from './screens/TeacherScreen';
import { AddFact } from './screens/addFact.js';
import { DailyMission } from './components/pop/dailyMission.js';
import { ShowInfoPop } from './components/pop/showInfoPop.js';
import { QuizzCreator } from './screens/QuizzCreator.js';
import { QuizzReader, sectionInfos } from './components/quizzReader.js';
import { BugRepport, centToStars, convertToDates, getCardsFromIDS, getDeckData, getTotalFromTestId, getWeekNumberAndYear, images, mapDate, pops, quizList, quizList2, sortAndFilterUserWorkspaces, tutoFlashcards, tutoForest, tutoPareto, tutoPerf } from '../utilities/helper/helper.js';
import { ClassScreen } from './screens/ClassScreen.js';
import { RecapSession } from './components/pop/recapSession.js';
import { MailBox } from './components/pop/mailBox.js';
import { Pareto } from './screens/Pareto.js';

import { Magnifier } from './components/magnifier.js';
import { formatDate, learn, validCard } from './components/learn/learn.js';
import { RedDot } from './screens/perfectScreen.js';
import { ChooseWP } from './components/chooseScreen.js';
import { IntroTuto } from './components/pop/introTuto.js';
import { TutoBook } from './screens/tutoScreen.js';
import { ModalWordLearned } from './components/pop/wordLearned.js';
import { ModalTraining } from './components/pop/modalTraining.js';
import { FastCardReview } from './components/pop/fastCardReview.js';
import { BlockedScreen } from './components/pop/BlockedScreen.js';
import { MainMenu } from './components/pop/MainMenu.js';
import { ForestMini } from "./screens/forestMini.js";
import { toggleVersion } from "../V3/helperFunctions.js";


const versionExt = "?&v=2"


const validSound = new Audio('/assets/g');

validSound.volume = 0.02;



const reload = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {

      Promise.all(registrations.map(registration => registration.update())).then(() => {

        window.location.reload(true);
      });
    });
  }
};





const today = new Date()
today.setHours(0, 0, 0, 0);




const checkAndUpdateVersion = async({user, setInfo, setUser, setShowInfo}) => {

  // comparer la version local et la version en ligne 

  const info = await getDoc(doc(db, "info", "mindseed"))
  const last_version_available = info.data().version
  const current_version = 1.67
  setInfo(info.data())
  console.log('last_version_available', last_version_available)
  console.log('current_version', current_version)



  if (user.last_version !== current_version) {
    console.log('NOUVELLE VERSION NEW :', current_version)
    const docRefWp = doc(db, 'users', user.id);
    console.log('-- user checkAndUpdateVersion', user)
    setUser(user => ({...user, last_version: current_version })); 
    await updateDoc(docRefWp, {last_version: current_version });
  }

  if (current_version == last_version_available) {setShowInfo()}

  
  if (current_version < last_version_available) {

    setShowInfo({title: "Nouvelle version disponible !",  classContent: "bg-[#5b00b1] text-white", content: <div>
      <img className='h-20 mx-auto mb-2' src="/images/deco/cool_seed.png" />
      <div className='text-sm text-center'>Il y a du nouveau depuis ta dernière visite. Appuie sur le bouton pour utiliser la dernière mise à jour de l'application.</div>
      <div  className='flex my-2 mt-4 justify-center'>
        <div onClick={async() => {
          if (current_version > last_version_available) {
            console.log('mise à jour de la version online', current_version)
            setInfo({...info, version: current_version})
            await updateDoc(doc(db, "info", "mindseed"), {version: current_version})
          }
          reload()
        }} className='bg-white border-2 border-b-4 border-indigo-900 text-indigo-500 px-4 py-2 rounded-xl'>Découvrir la dernière version</div>
        </div>
    </div>})
         

  } 
}


const handleSignOut = (auth, set) => {
  signOut(auth)
    .then(() => {
      console.log('User signed out');
      set()
    })
    .catch((error) => {
      console.error(error);
    });
};


const loadNextCards = async(wp_id, setCardMap, setCardMapGlobal, setDico) => {
  const q = query(collection(db, 'card_map'), where('wp_id', "==", wp_id))
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map(doc => doc.data())[0]
  const card_map_obj = data?.cards_map?.split(';')
  console.log('card_map_obj', card_map_obj)
  setDico(data?.dico_term_inflexions)
  setCardMap(card_map_obj)
  setCardMapGlobal(data.grouped_by_global_deck)
}


const loadCards = async ({wp_id, setCurrentCardWp, setCards}) => {
  console.log('loadCards 🟢', wp_id)
  const q = query(collection(db, 'cards'), where('workspace_id', "==", wp_id), limit(1))
  const querySnapshot = await getDocs(q);
  const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).sort((a,b) => a.rank - b.rank).filter(card => card.deck_id != "draft")
  setCurrentCardWp(wp_id)
  console.log('getCards ✅', cards.length)
  return cards
}


const App = ({props}) => {

  

 
    const [lastXp, setLastXp] = useState(0)
    const [cardMap, setCardMap] = useState()
    const [cardMapGlobal, setCardMapGlobal] = useState()
    const [showModalTraining, setShowModalTraining] = useState()

    const [showModalWords, setShowModalWords] = useState()

    const [fqlist, setfqlist] = useState()

    const [text_50k, setText_50k] = useState()
    const [int_50k, setInt_50k] = useState()

    const [lessonOfTheDay, setLessonOfTheDay] = useState()
    const [cantListen, setCantListen] = useState()
    const [showBug, setShowBug] = useState()
    const [cantSpeak, setCantSpeak] = useState()
    const [selectedQuiz, setSelectedQuiz] = useState()
    const [showInfo, setShowInfo] = useState()
    const [info, setInfo] = useState()
    const [classes, setClasses] = useState();
    const [dico, setDico] = useState();
    const [fastCards, setFastCards] = useState();
    const [date, setDate] = useState()
    const [user, setUser ] = useState();
    const [events, setEvents] = useState();
    const [tests, setTests ] = useState();
    const [chronosStart, setChronosStart] = useState()
    const [visitedUser, setVisitedUser] = useState()
    const [decks, setDecks ] = useState();
    const [magnifier, setMagnifier] = useState()
    const [cards, setCards ] = useState();
    const [workspace, setWorkspace ] = useState();
    const [userDecoration, setUserDecoration] = useState([])
    const [workspaces, setWorkspaces ] = useState();
    const [currentCardWp, setCurrentCardWp] = useState();
    const [notifications, setNotifications] = useState()
    const [userWorkspaces, setUserWorkspaces ] = useState();
    const [userWorkspace, setUserWorkspace ] = useState();
    const [selectedPack, setSelectedPack] = useState();
    const [showCards, setShowCards] = useState();
    const [userCards, setUserCards ] = useState();
    const [userDecks, setUserDecks ] = useState();
    const [selectedDeco, setSelectedDeco] = useState();
    const [activeDeck, setActiveDeck] = useState();
    const [userTests, setUserTests] = useState();
    const [totalDx, setTotalDx] = useState(200);
    const [totalDy, setTotalDy] = useState(600);
    const [showTuto, setShowTuto] = useState(false);
    const [cardsToPlace, setCardsToPlace] = useState()
    const [alerts, setAlerts] = useState();
    const [showBottomBar, setShowBottomBar] = useState(true);
    const [activeScreen, setActiveScreen] = useState("forest");
    const [triggerIndex, setTriggerIndex] = useState()
    const [showDeckId, setShowDeckId] = useState()
    const [selectedDeck, setSelectedDeck] = useState()
    const [plantMod, setPlantMod] = useState(false)
    const [userDaily, setUserDaily] = useState()
    const [showMenu, setShowMenu] = useState()
    const [showWorkspaces, setShowWorkspaces] = useState(false)
    const [showPop, setShowPop] = useState()
    const [statusCards, setStatusCards] = useState()
    const [dicoFQ, setDicoFQ] = useState()
    const [lastActivity, setLastActivity] = useState(Date.now());
    const [isBlocked, setIsBlocked] = useState(user?.blocked || true)
    const [nextCards, setNextCards] = useState()
    const [loadedDecoration, setLoadedDecoration] = useState()
    const [visitedUWP, setVisitedUWP] = useState();


    useEffect(() => {
      setIsBlocked(user?.blocked)
      console.log('useEf #1 blocked')
    }, [user?.blocked])




    const handleUserActivity = (e) => {

        //test si .no-last-time dans target
        if (!e.target.closest('.no-last-time')) {
       
            setLastActivity(Date.now());
        } else {
    
        }
    };



   

     
   


    const [timeSpend, setTimeSpend] = useState(0)
    const [getTime, setGetTime] = useState()
 
    const [timeSpendForest, setTimeSpendForest] = useState(0)
    const [globalDecks, setGlobalsDecks] = useState()

    const [isLoading, setIsLoading] = useState(true)


    const pb = true
    

    
    const auth = getAuth(app);



    const getDecks = async (wp_id) => {
        console.log('getDecks 🟢', wp_id)
        const q = query(collection(db, 'decks'), where('workspace_id', "==", wp_id), where('visible', "==", true), orderBy('order'))
        const querySnapshot = await getDocs(q);
        const decks = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).sort((a, b) => a.order - b.order)
        

        const q2 = query(collection(db, 'decks'), where('workspace_id', "==", "international"), where('presentation_mode', "==", "MindCard"))
        const querySnapshot2 = await getDocs(q2);
        const decks2 = querySnapshot2.docs.map(doc => ({...doc.data(), id: doc.id})).sort((a, b) => a.order - b.order)
 
        setDecks(decks.concat(decks2))
    }



  
  
    

    const getClass = async (wp_id, user_id) => {
      console.log('getUserCards 🟢🟢🟢🟢🟢', wp_id,user_id)
      const q = query(collection(db, 'class'))
      const querySnapshot = await getDocs(q);
      const _classes = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
      setClasses(_classes)
  }

    const getUserCards = async (wp_id, user_id) => {
      if (!user_id) return
        console.log('getUserCards 🟢', wp_id, user_id)
        const q = query(collection(db, 'user_cards'), where('workspace_id', "==", wp_id), where('user_id', "==", user_id), limit(1500))
        const querySnapshot = await getDocs(q);
        const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
        // console.log('getUserCards ✅', cards)
        // console.log('userCarc archived', cards.filter(c => c.archived).length)
        // console.log('userCarc perf', cards.filter(c => c?.path == "perf").length)
        // console.log('userCarc origin', cards.filter(c => c?.origin).length)
        // console.log('userCarc cm', cards.filter(c => c.content?.cm).length)

     
       
        setUserCards(cards)
    }


    const getUserDecks = async (wp_id, user_id) => {
        console.log('getUserDecks 🟢', wp_id)
        if (!user_id) return
        const q = query(collection(db, 'user_deck'), where('wp_id', "==", wp_id),  where('user_id', "==", user_id))
        const querySnapshot = await getDocs(q);
        const decks = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    
        setUserDecks(decks)
    }

      const getUserTests = async (wp_id, user_id) => {
        console.log('getUserTest 🟢', user_id)
        if (!user_id) return
        const q = query(collection(db, 'user_tests'), where('wp_id', "==", wp_id), where('user_id', "==", user_id))
        const querySnapshot = await getDocs(q);
        const decks = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    
        console.log('getUserTest ✅', decks)
        // decks.forEach(item => convertToDates(item))
   
        decks.forEach(item => convertToDates(item))
        setUserTests(decks)
    }

  


      useEffect(() =>{
        console.log('useEff #2 base')
        checkAuth(auth, user, setUser, () => null, setIsLoading);
        getClass()
        getGlobalDecks()
        getWorkspaces()      
      }, [])

    useEffect(() => {
      userCards?.length && setCards(userCards.map(lc => lc.content).filter(c => c != undefined))
    }, [userCards?.length])

    useEffect(() => {
      if (user?.fcmToken && workspace?.id) {
     
        if (user.coach != null) {
          console.log('useEff #3 notif')
          fire.addNotifications(context, coachs[user.coach], user);}
      }
    }, [user?.fcmToken, workspace?.id])
     


    useEffect(() => {
        if (userWorkspace?.id){
                fire.updateDaily(userDaily, context)
                console.log('useEff #4 change userWorkspace')
        }
      

    }, [userWorkspace?.id])

    useEffect(() => {
      !visitedUser && userWorkspace?.id && getEvents(userWorkspace?.id, user?.id)
    }, [userWorkspace?.id, user?.id])

    const getUserWorkspaces = async (user) => {
        console.log('getUserWorkspaces 🟢')
        const q = query(collection(db, 'user_workspace'),  where('user_id', "==", user?.id))
        const querySnapshot = await getDocs(q);
        const userWorkspaces = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))   
        const filteredUserWorkspaces = sortAndFilterUserWorkspaces(userWorkspaces)
        setUserWorkspaces(filteredUserWorkspaces)
        console.log('getUserWorkspaces ✅', userWorkspaces)
        console.log('filteredUserWorkspaces', filteredUserWorkspaces.map(e => e.workspace_id))
        
        setTimeout(() => setChronosStart(true), 5000)
    }

    const getTests = async (wp_id) => {
      console.log('getTests 🟢')
      const q = query(collection(db, 'tests'), where("wp_id", "==", wp_id))
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
      setTests(data);
    }

  
    const getWorkspaces = async () => {
        console.log('getWorkspaces 🟢')
        const q = query(collection(db, 'workspaces'))
        const querySnapshot = await getDocs(q);
        const workspaces = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).sort((a, b) => {
            return parseInt(a.order, 10) - parseInt(b.order, 10);
          });
        setWorkspaces(workspaces);
      }

    const getWorkspace = async (wp_id) => {
        console.log('getWorkspace 🟢', wp_id)
        const docRef = doc(db, 'workspaces', wp_id);
        const docSnap = await getDoc(docRef);

      

        
        
        setWorkspace({...docSnap.data(), id: wp_id});
        const wpName = docSnap?.data()?.name
        console.log('get FQ 🟢', wpName)
        const docRef2 = doc(db, 'fq', wpName);
        const docSnap2 = await getDoc(docRef2);
        console.log('fq', docSnap2?.data())
        setfqlist(docSnap2?.data())
       
      }





      const updateUserBug = async(id, newData) => {
        // await updateDoc(doc(db, 'users', id), newData)
      }
      useEffect(() => {
        const wp_id = user?.last_workspace_id
        console.log('-- user', user)
        console.log('-- wp_id', wp_id)
        console.log('-- userWorkspaces', userWorkspaces)
   
        const current_uwp_orignal = userWorkspaces?.find(uwp => uwp.workspace_id == wp_id)
        const current_uwp =  current_uwp_orignal || userWorkspaces?.[0]
        console.log('current_uwp??', current_uwp)
        console.log('userWorkspaces', userWorkspaces)
        // doublons ??

        console.log('-- current_uwp', current_uwp)
      
        current_uwp && setUserWorkspace(current_uwp)

        if (!current_uwp_orignal && current_uwp && current_uwp.workspace_id) {
          setUser(u => ({...u, last_workspace_id: current_uwp?.workspace_id, bug_last_workspace_id: true}))
          updateUserBug(user?.id, {last_workspace_id: current_uwp?.workspace_id, bug_last_workspace_id: true})
        
        }

        console.log('useEff #5 change user?.last_workspace_id')

  
      },[user?.id, user?.last_workspace_id, userWorkspaces]) 
  


    useEffect(() =>{
      console.log('useEff #6 active screen')
      console.log('activeScreen', activeScreen)
        if (activeScreen != "admin") {
          window?.addEventListener('clickend', handleUserActivity);
    //     window.addEventListener('keydown', handleUserActivity);
          window?.addEventListener('touchend', handleUserActivity);
       
        }

        return () => {
    
            // Supprime les listeners lorsque le composant est démonté
            window.removeEventListener('clickend', handleUserActivity);
         //   window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('touchend', handleUserActivity);
        }
    }, [activeScreen])

    const updateUser = async (user) => {
        console.log('updateUser 🟣', user)
        const userRef = doc(db, 'users', user.id);
        const updated = new Date()
        await updateDoc(userRef, {...user, updated});
      }
      
      

 


      const getEvents = async (uwp, user_id) => {
        console.log('getEvents 🟢🟢🟢')
        const q = query(collection(db, 'event'), where("box", "==", true), where("target_user_id", "==", user_id), orderBy('date', 'desc'), limit(200));
        const querySnapshot = await getDocs(q);
        const events = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
        const q2 = query(collection(db, 'event'), where("box", "==", true), where("from", "==", uwp), orderBy('date', 'desc'), limit(200));
        const querySnapshot2 = await getDocs(q2);
        const events2 = querySnapshot2.docs.map(doc => ({...doc.data(), id: doc.id}))
        const total = [...events, ...events2]
        
        console.log('getEvents', total)
        setEvents(total);
      }

      const [showTutoBook, setShowTutoBook] = useState(false)

     

      const changeWorkspace = (uwp_id, wp_id) => {
      
      
        console.log('changeWorkspace', wp_id)
       
  
        const _user = {...user, user_workspace_id: uwp_id, last_workspace_id: wp_id}
        updateDoc(doc(db, "users", _user.id), {user_workspace_id: uwp_id, last_workspace_id: wp_id})
        setUser(_user)
        
        // getWorkspace(wp_id)
        // getDecks(wp_id)
        // getCards(wp_id)
        // getUserCards(wp_id, _user.id)
        // getUserDecks(wp_id, _user.id)
        // getUserTests(wp_id, user.id)
      }
    



      // public context
      // private context
   


      let context = {
        lessonOfTheDay, setLessonOfTheDay,
        date, setDate, 
        cards, setCards,
        magnifier, setMagnifier,  
        currentCardWp, setCurrentCardWp,
        workspace, setWorkspace,
        workspaces, setWorkspaces,
        
        userCards, setUserCards,
        userWorkspaces, setUserWorkspaces,
        userWorkspace, setUserWorkspace,  
        showCards, setShowCards,
        user, setUser, 
        fastCards, setFastCards,
        events, setEvents,
        classes, setClasses,
        visitedUser, setVisitedUser,
        dicoFQ, setDicoFQ,
        dico, setDico,
        lastXp, setLastXp,
        plantMod, setPlantMod,
        cardMap, setCardMap,
        cardMapGlobal, setCardMapGlobal,
        loadedDecoration, 
        cardsToPlace, setCardsToPlace,
        alerts, setAlerts,
        selectedQuiz, setSelectedQuiz,
        decks, setDecks,
        setShowTutoBook, 
        notifications, setNotifications,
        selectedDeck, setSelectedDeck,
        selectedPack, setSelectedPack,
        globalDecks, setGlobalsDecks,
        userDecks, setUserDecks,
        cantListen, setCantListen,
        cantSpeak, setCantSpeak,
        text_50k, setText_50k,
        int_50k, setInt_50k,
        showInfo, setShowInfo,
        setShowModalTraining,setShowModalWords,
        activeDeck, setActiveDeck,
        userDaily, setUserDaily,
        selectedDeco, setSelectedDeco,
        triggerIndex, setTriggerIndex,
        setShowBottomBar, setActiveScreen,
        fire,
        showDeckId, setShowDeckId,
        tests, setTests,
        totalDx, setTotalDx,
        fqlist, setfqlist,
        setShowTuto,
        userTests, setUserTests,
        totalDy, setTotalDy,
        timeSpendForest, setTimeSpendForest,
        getTime, setGetTime,
        showWorkspaces, setShowWorkspaces,
        timeSpend, setTimeSpend,
        lastActivity, setLastActivity,
        setIsBlocked,
        showMenu, setShowMenu,
        showPop, setShowPop,
        visitedUWP, setVisitedUWP,
        statusCards, setStatusCards,
        nextCards, setNextCards,
        pb
    }

  

    useEffect(() => {
      if (user?.id) {
        console.log('useEff #7 user')
        checkAndUpdateVersion({user, setInfo, setUser, setShowInfo})
        getUserWorkspaces(user)
      }
      
    }, [user?.id])

    const getGlobalDecks = async () => {
      const global_decks = (await getDocs(query(collection(db, 'decks'), where("isGlobal", "==", true)))).docs.map(doc => ({...doc.data(), id: doc.id}));
      setGlobalsDecks(global_decks)
    }







    // useEffect(() => {
    //   nextCards?.length && setCards(c => [...c || [], ...nextCards || []])
    //   console.log('nextCards ajouter !!', nextCards?.map(n => n.term))
    // }, [nextCards])


    // choix des prochaines cartes pour mode découverte
    useEffect(() => {
      console.log('--------userWorkspace', userWorkspace)
      if (userWorkspace?.goal_id == "perfect") return
      if (!cardMap) return
      if (nextCards?.length > 4 && userWorkspace?.goal_id != "perfect") return
      console.log('nextCards', nextCards)
      console.log('nextCards length', nextCards?.length)
      if (nextCards?.length == 1) {
       
        return
      }  
      
      const learnedId = new Set(userCards?.map(uc => uc.card_id));
      const allId = cardMap || [];
      const nextID = [];


      for (const id of allId) {
        if (!learnedId.has(id)) {
          nextID.push(id);

          if (nextID.length >= 10) {
            break;
          }
        }
      }
      console.log('nextID LEARN 🐸🐸🐸', nextID)
      nextID.length > 9 && getCardsFromIDS(nextID, setNextCards)
     
    }, [userCards?.length, cardMap, nextCards, userWorkspace?.goal_id])


   




    
    useEffect(() => {

        if (activeScreen == "forest" && visitedUser) return 
        if (user?.id) {
            console.log('RECHARGE DE MAAA FORET 🌳🌳🌳🌳')
            console.log('useEff #7 last_workspace_id reload de toute les ressources')
            const wp_id = user?.last_workspace_id
            if (wp_id) {
                console.log('recharge forêt 1')
                getTests(wp_id)
                getDecks(wp_id)
                console.log('recharge forêt 2')
                loadCards({wp_id, setCards, setCurrentCardWp})
                console.log('recharge forêt 3')
                
                getWorkspace(wp_id)
                getUserCards(wp_id, user.id)
                getUserDecks(wp_id, user.id)
                getUserTests(wp_id, user.id)
                loadNextCards(wp_id, setCardMap, setCardMapGlobal, setDico)
            } 
         
           
        } 
    }, [user?.id, user?.last_workspace_id, visitedUser])

    useEffect(() => {
      console.log('useEff #8 workspace?.id')
      setInt_50k()
      setText_50k()
    }, [workspace?.id])

    const searchParams = new URLSearchParams(window.location.href);
    const visited_uwp = searchParams.get("uwp")
 

    const currentDeck = decks?.find(d => d.id === activeDeck)
 
   

    const [masteredCards, setMasteredCards] = useState()
    // const [filteredCards, setFilteredCards] = useState()
    const [deckData, setDeckData] = useState()
    const [lockerScreen, setLockerScreen] = useState()
    const [todaysEvents, setTodaysEvents] = useState()
    const [myEventNoRead, setmyEventNoRead] = useState()

  

    const getUserWorkspaceElements = async (uwp) => {
      console.log('getUserWorkspaceElements 🟢🟢🟢', uwp)

      // const fakeuserDeco = []

      // 70 par 70 taille dezom max // aucun problème si 5 élements par cases i
      // 100 par 100 fluide
      // 140 par 140 pas très fluide
      
      // for (let i = 0; i <= 50; ++i) {
      //   for (let y = 0; y <= 50; ++y) {
      //     fakeuserDeco.push({x: i, y: y, name: images[100+i+y]?.name })
      //   }
      // }

     


      const q = query(collection(db, 'user_workspace_element'), where('uwp', "==", uwp))
      const querySnapshot = await getDocs(q);
      const _objs = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
      console.log('getUserWorkspaceElements _objs', _objs)

      
      setUserDecoration([..._objs || []])
      setLoadedDecoration(true)
    }



    useEffect(() => {
      
      if (visited_uwp) {
        setLoadedDecoration(false)
        console.log('go pour la visite', visited_uwp)
        getUserWorkspace(visited_uwp, visited_uwp)
      

      } else {
        console.log('pas de visite', visited_uwp)
        setVisitedUWP()
        setVisitedUser()
        userWorkspace?.id && getUserWorkspace(userWorkspace?.id)   
      }
    }, [userWorkspace?.id, visited_uwp])



    const getUserWorkspace = async (uwp, visited_uwp) => {
      console.log('------getUserWorkspace ☀️', visited_uwp)
      console.log('------getUserWorkspace ☀️', visited_uwp)
      console.log('------getUserWorkspace ☀️', visited_uwp)


      const docRef = doc(db, 'user_workspace', visited_uwp || uwp );
      const docSnap = await getDoc(docRef);
  
      const userWorkspace = {...docSnap.data(), id: docSnap.id}
      if (visited_uwp) {
        console.log('MY USER WP', userWorkspace)
        // console.log('visited_uwp', visited_uwp)
        console.log('userWorkspace 🎁🎁🎁', userWorkspace)

        setVisitedUWP(userWorkspace)
        setVisitedUser(userWorkspace)
      }
    
      getUserCards(userWorkspace.workspace_id, userWorkspace.user_id)
      getUserDecks(userWorkspace.workspace_id, userWorkspace.user_id)
      getUserWorkspaceElements(visited_uwp || uwp)
     // setUserWorkspace({...docSnap.data(), id: wp_id});
    }


    
    
    const treeDecksID = decks?.filter(d => !d.isGlobal).map(d => d.id)
    const _cards= userCards?.map(e=>e.content).filter(c => c != undefined)
    const filteredCards = workspace?.classic ? _cards : _cards?.filter(c => !c.deck_id || !treeDecksID?.includes(c.deck_id));

    useEffect(() => {
      if (!userWorkspace?.id) return 
      const _todaysEvents =  events?.map(e => mapDate(e)).filter(e => e.type != "msg").filter(e =>  e.from == userWorkspace?.id && e.date.setHours(0, 0, 0, 0) == today.getTime() )
      const _myEventNoRead = events?.filter(e => e.target_user_id== user?.id)?.filter(e => !e.read)

      setTodaysEvents(_todaysEvents)
      setmyEventNoRead(_myEventNoRead)
    }, [events, userWorkspace?.id, user?.id])

    useEffect(() => {
      if (!userCards?.length) return 
     
      const _cards = userCards?.map(e=>e.content).filter(c => c != undefined).concat(nextCards || [])
      // ajouter les prochaines cartes ici
      const _masteredCards = userCards?.filter(uc => uc.lvl > 1)
      const filteredCards = workspace?.classic ? _cards : _cards?.filter(c => !c.deck_id || !treeDecksID?.includes(c.deck_id));
     
      const _deckData = getDeckData(context, filteredCards, {nbCardToLearn: 0})

      setMasteredCards(_masteredCards)
      // setFilteredCards(_filteredCards)
      setDeckData(_deckData)
 
    }, [userCards, nextCards])


    useEffect(() => {
      const totalCards = userCards?.filter(c => !c.archived && !c.stacked)?.length
      const factor = 4
      const _lockerScreen = {
        tt: totalCards,
       
        forest: {goal: 4, access: totalCards >= 4 , unlocked: userWorkspace?.['unlock_forest']},
        leaderboard: {goal: 8, access: totalCards >= 8, unlocked: userWorkspace?.['unlock_leaderboard']},
        news: {goal: 2*factor, access: totalCards >= 2*factor, unlocked: userWorkspace?.['unlock_news']},
        chatbot: {goal: 3*factor, access: totalCards >= 3*factor, unlocked: userWorkspace?.['unlock_chatbot']},
        cultureG: {goal: 16*factor, access: totalCards >= 16*factor, unlocked: userWorkspace?.['unlock_cultureG']},
        book: {goal: 40*factor, access: totalCards >=40*factor, unlocked: userWorkspace?.['unlock_book']}, 
        radios: {goal: 28*factor, access: totalCards >=28*factor, unlocked: userWorkspace?.['unlock_radios']},
        disco: {goal: 40*factor, access: totalCards >= 40*factor, unlocked: userWorkspace?.['unlock_disco']}
      }
      setLockerScreen(_lockerScreen)

    }, [userWorkspace, userCards])



    console.log('app...')


    const ready = ((user && !isLoading) && userWorkspace && userCards )
    const [showChoose, setShowChoose] = useState(false)

    const noLogged = !user?.id && !isLoading



    useEffect(() => {
      console.log('useEff #9 !user?.last_workspace_idd')
      if (user?.id && !user?.last_workspace_id || (!userWorkspace && (user && !isLoading) && (workspace) ) ) {
        setShowChoose(true)
      } else {
        showChoose != false && setShowChoose(false)
      }

    }, [user?.id, !user?.last_workspace_id])
  

    // console.log('user', user)
    // console.log('isLoading', isLoading)
    // console.log('ready', ready)
    // console.log('user', user ? "✅" : "❌")
    // console.log('cards', cards ? "✅" : "❌")
    // console.log('userWorkspace', userWorkspace ? "✅" : "❌")
    // console.log('userCards', userCards ? "✅" : "❌")
    // console.log('workspace', workspace ? "✅" : "❌")
    // console.log('workspace', workspace)



   
 

  return (
    <Router>
         <Routes>
            <Route path="/test" element={<TestScreen loadNextCards={loadNextCards} context={context}/>} />
          
            <Route path="/landing" element={<LandingScreen context={context} />} />
            <Route path="/pareto" element={<Pareto context={context} dico={dico}/>} />           
            <Route path="/publicDeck" element={<TestScreen context={context}/>} />
            <Route path="/classScreen" element={<ClassScreen context={context}/>} />
            <Route path="/choose" element={ <ChooseWP user={user} showWorkspaces={showWorkspaces} workspace={workspace} workspaces={workspaces} userWorkspaces={userWorkspaces} changeWorkspace={changeWorkspace}  setShowWorkspaces={setShowWorkspaces}  setUser={setUser} setWorkspace={setWorkspace} setUserWorkspaces={setUserWorkspaces} setUserWorkspace={setUserWorkspace} updateUser={updateUser} />} />
            {(user && ready) ?
            <>
                <Route path="/leaderBoard" element={<LeaderScreen lockerScreen={lockerScreen} context={context}/>} />
                <Route path="/forest" element={<ForestScreen deckData={deckData} filteredCards={filteredCards} masteredCards={masteredCards} myEventNoRead={myEventNoRead} todaysEvents={todaysEvents} userDecoration={userDecoration} setUserDecoration={setUserDecoration} setShowBug={setShowBug} lockerScreen={lockerScreen} getUserCards={getUserCards} loadCards={loadCards} getUserDecks={getUserDecks}  deckScreen={<DeckScreen />} context={context}/>} />
                <Route path="/forest_mini" element={<ForestMini 
                  userDecoration={userDecoration} 
                  setUserDecoration={setUserDecoration} 
                  context={context}
                  setAlerts={setAlerts}
                  decks={[]}
                  isLearning={false}
                  selectedDeco={{}} // ??
                  userWorkspace={{}}
                  userCards={[]}
                  user={{}}
                  globalDecks={[]}
                  isVisiting={false}
                  />} />

                <Route path="/home" element={<HomeScreen lockerScreen={lockerScreen}  context={context}/>} />
                <Route path="/me" element={<UserScreen context={context} user={user}/>} />
                <Route path="/goal" element={<GoalScreen context={context} user={user}/>} />
                <Route path="/deck" element={<DeckScreen context={context}/>} />
                <Route path="/chat" element={<ChatScreen context={context}/>} />
                <Route path="/adminSchool" element={<AdminSchool ct={context} />} />
                <Route path="/manager" element={<ManagerScreen context={context}/>} />
                <Route path="/notifications" element={<NotificationScreen context={context}/>} />
                <Route path="/*" element={<HomeScreen lockerScreen={lockerScreen} context={context}/>} />
            </> : 
            showChoose ? 
            <Route path="/*" element={<ChooseWP user={user} showWorkspaces={showWorkspaces} setUserWorkspaces={setUserWorkspaces}  workspace={workspace} workspaces={workspaces} userWorkspaces={userWorkspaces} changeWorkspace={changeWorkspace}  setShowWorkspaces={setShowWorkspaces}  setUser={setUser} setWorkspace={setWorkspace} setUserWorkspace={setUserWorkspace} updateUser={updateUser} />}/>
            : 
            noLogged ? <Route path="/*" element={<LandingScreen user={user} context={context}/>} /> : <Route path="/*" element={<div>.. . </div>} /> 
            }
            {(user?.admin ||  window.location.hostname === 'localhost') && <Route path="/admin" element={<AdminScreen context={context}/>} />}
            {/* <Route path="/editor" element={<CodeView user={user} setCurrentPage={setActiveScreen} context={context}/>} /> */}
            <Route path="/verification" element={<VerificationScreen ct={context} />} />
            
            <Route path="/quizzCreator" element={<QuizzCreator ct={context} />} />
            <Route path="/teacher" element={<TeacherScreen ct={context} />} />
            <Route path="/addFact" element={<AddFact ct={context} />} />
         </Routes>

         {alerts && <Alert alerts={alerts} setAlerts={setAlerts}/>}


         {!ready && !noLogged && !showChoose && activeScreen != "admin" &&  <div className={`z-[101] bottom-0 fixed top-0 bg-purple-50 flex items-center justify-center left-0 right-0`}>
             <div className='text-center fredoka'>
                <img src="/images/ikon.svg" className='rounded-[30px] boundEffet p-2 h-[96px]  mx-auto mb-8'/>
                 <h2 className='text-[#6D55B7] fredoka text-4xl'>Mind<span className='text-[#55B9A2]'>Seed</span></h2>
              
                 <div className='text-slate-300 text-xs mt-2'>
                  <div className='flex gap-1 items-center justify-center'>
                    <div className={`rounded-full h-1 w-1 ${user ? "bg-green-500" : "bg-slate-200"} `}></div>
                    <div className={`rounded-full h-1 w-1 ${cards ? "bg-green-500" : "bg-slate-200"} `}></div>
                    <div className={`rounded-full h-1 w-1 ${userWorkspace ? "bg-green-500" : "bg-slate-200"} `}></div>
                    <div className={`rounded-full h-1 w-1 ${userWorkspaces?.length > 0 ? "bg-green-500" : "bg-slate-200"} `}></div>
                    <div className={`rounded-full h-1 w-1 ${userCards ? "bg-green-500" : "bg-slate-200"} `}></div>
                    <div className={`rounded-full h-1 w-1 ${workspace ? "bg-green-500" : "bg-slate-200"} `}></div>
                    </div>
                    <div className='text-slate-300 text-xs mt-4'>v 1.2.4</div>
                    {user?.email == "deviennepro@gmail.com" && <div className='text-green-500 p-2 max-h-[300px] overflow-scroll text-white text-xs'>
                      user_id: {user.id}<br/>
                     
                      user_workspace_id: {user.user_workspace_id}
                      last_workspace_id: {user.last_workspace_id}
                      userWorkspaces {userWorkspaces?.length}
                      {userWorkspaces?.map(e => e.workspace_id)}

                      <br/>
                      </div>}
                    {user && chronosStart && user?.email != "deviennepro@gmail.com"  && <div onClick={() => handleSignOut(auth, () => setUser())} className='text-slate-300 text-xs mt-4'>Se déconnecter</div>}
                    
                 </div>
             </div>
            </div>}

          {/* <div className='fixed top-0 left-0 right-0 bottom-0'><TestScreen loadNextCards={loadNextCards} context={context}/></div> */}


          


          {ready && <>
             {showTutoBook && <TutoBook setShowTuto={setShowTuto} showTutoBook={showTutoBook} setShowTutoBook={setShowTutoBook} context={context}/>}
             {showTuto && <IntroTuto showTuto={showTuto} setShowTuto={setShowTuto} /> }
               
             {showBottomBar && user && <div 
              // style={{paddingBottom: pb ? "24px" : "12px"}} 
              className={`flex fixed bottom-0 z-50 border-t-2 items-center w-full justify-center  ${activeScreen == "forest" ? "menu-gradient text-[#FFE68B] border-[#4E4433]" : "bg-purple-800 border-[#331675] text-purple-300"}`}>
                  <Link to={"/home"+versionExt} className={`${activeScreen == "home" ? "bg-[#8e25e5]" : ""}   grow flex pb-safe py-2  h-full justify-center items-center transition `}>
                  <img className={`h-[36px] -mb-2 pt-1 transition ${activeScreen == "home" ? "scale-[1.3]" : "grayscale-[0.8] opacity-[0.8]"}`} src="/images/logo-card.png" />
                  </Link>
                
                  <Link to={"/forest"+versionExt} className={`${activeScreen == "forest" ? "bg-white/20" : ""} grow flex pb-safe py-2  h-full justify-center items-center transition `}>
                
                  <div className='relative'>
                    <img className={`h-[36px] -mb-2 pt-1 transition ${activeScreen == "forest" ? "scale-[1.3]" : "grayscale-[0.8] opacity-[0.8]"}`} src="/images/logo-house.png" />
                  </div>
                  </Link>

                  <Link to={"/leaderboard"} className={`${activeScreen == "leaderboard" ? "bg-[#8e25e5]" : ""} grow flex pb-safe py-2  h-full justify-center items-center transition `}>
                  <div className='relative'><img className={`h-[36px] -mb-2 pt-1 transition ${activeScreen == "leaderboard" ? "scale-[1.3]" : "grayscale-[0.8] opacity-[0.8]"}`} src="/images/logo-trophee.png" />
                      {lockerScreen.leaderboard.access && !lockerScreen.leaderboard.unlocked && <RedDot className="absolute heartbeat-xs -top-0 -right-0 "/>}
                      </div>
                  </Link>

              </div> }

            
              {user && !user.newVersion && <div className="fixed z-[999] top-0 backdrop-blur-xl bottom-0 left-0 right-0 flex items-center justify-center">
                  <div className="bg-black/60 p-8 relative z-[999] fredoka text-white border-2 border-black/20 rounded-xl">
                    <div>La version 2.0 est disponible !</div>
                    <div onClick={async () => {
                      await updateUser({...user, newVersion: true})
                      setUser({...user, newVersion: true})

                      setTimeout(() => {
                        toggleVersion()
                      }, 500)

                    

                      }} className="flex mt-8 justify-center"><div className="bg-purple-500 p-2 px-4 inline-block mx-auto text-center rounded-xl btn-game game-btn my-2 text-white border-2 border-b-4 border-black">Découvrir</div></div>

                      <div></div>
                  </div>
                </div>}
              {showPop == "dailyMission" && <DailyMission context={context}/> }
              {showPop == "mailbox" && <MailBox context={context} /> }
              {showPop == "pareto" && <Pareto  darkmode={true} context={context} /> }
              {showPop == "recapSession" && statusCards?.filter(c => !c.deck_id)?.length && <RecapSession context={context}/> }
              {currentDeck ? currentDeck.presentation_mode == "Dialogue" ? <LearnDialog context={context}/> : <LearnMindCard  context={context} /> : <div></div>}
              {showCards?.length ? <LearnContainer deckData={deckData} trigger_index={triggerIndex} context={context} /> : <div></div>}
              {showDeckId && <DeckScreen context={context} deck_id={showDeckId}/>}
              {isBlocked && <BlockedScreen setUser={setUser} user={user} classes={classes} userWorkspace={userWorkspace} setUserWorkspace={setUserWorkspace} setIsBlocked={setIsBlocked}/>}
              {activeScreen != "admin" &&  chronosStart && user && userWorkspace && <Chronos ct={context} type={"general"} />}
              {magnifier && <Magnifier center={magnifier.center} top={magnifier.top} context={context} />}
              {fastCards && <FastCardReview validCard={validCard} setFastCards={setFastCards} fastCards={fastCards} context={context}/>}
              {showModalTraining && <ModalTraining setShowModalTraining={setShowModalTraining} tests={tests} userTests={userTests} />}
              {showModalWords && <ModalWordLearned filteredCards={filteredCards} setShowModalWords={setShowModalWords}  userCards={userCards} cards={cards} setMagnifier={setMagnifier}/>}
              {showBug && <BugRepport setShowBug={setShowBug} context={context} />}
              {showInfo && <ShowInfoPop context={context} />}
              {selectedQuiz && <div className='fixed top-0 left-0 bottom-0 right-0 bg-amber-50 z-[50]'><QuizzReader fetch={true} mode={selectedQuiz.mode} test={selectedQuiz} ct={context}/></div>}
              {user && (!user.fcmToken && user.coach == null) && userWorkspace?.goal_id && <div className='fixed top-0 left-0 right-0 bottom-0 z-[60]'><NotificationScreen context={context} /></div>}
              {showMenu && <MainMenu user={user} setShowInfo={setShowInfo} setShowMenu={setShowMenu} setShowWorkspaces={setShowWorkspaces} setShowTutoBook={setShowTutoBook} setShowBug={setShowBug}/>}
          </>}

       
       
       

     
   
    </Router>
  );
} 

export default App;