import { useEffect, useState } from "react"
import { categories, evolutionsQuest, images, nToStars } from "../../utilities/helper/helper"
import { useStateContext } from "../StateProvider"
import { capitalizeFirstLetter } from "../helperFunctions"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { sbUpdate } from "../DbFunction";


const AlchimieScreen2 = ({setShowModalForest}) => {

return (
  <div  className={`fredoka text-center p-8 bg-gradient-to-b from-indigo-800  to-indigo-900 border-indigo-600 z-[40]  transition fixed bottom-[50px] top-0 left-0 right-0 `} >
      


      <div onClick={() => {setShowModalForest()}}   className="absolute top-4 right-4 text-white z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700">
     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
       <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
     </svg>
   </div>


      <div className="text-2xl pt-12">L'alchimie</div>
      
      <div className="description">
        <p>
          Débloque des nouvelles évolutions pour tes graines.
         
        </p> 
        <p className="text-white/80">Organise mieux ton île en classant les cartes par thématique et couleurs.</p>
      </div>

      <div className="coming-soon mt-8">
        <img 
          src="/images/UX/time_logo.png" 
          alt="Time logo" 
          className="h-20 mx-auto" 
        />
        <p>Bientôt disponible</p>
      </div>
    </div>
  );
};



const evolutions = [
  {name: "Fleur", n: 4, lvl: 1, pc: true,  eName: "seed#1_@@@_2_mastered", element: "/images/seeds/@@@-3.png"},
  {name: "Orchidée", n: 8, lvl: 2, eName: "orchidax-@@@", element: "/images/seeds/evo/orchidax-@@@.png", recipe: ["Fleur", "Fleur"]},
  {name: "Champignon", n: 16, lvl: 3, eName: "champ-@@@", element: "/images/seeds/evo/champ_@@@.png", recipe: ["Orchidée", "Orchidée"]},
  {name: "Jardin", n: 32, lvl: 4, eName: "forest-@@@", element: "/images/seeds/evo/forest-@@@.png", recipe: ["Champignon", "Champignon"]}
  ]


const MiniCard = ({term}) => <div className="text-xs text-center pb-1">
<img src="/images/seeds/green-3.png" className={` ${term ? "-mt-4 -mb-1" : " -m-2 opacity-30 filter-grayscale"} h-[50px]   mx-auto`} />
<div className={`${
  term > 7
    ? term > 10
      ? 'text-[9px]'
      : 'text-[10px]'
    : ''
} overflow-hidden text-ellipsis whitespace-nowrap`}>
  {capitalizeFirstLetter(term)}
</div>
</div>

const PackedSeedImg = ({selectedCategories, className}) => {

  return <div className={className+" relative pointer-events-none w-[45px] h-[45px] mx-auto"}>
  <img className="absolute -top-8 bottom-2 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />
  <img className="absolute -top-4 -left-4 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />
  <img className="absolute -top-4 -right-4 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />
  <img className="absolute top-0 bottom-2 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />

</div>
}

const renderInventoryItems = (evo, selectedCategories, userDecorationWithElement, evolutions) => {
  const cards = evo?.cards || [];
  const ingredients = evo?.ingredients || [];
  const recipe = evo.recipe
  // console.log('evo', evo)
  // Ensure at least 4 items
  let items = [...(cards || [])];
  if (ingredients.length) {items = [...(ingredients || [])];}
  while (items.length < 4) {
    items.push({ card: { term: '' } });
  }

  // console.log('userDecoration', userDecorationWithElement.map(ud => ud.id))
  // console.log('items', items)
  const imagesName = items.map((item) => userDecorationWithElement.find(ud => ud.id == item && !ud.name.includes('seed'))?.element?.src || "seed")
  // console.log('imagesName', imagesName)

  if (!ingredients.length) {

    if (recipe) {
     
      return items.map((item, index) => {
        const currentEvo = evolutions.find(evo => evo.name == recipe?.[index])
        return (
        <div key={index} className="e-inventaire bg-[#422A94] h-[50px] rounded-md">
          {currentEvo?.name == "Fleur" ?   <PackedSeedImg className={"opacity-50 scale-[0.8]"} selectedCategories={selectedCategories} />  : <img className="opacity-50" src={currentEvo?.element.replace('@@@', selectedCategories.color_seed)}/>}
        </div>
      )});
    }
   
    return items.map((item, index) => (
      <div key={index} className="e-inventaire bg-[#422A94] h-[50px] rounded-md">
        <MiniCard term={item?.card?.term} />
      </div>
    ));
  }
   // <PackedSeedImg selectedCategories={selectedCategories} />  
  return items.map((item, index) => (
    <div key={index} className="e-inventaire bg-[#422A94] h-[50px] rounded-md">
     {(ingredients && !item.card) ?  
  ( 
    imagesName?.[index] != "seed" ? 
    <img src={imagesName?.[index]} className="h-[60px] mx-auto" /> :
      <PackedSeedImg selectedCategories={selectedCategories} /> 
      
  ) : 
  (
    <MiniCard term={item?.card?.term} />
  )
}
    </div>
  ));
};


  const AlchimieScreen = ({
    setShowModalForest,
    setEditDeco,
    userDecoration,
    setUserDecoration,
    setSelectedDeco,
  })=> {

      const [selectedEvo, setSelectedEvo] = useState()
      const {profileWorkspace, setProfileWorkspace, setAlert, learnedCards, setLearnedCards} = useStateContext()
      const selectedCategories = categories?.[selectedEvo?.bc_id]
      const cardsData = learnedCards.map(e => ({...e.profile_card, card: e.card})).filter(e => !e.archived)
      const currentCategoryCards = cardsData?.filter( c=> c.card?.bc_id == selectedEvo?.bc_id)
      const currentCategoryFreeCards = currentCategoryCards?.filter(c=> !userDecoration?.find(ud => ud.id == c.ud_id) ) // a adapter avec evo
      const currentBCcards = learnedCards?.filter(e => !e.profile_card?.archived).filter(e => e.profile_card?.bc == selectedEvo?.bc_id && e.profile_card?.ud_id)

      const decoName = selectedEvo?.eName?.replace("@@@", selectedCategories.color_seed)
      const coloredPackedSeed = userDecoration?.filter(e => e.bc == selectedEvo?.bc_id).map(e => ({...e, element: images?.find(i => i.name == e.name)})).slice(0, Math.floor(currentBCcards?.length/4))

      const userDecorationWithElement = userDecoration?.filter(e => e.type == "evo").map(e => ({...e, element: images?.find(i => i.name == e.name)})).filter(e => e.element?.bc_id == selectedEvo?.bc_id).concat(coloredPackedSeed)

      const seedMode = selectedEvo?.name == "Fleur"

      const currentBCcardsFree = learnedCards?.filter(e => !e.profile_card?.archived).filter(e => e.profile_card?.bc == selectedEvo?.bc_id && !e.profile_card?.ud_id)

      // console.log('-chimie', currentBCcards)
      console.log('coloredPackedSeed alchimie', {coloredPackedSeed, currentBCcards, userDecorationWithElement, floor: Math.floor(currentBCcards?.length/4)})
  
      useEffect(() => {
      
        seedMode && setSelectedEvo(ev => ({...ev, cards: currentCategoryFreeCards?.filter(c => c.card?.bc_id == selectedEvo?.bc_id && !c.ud_id)?.slice(0, 4) || [] }))
      }, [seedMode, selectedEvo?.eName, userDecoration])
  
  
      const nbEvo = categories?.map((cat, i) => evolutions?.filter(ev => ev.lvl <= profileWorkspace?.options?.["bc_"+i+"_lvl"]) )?.flat()?.length || 0
  
  
  return <div className="h-screen pb-20 text-left fredoka text-white fixed top-0 bottom-0 z-[100] left-0 right-0 bg-[#433182]">
    
    {!selectedEvo && <div>
      
    <div className="p-4  bg-[#58459d] items-center justify-between flex gap-2">
      <img src="/images/deco/alchimie_logo.png" className="h-[40px] -ml-2 mx-auto" />
  
      <div className="mb-1 grow text-center text-2xl">Alchimie</div>
       
  
      <div onClick={(e) => {    setShowModalForest
(false); console.log('test')}}  className=""><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8  bg-red-500 rounded">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
    </div>  
     
    </div>
    </div>}
    <div className="p-4 overflow-scroll pb-[200px] max-h-screen ">
  
      <div className="p-2 px-4 text-sm bg-[#513D9A] my-4 rounded-xl">Donne de la <span className="text-[#FFC700]">couleur</span> à tes souvenirs en fonction de leur <span className="text-[#FFC700]">thématique</span>, et  <span className="text-[#FFC700]">groupe</span> les ensemble pour mieux organiser tes connaissance</div>
  
    
      
  
  
      {selectedEvo && <div className="bg-[#412B8E] z-[10] max-h-screen  overflow-scroll fixed bg-[#433182] top-0 bottom-0 right-0 left-0 ">
      
        <div className=" px-4 py-2  text-center">
          <div onClick={() => setSelectedEvo()}  className="my-2 text-left flex items-center gap-2" > <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
  </svg>
   Retour </div>
   
          <div className="flex justify-end -mt-2 items-center text-right gap-4">
            <div>
              <div className="text text-shadow font-sembold "><span style={{borderColor: selectedCategories?.color}} className="inline-block ">{categories?.[selectedEvo.bc_id].evo_name_fr[selectedEvo.lvl -1]}</span></div>
              <div className="text-sm text-white/60">Fusionne 8 souvenirs de la même thématique</div>
            </div>
            <div className="relative mt-4">
                <svg className="h-[80px] absolute -bottom-8" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="41" cy="39" r="37" fill="#573ABB" stroke="#2C196E" stroke-width="4"/>
                    <path d="M33.7129 73.7031L13.1401 79.6635L9.99392 56.6617" fill="#573ABB"/>
                    <path d="M33.7129 73.7031L13.1401 79.6635L9.99392 56.6617" stroke="#2C196E" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
  
              <div><img src={selectedEvo?.element.replace('@@@', selectedCategories.color_seed)} className="h-[100px] -mb-4 relative"/></div>
            </div>
          </div>
          {/* <div>Fais évoluer tes graines neutre en graine de <span style={{color: selectedCategories.color}}>{categories?.[selectedEvo.bc_id].name_fr}</span></div> */}
        </div>
        {/* <div>Selected Evo : {selectedEvo?.id}</div> */}
        <main className="">
        <div>
          {/* <img src="/images/deco/alchimie_logo.png" className="h-[160px] -mt-16 mx-auto" /> */}
        </div>
        <div className="flex  justify-center gap-4 items-center">
          <div className="bg-[#2C196E] p-1 rounded-xl -mr-4">
            <div className="p-1 rounded-xl text-sm text-center">Ma selection</div>
            <div className="bg-[#573ABB] gap-1 rounded-xl w-[120px] p-1 grid-cols-2 grid">
              {renderInventoryItems(selectedEvo, selectedCategories, userDecorationWithElement, evolutions)}
              {/* {selectedEvo?.ingredients?.map(e => <div>{
                <PackedSeedImg evo={evolutions[0]} selectedCategories={selectedCategories} />
              
              }</div>)} */}
  
            </div>
          </div>
          {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="h-8 text-[#9279EB]">
    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
  </svg> */}
  <svg width="66" height="55" viewBox="0 0 66 55" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M52.7552 44.6068L46.0912 18.2816L41.2912 31.4632L26.5639 35.696L52.7552 44.6068Z" fill="#231163"/>
  <path d="M9.46106 13.8857C14.4497 12.2037 35.9432 19.1545 45.4856 29.3858L36.0648 35.7833C31.1974 23.1085 7.86703 14.5266 6.13524 16.3042C6.13524 16.3042 4.47241 15.5676 9.46106 13.8857Z" fill="#231163"/>
  </svg>
  
  
  <div className="relative -ml-6 mt-6 ">
      <CircularProgressbar
                              value={Math.ceil(selectedEvo.cards?.length*100/selectedEvo?.n) || 0}
                              className='absolute circularProgress !scale-x-[2.4] bg-[#2C196E] rounded-full left-[30px] bottom-[-30px]  transition w-[80px] !h-[80px]'
                              styles={buildStyles({
                                strokeLinecap: 'butt',
                                strokeWidth: 2,
                                textSize: '0px',
                                pathColor: "#FFC700",
                                textColor: 'red',
                                pathTransitionDuration: 0.5,
                                strokeLinecap: 'round', 
                                trailColor: '#573ABB',
                                backgroundColor: 'yellow',
                                transform: 'rotateX(37deg)'
                              })}
                              strokeWidth={8}
  
                      />
  <img src="/images/UX/alchimie_logo.png" className="h-[160px] chaudron relative z-[10] mx-auto" />
  
                      </div>
        
        </div>
  
        <div className="bg-[#573ABB] border-t-4 border-black/70 mt-[70px] p-3 pb-[120px]  overflow-hidden p-1 ">
          <div className="">
  
            <div onClick={() => {
              console.log('__alchimie cardsData', cardsData)
            }} className="text-xs mb-2 text-white/70">{!seedMode ? "" :"Graines neutres >"} <span className=" border-b-[1.5px]" style={{borderColor: selectedCategories.color}}>{categories?.[selectedEvo.bc_id].name_fr}</span></div>
            </div>
            {/* <div className="text-[10px]">{selectedEvo?.cards?.map(e => <span>{e.card_id } </span>)}</div> */}
       
            
        <div className="text-xs bg-[#573ABB]/0 overflow-scroll justify-center rounded mt-2 grid gap-[4px] grid-cols-[repeat(auto-fit,_minmax(50px,_1fr))]">
     
           
          {!seedMode && userDecorationWithElement?.map(pc => <div
           onClick={() => {
            // evolutions

            const currentEvolutionNb = evolutions?.find(e => e.eName?.split('-')[0] == pc.name.split('-')[0])?.n || 4 
            let cards = cardsData?.filter(uc => uc.bc == pc.bc && !uc.evo_name && !selectedEvo?.cards?.find(c => c.card_id == uc.card_id))?.slice(0, currentEvolutionNb).map(e => ({...e, card: e.content})) || []
            if (selectedEvo?.name == "Fleur") {
              cards = cardsData?.filter(uc => uc.bc == pc.bc).slice().map(e => ({...e, card: e.content})) || []
            }
            console.log('__alchimie pc', pc)
            console.log('__alchimie cardsData', cardsData)
            console.log('__alchimie selectedEvo', selectedEvo)
            selectedEvo?.ingredients?.find(i => i == pc.id) ?   
              setSelectedEvo(ev => ({...ev, 
                cards: ev.cards.slice(0,-currentEvolutionNb)  || [], 
                ingredients: ev.ingredients.filter(i => i != pc.id)})) 
              : setSelectedEvo(ev => ({...ev, 
                cards: [...ev.cards || [], ...cards], 
                ingredients: [...ev.ingredients || [], pc.id]})) 
          }}
           className={`${ selectedEvo?.ingredients?.find(i => i == pc.id) ? "border-yellow-500" : ""} relative h-[55px] text-xs mb-1 pointer-events-auto bg-[#422A94] text-center rounded border-2 border-black/20`}>
            
            {pc.name.includes('bourse_l') ? <PackedSeedImg evo={evolutions[0]} selectedCategories={selectedCategories} /> : <img src={pc?.element.src} className="h-[60px] mx-auto" />}
              </div>)}
          
          {!seedMode && Array.from({ length: 10 - userDecorationWithElement.length }, (_, index) => (<div className="bg-[#422A94]/40 h-[55px] rounded"></div>))}
         
          {
        seedMode && currentCategoryFreeCards?.filter(c => c.card?.bc_id == selectedEvo?.bc_id && !c.ud_id)
          .map(c => 
            <div 
              onClick={() => {
                console.log('__alchimie seedMode selectedEvo', c)
                if(selectedEvo?.cards?.map(uc => uc.card_id).includes(c.card_id)) {
                  setSelectedEvo(ev => ({...ev, cards: ev.cards?.filter(ev => ev.card_id != c.card_id)|| []}))
                } else {
                  setSelectedEvo(ev => ({...ev, cards: [...ev.cards || [], c]}))
                }}} 
              // style={{borderColor: categories?.[selectedEvo.bc_id].color}} 
              className={`${c.ud_id ? "bg-red-500/10" : ""} ${selectedEvo?.cards?.map(uc => uc.card_id).includes(c.card_id) ? "opacity-100 border-yellow-500":"opacity-70"} relative h-[55px] text-xs  bg-[#422A94] text-center rounded border-2 border-black/20`}>
                <div style={{ backgroundColor: selectedCategories.color }} className="absolute z-0 rounded  h-[4px]  opacity-[50%] left-[1px] right-[1px] bottom-[2px] "></div>
                <img src="/images/seeds/green-3.png" className="h-[50px] relative -mt-4 -mb-1 mx-auto" />
                <div className={` ${c.card.term.length > 7 ? c.card.term.length > 10 ? "text-[9px]" :  "text-[10px]" : ""} relative overflow-hidden  text-ellipsis whitespace-nowrap`}>{capitalizeFirstLetter(c.card.term)}</div>
            </div>)}
            {seedMode && Array.from({ length: 12 - currentCategoryFreeCards.length }, (_, index) => (<div className="bg-[#422A94]/40 h-[55px] rounded"></div>))}
            </div>
        </div>

      
        <div></div>
        <div className="flex fixed bottom-0 left-0 right-0 bg-[#573ABB] p-4">       
          <div onClick={() => {setShowModalForest(); setEditDeco(true)}} className="px-3 flex gap-2 items-center  rounded-xl py-2 border-2 border-black/20 bg-black/10 border-b-4">
            x {Math.floor(currentBCcards?.length/4)} 
            <div className="h-[30px] overflow-hidden w-[30px]"><img src={`/images/seeds/${categories[selectedEvo?.bc_id]?.color_seed}-3.png`} className="scale-[2.5] -ml-1 -mt-3 mb-3 object-contain pb-1 p-1 rounded-md" /></div>
            {/* <img src={'/images/UX/pelle.png'} className="h-5 my-1"/> */}

            </div>

             {/* {learnedCards?.filter(e => e.card?.bc_id == selectedEvo?.bc_id)?.length}  */}
          <div className={`${(selectedEvo?.n - selectedEvo.cards?.length) == 0 ? "scale-[1.2] bg-[#FFC700]" : "pointer-events-none grayscale-[80%] bg-black/20 opacity-[60%] "} flex gap-2 justify-between items-center border-black transition-all  text-shadow font-semibold px-4 border-b-[8px] border-2  py-2 rounded-xl mx-auto`} 
        onClick={() => {
          console.log('selectedEvo', selectedEvo)

          console.log('currentCategoryFreeCards', currentCategoryFreeCards)
          setSelectedEvo(ev => ({...ev, cards: [] }))
          setLearnedCards(prev => prev.map((e, i) => selectedEvo.cards?.map(c => c.card_id).includes(e.card.id) ? {...e, profile_card: {...e.profile_card, bc: selectedEvo?.bc_id, ud_id: selectedEvo.id, evo_name: selectedEvo?.name == "Fleur" ? null : decoName}} : e))

          if (selectedEvo?.name == "Fleur") {
            selectedEvo.cards.forEach(c => {
              console.log("yalaaa", {c: c, id: c?.id, bc: selectedEvo?.bc_id, ud_id: selectedEvo.id})
              sbUpdate('profiles_cards', c?.id, {bc: selectedEvo?.bc_id, ud_id: selectedEvo.id})
            })
            
          } else {
            // on supprime les packed_seed coloré (dans ingredients)
            // on créer une evolution dans décoration (nouvelle decoration
            // pour afficher la bonne graine :
            // si evo_id : 
            // on ajoute evo_id sur la carte :  
            // sinon cards sur la deco

            const ingredientsToDelete = selectedEvo.ingredients.map(i => userDecorationWithElement?.find(e => e.id == i))
            // on affiche les orchidé disponible dans l'inventaire 

            setUserDecoration(prev => (prev.filter(e => !selectedEvo.ingredients.includes(e.id))))
            console.log('ingredientsToDelete', {ingredientsToDelete, new_ud: userDecoration.filter(e => !selectedEvo.ingredients.includes(e.id)), userDecoration, in: selectedEvo.ingredients, userDecorationWithElement})

            // ingredientsToDelete.forEach(element => removeElement(element.x, element.y, element?.element?.id, profileWorkspace?.id))
            
          }
          // on supprime un pack_seed


          // const new_deco = {
          //   "c": 0,
          //   "name": decoName,
          //   "type": "evo",
          //   "render_card": true, // render_card
          //   "bc_id": selectedEvo?.bc_id,
          //   size: 8,
          //   cd: new Date(),
          //   "uwp": profileWorkspace?.id
          // }
         
          // setSelectedDeco(new_deco)
          // setShowModalForest(false)
          
  
  
          // on supprime les ingrédients
          // console.log('selectedEvo', selectedEvo)
          // selectedEvo?.ingredients?.forEach(i => {
          // const selectedObj = userDecoration.find(e => e.id == i)
          //   // ct.fire.remove(selectedObj.id, "user_workspace_element")
          // })
         
          // rajouter 4 cardsData avec bc_id 
          // on les decomptes des pack_seed classique 
          // pour les évolutions on evo_lvl
          // setUserDecoration((userDecoration.filter(e => !selectedEvo?.ingredients?.includes(e.id))))
  
  
          }}>Fusionner
          <img src="images/UX/magic.png" className="h-[40px] -mt-4 -mr-4" />
           </div>
          
          </div>
        </main>
      </div>}
  
      <div></div>
      <div className="flex mt-8 -mb-2"><div className="rounded-full" onClick={() => {console.log('index_Car')}}>Evolution disponible</div></div>
  
      {nbEvo == 0 ?  <div className="p-2 px-4 rounded-xl bg-black/30 border-2 border-black/30 my-2 mt-8 ">Apprends plus de mots de la même catégorie pour débloquer une recette d'évolution.</div>: ""}
  
  
      <div className=" pb-1 pt-[20px] ">
      {categories.slice(0, 12).map((c,i) => {
        const totalSeed = cardsData?.filter(c => c.card?.bc_id == i)?.length
        const totalSeedNonPlanted = cardsData?.filter(c => c.card?.bc_id == i && !c.ud_id )?.length
        const totalSeedPlanted = cardsData?.filter(c => c.card?.bc_id == i && c.ud_id )?.length
        const evoAvailable = evolutions?.filter(ev => ev.lvl <= profileWorkspace?.options?.["bc_"+i+"_lvl"]) || 0
       
  
        if (evoAvailable.length == 0) return
        
        return <div><div className="text-sm mb-1 text-white/70">{c.evo_name_fr[0]}</div><div className="grid grid-cols-4 gap-2 mb-1"> 
          {evoAvailable.map(ev => {
  
  
        const isAvailable = ev?.n == 4 ? totalSeedNonPlanted > 3 : totalSeedPlanted > (ev?.n - 1)
        const id = ev.name + "_" + i
         // si plante on vérifie juste que c'est possible qu'il y a 4 cartes
         // si orchidé on vérifie qu'il y a assez de cartes - le nb d'orchidé plantés 
         // si champi on vérifie qu'il y a assez de cartes - nb de champi 
         //borderColor: c.color+'80'
  
          return <div onClick={() => {ev.name == "Fleur" ? setSelectedEvo({...ev, id: id, bc_id: i}) : setAlert({title: "Bientôt disponible"})}} style={{color: c.text_color, }} className={`h-[70px] bg-[#291B5B] border-b-[5px] ${!isAvailable ? "opacity-50" : ""} flex  items-center border-black/60 rounded-xl border-2 p-1 w-[80px] text-xs text-center `}>
            <div>
              <div><img  src={ev.element.replace('@@@', c.color_seed)} />
              {/* {totalSeedPlanted}/{ev?.n} */}
              </div>
              {/* <div>{ev.name}</div> */}
              {/* <div>x{ev.n}</div> */}
            </div>
            </div>})
      
      }
      {Array.from({ length: 4 - evoAvailable.length }, (_, index) => (<div className="h-[70px] border-b-[5px] bg-[#392778] border-none flex items-center justify-center items-center border-black/60 rounded-xl border-2 p-1 w-[80px] text-xs text-center   "> 
      <svg className="h-10 grayscale-[50%] opacity-50 rotate-[-10deg]" viewBox="0 0 39 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.6967 9.73285C16.1664 4.71999 21.4728 1.81961 26.4857 3.28928C31.3376 4.71178 34.2106 9.72871 33.0574 14.5948L30.1727 13.7491C30.8668 10.4671 28.912 7.12689 25.6417 6.16811C22.21 5.162 18.5816 7.14517 17.5755 10.5769L16.5908 13.9355L13.712 13.0915L14.6967 9.73285Z" fill="#424242" stroke="white"/>
  <path d="M27.0289 44.0897L3.9983 37.3376C2.15214 36.7963 1.08309 34.8404 1.62435 32.9942L6.68844 15.7213C7.22969 13.8751 9.1856 12.8061 11.0317 13.3473L34.0624 20.0995C35.9085 20.6407 36.9776 22.5966 36.4363 24.4428L31.3722 41.7157C30.831 43.5619 28.8751 44.6309 27.0289 44.0897Z" fill={"#625398"} stroke="white"/>
  <path d="M18.1869 31.5978C19.7768 32.0639 21.4436 31.1529 21.9097 29.5629C22.3759 27.973 21.4649 26.3062 19.8749 25.8401C18.285 25.374 16.6182 26.285 16.1521 27.8749C15.6859 29.4649 16.597 31.1316 18.1869 31.5978Z" fill="white"/>
  <path d="M21.4299 29.4223C21.0415 30.7472 19.6525 31.5064 18.3276 31.118C17.0026 30.7295 16.2434 29.3405 16.6319 28.0156C17.0203 26.6906 18.4093 25.9315 19.7343 26.3199C21.0592 26.7084 21.8184 28.0973 21.4299 29.4223Z" stroke="white" stroke-opacity="0.32"/>
  </svg>
  </div>))}
      </div></div>}
      )}
      </div>
  
      <div className="flex mt-8 -mb-4"><div className="rounded-full" onClick={() => {console.log('index_Car')}}>Quêtes d'évolutions</div></div>
      <div className="pt-[20px]  rounded-xl">
      {categories.slice(0, 12).map((e, i) => {
      const mc = cardsData?.filter(c => c.card?.bc_id == i)?.length

      const totalCards = evolutionsQuest?.[profileWorkspace?.options?.["bc_"+i+"_lvl"] || 0]?.card
      const lvl = profileWorkspace?.options?.["bc_"+i+"_lvl"] || 0
  
      let cent = Math.ceil(mc*100/totalCards) || 0
      if (cent > 99) cent = 100
      const isMax = cent == 100 && lvl == 4
      return <div className={`border-2 flex border-black/40 bg-[#291B5B] gap-2 p-2 rounded-xl mb-2 `}>
        <div className="h-[55px] flex justify-center items-center w-[55px] bg-black/20 rounded-xl">
        <svg width="30" height="40" viewBox="0 0 74 85" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M34.3911 9.5L31.8911 17.5L50.8911 24.5L53.8911 17.5L34.3911 9.5Z" fill="#94A7C5"/>
          <path d="M30.8905 29.5L34.8905 18.5L46.8905 23.5L42.8905 33.5C71.4444 60.8744 42.7207 92.0162 17.891 82.5C-7.86941 72.6271 -7.71465 28.8995 30.8905 29.5Z" fill="#CEDFF8"/>
          <path d="M50.3921 58.4883L8.89213 43.4883C2.08907 46.87 0.349449 71.7682 19.8921 77.9883C39.5717 84.252 53.8231 63.0783 50.3921 58.4883Z" fill={e.color}/>
          <circle cx="13.3911" cy="57" r="3" fill="white" fill-opacity="0.3"/>
          <circle cx="29.3911" cy="69" r="5" fill="white" fill-opacity="0.3"/>
          <circle cx="41.3911" cy="63" r="3" fill="white" fill-opacity="0.3"/>
          <path d="M40.8911 4L38.3911 11L49.8911 15.5L52.3911 8L40.8911 4Z" fill="#7D889E"/>
          <path d="M36.8911 32.5L41.8911 21L47.3911 23L43.3911 34C71.4555 62.8139 45.5058 92.0933 14.8911 81.5C54.1669 77.4882 58.9983 58.0079 36.8911 32.5Z" fill="#C0D3F0"/>
          <path d="M16.3911 4.5C22.5231 6.44101 24.4953 4.96086 26.3911 0C24.8552 5.24785 26.6974 7.60793 31.8911 10.5C26.1539 8.0822 22.9848 10.0773 20.8911 15.5C22.7472 10.6491 21.7308 6.93732 16.3911 4.5Z" fill="#FFCC57"/>
          <path d="M16.3911 4.5C22.5231 6.44101 24.4953 4.96086 26.3911 0C24.8552 5.24785 26.6974 7.60793 31.8911 10.5C26.1539 8.0822 22.9848 10.0773 20.8911 15.5C22.7472 10.6491 21.7308 6.93732 16.3911 4.5Z" fill="#FFCC57"/>
          <path d="M11.3911 29.5C17.5231 31.441 19.4953 29.9609 21.3911 25C19.8552 30.2478 21.6974 32.6079 26.8911 35.5C21.1539 33.0822 17.9848 35.0773 15.8911 40.5C17.7472 35.6491 16.7308 31.9373 11.3911 29.5Z" fill="#FFCC57"/>
          <path d="M11.3911 29.5C17.5231 31.441 19.4953 29.9609 21.3911 25C19.8552 30.2478 21.6974 32.6079 26.8911 35.5C21.1539 33.0822 17.9848 35.0773 15.8911 40.5C17.7472 35.6491 16.7308 31.9373 11.3911 29.5Z" fill="#FFCC57"/>
          <path d="M51.3911 25.6774C60.0946 28.5576 62.8939 26.3613 65.5847 19C63.4047 26.7871 66.0194 30.2892 73.3911 34.5806C65.248 30.9929 60.7499 33.9534 57.7782 42C60.4126 34.802 58.97 29.2941 51.3911 25.6774Z" fill="#FFCC57"/>
          <path d="M51.3911 25.6774C60.0946 28.5576 62.8939 26.3613 65.5847 19C63.4047 26.7871 66.0194 30.2892 73.3911 34.5806C65.248 30.9929 60.7499 33.9534 57.7782 42C60.4126 34.802 58.97 29.2941 51.3911 25.6774Z" fill="#FFCC57"/>
        </svg>
        </div>
  
          <div className="grow">
        <div className="flex items-center gap-1">{e.name_fr} <span className="scale-[0.8]">{nToStars(profileWorkspace?.options?.["bc_"+i+"_lvl"] || 0, 4)}</span> </div>
         {/* <span className="px-2 rounded-full text-sm bg-black/20">niv. {profileWorkspace?.options?.["bc_"+i+"_lvl"] || 0} </span> */}
        {!(cent == 100 && !isMax) && <div className="flex gap-2">
        <div className={`bg-black/40 mt-2 rounded-full h-[10px] w-full`}>
          <div className="h-[10px] rounded-xl" style={{width: cent+"%", backgroundColor: e.color}}>{}</div>
        </div>
        {isMax ? <div className="text-xs mt-1 text-white/50" >{mc}</div> : <div className="text-xs mt-1 text-white/50">{mc}/{totalCards}</div>}
        </div>}
        {/* <div>{planted_seed} déjà planté</div> */}
        {cent == 100 && !isMax ? <div className="flex mt-2 justify-end"><div className="px-4 grow py-1 bg-[#6144C8] inline-block border-black/90 rounded-xl border-2 border-b-4 flex items-center justify-between gap-4 " onClick={() => {
          setProfileWorkspace(uc => ({ 
            ...uc, 
            options: {
                ...uc?.options, 
                ["bc_" + i + "_lvl"]: ((uc.options?.["bc_" + i + "_lvl"]) || 0) + 1 
            }
          }))          
          sbUpdate("profiles_workspaces", profileWorkspace?.id, {
            options: {
              ...profileWorkspace?.options, 
              ["bc_" + i + "_lvl"]: ((profileWorkspace.options?.["bc_" + i + "_lvl"]) || 0) + 1 
          }})
          // const docRef = doc(db, 'user_workspace', profileWorkspace.id);
          // updateDoc(docRef, {["bc_" + i + "_lvl"]: (profileWorkspace["bc_" + i + "_lvl"] || 0) +1})
  
          }}>
          Débloquer l'évolution
          <svg className="h-6" viewBox="0 0 39 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.6967 9.73285C16.1664 4.71999 21.4728 1.81961 26.4857 3.28928C31.3376 4.71178 34.2106 9.72871 33.0574 14.5948L30.1727 13.7491C30.8668 10.4671 28.912 7.12689 25.6417 6.16811C22.21 5.162 18.5816 7.14517 17.5755 10.5769L16.5908 13.9355L13.712 13.0915L14.6967 9.73285Z" fill="#424242" stroke="black"/>
  <path d="M27.0289 44.0897L3.9983 37.3376C2.15214 36.7963 1.08309 34.8404 1.62435 32.9942L6.68844 15.7213C7.22969 13.8751 9.1856 12.8061 11.0317 13.3473L34.0624 20.0995C35.9085 20.6407 36.9776 22.5966 36.4363 24.4428L31.3722 41.7157C30.831 43.5619 28.8751 44.6309 27.0289 44.0897Z" fill="#FB8C00" stroke="black"/>
  <path d="M18.1869 31.5978C19.7768 32.0639 21.4436 31.1529 21.9097 29.5629C22.3759 27.973 21.4649 26.3062 19.8749 25.8401C18.285 25.374 16.6182 26.285 16.1521 27.8749C15.6859 29.4649 16.597 31.1316 18.1869 31.5978Z" fill="#C76E00"/>
  <path d="M21.4299 29.4223C21.0415 30.7472 19.6525 31.5064 18.3276 31.118C17.0026 30.7295 16.2434 29.3405 16.6319 28.0156C17.0203 26.6906 18.4093 25.9315 19.7343 26.3199C21.0592 26.7084 21.8184 28.0973 21.4299 29.4223Z" stroke="black" stroke-opacity="0.32"/>
  </svg>
  
  
          </div> </div>: ""}
     
        </div>
        </div>})}</div>
    </div>
    </div>}


  export {AlchimieScreen, evolutions};