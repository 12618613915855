import { useEffect, useState } from "react"
import { useStateContext } from "../StateProvider"
import { evolveProfileCard, getCardToLearn, getTextBetweenBrackets } from "../helperFunctions"
import { mp3_valid } from "../helperData"
import { sbUpdate } from "../DbFunction"



//     let fastCards =  cardToReviewExp?.filter(c => c.user_card.lvl > 2)
const FastRefresh = ({setShowModalForest}) => {


    const {learnedCards, setLearnedCards} = useStateContext()
    const [fastCards, setFastCards] = useState([])

    useEffect(() => {
        

        const cardsToRefreshExpLvl2 = getCardToLearn(learnedCards)?.filter(c => new Date(c.profile_card?.triggers[1]?.next_date) < new Date())
        console.log('cardsToRefreshExpLvl2', cardsToRefreshExpLvl2)
        setFastCards(cardsToRefreshExpLvl2.filter(c => c.profile_card.lvl > 2))
    }, [])


    return <div id="backdrop" onClick={() => setShowModalForest()} className='no-last-time p-2  flex flex-col items-center justify-center' style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.7)", zIndex: 999}}>
    <div className='max-w-[700px]  fredoka mx-auto w-full h-screen overflow-scroll '>
     <div className='flex justify-end'><div className='top-6 right-6 text-white'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
</div></div>
     <div className='mt-6 text-xl text-center fredoka text-white'>Révision rapide</div>
     <div className='text-sm px-3 text-center text-white/80 mb-5'>Appuis sur toute les cartes qui te semble <span className='inline-block bg-indigo-100/20 rounded-xl px-2'>trop facile</span></div>

    <div className='flex flex-wrap pb-8 mb-8 gap-2 justify-center'>{fastCards?.filter(c => !c.fasted).map(e => ({...e.card, profile_card: e.profile_card})).slice(0,100).map(card => <div onClick={(e) => {
     e.stopPropagation()
     mp3_valid.play()
    //  validCard(context, card.user_card, true, 1, {fastUptade: true})
    console.log('fastCards', fastCards)

    console.log('before', card.profile_card.triggers)
    const _profile_card = evolveProfileCard({profile_card: card.profile_card, trigger: 1, valid: 1, opts: {fastUpdate: true}})
    setLearnedCards(prev => prev.map(e => e.profile_card?.id == _profile_card?.id ? {...e, profile_card: _profile_card} : e))
    
    
    sbUpdate('profiles_cards', _profile_card.id, {triggers: _profile_card.triggers})
    console.log('after', _profile_card.triggers)

     setFastCards(prev => prev.map(c => c.profile_card.id == card.profile_card.id ? {...c, fasted: true} : c))
    }} className='relative overflow-hidden bg-black/20 backdrop-blur-xl text-white rounded-xl'>
     <img className='h-[86px] w-[80px]' src={card.img_400 || card.img} />
     <div className='px-3 text-center py-1 bg-purple-900/50 backdrop-blur-xl text-white absolute bottom-0 left-0 right-0 text-xs'>{card.trad?.split(',').slice(0,1)?.join(', ') || getTextBetweenBrackets(card.context_fr) || card.more?.definitions?.[0].trad_fr?.split(',')?.slice(0,1)?.join(', ')}</div>
     </div>)}</div>
     <div className="absolute bottom-3 bg-indigo-900/90 backdrop-blur-xl left-2 right-2 overflow-hidden rounded-2xl gap-2 flex  items-center text-white justify-center p-4">
       <div>Tu as validé {fastCards.filter(c => c.fasted).length} souvenir{fastCards.filter(c => c.fasted).length > 1 ? "s":""}</div>
       <div className='px-4 py-2 items-center rounded-full text-xl flex gap-2 text-white bg-indigo-500 border-2 border-b-4 border-indigo-700'>Terminer 
</div>
       </div>
     </div>
   </div>

}


export {
    FastRefresh
}