import { useState, useRef } from 'react'
import { Editor } from '@tiptap/core'
import Underline from '@tiptap/extension-underline'
import CodeBlock from '@tiptap/extension-code-block'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import { Link } from '@tiptap/extension-link'
import {lowlight} from 'lowlight/lib/core'
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import Blockquote from '@tiptap/extension-blockquote'; 
import TableHeader from '@tiptap/extension-table-header'; // Importez l'extension TableHeader

import js from 'highlight.js/lib/languages/javascript'

lowlight.registerLanguage('javascript', js)

function TipTapEditor(props) {
const [content, setContent] = useState('')

const editor = useEditor({
  extensions: [
    StarterKit,
    Underline,
    CodeBlock,
    Link.configure({ openOnClick: true, defaultProtocol: 'https' }),
    CodeBlockLowlight.configure({
      lowlight,
      supportedLanguages: ['javascript']
    }),
    Image.configure({
      inline: true,
      levels: [1, 2, 3],
      HTMLAttributes: {
        class: 'custom-image-class'
      }
    }),
    Table.configure({
      resizable: true,
    }),
    Blockquote,
    TableRow,
    TableCell,
    TableHeader
  ],
content: props.content,
// editable: props.readOnly !== true,
onBlur: ({ editor }) => {
setContent(editor.getHTML())
console.log('Editor blur', editor.getHTML())
!props.readOnly && props.onBlur(editor.getHTML())
},
onUpdate: ({ editor }) => {
setContent(editor.getHTML())
!props.readOnly && props.onChange(editor.getHTML())
},
})

const editorRef = useRef(null)

const handleAddLink = () => {
const url = window.prompt("Entrez l'URL du lien")
if (url) {
  console.log('URL', url)
  editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
}
}

const handleAddImageUrl = () => {
  const url = window.prompt("Entrez l'URL de l'image")
  if (url) {
    editor.chain().focus().setImage({ src: url }).run()
  }
}



return (
<div className={props.className}>
{editor && (
<BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor}>
<button
onClick={() => editor.chain().focus().toggleBold().run()}
className={editor.isActive('bold') ? 'is-active' : ''}
>
B
</button>
<button
onClick={() => editor.chain().focus().toggleItalic().run()}
className={editor.isActive('italic') ? 'is-active' : ''}
>
I
</button>
<button onClick={() => editor.chain().focus().toggleBlockquote().run()}
            className={editor.isActive('blockquote') ? 'is-active' : ''}
          >
            Citation
          </button>
<button
onClick={() => editor.chain().focus().toggleUnderline().run()}
className={editor.isActive('underline') ? 'is-active' : ''}
>
U
</button>
<button
onClick={() => editor.chain().focus().toggleCodeBlock().run()}
className={editor.isActive('codeBlock') ? 'is-active' : ''}
>
Code
</button>
<button onClick={handleAddLink}>
Lien
</button>

<button onClick={handleAddImageUrl}>
Image
</button>
</BubbleMenu>
)}

  <EditorContent editor={editor} className="" />
</div>
)
}

export { TipTapEditor }