import { useEffect, useState } from "react"
import { supabase } from "../../V2/screens/testScreen"
import { useStateContext } from "../StateProvider"
import { SuperEditor } from "../../components/editor"
import { TipTapEditor } from "../TipTapEditor"
import { getCardToLearn } from "../helperFunctions"


const Grammar = () => {




    const {workspace, decks, setDecks, learnedCards, setShowModal, setCardsToRefresh} = useStateContext()

 
    const [selectedDeck, setSelectedDeck] = useState()
    const [showComingSoon, setShowComingSoon] = useState()

    const updatedCards = selectedDeck?.cards?.map(e => e.card.id == learnedCards?.find(c => c.card.id == e.card.id)?.card.id ? {...e, profile_card: learnedCards?.find(c => c.card.id == e.card.id)?.profile_card} || {} : e)
                          
    const _cardsToDiscover = updatedCards?.filter(e => !e.profile_card?.id)
    const _cardsToRefresh = getCardToLearn( updatedCards?.filter(e => e.profile_card?.id))?.slice(0, 20)
    const _cardsToLearn = [..._cardsToRefresh || [], ..._cardsToDiscover || []]



    console.log('grammar _cardsToLearn all', _cardsToLearn)
    useEffect(() =>{
        workspace?.id && getGrammarDecks(workspace?.id, setDecks)
    }, [workspace?.id])


    const getGrammarDecks = async(workspaceId, set) => {
        const { data, error } = await supabase
            .from('decks')
            .select(`
                *,
                cards:cards!deck_id(*)
            `)
            .eq('type', 'Grammar')
            .eq('workspace_id', workspaceId)
    
        if (error) {
            console.error('Error fetching grammar decks:', error)
            return { data: null, error }
        }
    
        // Transformation des données pour n'avoir qu'un seul profile_card
        const formattedData = data.map(deck => ({
            ...deck,
            cards: deck.cards.map(card => ({
                card: {
                    id: card.id,
                    // ... autres propriétés de card
                    ...card,
                   
                },
                profile_card: card.profiles_cards?.[0] || null
            }))
        }))?.sort((a, b) => (a.order || 99) - (b.order || 99));
    
        console.log('data getGrammarDecks', formattedData)
        set(formattedData)
        return { data: formattedData, error }
        
    }







    return <div className='fredoka'>

        {showComingSoon && <div className="fixed bg-yellow-50 text-slate-600 p-6 pt-[100px] top-0 left-0 right-0 z-[999] pb-[100px] max-h-screen overflow-scroll bottom-0">
               
                <div className='fixed z-[80] top-2 right-2 text-blue-900' onClick={() => setShowComingSoon()}>
                    <div class="absolute top-4 right-4 text-white z-40   bg-red-500 p-1 rounded-xl border border-b-4 border-red-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></div>
                </div>

                <div className="flex text-center  h-full flex-col justify-center">
                <div className=" text-2xl ">Bientôt disponible</div>
                <div className="text-slate-500 mt-4">Cette fonctionnalité sera bientôt disponible, reviens dans quelques jours !</div>
                </div>
        </div>
        }


        <div>
            {selectedDeck && <div className="fixed bg-yellow-50 p-6 pt-[100px] top-0 left-0 right-0 z-[79] pb-[100px] max-h-screen overflow-scroll bottom-0">
                <div className='fixed z-[80] top-2 right-2 text-blue-900' onClick={() => setSelectedDeck()}>
                    <div class="absolute top-4 right-4 text-white z-40   bg-red-500 p-1 rounded-xl border border-b-4 border-red-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></div>
                </div>
                    <div className="text-2xl font-semibold text-slate-700">{selectedDeck?.name}</div>
                    {/* <div className="text-2xl font-semibold text-slate-700">{selectedDeck?.cards?.length}</div> */}

                   
                    
                    <div className="fixed bottom-0 left-0 right-0 p-4 backdrop-blur-[1px] z-[20]">
                    <div className="justify-center flex ">
                        {_cardsToLearn.length > 0 && <div onClick={() => {
                            // setShowComingSoon(true)
                            // return
                            // const _cardsToRefresh = getCardToLearn(learnedCards)?.slice(0, 20)
                            const updatedCards = selectedDeck.cards.map(e => e.card.id == learnedCards?.find(c => c.card.id == e.card.id)?.card.id ? {...e, profile_card: learnedCards?.find(c => c.card.id == e.card.id)?.profile_card} || {} : e)
                          
                            const _cardsToDiscover = updatedCards.filter(e => !e.profile_card?.id)
                            const _cardsToRefresh = getCardToLearn( updatedCards.filter(e => e.profile_card?.id))?.slice(0, 20)

                            console.log('grammar _cardsToDiscover', _cardsToDiscover)
                            console.log('grammar _cardsToRefresh', _cardsToRefresh)
                           
                            setCardsToRefresh(_cardsToLearn);
                            setShowModal('learnCards')
                        }} className="bg-purple-500 border-2 border-b-4 border-black/70 py-2 px-4 rounded-xl">Faire les exercices <span className="text-xs bg-black/20 rounded-xl px-3 p-1">x {_cardsToLearn.length}</span></div>}
                    </div>
                    </div>
                    <TipTapEditor readOnly={true}  content={selectedDeck?.content} className={"mt-8 text-slate-600"} />
            </div>}

            {!selectedDeck && decks?.map(deck => {
            
            const updatedCards = deck.cards.map(e => e.card.id == learnedCards?.find(c => c.card.id == e.card.id)?.card.id ? {...e, profile_card: learnedCards?.find(c => c.card.id == e.card.id)?.profile_card} || {} : e)
                          
            const _cardsToDiscover = updatedCards.filter(e => !e.profile_card?.id)
            const _cardsToRefresh = getCardToLearn( updatedCards.filter(e => e.profile_card?.id))?.slice(0, 20)
            const actions = [..._cardsToRefresh || [], ..._cardsToDiscover || []]
           
            
           return  <div><div onClick={() => setSelectedDeck(deck)} className="p-4 mt-2 border-2 relative flex gap-2 items-center justify-between border-b-4 cursor-pointer border-black/50 rounded-xl" key={deck.id}>
                <div className="flex gap-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 text-indigo-200">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>
                    <div className="pb-2">
                        <div>{deck.name}</div>
                        <div className="text-xs text-white/60 absolute">{deck.cards.length - actions?.length}/ {deck.cards.length}</div>
                    </div>
                    
                </div>
               

                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </div>


            </div>
            {/* <div>
                        <div onClick={() => {console.log('deck.cards', deck.cards)}}>Exercices: {deck.cards?.length}</div>
                        {deck.cards?.map(e => e.card).map(card => {
                            if (!card) return
                            let props = [...new Set(card?.data?.options)]
                            
                            const parts = card?.term?.split("|")?.[0]?.split(/\[(.*?)\]/);
                            const correct = card?.data?.correct
                            const trad = card?.data?.trad

                            !props.includes(correct) && props.push(correct)

                        return <div className="mt-2"><div>{card.id} {parts?.map((part, index) => {
                            // Si l'index est impair, c'est le contenu entre crochets
                            // donc on le remplace par un input
                            if (index % 2 === 1) {
                                return <span className="text-pink-100">___</span>
                            }
                            // Sinon on affiche le texte tel quel
                            return <span key={index}>{part}</span>;
                        })}</div>
                        <div className="flex gap-2 text-xs">{props.map(e => <div className={`${e == correct ? "hover:text-pink-500": ""} px-2 bg-white/10 rounded-md`}>{e}</div>)}</div></div>
                        })}
                    </div> */}
            </div>})}
        </div>

    </div>

}


export {Grammar}