import { useState, useEffect } from "react";
import { db, app } from "../../config.js";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate} from 'react-router-dom';

import { collection, onSnapshot, querySnapshot, where, getDocs, addDoc, getDoc, query, updateDoc, doc, setDoc } from "firebase/firestore";

import { GoogleAuthProvider , getAuth, sendPasswordResetEmail, signInWithPopup,signInWithRedirect, getRedirectResult, signOut, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { transfertFire } from "../DbFunction.js";
import {useStateContext } from "../StateProvider.js";

const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
//const ios = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
const ios = true


function toggleVersion() {
  let currentVersion = localStorage.getItem('new_version') || 'classic';
  let newVersion = currentVersion === 'classic' ? 'v2' : 'classic';
  localStorage.setItem('new_version', newVersion);
  window.location.reload()
}

const mainBtn = "border-2  border-b-4 border-indigo-900 fredoka text-white btn-game  rounded-xl px-4 py-1 mx-auto bg-indigo-500 flex gap-2 items-center"

const LoggingScreen = ({user, setUser, setAlerts}) => {

  let pwa = "--"
  let ua = "--"
  let device = "--"
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      device = 'Mobile';
  } else {
      device = 'Desktop';
  }
    
  
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      ua = "IOS"
  } else if (/android/i.test(userAgent)) {
      ua = "android"
  } else {
      ua = "autre"
  }
  if (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
    pwa = "yes"
    // console.log("L'application est exécutée en mode standalone/PWA");
  } else {
    pwa = "false"
    // console.log("L'application est exécutée dans le navigateur");
  }
  let askPWA = (device == "Mobile" && pwa == "false") ? true : false


  const {setProfile} = useStateContext()
 
  const [uc,  setuc] = useState()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [showModal, setShowModal] = useState()
  const navigate = useNavigate()
  useEffect(() => {
    console.log("LoggingScreen loaded")
}, [])

const auth = getAuth(app);

const handleSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    console.log("result.user", result.user);

    // Vérifier si le document utilisateur existe
    const userRef = doc(db, 'users', result.user.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      // Créer un nouveau document utilisateur
      await setDoc(userRef, {
        uid: result.user.uid,
        name: result.user.displayName,
        email: result.user.email,
        photo: result.user.photoURL,
        blocked: true,
        demo: true
      });
      console.log('Nouveau document utilisateur créé');
    } else {
      console.log('Le document utilisateur existe déjà');
    }

    // Mettre à jour le contexte utilisateur
    const user = (await getDoc(userRef)).data();
    setUser(prev => ({...prev, 
      name: user.name,
      id: userRef.id,
      blocked: user.blocked,
      demo: user.demo,
      photo: user.photo
    }));

    navigate("/home");

  } catch (error) {
    console.error(error);
  }
};


const handleSignInWithEmail = async () => {
  try {
    console.log('Connexion en cours 🟣', uc);
    setLoading(true);
    const userCredential = await signInWithEmailAndPassword(auth, uc.email, uc.password);
    console.log("Utilisateur connecté :", userCredential.user);
    const usersRef = collection(db, "users");
    const _query = query(usersRef, where("email", "==", userCredential.user.email));
    const users = (await getDocs(_query)).docs.map(doc => ({
      id: doc.id,  // Ajoute l'id du document
      ...doc.data() // Spread des autres données
    }))   


    console.log('users', users)
    const user = users?.[0]
    
    


    console.log('user db',user)

    // Mettre à jour le contexte utilisateur
    setUser(prev =>({
      ...prev,
      name: user?.name || uc.email.split('@')[0],
      id: user?.id,
      blocked: user?.blocked,
      demo: user?.demo
    }));

    navigate("/home");
    try {
        localStorage?.setItem('xw8', "xw8" + uc.password);
    } catch (error) {
        console.error(error);
    }

  } catch (error) {
    console.error(error);
    setError('Mauvais identifiant ou mot de passe');
    setLoading(false);
  }
};


const handleForgotPassword = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Email sent.
      console.log('Password reset email sent!');
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // Handle the errors accordingly.
      console.error("Error sending password reset email: ", errorMessage);
    });
}


const handleSignUpWithEmail = async () => {
  try {
    if (loading) return
    setLoading(true)
    const userCredential = await createUserWithEmailAndPassword(auth, uc.email, uc.password);
    console.log("Utilisateur créé :", userCredential.user);

    // Créer le document utilisateur dans Firestore
    const userRef = doc(db, 'users', userCredential.user.uid);
    await setDoc(userRef, {
      uid: userCredential.user.uid,
      name: uc.name || uc.email.split('@')[0],
      email: uc.email.toLowerCase(),
      id: userCredential.user.uid,
      blocked: true,
      demo: true,
      creation_date: new Date(), 
    });

    // Récupérer les données de l'utilisateur depuis Firestore
    const userSnapshot = await getDoc(userRef);
    const user = userSnapshot.data();

    // Mettre à jour le contexte utilisateur
    setUser(prev => ({
      ...prev,
      name: user.name || uc.email.split('@')[0],
      id: userRef.id || userCredential.user.uid,
      uid: userCredential.user.uid,
      name: uc.name,
      email: uc.email,
      blocked: true,
      demo: true,
      creation_date: new Date(), 
    }));

    const newUser = {
        name: user.name || uc.email.split('@')[0],
        id: userRef.id,
        uid: userCredential.user.uid,
        name: uc.name,
        email: uc.email,
        blocked: true,
        demo: true,
        creation_date: new Date(), 
    }


   
    

    console.log('Données utilisateur:', newUser);
    await transfertFire(newUser, setProfile)
    setProfile(prev => ({...prev, join_code: null}))
    setLoading(false)
    try {
        localStorage?.setItem('xw8', "xw8" + uc.password);
        localStorage?.setItem('ud', userRef.id);
        
    } catch (error) {
        console.error(error);
        setLoading(false)
    }

  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      alert("L'utilisateur existe déjà !");
      setLoading(false)
    } else {
      console.error(error);
      setLoading(false)
    }
  }
};
  
  return (
    <div className=" fredoka bg-yellow-50 justify-center w-screen rounded-md relative p-16 overflow-scroll h-screen text-center">
      {<div className="fixed bottom-3 left-0 right-0 flex justify-center z-10"><a target="_blank" href="/home.html" className="px-4 py-1 rounded-full border-2 flex gap-2 border-b-4 items-center border-black/50 bg-white text-slate-800 ">En savoir plus sur le projet <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
</svg>
</a> </div>}
      <div onClick={() => {toggleVersion()}} className="text-xs text-center top-4 fixed right-0 left-0 text-slate-500/80">MindSeed 2.0</div>
      <h1 className="fredoka self-start mt-4">Connexion</h1>
      <div className="h-full flex-col flex justify-center pb-[200px]">
      {!ios && <button className={mainBtn} onClick={handleSignInWithGoogle}>Se connecter avec google {ios && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
</svg>
}</button> }
      <button className={` ${ios ? mainBtn : "text-sm text-gray-400 w-full mt-4 "}`} onClick={() => setShowModal("signup")}>Se connecter {ios ? "" : "par mail"}</button>
      </div>
      {showModal && <div id="backdrop" onClick={() => (setShowModal())} className="!flex items-center bg-purple-900 justify-center !p-8"  style={{position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "", zIndex: 999}}>
      
          
      <div onClick={(e) => e.stopPropagation()} className={`bg-white  border-2  w-full flex pb-4 flex-col justify-center items-center gap-2 mx-auto rounded-xl`} style={{maxWidth: 500}}>
        <div className="flex p-4 w-full ">
          <div className={`${showModal == 'login' ? "border-b-indigo-500 text-indigo-500" : "text-slate-500"} w-1/2 border-b-4 pb-2`} onClick={() => {setShowModal("login")}}>Déjà un compte</div>
          <div className={`${showModal == 'signup' ? "border-b-indigo-500  text-indigo-500" : "text-slate-500"} w-1/2 border-b-4 pb-2`} onClick={() => {setShowModal("signup")}}>Créer un compte</div>
        </div>
        <div className="p-2 flex flex-col gap-2">
        <input type="email" placeholder="Email" className="px-2 py-1 rounded-xl mx-auto border block w-[220px]" defaultValue={uc?.email} onBlur={(e) => setuc({...uc, email: e.currentTarget.value})} />
        {showModal != "login" && <input placeholder="Prénom Nom"className="px-2 py-1 rounded-xl mx-auto border block w-[220px]" defaultValue={uc?.name} onBlur={(e) => setuc({...uc, name: e.currentTarget.value})} />}

        <input type="password" placeholder="Mot de passe" className="px-2 py-1 rounded-xl mx-auto border block w-[220px]" defaultValue={uc?.password} onBlur={(e) => setuc({...uc, password: e.currentTarget.value})} />
        {showModal != "login" && <div className={`text-xs ${uc?.password?.length > 7 ? "text-green-500": "text-slate-500"} w-[220px] mx-auto text-justify`}>Ton mot de passe doit contenir au moins 8 caractères</div>}
        {showModal == "login" && <div onClick={() => {
          console.log('uc', uc)
          
          if (uc?.email){
            
            handleForgotPassword(uc?.email)
            setAlerts([{title: `Un email t'as été envoyé à ${uc?.email} ✅`, time: 3000}])

            } else{
              setAlerts([{title: "Ajoute ton email pour réinitialiser ton mot de passe", time: 3000}])
            }
            }} className="text-blue-500 cursor-pointer underline">J'ai oublié mon mot de passe 😅</div>}
        <div className={` ${loading ? "pointer-events-none opacity-50" : ""} mt-6 bg-indigo-500 text-white flex gap-2 justify-center gap-4 hover:bg-purple-400 rounded-xl px-4 py-2 text-center cursor-pointer inline-block `} onClick={() => {
         showModal == "login" ? handleSignInWithEmail() : handleSignUpWithEmail()
        }} style={{minWidth: 200}}>{showModal == "login" ? "Se connecter" : "Créer mon compte"} {loading ? <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg> : " "}</div>
      {error ? <div className="text-pink-500 text-center py-2">{error}</div> : ""}
      </div>
      </div>

    </div>}



    

    <div className="fixed bottom-0 left-0 right-0">
      <img src="images/UX/fun_signup.png" className="mx-auto" />
    </div>
    </div>
  );
};

export {LoggingScreen};