import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import {Howl, Howler} from 'howler';
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../config";
import { boosters } from "./helperData";

import { pinyin } from 'pinyin-pro';
import { supabase } from "../V2/screens/testScreen";




const transformDatesToJS = (obj) => {
    const newObj = { ...obj };
    for (let key in newObj) {
        if (typeof newObj[key] === 'string' && 
            /^\d{4}-\d{2}-\d{2}/.test(newObj[key])) { // vérifie si ça commence par YYYY-MM-DD
            const date = new Date(newObj[key]);
            if (date instanceof Date && !isNaN(date)) { // vérifie si c'est une date valide
                newObj[key] = date;
            }
        }
    }
    return newObj;
};

function getTextBetweenBrackets(text) {
  // Utilise une expression régulière pour trouver le contenu entre les premiers []
  const match = text?.match(/\[(.*?)\]/);
  
  // Si un match est trouvé, retourne le contenu entre les crochets
  // Sinon retourne null ou une chaîne vide selon le besoin
  return match ? match[1] : null;
}

function getWeekNumberAndYear(date) {
  // Copier la date pour ne pas modifier l'original
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  // Obtenir le jour de la semaine (1-7), avec 1 pour lundi et 7 pour dimanche
  const dayNum = d.getUTCDay() || 7;

  // Ajuster la date au jeudi de la même semaine (ISO 8601)
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);

  // Obtenir l'année de la semaine
  const year = d.getUTCFullYear();

  // Obtenir le premier jour de la première semaine de l'année (lundi)
  const yearStart = new Date(Date.UTC(year, 0, 1));

  // Calculer le numéro de semaine
  const weekNumber = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);

  return `${year.toString().slice(2,4)}-${weekNumber.toString().padStart(2, '0')}`;
}

function hasWordInBrackets(phrase) {
    // Utilise une expression régulière pour trouver un mot entre []
    const regex = /\[([^\]]+)\]/;
    
    // Test si la regex trouve une correspondance dans la phrase
    return regex?.test(phrase);
}

function extractFirstBracketContent(str) {
  const match = str?.match(/\[([^\]]+)\]/);
  return match ? match[1] : null;
}

function comparerSansAccents(mot1, mot2) {
  // Normaliser les chaînes et retirer les accents
  const normalize = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

  // Appliquer la normalisation à chaque mot
  const mot1Normalise = normalize(mot1);
  const mot2Normalise = normalize(mot2);

  // Comparer les versions normalisées des mots
  return mot1Normalise === mot2Normalise;
}



const levenshteinDistance = (str1, str2) => {
  const m = str1.length;
  const n = str2.length;
  const dp = Array(m + 1).fill().map(() => Array(n + 1).fill(0));

  for (let i = 0; i <= m; i++) dp[i][0] = i;
  for (let j = 0; j <= n; j++) dp[0][j] = j;

  for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
          if (str1[i - 1] === str2[j - 1]) {
              dp[i][j] = dp[i - 1][j - 1];
          } else {
              dp[i][j] = 1 + Math.min(
                  dp[i - 1][j],     // suppression
                  dp[i][j - 1],     // insertion
                  dp[i - 1][j - 1]  // substitution
              );
          }
      }
  }
  return dp[m][n];
};

const brackSentence = ({sentence, word}) => {
  if (!sentence || !word) return sentence;
  
  const originalResult = brackSentenceWithRegex({sentence, word});
  
  // Si le mot exact a été trouvé, retourner le résultat original
  if (originalResult !== sentence) {
      return originalResult;
  }
  
  // Si le mot exact n'a pas été trouvé, chercher le mot le plus proche
  const words = sentence.split(/\s+/);
  let closestWord = '';
  let minDistance = Infinity;
  const threshold = Math.ceil(word.length * 0.4); // Seuil de similarité ajustable
  
  words.forEach(currentWord => {
      // Nettoyer le mot de la ponctuation
      const cleanWord = currentWord.replace(/[.,!?;:]*/g, '');
      const distance = levenshteinDistance(word.toLowerCase(), cleanWord.toLowerCase());
      
      if (distance < minDistance && distance <= threshold) {
          minDistance = distance;
          closestWord = currentWord;
      }
  });
  
  if (closestWord) {
      // Utiliser brackSentence avec le mot trouvé
      return brackSentence({sentence, word: closestWord});
  }
  
  return sentence;
};
const brackSentenceWithRegex = ({sentence, word}) => {
  if (!sentence || !word) return sentence;
  
  // Échappe les caractères spéciaux de regex dans le mot recherché
  const escapedWord = word.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  
  // Regex qui capture le mot avec potentiellement de la ponctuation après
  const regex = new RegExp(`([^\\s]*)(${escapedWord})([.,!?;:]*)`, 'gi');
  
  // Remplace en conservant la ponctuation
  return sentence.replace(regex, (match, prefix, targetWord, punctuation) => {
      return `${prefix}[${targetWord}]${punctuation}`;
  });
}







const transformDates = (data) => {
  if (!data) return data;
  
  const isObject = (value) => value && typeof value === 'object' && !Array.isArray(value);
  const isDate = (value) => value instanceof Date;
  
  if (Array.isArray(data)) {
    return data.map(transformDates);
  }
  
  const transformed = { ...data };
  
  for (const [key, value] of Object.entries(transformed)) {
    if (isObject(value) && !isDate(value)) {
      transformed[key] = transformDates(value);
    } else if (
      typeof value === 'string' &&
      (key.includes('_at') || key.includes('date') || key.includes('time'))
    ) {
      const date = new Date(value);
      if (date.toString() !== 'Invalid Date') {
        transformed[key] = date;
      }
    }
  }
  
  return transformed;
};



  function textToSpeech(text, lang) {
    const synthesis = window.speechSynthesis;
  
    // Récupère toutes les voix disponibles sur l'appareil
    const voices = synthesis.getVoices();
  
    // Trouve la voix correspondant à la langue spécifiée
    const voice = voices.find(voice => voice.lang === lang);

  
    // Si la voix est trouvée, crée un nouvel objet de synthèse vocale et le configure
    if (voice) {
      const utterance = new SpeechSynthesisUtterance(extractTextWithTags(text));
      utterance.voice = voice;
      synthesis.speak(utterance);
    } else {
      console.log(`Voix pour la langue ${lang} non trouvée.`);
    }
  }

  const storage = getStorage(app);

  let currentAudio;

  const stopSound = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
  }

  const getLangVoice = (obj) => {
    let voice = {
      languageCode: obj.lang,
     
    }
    if (obj.lang == "en-GB") {
      voice = obj.variation == "A" ? {
        languageCode: 'en-GB',
        name: 'en-GB-Neural2-F',

      } : {
        languageCode: 'en-GB',
        name: 'en-GB-Neural2-B',
  
      }
    } else if (obj.lang == "fr-FR") {
      voice = {
        languageCode: 'fr-FR',
        name: 'fr-FR-Neural2-B',

      }
    } else if (obj.lang == "de-DE") {
      voice = obj.variation == "A" ? { languageCode: 'de-DE' ,name: 'de-DE-Neural2-B'} : { languageCode: 'de-DE', name: 'de-DE-Neural2-C'}
    }
    else if (obj.lang == "pt-BR") {
      voice = obj.variation == "A" ? {languageCode: obj.lang, name: 'pt-BR-Neural2-C'} : {languageCode: obj.lang, name: 'pt-BR-Neural2-B'}
    }
    else if (obj.lang == "es-ES") {
      voice = obj.variation == "A" ?  {languageCode: obj.lang, name: 'es-ES-Neural2-E'}: {languageCode: obj.lang, name: 'es-ES-Polyglot-1'}  
    }
    else if (obj.lang == "it-IT") {
      voice = obj.variation == "A" ?  {languageCode: obj.lang, name: obj.lang+'-Neural2-A'}: {languageCode: obj.lang, name: obj.lang+'-Neural2-C'}  
    }
    else if (obj.lang == "nl-NL") {
      voice = obj.variation == "A" ?  {languageCode: obj.lang, name: obj.lang+'-Wavenet-E'}: {languageCode: obj.lang, name: obj.lang+'-Wavenet-C'}  
    }
    else if (obj.lang == "cmN-CN" || obj.lang == "cmn-CN") {
      voice = obj.variation == "A" ?  {languageCode:'cmn-CN', name:'cmn-CN'+'-Wavenet-B'}: {languageCode:'cmn-CN', name:'cmn-CN'+'-Wavenet-B'}  
    }
    
    return voice

  }


  function extractTextWithTags(input) {
    const temp = document.createElement("div");
    temp.innerHTML = input;
    return temp.innerText;
  }

  async function checkFileExists(storageRef) {
    try {
      // Tentative d'obtenir l'URL de téléchargement du fichier
      const downloadURL = await getDownloadURL(storageRef);
      console.log('Le fichier existe déjà :', downloadURL);
      return downloadURL; // Le fichier existe
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        console.log('Le fichier n\'existe pas.');
      } else {
        // Gérer les autres types d'erreurs éventuels
        console.error('Erreur lors de la vérification de l\'existence du fichier :', error);
      }
      return false; // Le fichier n'existe pas ou une autre erreur s'est produite
    }
  }

  const uploadAudio = async (data, obj, storageRef) => {
    const audioBase64 = `data:audio/mp3;base64,${data.audioContent}`;
   
   
    console.log('speak obj', obj);
    console.log('speak storage', storage);


    const audioBlob = await (await fetch(audioBase64)).blob();
    await uploadBytes(storageRef, audioBlob);

    const url = await getDownloadURL(storageRef)
    const sound = new Audio(url);
  }

  async function speak(obj, ct) {

    console.log('speak', obj);
    return new Promise(async (resolve, reject) => {
        const timeoutDuration = 15000; // 10 secondes pour le timeout
        const timeoutPromise = new Promise((_, timeoutReject) => {
            setTimeout(() => {
                timeoutReject(new Error("Le délai d'attente pour la parole a été dépassé."));
                resolve(true);
            }, timeoutDuration);
        });

        try {

            if (obj.mp3){

              console.log('mp3 !!!!!!!!!', obj.mp3);
              const sound = new Howl({
                src: [obj.mp3],
                html5: true,
                onplayerror: (e) => {
                  console.log('error onplayerror', e);
                    reject(new Error("Erreur lors du chargement du fichier audio. onplayerror"))
                  },
          
                onloaderror: (e) => {
                    console.log('error onloaderror', e);
                    reject(new Error("Erreur lors du chargement du fichier audio."));
                },
                onend: function() {
                    resolve(true);
                    console.log('Finished!');
                }
            });

            currentAudio = sound;
            sound.play();
            console.log('lecture depuis le mp3');
            return;
            }
            console.log('speak3');
            const voice = getLangVoice(obj);
            console.log('voice', voice)
            const apiKey = "AIzaSyA98BOQxv5Sp4NL53Mn_wBzWazgepDh7BQ";
            const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;

            const storageRef = ref(storage, `google_speak/${obj.lang}/${encodeURIComponent(obj.text.toLowerCase())}.mp3`);

            let exist = false
            if (obj.upload) {exist = await checkFileExists(storageRef);}

            if (exist) {
                console.log('lecture depuis le storage0');

                if (currentAudio) {
                    currentAudio.stop(); // Utilisez stop() au lieu de pause() pour Howler
                }

                const sound = new Howl({
                    src: [exist],
                    html5: true,
                    onplayerror: (e) => {
                      console.log('error onplayerror', e);
                        reject(new Error("Erreur lors du chargement du fichier audio. onplayerror"))
                      },
              
                    onloaderror: (e) => {
                        console.log('error onloaderror', e);
                        reject(new Error("Erreur lors du chargement du fichier audio."));
                    },
                    onend: function() {
                        resolve(true);
                        console.log('Finished!');
                    }
                });

                currentAudio = sound;
                sound.play();
                console.log('lecture depuis le storage');
                return;
            }

            const fetchPromise = fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    input: {
                      ssml: `
                      <speak>
                          ${obj.text?.replace(/[\[\]]/g, '')}
                      </speak>`
   
                    },
                   
                    voice,
                    audioConfig: {
                        audioEncoding: 'MP3',
                        pitch: obj.variation !== "A" ? -4 : 0,
                        speakingRate: obj.speakingRate || 1,
                        sampleRateHertz: 24000 // Essa
                    }
                })
            }).then(async response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                const sound = new Howl({
                    src: [`data:audio/mp3;base64,${data.audioContent}`],
                    html5: true,
                    onend: function() {
                        resolve(true);
                        console.log('Finished!');
                    }
                });

                if (currentAudio) {
                    currentAudio.stop(); // Assurez-vous d'arrêter tout audio en cours avant d'en jouer un nouveau
                }

                currentAudio = sound;
            
              
                sound.play();
                
                if (obj.upload) { uploadAudio(data, obj, storageRef); }
                return {test: "test"}
            }).catch(error => {
                console.log("Une erreur s'est produite lors de l'appel Google TTS :", error.message);
                ct && ct.setAlerts([{title: "Erreur lecture du son: "+JSON.stringify(error), time: 2000 }]);
                textToSpeech(obj.text, obj.lang);
                resolve(true); // Vous pourriez vouloir rejeter ici pour indiquer une erreur
            });

            // Utilisez Promise.race pour gérer le timeout
            await Promise.race([fetchPromise, timeoutPromise]);
        } catch (error) {
            console.log("Une erreur s'est produite lors de la lecture :", error.message);
            reject(error); // Assurez-vous de rejeter la promesse principale en cas d'erreur
        }
    });
}


function segmenter(texte, lang) {
  let segmenterLang = ""
  if (lang == "cmN-CN") segmenterLang = "zh"
  if (lang == "ko-KR") segmenterLang = "ko"
  if (lang == "ja-JP") segmenterLang = "ja"

  if (!segmenterLang) return texte

  if (Intl.Segmenter) {
      const segmenter = new Intl.Segmenter(segmenterLang, { granularity: 'word' });
      return Array.from(segmenter.segment(texte)).map(s => s.segment).join(' ') + "\n\n";
  } else {
      // Solution de repli : séparation caractère par caractère
      return Array.from(texte);
  }
}

const hanziToPinyin = (hanzi, options = {}) => {
  try {
    const defaultOptions = {
      toneType: 'num',
      type: 'array',
      removeNonZh: true,
      nonZh: 'retain'
    };

    const mergedOptions = { ...defaultOptions, ...options };
    
    // Fonction pour segmenter le texte
    const segmentText = (text) => {
      if (Intl.Segmenter) {
        const segmenter = new Intl.Segmenter('zh', { granularity: 'word' });
        return Array.from(segmenter.segment(text))
          .filter(segment => segment.segment.trim()) // Filtrer les espaces vides
          .map(segment => segment.segment);
      } else {
        return null;
      }
    };
    
    // Découper le texte en mots
    const words = segmentText(hanzi);
    if (!words) return hanzi;

    // Convertir chaque mot en pinyin sans espace entre les caractères du même mot
    return words.map(word => {
      const pinyinArray = pinyin(word);
      // Si le mot est composé de plusieurs caractères, les joindre sans espace
      return Array.isArray(pinyinArray) ? pinyinArray.join('') : pinyinArray.replace(' ', '');
    }).join(' '); // Ajouter un espace entre les mots différents

  } catch (error) {
    console.error('Erreur de conversion Hanzi->Pinyin:', error);
    return hanzi;
  }
};

  
function shuffleArray(array) {
     // Vérifie si le tableau est vide ou si il contient un seul élément
    if (array.length <= 1) return
    // Copie du tableau original pour éviter de le modifier directement
    let shuffledArray = array.slice();
    let currentIndex = shuffledArray.length, randomIndex;
  
    // Tant qu'il reste des éléments à mélanger
    while (currentIndex !== 0) {
      // Sélectionne un élément restant au hasard
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // Échange l'élément courant avec l'élément aléatoire
      [shuffledArray[currentIndex], shuffledArray[randomIndex]] = 
      [shuffledArray[randomIndex], shuffledArray[currentIndex]];
    }
  
    return shuffledArray;
  }

function toggleVersion() {
    // Récupérer la valeur actuelle (ou 0 si elle n'existe pas)
    let currentVersion = localStorage.getItem('new_version') || 'classic';
    
    // Toggle entre 0 et 1
    let newVersion = currentVersion === 'classic' ? 'v2' : 'classic';
    
    // Sauvegarder la nouvelle valeur
    localStorage.setItem('new_version', newVersion);
    
    // Optionnel : afficher la nouvelle valeur
    console.log('Version changée à:', newVersion);
    window.location.reload()
}

const getEvents = async (profileId, set) => {
  try {
      console.log('getEvents for', profileId)
      
      const { data: events, error } = await supabase
          .from('events')
          .select('*')
          .eq('target_profile', profileId)

      if (error) {
          throw error
      }

      console.log('getEvents events', events)
      set(events)
      return events

  } catch (error) {
      console.error('Error fetching events:', error)
      return []
  }
}


function formatDateDMY(date) {
  // Si aucune date n'est fournie, utiliser la date actuelle
  if (!date) date = new Date();
  
  // Obtenir le jour, mois et année
  let jour = date.getDate();
  let mois = date.getMonth() + 1; // getMonth() retourne 0-11
  let annee = date.getFullYear().toString().slice(-2); // Prendre les 2 derniers chiffres
  
  // Ajouter un 0 devant si nécessaire
  jour = jour < 10 ? '0' + jour : jour;
  mois = mois < 10 ? '0' + mois : mois;
  
  // Retourner la date formatée
  return `${jour}/${mois}/${annee}`;
}

const today = new Date().getTime();

const getCardToLearn = (learnedCards) => {
  const today = new Date().getTime();
  return learnedCards?.filter(e => !e.profile_card?.archived)
      .sort((a, b) => {
          const dateA = getDateValue(a.profile_card.next_date);
          const dateB = getDateValue(b.profile_card.next_date);
          return dateA - dateB;
      })
      .filter(e => {
          const nextDate = getDateValue(e.profile_card.next_date);
          return nextDate <= today;
      });
}

const getDateValue = (date) => {
  if (date instanceof Date) {
      return date.getTime();
  }
  return new Date(date).getTime();
}

function formatDateDM(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois vont de 0 à 11
  return `${day}-${month}`;
}


function getCustomDayOfWeek(date) {
  const day = date.getDay();
  return day === 0 ? 7 : day - 1;
}


function capitalizeFirstLetter(text) {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
}




const getAverageLvlTrigger = (triggers) => {
  // Vérifier si le tableau est vide
  if (!triggers || triggers.length === 0) {
      return 0;
  }

  // Calculer la somme des niveaux
  const sum = triggers.reduce((acc, trigger) => acc + trigger.lvl, 0);
  
  // Calculer et retourner la moyenne arrondie à l'entier inférieur
  return Math.floor(sum / triggers.length);
}

const getNextTrigger = (triggers) => {
  let earliestIndex = 0;
  let earliestDate = new Date(triggers[0].next_date);

  triggers.forEach((trigger, index) => {
      const currentDate = new Date(trigger.next_date);
      if (currentDate < earliestDate) {
          earliestDate = currentDate;
          earliestIndex = index;
      }
  });

  return earliestIndex;
};


const evolveProfileCard = ({profile_card, trigger, valid, opts}) => {
  console.log('debug-- evolveProfileCard valid' + valid, {profile_card, trigger})
  const triggers = transformDates(profile_card?.triggers)?.map(e => ({...e, displayDuration: displayDuration(new Date, e.next_date)}))
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

  const today = new Date()
  const now = new Date()
  const todayPlus15min = new Date(today.getTime() + 15 * 60000);
  const todayPlus2min = new Date(today.getTime() + 2 * 60000);

  const newTriggers = triggers.map((e, i) => {
      if (i == trigger) {
          const lvl = (e.lvl || 0) + valid
          const lvlBooster = Math.min(Math.max((lvl > 5 ? 5 : lvl) - 1, 0), 5);

          const next_date = new Date(today.setMinutes(today.getMinutes() + boosters[lvlBooster].time)) 
          if (valid == 1) {
              return {...e, lvl, next_date}
          }   
          if (valid == 2) {
              return {...e, lvl, next_date}
          }
          if (valid == 0) {
              return {...e, lvl: e.lvl - 3 > 0 ? e.lvl - 3 : 0, next_date: new Date()}
          }   
          
      } else {

      
    
      //  
      return { 
          ...e, 
          next_date: e.next_date && e.next_date > now  ? e.next_date : e.lvl > 1 ? (opts?.fastUpdate ? tomorrow : todayPlus15min) : (e.next_date||todayPlus2min)
      };
      }
  })


  const next_trigger = getNextTrigger(newTriggers)

  const new_profile_card = {
      ...profile_card,
      next_trigger: next_trigger.toString(),
      triggers: newTriggers,
      last_update: new Date(),
      collected: valid > 0 ? false : true,
      lvl: getAverageLvlTrigger(newTriggers),
      next_date: newTriggers[next_trigger].next_date
  }

  return new_profile_card

}

const removeDigits = (inputString) => inputString.replace(/\d+|\n|\r/g, '')
const removeNoDigits = (inputString) => inputString.replace(/[^\d\n\r]/g, '');

async function fetch50k(workspace) {
  let lang = workspace?.lang?.split('-')?.[0]


  try {
    console.log('fetching 50k 🐸🐸🐸🐸🐸🐸🐸🐸🐸🐸🐸')
      const response = await fetch(`https://raw.githubusercontent.com/hermitdave/FrequencyWords/master/content/2018/${lang}/${lang}_50k.txt`);
      if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
      }
      const data = await response.text();
  
      return {
        text_50k: removeDigits(data).split(' '),
        int_50k: removeNoDigits(data).split('\n')
      }
      // console.log(removeDigits(data));
  } catch (error) {
      console.error('There was a problem with the fetch operation: ', error);
  }
}

function displayDuration(startDate, endDate = new Date()) {
  const diffInMs = endDate - startDate;
  const diffInMinutes = diffInMs / 1000 / 60;
  const diffInHours = diffInMinutes / 60;
  const diffInDays = diffInHours / 24;
  const diffInWeeks = diffInDays / 7;
  const diffInMonths = diffInDays / 30.44; 


  if (diffInMs < 0) {
      return `maintenant`;
  } else if (diffInMinutes < 2) {
      return `${Math.round(diffInMs / 1000)} s`;
  } else if (diffInMinutes < 120) {
      return `${Math.round(diffInMinutes)} min`;
  } else if (diffInHours < 48) {
      return `${Math.round(diffInHours)} h`;
  } else if (diffInDays <= 10) {
      return `${Math.round(diffInDays)} jours`;
  } else if (diffInWeeks <= 5) {
      return `${Math.round(diffInWeeks)} sem`;
  } else { 
      return `${Math.round(diffInMonths)} mois`;
  }

}

const goodPrint = (int) => int > 9 ? int : "0"+int;
const displayDate = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();
  return `${goodPrint(day)}/${goodPrint(month)} à ${goodPrint(hours)}:${goodPrint(minutes)}:${goodPrint(seconds)}`
}

const getTraduction = (card) => {
  if (hasWordInBrackets(card.context_fr)) return extractFirstBracketContent(card.context_fr)
  if (!card.definitions) return null;

  return JSON.parse(card.definitions).definitions.map(e => e.trad_fr)[0]
}

const clean = (txt) => txt?.replace(/[.,!?;¡::/¿()“"'\n\r]/g, "").toLowerCase();



const sentenceEndRegex =/[^.!?\s][^.!?]*(?:[.!?](?!['"]?\s|$)[^.!?]*)*[.!?]?['"]?(?=\s|$)(?!\s*(?:Dr|Mrs|Mr|Ms|Jr|Sr|Prof|Rev|Gen|Rep|Sen)\.*(?:\s|$))/g;

function removeEmojis(text) {
  return text.replace(
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u2600-\u26FF\u2700-\u27BF\ufe0f]/gu, ''
  );
}


const removeNonLetters = (text) => {
  if (!text) return '';
  
  // Utiliser une expression régulière pour supprimer tout caractère qui n'est pas une lettre
  return text.replace(/[^a-zA-Z]/g, '');
};

const numberSentences = (text) => {
  if (!text) return '';

  const clearedText = text
   
      .replace(/\[\d+\]/g, '');

  const protectedText = clearedText.replace(/\b(Dr|Mrs|Ms|Mr|Jr|Sr|Prof|Mx)\./g, '$1<dot>');

  // Diviser les phrases en utilisant la regex, en conservant les sauts de ligne
  let sentences = protectedText.match(sentenceEndRegex);

  // Traiter les phrases pour ajouter les numéros
  let result = '';
  let sentenceCounter = 1;
  let remainingText = clearedText;

  sentences.forEach((sentence) => {
      const trimmedSentence = sentence.trim();
      if (trimmedSentence.length > 0) {
          const startIndex = remainingText.indexOf(sentence);
          const prefix = remainingText.slice(0, startIndex);
          remainingText = remainingText.slice(startIndex + sentence.length);

          result += prefix + `[${sentenceCounter}] ${trimmedSentence} `;
          sentenceCounter++;
      } else {
          result += sentence;
      }
  });

  result += remainingText;

  return result.replace(/<dot>/g, '.').trim();
};


const  readingTime = (text) => {
  if (!text) return '0 minutes';

  // Compter le nombre de caractères dans le texte
  const numCharacters = text.length;

  // Estimer le nombre de mots (moyenne de 5 caractères par mot)
  const numWords = numCharacters / 5;

  // Vitesse de lecture moyenne (225 mots par minute)
  const wordsPerMinute = 225;

  // Calculer le temps de lecture en minutes
  const timeInMinutes = numWords / wordsPerMinute;

  // Arrondir à la minute la plus proche
  const roundedTimeInMinutes = Math.round(timeInMinutes);

  return `${roundedTimeInMinutes} min `;
};


export {transformDatesToJS, transformDates, toggleVersion, getCardToLearn, shuffleArray,
  capitalizeFirstLetter, getTraduction, speak, getTextBetweenBrackets, displayDuration,displayDate,
  getDateValue, readingTime,fetch50k,formatDateDMY,
  getEvents,segmenter,
    brackSentence, hasWordInBrackets, getWeekNumberAndYear, getCustomDayOfWeek, formatDateDM,hanziToPinyin,
    getNextTrigger, getAverageLvlTrigger, evolveProfileCard, stopSound,extractFirstBracketContent, numberSentences, removeEmojis, clean,removeNonLetters
  }