import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useStateContext } from "../StateProvider"
import { useState } from 'react';
import { toggleVersion } from '../helperFunctions';
import { signOut } from 'firebase/auth';
import { TutorialPage } from '../pages/TutorialPage';


const handleSignOut = (auth, set) => {
  signOut(auth)
    .then(() => {
      console.log('User signed out');
      set()
    })
    .catch((error) => {
      console.error(error);
    });
};


const TopBar = ({auth}) => {

  const {profile, setProfile, setShowModal, setView, workspace,  setUser, setProfileWorkspace, activeScreen} = useStateContext()


  const [showMenu, setShowMenu] = useState(false)

  if (showMenu) return <div className={`fixed backdrop-blur-md z-[90] p-4 bottom-0 -left-0 right-0 text-white top-0 bg-[#0e0c539e]`}>
  <svg onClick={() => setShowMenu(false)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-6">
 {showMenu ?   <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
: <path fillRule="evenodd" d="M2 3.75A.75.75 0 0 1 2.75 3h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75ZM2 8a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 8Zm0 4.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />}
  </svg>
   <div className='p-1 flex flex-col items-start'>
     <div onClick={() => {setProfileWorkspace(); setShowMenu(false)}} className='bg-purple-500 px-4 rounded-md border-2 border-b-4 border-black/50 py-1 mt-4 flex gap-2 items-center'><img className='h-4 w-4 rounded-full ' src={workspace?.img} /> Changer de langue</div>
     <div onClick={() => {setProfileWorkspace(prev => ({...prev, goal_id: null})); setShowMenu(false)}} className='bg-purple-500 px-4 rounded-md border-2 border-b-4 border-black/50 py-1 mt-4 flex items-center gap-2'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
</svg>
Changer de cours</div>
     <div onClick={() => {setShowModal("tuto"); setShowMenu(false)}} className='bg-purple-500 px-4 rounded-md border-2 border-b-4 border-black/50 py-1 mt-4 flex items-center gap-2'>
     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
</svg>

Voir les tutoriels</div>



   </div>
   <div className='fixed bottom-0 p-4 left-0 right-0'>
   <div onClick={() => toggleVersion()} className='text-white/50 mb-4 text-center'>Revenir à la version 1.9</div>
   <div onClick={() => handleSignOut(auth, () => {setProfile(); setUser()})} className='text-white/50 mb-4 text-center'>Se deconnecter</div>

   </div>
   </div>

  return <div className={`fixed 	z-60  ${activeScreen == "league" ? "text-amber-500" : "text-white" }   left-4   top-4 `}>
    <svg onClick={() => setShowMenu(true)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-6">
      {showMenu ?   <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
 : <path fillRule="evenodd" d="M2 3.75A.75.75 0 0 1 2.75 3h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75ZM2 8a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 8Zm0 4.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />}
    </svg>



  </div>
}

const BottomBar = () => {
    const {activeScreen} = useStateContext()


    console.log('bottom bar')


    return <div 
        // style={{paddingBottom: pb ? "24px" : "12px"}} 
        className={`flex fixed bottom-0 z-50 border-t-2 items-center w-full justify-center  ${activeScreen == "forest" ? "menu-gradient text-[#FFE68B] border-[#4E4433]" : "bg-purple-800 border-[#331675] text-purple-300"}`}>
            <Link to={"/home"} className={`${activeScreen == "home" ? "bg-[#8e25e5]" : ""}   grow flex pb-safe py-2  h-full justify-center items-center transition `}>
            <img className={`h-[36px] -mb-2 pt-1 transition ${activeScreen == "home" ? "scale-[1.3]" : "grayscale-[0.8] opacity-[0.8]"}`} src="/images/logo-card.png" />
            </Link>
          
            <Link to={"/forest"} className={`${activeScreen == "forest" ? "bg-white/20" : ""} grow flex pb-safe py-2  h-full justify-center items-center transition `}>
          
            <div className='relative'>
              <img className={`h-[36px] -mb-2 pt-1 transition ${activeScreen == "forest" ? "scale-[1.3]" : "grayscale-[0.8] opacity-[0.8]"}`} src="/images/logo-house.png" />
            </div>
            </Link>

            <Link to={"/league"} className={`${activeScreen == "league" ? "bg-[#8e25e5]" : ""} grow flex pb-safe py-2  h-full justify-center items-center transition `}>
              <div className='relative'><img className={`h-[36px] -mb-2 pt-1 transition ${activeScreen == "league" ? "scale-[1.3]" : "grayscale-[0.8] opacity-[0.8]"}`} src="/images/logo-trophee.png" />
                </div>
            </Link>

        </div>
    
}


export {BottomBar, TopBar}