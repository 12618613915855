import React, { useEffect, useState } from "react"
import { capitalizeFirstLetter, getTextBetweenBrackets, getTraduction, shuffleArray } from "../../helperFunctions";
import { mp3_bad, mp3_valid } from "../../helperData";




const RenderImages = ({randomElement, handleClick, showImage}) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadedCount, setLoadedCount] = useState(0);

  useEffect(() => {
    // Réinitialiser le compteur quand randomElement change
    setLoadedCount(0);
    setImagesLoaded(false);
    
    // Précharger toutes les images
    const imagePromises = randomElement.map(element => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = element.img;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setImagesLoaded(true);
      })
      .catch(error => {
        console.error("Erreur lors du chargement des images:", error);
      });
  }, [randomElement]);

  // if (!imagesLoaded) {
  //   return <div>Chargement...</div>; // ou votre composant de loading préféré
  // }

  return (
    <div className={`grid ${randomElement.length == 9 ? "grid-cols-3": "grid-cols-2"}  gap-1`}>

      {randomElement.map((e, index) => (
        <div 
          style={{backgroundImage: showImage && imagesLoaded && `url("${e.img}")`}} 
          onClick={(event) => handleClick(e, event)} 
          className="flex relative border-2 bg-white/50 relative h-[84px] bg-emerald-500/70 transition-all bg-cover bg-center game-btn border-b-4 border-green-900/60 rounded-xl py-2 items-center justify-center" 
          key={index}
        >
          {showImage ? <div className="bg-green-900/80 text-white absolute text-xs -bottom-[2px] left-0 right-0 rounded-b">
            {imagesLoaded && getTraduction(e)}
          </div> : <div className="h-full w-full flex items-center justify-center">{getTraduction(e)}</div>}
        </div>
      ))}
    </div>
  );
}


const QCM = React.memo(({noXp,  elements, goodElement, field, className, validCard, pass, mode, setState, handleSpeak}) => {
    
    const [randomElement, setRandomElement] = useState(elements);
    const [errors, setErrors] = useState(0)
    
    useEffect(() => {
        const _elements = shuffleArray(elements.concat(goodElement))
        setRandomElement(_elements)
        setErrors(0)
    }, [goodElement, elements])


    const animationXp = (event, points) => {
      const el = event?.target;
      if (!el) return
      el.classList.add('animate-pulse');
      setTimeout(() => el.classList.remove('animate-pulse'), 500);
    
      // Créer un élément "+1xp" dynamiquement

      const xpElement = document.createElement('div');
      xpElement.classList = 'absolute -top-2 bg-white/10 px-2 rounded-xl text-xl text-shadow flex gap-1 items-center pointer-events-none add-xp'
      xpElement.innerHTML = `+${points} <img src="/images/icons/coin-xp.png" class="h-6" />`;
    
      // Obtenir le conteneur en haut à droite et ajouter l'élément
      const xpContainer = el; // ici on utilise `el` comme conteneur
      xpContainer.appendChild(xpElement);
    
      // Supprimer l'élément après l'animation
      setTimeout(() => {
        if (xpContainer.contains(xpElement)) {
          xpContainer.removeChild(xpElement);
        }
      }, 1000);
    };

    const handleClick = (element,event) => {
      console.log('element', elements.length)
        if (element?.[field] == goodElement?.[field]) {

            const el = event?.target;
            el?.classList.add('text-blink');
            setTimeout(() => el?.classList?.remove('text-blink'), 300);
            const bonus = elements.length > 6 && errors == 0
            mp3_valid.play()
            console.log('juste QCM', elements.length)
           
            let points = bonus ? 2 : 1
            if (errors > 0 || elements.length == 1) points = 0
            points > 0 && !noXp && animationXp(event, points)

            console.log('points', points)
          

            handleSpeak && handleSpeak()

            if (setState) {
              // en mode révision
              setTimeout(() => {
                  validCard(points)
                  !bonus && setState(3) 
              }, [900])
            } else {
              // en mode classique
              setTimeout(() => {validCard(element) }, [300])
            }
            
           
          
            
            
        } else {
            mp3_bad.play()
            setErrors(prev => prev + 1)
            const el = event.target;
            el.classList.add('wizz');
            setTimeout(() => el.classList.remove('wizz'), 500);
            console.log('faux QCM')
        }
       
        
    }


    if (mode == "lang-comp") return <RenderImages showImage={goodElement?.img} animationXp={animationXp} handleClick={handleClick} randomElement={randomElement} />

    return (
      <div className={`grid grid-cols-2 gap-2`}>
        {randomElement.map((e, index) => (
          <div onClick={(event) => handleClick(e, event)} className={`${className} flex border-2 relative text-indigo-900/90  bg-white  border-b-4 border-black/30 rounded-xl py-2 items-center justify-center`} key={index}>{capitalizeFirstLetter(e?.[field])}</div>
        ))}
      </div>
    );
  }, (prevProps, nextProps) => {
    return prevProps.goodElement === nextProps.goodElement 
    && prevProps.elements === nextProps.elements
    && prevProps.mode === nextProps.mode
    && prevProps.field === nextProps.field
  });


export {QCM}