
import { useState } from "react"
import { useStateContext } from "../../StateProvider"
import { supabase } from "../../../V2/screens/testScreen"
import { sbCreate, sbUpdate } from "../../DbFunction"
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { SVG_close } from "../../svgs";


const JoinGroup = (props) => {

    const {user, profile, setProfile} = useStateContext()
    const [state, setState] = useState()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const handleClick = async () => {
        try {
            if (loading == true) return
            setLoading(true)
            
            const codeClassValue = document.querySelector('#code_class')?.value
    
            if (!codeClassValue) {
                setState("Veuillez entrer un code de classe")
                setLoading(false)
                return
            }

            console.log('codeClassValue', codeClassValue)
    
            // Recherche dans Supabase
            const { data: foundClass, error } = await supabase
                .from('groups')
                .select('*')
                .eq('code', codeClassValue?.toLowerCase())
                .single()
    
            if (error) {
                console.error('Erreur lors de la recherche:', error)
                if (error.code == "PGRST116") {
                    setState("Aucune classe n'a été trouvée avec ce code")
                } else {
                    setState("Une erreur est survenue lors de la recherche")
                }
               
                setLoading(false)
                return
            }
    
            if (!foundClass) {
                setState("Aucune classe n'a été trouvée avec ce code")
                setLoading(false)
                return
            }
    
            // Vérification de l'existence du profile_group
            const { data: existingProfileGroup, error: profileGroupError } = await supabase
                .from('profiles_groups')
                .select('*')
                .eq('profile_id', profile?.id)
                .eq('group_id', foundClass?.id)
                .single()
    
            if (profileGroupError && profileGroupError.code !== 'PGRST116') { // PGRST116 = not found
                console.error('Erreur lors de la vérification:', profileGroupError)
                setState("Une erreur est survenue lors de la vérification")
                setLoading(false)
                return
            }
    
            if (existingProfileGroup) {
                setState("Vous êtes déjà membre de cette classe")
                setLoading(false)
                await sbUpdate('profiles', profile.id, {last_class_id: foundClass.id}, setProfile)
                return
            }
    
            console.log('Classe trouvée:', foundClass)
            setState(`Classe trouvée: ${foundClass.name}`)
    
            // Création du profile_group uniquement s'il n'existe pas
            sbCreate('profiles_groups', {
                profile_id: profile.id, 
                group_id: foundClass.id, 
                created_time: new Date(), 
                updated_time: new Date()
            })

            await sbUpdate('profiles', profile.id, {last_class_id: foundClass.id}, setProfile)
            navigate("/")
            setLoading(false)
            
        } catch (error) {
            console.error('Erreur:', error)
            setState("Une erreur inattendue est survenue")
            
        }
    }
    console.log('profile', profile)
    
    return  <div className='flex flex-col gap-4 items-center fredoka p-4 text-center justify-center fixed z-[62] bg-amber-50 top-0 left-0 right-0 bottom-0'>
              {props.choice && <div className="fixed top-4 left-4" onClick={() => {
            
              }}><Link to="/home" ><SVG_close/></Link></div>}
              <img src="images/UX/tuto/cool_seed.png" className='h-[200px]'/>
              <h3>Hello {profile?.name},</h3>
              <p>Pour accèder à ce cours entre ton code de classe</p>
              <input id="code_class" placeholder='_____' className='bg-transparent text-center text-2xl border-2 px-2 py-1 mb-4 bg-white rounded-xl'/>
              <div className="py-4 text-red-500">{state}</div>
              {loading && <div>Chargement ...</div>}
              <div className="bg-purple-500 border-2 border-b-4 border-black/70 game-btn px-4 py-2 rounded-xl text-white text-xl hover:scale-110" onClick={handleClick}>Rejoindre</div>
             
              {/* <p>Pas de code ?</p>
              <p onClick={() => {fire.updateUser({...user, asked_acces: true}); alert('Ta demande a bien été envoyée ! 🤌')}} className='px-4 py-2 rounded-xl  text-lg text-purple-500 '>Envoyer une demande d'accès</p> */}
            </div>
  }
  
  
  
export {JoinGroup}  