import {Howl, Howler} from 'howler';

const mp3_valid_2 = new Audio('https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/sound_interface%2Fgood_2_sound.mp3?alt=media&token=b17746fd-7d99-4f05-841c-46dded6eeefe')
const mp3_click = new Audio('assets/click.mp3')
const mp3_bg = new Audio('/assets/better_day_lowered.mp3');
const mp3_valid = new Howl({src: [`/assets/valid_sound.mp3`],html5: true, volume: 0.5});
const mp3_bad = new Howl({src: [`/assets/bad_sound.mp3`],html5: true, volume: 0.1});
const mp3_new = new Howl({src: [`/assets/valid_sound.mp3`],html5: true, volume: 0.1});
mp3_bg.loop = true;


const toeicPaths = [
  {
    "id": 14,
    "name": "Voyages"
  },
  {
    "id": 23,
    "name": "Santé"
  },
  {
    "id": 34,
    "name": "Loisirs"
  },
  {
    "id": 38,
    "name": "Travail"
  },
  {
    "id": 41,
    "name": "Vente"
  },
  {
    "id": 51,
    "name": "Finance"
  }
]


const menu = [
    {
      
      pathName: "news", 
      id: "news",
      name: "Actualités",
      icon: "/images/UX/i_actualite.png",
      ready: false,
      hue: 42,
      description: "Découvre l’actualité directement en @lang",
      open_only_to: ["learn", "perfect", "toeic"],
    },
    {
      
      pathName: "chat", 
      id: "chatbot",
      name: "Scénarios intéractifs", 
      icon: "/images/UX/i_chat.png",
      ready: false,
      beta: true,
      hue: 140,
      description: "Exerce-toi directement à parler en immersion.",
      open_only_to: ["learn", "perfect", "toeic"],
    },
    
    {
      
      pathName: "voc", 
      id: "voc",
      name: "Vocabulaire", 
      icon: "/images/UX/i_voc.png",
      ready: true,
      available: true,
      hue: 0,
      open_only_to: ["learn", "perfect", "TOEIC"],
      description: "Des groupes de vocabulaire spécifiques à différents domaines : voyage, immobilier, langage soutenu, finance... ",
    },
    {
      
      pathName: "brain", 
      id: "cultureG",
      name: "Culture Générale", 
      icon: "/images/UX/i_culture.png",
      ready: false,
     
      hue: 100,
      open_only_to: ["learn", "perfect"],
      description: "Apprends des informations utiles pour ta culture générale tout en améliorant ton niveau de langue",
    },
    {
      
      pathName: "exam", 
      id: "train",
      name: "Entrainement", 
      icon: "https://media-public.canva.com/WXcBI/MAF5M5WXcBI/1/tl.png",
      ready: true,

      hue: 100,
      open_only_to: ["TOEIC"],
      description: "Apprends des informations utiles pour ta culture générale tout en améliorant ton niveau de langue",
    },

    {
      
      pathName: "grammar", 
      id: "grammar",
      name: "Grammaire", 
      icon: "/images/UX/i_book.png",
      ready: true,
      hue: 100,
      open_only_to: ["TOEIC", "learn"],
      description: "Apprends des informations utiles pour ta culture générale tout en améliorant ton niveau de langue",
    },
    
    {
      
      pathName: "book", 
      id: "book",
      name: "Librairie", 
      icon: "/images/UX/i_book.png",
      ready: false,
      hue: 300,
      open_only_to: ["perfect"],
      description: "Apprends la langue en lisant des histoires et des livres adaptés à ton niveau",
    }
   
    
  
  
  ]


  const boosters = [{
    name: "En cours d'apprentissage", 
    fr: "1 min",
    time: 1,
    // time: 1,
  },{
    name: "Travaille", 
    fr: "24h",
    time: 1440,
    // time: 1,
  },{
    name: "Bon", 
    fr: "72h",
    // time: 1,
    time: 4320,
  },{
    name: "Très bon", 
    fr: "5 jours",
    time: 10080,
  },{
    name: "Excellent", 
    fr: "1 mois",
    time: 43200,
  },{
    name: "Magistral", 
    fr: "6 mois",
    time: 259200,
  },{
    name: "Divin", 
    fr: "2 ans",
    time: 1036800,
  }] 
  

  const goals = [
    {
      name: "Apprendre l'alphabet et les signes",
      courseName: "Pierre de Rosette",
      short: "Pierre de Rosette",
      goal_id: "roseta",
      description: "Apprends le système d'écriture @lang",
      content: "Ce cours te permet d'apprendre les lettres de l'alphabet et les différents signes utilisés dans la langue.",
      goal: "Apprendre à lire et écrire en utilisant les lettres de l'alphabet et les signes.",
      bgColor: "#FFCA7B",
      img: "/images/UX/stone_full.png",
      open_only_to: ["Arabe", "hindi", "Russe", "ukrainien", "Coréen", "japonais", "chinois"],
      lvl: ["Débutant"]
    },
      {
        name: "Apprendre @lang",
        goal_id: 'learn',
        short: "Débutant",
        courseName: "L'archipel du débutant",
        description: "La méthode idéale pour maîtriser les bases le plus efficacement possible.",
        content: "Apprends à comprendre et à t'exprimer en utilisant les 1 200 mots les plus utilisés en @lang, ce qui te donnera la capacité de comprendre 80 % de n'importe quel texte",
        goal: "Atteindre le niveau B1 et comprendre 80% de n'importe quel texte",
        bgColor: "#00FFE0",
        img: "images/UX/plant_seed.png",
        tuto: true,
        // pages: tutoPareto(ct),
        open_only_to: ["anglais-gb", "allemand", "esp", "italien", "Russe", "Arabe", "Coréen", "japonais", "chinois"],
        lvl: ["Débutant"]
      }, {
        name: "Perfectionner son @lang",
        goal_id: 'perfect',
        short: "Perfectionnement",
        courseName: "Perfectionner mon @lang",
        description: "Choisis parmis tes thèmes préférés et progresse à ton rythme",
        goal: "Adopte une approche personnalisée et dynamique pour maîtriser le @lang. Selon tes passions, qu'il s'agisse de littérature, de conversations interactives, de sujets d'actualité brûlants ou de trésors de la culture générale, construis ton parcours d'apprentissage sur mesure et deviens un virtuose de la langue.",
        bgColor: "#0EC5FF",
        content: "Améliore ton @lang avec des conversations interactives, des histoires, de l'actualité ou des cours de culture générale",
        img: "images/UX/fusee.png",
        open_only_to: ["anglais-gb", "allemand", "esp", "italien"],
        // pages: tutoPerf,
        admin: true,
        tuto: true,
        lvl: ["Intermédiaire", "Avancé"]
      },
      {
        name: "Préparer un concours",
        courseName: "Les jardins du TOEIC",
        goal_id: "TOEIC", 
        short: "Jardins du TOEIC",
        description: "La formation ultime pour développer ton vocabulaire, ta grammaire, et ta compréhension écrite et orale.",
        content: "Un parcours complet dédié au TOEIC, axé sur la compréhension. Mets-toi au défi sur chaque section du test et construis un socle lexical solide, te donnant les clés pour décrypter 90 % de n'importe quel texte du TOEIC.",
        goal: "Réussir le concours devient un jeu d'enfant",
        bgColor: "#6D41EB",
        content: "Prépare ton TOEIC avec du vocabulaire, de la grammaire et des exercices de compréhension orale et écrite",
        img: "images/UX/toeic.png", 
        open_only_to: [],
        // open_only_to: [""],
        lvl: ["Intermédiaire"]
      }
      
    ]

    const extensionLvl = [
      {
          elixirs: 10,
          seeds: 10,
          house_lvl: 1
      },
      {
          elixirs: 10,
          seeds: 40,
          house_lvl: 2
      },
      {
          elixirs: 10,
          seeds: 100,
          house_lvl: 2
      },
]

export {
    mp3_valid_2,
    mp3_click,
    mp3_bg,
    mp3_valid,
    mp3_bad,
    mp3_new,
    menu, 
    boosters,
    goals,
    toeicPaths,
    extensionLvl
}