import React, { useState, useEffect } from 'react';
import { supabase } from '../../V2/screens/testScreen';
import { useStateContext } from '../StateProvider';
import { capitalizeFirstLetter, getWeekNumberAndYear } from '../helperFunctions';
import { collection, getDocs, query, where } from '@firebase/firestore';
import { db } from '../../config';


const ProfilesInGroup = () => {

  const currentWeek = getWeekNumberAndYear(new Date());
  const {profile} = useStateContext()
  const [profilesInGroup, setProfilesInGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [groups, setGroups] = useState([])
  const [groupId, setGroupId] = useState()


  const liguesNames = ["bronze", "argent", "or"]

   
  
  async function loadGroups(set) {
    const { data, error } = await supabase
        .from('groups')
        .select(`
            id,
            code,
            name,
            profiles_count:profiles_groups(count)
        `)
        .order('name');
        console.log('data', data)
        set(data)
    return data

}


    useEffect(() => {
        loadGroups(setGroups)
    }, [])


    useEffect(() => {
        
        if (!groupId) return;
        setLoading(true);
        const query = `
          *,
          profiles:profile_id (
            *,
            profiles_workspaces (
              tt_xp, goal_id, extension_lvl, house_lvl,
              workspaces (name, img)
            )
          )
        `
        const loadProfilesInGroup = async () => {

          
          try {
            const { data, error } = await supabase
              .from('profiles_groups')
              .select(query)
              .eq('group_id', groupId);
      
            if (error) throw error;
            setProfilesInGroup(data);
            console.log('loadProfilesInGroup data', data)
            setLoading(false);
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };
      
        loadProfilesInGroup();
      
        const subscription = supabase
          .channel('profiles_groups_changes')
          .on(
            'postgres_changes',
            {
              event: 'INSERT',
              schema: 'public',
              table: 'profiles_groups',
              filter: `group_id=eq.${groupId}`
            },
            async (payload) => {
              const { data, error } = await supabase
                .from('profiles_groups')
                .select(query)
                .eq('id', payload.new.id)
                .single();
      
              if (!error && data) {
                setProfilesInGroup(current => [...current, data]);
              }
            }
          )
          .on(
            'postgres_changes',
            {
              event: 'DELETE',
              schema: 'public',
              table: 'profiles_groups',
              filter: `group_id=eq.${groupId}`
            },
            (payload) => {
              setProfilesInGroup(current =>
                current.filter(profile => profile.id !== payload.old.id)
              );
            }
          )
          .on(
            'postgres_changes',
            {
              event: 'UPDATE',
              schema: 'public',
              table: 'profiles_groups',
              filter: `group_id=eq.${groupId}`
            },
            async (payload) => {
              const { data, error } = await supabase
                .from('profiles_groups')
                .select(query)
                .eq('id', payload.new.id)
                .single();
      
              if (!error && data) {
                setProfilesInGroup(current =>
                  current.map(profile =>
                    profile.id === payload.new.id ? data : profile
                  )
                );
              }
            }
          )
          .subscribe();
      
        // Ajout d'écouteurs pour les changements dans profiles_workspaces
        const workspacesSubscription = supabase
          .channel('profiles_workspaces_changes')
          .on(
            'postgres_changes',
            {
              event: '*', // Écoute tous les événements (INSERT, UPDATE, DELETE)
              schema: 'public',
              table: 'profiles_workspaces'
            },
            async () => {
              // Recharger toutes les données en cas de changement
              await loadProfilesInGroup();
            }
          )
          .subscribe();
      
        return () => {
          subscription.unsubscribe();
          workspacesSubscription.unsubscribe();
        };
      }, [groupId]);

 

  if (error) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 p-4 my-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-red-700">Erreur: {error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='bg-slate-50 min-h-screen h-screen overflow-scroll max-h-screen pb-20'>
            {loading && <div className="flex fixed top-0 left-0 right-0 bottom-0 backdrop-blur-xl justify-center items-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                </div>}
        
        <div className='fixed bg-white top-0 left-0 bottom-0 shadow w-[200px]'>
      
        <div className='mt-16' onClick={() => {
            loadGroups()
            console.log('groups', groups)
        }}>
            <div className='px-3 border-b py-2' onClick={async() => {
               const q = query(
                collection(db, 'users'),
                where('class', 'array-contains', '4UlypC5WadwkLEVbt2Ck')
            )
            const querySnapshot = await getDocs(q);
            const users = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))

            const userTest = users.filter(user => user.email.toLowerCase().includes('test') || user.name.toLowerCase().includes('test'))
            const noUserTest = users.filter(user => !(user.email.toLowerCase().includes('test') || user.name.toLowerCase().includes('test')))

            const workspaces = users.reduce((acc, user) => {
                const workspaceId = user.last_workspace_id;
                if (workspaceId) {
                    acc[workspaceId] = (acc[workspaceId] || 0) + 1;
                }
                return acc;
            }, {});
            console.log('users', users)
            console.log('noUserTest', noUserTest)
            console.log('userTest', userTest)
            console.log('workspaces', workspaces)

            }}>GET FIRE USER</div>
            {groups?.map(e => ({...e, count: e?.profiles_count?.[0]?.count})).filter(e => e.count).map(group => <div onClick={() => {setGroupId(group.id)}} className={`${groupId == group.id ? "bg-indigo-100/30  " : ""} px-3 py-2 border-b flex justify-between`}>
                <div className='w-[100px] truncate'>{group.name}</div>
            <span className='bg-slate-100 text-slate-400 flex items-center gap-2 px-2 rounded-full'>
                {group?.count}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 text-slate-400">
                    <path d="M10 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.465 14.493a1.23 1.23 0 0 0 .41 1.412A9.957 9.957 0 0 0 10 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 0 0-13.074.003Z" />
                </svg>
                </span>
            </div>)}
        </div>
        </div>
    <div className="max-w-[1200px] mt-16 mx-auto p-4 pl-[220px] ">
    
      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        Membres du groupe
      </h2>

      {profilesInGroup.length === 0 ? (
        <div className="bg-gray-50 rounded-lg p-6 text-center">
          <p className="text-gray-500">Aucun membre dans ce groupe</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {profilesInGroup.sort((a,b) => (b.profiles.tt_xp || 0) - (a?.profiles?.tt_xp || 0 )).map((profileGroup) => (
            <div
              onClick={() => {
                console.log('profileGroup', profileGroup) 
              }}
              key={profileGroup.id}
              className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden"
            >
              <div className="p-5 max-h-[200px] hover:max-h-none transition-all">
                {/* Avatar avec initiales */}
                <div className="flex items-center mb-4">
                  <div className="w-8 h-8 min-w-8 rounded-full bg-indigo-500 flex items-center justify-center text-white font-bold text-xl truncate">
                    {profileGroup.profiles.full_name?.charAt(0)?.toUpperCase() || '?'}
                  </div>
                  <div className="ml-4">
                    <h3 className="text font-semibold text-gray-700 truncate">
                      {profileGroup.profiles.name}
                    </h3>
                    {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                      {profileGroup.role || "étudiant"}
                    </span> */}
                    <div className="items-center text-gray-600">
                    <span className="text-sm truncate">
                      {profileGroup.profiles.email}
                    </span>
                    <div>
                    {profileGroup?.profiles?.week_table?.[currentWeek]?.xp && <span className="text-sm truncate text-amber-500 px-2 mr-2 rounded-xl border bg-amber-100 border-amber-500">
                      +{profileGroup?.profiles?.week_table?.[currentWeek]?.xp} xp
                    </span>}

                    
                      {profileGroup?.profiles?.week_table?.[currentWeek]?.lig_lvl && <span className="text-sm border rounded-xl bg-slate-100 truncate text-slate-500 px-2">{liguesNames[profileGroup?.profiles?.week_table?.[currentWeek]?.lig_lvl - 1]}</span>}           
         
                    </div>

                  </div>
                  </div>
                </div>

                {/* Informations du profil */}
                <div className="space-y-2">
                  

                  {/* <div className='p-2 rounded-xl bg-slate-100 text-xs mb-2'>
                    <div>Nombre d'exercice total {profileGroup.profiles.tt_xp}</div>
                    <div>Nombre d'exercice de la semaine {profileGroup.profiles.tt_xp}</div>
                  </div> */}

                
                  {profileGroup?.profiles?.profiles_workspaces?.map(e => <div className='flex justify-between'>
                    <div className='flex gap-2 items-center'>
                        <img src={e.workspaces?.img} className='h-4 w-4' /> {capitalizeFirstLetter(e.workspaces?.name)} <span className='text-xs text-slate-400'>{e.goal_id}</span>
                    </div>
                    <div className='text-amber-700/80 flex gap-2 items-center text-xs'>
                    {e.extension_lvl > 1 ? "lvl." + e.extension_lvl : ""}
                    <div className='bg-amber-100 text-sm rounded-xl px-2 text-amber-500'>
                     {e.tt_xp} xp 
                      </div>
                      </div>
                    </div>)}

                 
                 
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
       <h3 className="text-xl font-bold text-gray-600 mt-6 mb-2">Liste des mails</h3>
       <div className='text-slate-500'>{profilesInGroup.map(p => <div >{p?.profiles?.email}</div>)}</div>
    </div></div>
  );
};

export default ProfilesInGroup;