import React, { useEffect, useState } from "react";
import { splitNumeredSentence } from "../../utilities/helper/text";
import { clean, removeNonLetters, segmenter, segmenterTexteChinois } from "../helperFunctions";
import { useStateContext } from "../StateProvider";
import { sbUpdate } from "../DbFunction";


const SuperText = React.memo(({ text, ct, decks, imgContext, path, darkMode, trad, tradMode, colorText, className }) => {
    

    const {setMagnifier, learnedCards, profile, setProfile, workspace} = useStateContext()

   
    const findWord = (cards, w) => {
        const transformedW = clean(w);
        return cards?.find(c => 
          clean(c.term) === transformedW || c.inflexions?.split(',').some(word => clean(word) === transformedW)
        );
      };
    
    const [selectedWord, setSelectedWord] = useState(null);
    const [processedSentences, setProcessedSentences] = useState([]);

    
  
    // console.log('trad', trad)
  
    const tradArray = splitNumeredSentence(trad)
    const textArray = splitNumeredSentence(text?.trim())
  
    // console.log('splitNumeredSentence', splitNumeredSentence(text))
  
    const grammarDecks = decks?.filter(e => e.presentation_mode === "Grammar");
  
    const blackList = [];
    const allInflexionsFromDecks = grammarDecks?.map(e => e.words?.toLowerCase().split(';')).flat();
    // console.log('allInflexionsFromDecks', allInflexionsFromDecks)
  
    useEffect(() => {
        const cards = learnedCards.map(e => e.card)
      if (colorText) {
        const processed = textArray.map(sentence => // Split each line into sentences
        segmenter(sentence, workspace?.lang)?.replace(/\|/g, '').replace(/\n\n/g, '\n\n ').split(/(?![\r\n])\s/).filter(word => word !== ' ').map(word => ({
          word,
          cleanedWord: clean(word),
          hasSpace: word.includes('\n\n'),
          isInCards: !!findWord(cards, clean(word)),
          isInDecks: !!allInflexionsFromDecks?.includes(clean(word)),
          isDel: !!blackList?.includes(clean(word)),
          isGram: !!allInflexionsFromDecks?.includes(clean(word)),
          isBlackList: !!blackList?.includes(clean(word)),
          inCards: !!findWord(cards, clean(word)),
        }))
      ) 
    
      setProcessedSentences(processed);
      } else {
        const processed = textArray.map(sentence => // Split each line into sentences
        sentence.replace(/\|/g, '').replace(/\n\n/g, '\n\n ').split(/(?![\r\n])\s/).filter(word => word !== ' ').map(word => ({
          word,
          cleanedWord: clean(word),
          hasSpace: word.includes('\n\n'),
          isInCards: null,
          isInDecks: null,
          isDel: null,
          isGram: null,
          isBlackList: null,
          inCards: null,
        })))
        setProcessedSentences(processed);
      }
      
    }, [text, decks, colorText, learnedCards?.length]);
  
    const handleClick = (word, sentence, trad, e) => {
      const top = e.target.getBoundingClientRect().top + window.scrollY;
      const center = e.target.getBoundingClientRect().left + (e.target.offsetWidth / 2);

      setProfile(prev => ({...prev, events: {...prev.events, learn_super_text: (prev.events?.learn_super_text || 0) + 1 }}))
      sbUpdate("profiles", profile.id, {events: {...profile?.events, learn_super_text: (profile?.events?.learn_super_text || 0) + 1}})

  
  
      setSelectedWord({ word, sentence });
      const cleanedWord = clean(word);
      const regex = new RegExp(`\\b${cleanedWord}\\b`, 'g');
      const cleanSentence = removeNonLetters(sentence)
      const index = textArray.findIndex(e => removeNonLetters(e).includes(cleanSentence))
      const card = findWord(ct.cards, cleanedWord);
    //   console.log('__debug card !!', card)
    //   console.log('__debug card !!', trad)
      setMagnifier(card ? {...card, sentence: sentence, path, sentence_trad: trad, top: top, imgContext: imgContext, word: cleanedWord, center: center} : {top: top, path, center: center, word: cleanedWord, sentence, imgContext: imgContext, sentence_trad: trad });
    };
  
    const getHighlightedSentence = (sentence, word) => {
      const cleanedWord = clean(word);
      const regex = new RegExp(`\\b${cleanedWord}\\b`, 'g');
      return sentence.replace(regex, `[${cleanedWord}]`);
    };
  
    // console.log('processedSentences', processedSentences)
    const green = darkMode ?"text-green-300" : "text-emerald-700"
    const decorationGreen = darkMode ?"decoration-green-300/50" : "decoration-emerald-700/50"

    console.log("processedSentences", processedSentences)
  
    
    return (//sentence.map(w => w.word).join(" ")
      <div className={`${className} ${workspace?.lang == "ar-XA" ? " text-right": ""}`}>
        {processedSentences.map((sentence, sentenceIndex) => (
              <><span className={`${tradMode && "mt-2"}`} key={sentenceIndex}>
                {sentence.map(({ word, cleanedWord, isInCards, isInDecks, hasSpace }, wordIndex) => (
                  <><span 
                    key={wordIndex} 
                    onClick={(e) => {

                   

                      handleClick(word, textArray[sentenceIndex], tradArray[sentenceIndex], e)
  
                    }}
                    className={`hover:text-yellow-500 ${isInCards ? `${green} ${decorationGreen}  ` : isInDecks ? green : ""} -underline decoration-slate-400 underline-offset-4 decoration-dashed `}
                  >
                    {word}
                  </span>{" "}{hasSpace && <><br/><br/></>}</>
                ))}
              </span>
              {tradMode && <div className={`text-sm ${darkMode ? "text-white/50" : "text-black/40"} italic mb-2`}>{tradArray[sentenceIndex]}</div>}
              </>
            ))}
           
          </div>)
         
        
       
        {/* {selectedWord && (
          <div className="mt-4">
            <strong>Selected Word in Context:</strong> <br />
            {getHighlightedSentence(selectedWord.sentence, selectedWord.word)}
          </div>
        )} */}
     
  
  }, (prevProps, nextProps) => {
    return prevProps.text?.length === nextProps.text?.length 
      && prevProps.cards === nextProps.cards
      && prevProps.tradMode === nextProps.tradMode 
      && prevProps.colorText === nextProps.colorText
      && prevProps.decks?.length === nextProps.decks?.length;
  
  });


  export {SuperText}