import { images } from "../../utilities/helper/helper"
import { sbUpdate } from "../DbFunction"
import { useStateContext } from "../StateProvider"

const SeedStore = ({setShowModalForest, setEditDeco}) => {

    const {setAlert, workspace, setShowInfo, profileWorkspace, setProfileWorkspace, setSelectedDeco} = useStateContext()

    const elixirs = profileWorkspace?.tt_elixirs - profileWorkspace.elixirs_spend
    const xpCoin = profileWorkspace?.tt_xp - profileWorkspace.xp_spend
    const seedToPlace = 4
    const hasHouse = true

    const imageWithData = images.filter(el => el.deco && (!el.wp || el.wp == workspace?.id) ).reverse().map(t => {
        const canBuy = t?.price <= (elixirs ||0)
        const canUnlock = t?.unlock <= (xpCoin ||0)
        const isLocked = true || profileWorkspace?.unlocked_deco?.find(ud => ud == t.name);
        const isLockedByLvl = false
        const isPurchasable = !isLocked && !isLockedByLvl
        const isAvailable = canBuy && isPurchasable
        return {...t, canBuy, canUnlock, isLocked, isLockedByLvl, isPurchasable, isAvailable}
      })

      const usedBase = 100
      const priceExtension = []
      const lvlExtension = []
      const territory = {}


      const renderDeco = (t) =>  <div 
onClick={() => {
//  t.isAvailable && setSelectedDeco(t)
 t.isLocked &&  setShowInfo({title: "Acheter une décoration",  classContent: "bg-[#5b00b1] text-white", content: 
 <>
 <div className="text-white text-sm mb-2">Veux-tu débloquer cette décoration ? Tu pourras l'utiliser dans ton inventaire <img className="ml-1 inline-block h-4" src={'/images/UX/pelle.png'} /></div>
 {xpCoin >= t.unlock  ?  <div className="flex mt-4 gap-4 justify-between items-center ">
   <div className="px-3 py-2 rounded-xl bg-white/10">Annuler</div>
   <div onClick={() => {
   
      
       if (xpCoin >= t.unlock ) {
         setAlert({title: "La décoration a été ajoutée dans ton inventaire" })
         setProfileWorkspace({...profileWorkspace, xp_spend: profileWorkspace.xp_spend + t.unlock, unlocked_deco: [...profileWorkspace.unlocked_deco || [], t.id]})
         sbUpdate('profiles_workspaces', profileWorkspace?.id, {xp_spend: profileWorkspace.xp_spend + t.unlock, unlocked_deco: [...profileWorkspace.unlocked_deco || [], t.id]})
       } else {
         setAlert({title: "Tu n'as pas assez de médaille d'xp pour acheter cet objet" })
         setShowInfo()
       }
     
   }} className="px-3 bg-white justify-center grow text-amber-600 py-2 font-bold rounded-xl flex gap-2 items-center">Acheter <span className="bg-amber-200 px-2  text-amber-600 rounded-xl flex gap-2">x{t.unlock} <img className='h-[22px] w-[22px]' src={'/images/icons/coin-xp.png'}/></span>
   </div>
 </div> : <div className="mt-4 text-center text-pink-300">Tu n'as pas assez de médaille d'xp pour obtenir cet objet</div>}
 </>
})
 !t.canBuy && setAlert({title: "Tu n'as pas assez d'argent pour acheter cet objet" })
}} 
 className={` ${t.isAvailable ? "bg-gradient-to-b from-indigo-500  to-blue-500 border-black/70 " : " border-black/50  bg-indigo-800"} ${t.span == 2 ? "col-span-2"  :"" } flex flex-col justify-between items-center relative b ${t.edition ? "h-[70px]":"h-[100px]"}  border-2 border-b-4   p-1 rounded-xl`}>
   <div className={`${t.isAvailable ? "": "filter-gray filter  grayscale opacity-60"}`}>
   <div className="text-shadow font-bold capitalize text-[12px]">{t.fr}</div>
   <img className="w-[50%] mx-auto mb-4 max-h-[120px]" src={t.src}/>
   </div>
   {t.nb ? <div className="text-shadow absolute bottom-2 self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">{t.nb}</div> : null}
   {/* {t.isPurchasable && t.price ? <div className="text-shadow absolute bottom-2 self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">{t.price} <img className='h-[12px] w-[12px]' src={'/images/icons/coin-xp.png'}/></div> : null} */}
   {t.isLocked && <div className="text-shadow absolute bottom-2 self-end capitalize text-[12px] items-center flex gap-[4px] font-bold bg-black/20 rounded-xl text-purple-100 px-[4px] py-[1px]">{t.unlock} <img className='h-[12px] w-[12px]' src={'/images/icons/coin-xp.png'}/></div>}

   {/* {t.name == "plant" && <div className='px-2 rounded-full'>{cardBadPosition.length + cardToPlace.length}</div> */}
   
   </div>

    return <div  className={`fredoka text-left bg-gradient-to-b from-indigo-800  to-indigo-900 border-indigo-600 z-[40]  transition fixed bottom-[50px] top-0 left-0 right-0  p-4`} 
    style={{
     backgroundColor: "#2b267d",
     backgroundImage: `url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.06'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
    }}
    >
     <div className="flex z-10 gap-2 fixed">
       <div  className={`text-shadow text-amber-50 px-3 py-1 flex items-center   gap-2 rounded-md  font-bold text-xl`} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
           <div>{xpCoin || 0}</div>
           
         <img className='h-5 w-5' src={'/images/icons/coin-xp.png'}/>
       </div>
        <div className={`text-shadow text-amber-50 px-3 py-1 flex items-center   gap-2 rounded-md  font-bold text-xl`} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
         <div>{elixirs || 0}</div>
         <img className='h-5 w-5' src={'/images/deco/elixir.png'}/>

       </div>
       
     </div>
       
   { true && 
   <div className=" max-h-screen text-white pb-[300px] pt-[60px] overflow-scroll">
   {/* {seedToPlace && <div className="p-3 mb-4 rounded-xl bg-black/20">
   <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Graine de connaissance</div> </div>
   <div className="text-xs mb-2 opacity-70">Ajoute tes connaissances dans ton palais pour voir temps réel l'état de ta mémoire</div>
   <div>
      {seedToPlace ? <div onClick={() => {
        //    setSelectedDeco({type: "pc", uwp: profileWorkspace?.id, n: missingN(packed_seeds)[0] })
         }}
         className={`${seedToPlace ?  "bg-gradient-to-b  from-indigo-500  to-blue-500" : " opacity-60 bg-indigo-800 "} border-black/90 rounded-xl border-2 border-b-4 col-span-3 md:col-span-5 text-white   lg:col-span-8 overflow-hidden relative fredoka text-shadow flex items-center justify-between gap-4 px-3 font-bold text-[18px] h-[70px] fond-bold text-center border-2 my-4 p-1 rounded overflow-visible `}>
                   <div className="text-left font-base">Planter des graines<div className="text-left text-xs nunito">Pour muscler ta mémoire et ne rien oublier</div> </div>
                   <img src="/images/seeds/blue-2.png" className="absolute  opacity-[70%] right-[-10px] -top-[36px] h-[120px] " />
                   <img src="/images/seeds/green-2.png" className="absolute  opacity-[70%] right-[-10px] -top-[6px] h-[60px] " />
                   <img src="/images/seeds/blue-2.png" className="absolute  opacity-[70%] right-[40px] -top-[12px] h-[80px] " />
                   {seedToPlace? <div className="text-shadow absolute -top-2 right-2 self-end capitalize text-[12px] items-center flex gap-[4px] bg-red-500 rounded-xl px-[8px] py-[1px]"><span className="text-[8px]">x</span> {Math.ceil(seedToPlace)} </div> : ''}
       </div> : <div className="text-sm opacity-[0.7] italic my-2 text-center">Aucune plante disponible pour le moment</div>}

                 
            
       </div>
       </div>} */}


        <div onClick={() => {setEditDeco(true); setShowModalForest()}} className="p-3 py-2 cursor-pointer items-center mb-4 rounded-xl gap-4 flex border-black/50  bg-indigo-800 border-2 border-b-4 ">
            {/* <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Mode éditions</div> </div> */}
             <img src={'/images/UX/pelle.png'} className="h-10"/>
             <div className="">
                <div className="text-md">Mode édition</div>
                <div className="text-sm text-white/80">Personnalise ta forêt avec les objets que tu possèdes</div>
             </div>

        </div>

       <div className="p-3 mb-4 rounded-xl bg-black/20">
            <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Acheter des décoration</div> </div>

            <div className="text-xs mb-2 opacity-70">Débloque des décorations et personnalise ton palais mental pour le rendre encore plus mémorable</div>
            <div className='grid grid-cols-4   md:grid-cols-5 lg:grid-cols-8 items-center gap-2 touch-scroll text-white mb-2  '>  
                {imageWithData.filter(t => !t.edition && t.isLocked && !t.admin && t.unlock && !profileWorkspace?.unlocked_deco?.find(u => u == t.id)).map(t => renderDeco(t))}
            </div>
            <br/>

        </div>

   <div className="p-3 mb-4 rounded-xl bg-black/20">
   <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Terraformation</div></div>
   <div className="text-xs mb-2 opacity-70">Change la forme de ton île</div>

   <div className='grid grid-cols-4   md:grid-cols-5 lg:grid-cols-8 items-center gap-2 touch-scroll text-white mb-2  '>  
   {/* {imageWithData.filter(t => !t.edition && !t.admin && t.terra)
   .map(t => t.name == "base" ? {...t, nb: 0 - usedBase} : t)
   .map(t => t.name == "water" ? {...t, src: "/images/deco/water-top.png"} : t)
   .filter(t => !(t.nb != null && t.nb <= 0))
   .map(t => renderDeco(t))} */}
   
   </div>Bientôt disponible
   
   </div>

   <div className="mt-[160px] text-pink-500 cursor-pointer  text-center" onClick={() => {
                     sbUpdate('profiles_workspaces', profileWorkspace?.id, {
                        extension_lvl: 1, 
                        house_lvl: 0,
                        xp_spend: 0,
                        elixirs_spend: 0,
                        map_deco: null})
                        
                        window.location.reload()
                     
                }} >

                    Réinitialiser mon palais
                </div>

  


   
   {/* <div className="p-3 mb-4 rounded-xl bg-black/20">
   <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Spécial</div> </div>
   
   {
     !hasHouse && <div onClick={() => {
     }} className="bg-gradient-to-b from-indigo-500  to-blue-500  text-white   lg:col-span-8 relative fredoka text-shadow flex items-center gap-4 px-3 font-bold text-[18px] h-[70px] fond-bold text-center border-2 border-b-4 border-black/90  p-1 rounded-xl">
       <img className="h-[80px]" src="/images/seeds/house/house_0.png"/>
       Maison du savoir</div>
   }
   <div onClick={() => {
      }} className={`${territory.canUpgrade ?  "bg-gradient-to-b from-indigo-500  to-blue-500" : " border-purple-800 opacity-60 bg-indigo-800"} col-span-3 md:col-span-5 text-white   lg:col-span-8 relative fredoka text-shadow flex items-center justify-between gap-4 px-3 font-bold text-[18px] h-[70px] fond-bold text-center  border-2 border-b-4 border-black/90  p-1 rounded-xl `}> 
      {territory.canUpgrade &&<div className="h-4 w-4 heartbeat-sm bg-red-500 rounded-full absolute -top-2 -right-2"></div>}
     <div>Extension du territoire<div className="text-left text-xs ">niv {profileWorkspace?.extension_lvl || 0} → niv {(profileWorkspace?.extension_lvl || 0) +1}</div> </div>
     <img src="/images/deco/upgrade_extension.png" className=" absolute opacity-[90%] right-[10px] -top-[8px] h-[60px] " />
     <div className="absolute bottom-2 flex right-2">
       <div className="text-shadow   self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">{priceExtension[lvlExtension]?.elixir} <img className='h-[12px] w-[12px]' src={'/images/deco/elixir.png'}/></div>
       <div className="text-shadow   self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">{priceExtension[lvlExtension]?.gold} <img className='h-[12px] w-[12px]' src={'/images/icons/coin-xp.png'}/></div>
       {!territory.enoughSeeds && <div  className="text-shadow  self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">xx <img className='h-[12px] scale-[3] -mt-2 w-[12px]' src={'/images/seeds/blue-2.png'}/></div>}
     </div>
   </div>
   </div> */}
   </div>
     }
    
      
    
     
    
     {/* {true && userCards?.filter(uc => !uc.x && uc.x != 0).map(uc => <div onClick={() => setSelectedCard(uc)} className={`${selectedCard?.id == uc.id ? "border-4 border-yellow-500" : ""}  bg-white w-[80px] rounded b border-2`}>
       <img src={uc.card?.img_400 || uc.card?.img } className="w-full h-[60px]"/>
       <div className="p-2 text-slate-600">{uc.card?.term} {uc.x}</div>
       </div>)} */}

       

     {/* { decorationMode == "decks" && userDecks?.filter(ut => !ut.x && ut.x != 0).map(ut => <div onClick={() => setSelectedDeck(ut)} className={`${selectedDeck?.id == ut.id ? "border-4 border-yellow-500" : ""} text-center w-[160px] rounded b`}>
     <img src={ut.image?.src} className="w-full h-[60px]"/>
     <div className="p-2 bg-white/30 mt-2 rounded-full px-4 py-1 text-white ">{ut.deck?.name} {ut.x}</div>
     </div>)} */}

     
       
       <div className="mt-8">
         {/* <div className="p-4 mx-auto text-center  rounded-xl border-2 border-whiter/30 py-1 text-white" onClick={() => resetForest()}>Reset la forêt</div> */}
         {/* <div className="p-4 mx-auto text-center  rounded-xl border-2 border-white/10 py-1 text-white/60 lg:hover:scale-125" onClick={() => initForestObj(profileWorkspace?.id)}>Réinitialiser la forêt!</div> */}

       </div>
     <div className='mb-4 flex gap-4 items-center justify-between'>
   
       <div className='flex items-center gap-4'>
         <div>
      </div>

     {/* <div className='flex gap-3 rounded-xl px-3 py-1 items-center font-semibold text-white' style={{backgroundColor: 'rgba(0,0,0,0.4)'}}>{money} <img className='h-5 w-5' src={'/images/icons/coin-xp.png'}/></div> */}
     </div>

     <div onClick={() => {setShowModalForest()}}   className="absolute top-4 right-4 text-white z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700">
       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
         <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
       </svg>
     </div>
    
     </div>

    

    
     {/* {selectedDeco?.id == "deco" && <div className='flex gap-4 !bg-red-400'>
     
     {images.filter(el => el.deco).reverse().map(d => <div onClick={() => {(money - d.price > 0  || d.price == 0) && setSelectedDeco(d); document.body.style.cursor = `url('/${images.find(i => i.name == d.img).src}')` ;
}} className={`${decorationMode?.name == d.name ? "text-yellow-300" : "text-gray-200"} relative`}>
       <img src={images.find(i => i.name == d.img).src} className={` ${decorationMode?.name == d.name ? "border-yellow-200 scale-115 mb-1 border-4 -rotate-3" : (money - d.price > 0  || d.price == 0) ? " border-yellow-500" : "border-gray-500" } h-[80px] mb-1 bg-slate-800  border-2  rounded h-24 transition cursor-pointer lg:hover:scale-110 lg:hover:rotate-6`}/>
       {d?.displayName}
       <div>
       {d.price ? <div className='absolute  top-1 right-1 flex justify-end gap-2 rounded-xl px-2  items-center font-semibold text-white' style={{backgroundColor: 'rgba(0,0,0,0.45)'}}>{d.price} <img className='h-5 w-5' src={'/images/icons/coin-xp.png'}/></div> : ""}
       </div>
       </div>)}
     </div>} */}
   
   </div>
}


export {SeedStore}