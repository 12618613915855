
const ile3_pc = [
    {
        "name": "bourse_l",
        "n": 35,
        "x": 18,
        "y": 20
    },
    {
        "name": "bourse_l",
        "n": 36,
        "x": 18,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 37,
        "x": 19,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 38,
        "x": 20,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 39,
        "x": 21,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 40,
        "x": 22,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 41,
        "x": 22,
        "y": 22
    },
    {
        "name": "bourse_l",
        "n": 42,
        "x": 22,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 43,
        "x": 22,
        "y": 24
    },
    {
        "name": "bourse_l",
        "n": 44,
        "x": 21,
        "y": 24
    },
    {
        "name": "bourse_l",
        "n": 45,
        "x": 20,
        "y": 24
    },
    {
        "name": "bourse_l",
        "n": 46,
        "x": 20,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 47,
        "x": 19,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 48,
        "x": 18,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 49,
        "x": 17,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 50,
        "x": 16,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 51,
        "x": 16,
        "y": 22
    },
    {
        "name": "bourse_l",
        "n": 52,
        "x": 16,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 53,
        "x": 15,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 54,
        "x": 14,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 55,
        "x": 13,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 56,
        "x": 13,
        "y": 20
    },
    {
        "name": "bourse_l",
        "n": 57,
        "x": 13,
        "y": 19
    },
    {
        "name": "bourse_l",
        "n": 58,
        "x": 13,
        "y": 18
    },
    {
        "name": "bourse_l",
        "n": 59,
        "x": 13,
        "y": 17
    },
    {
        "name": "bourse_l",
        "n": 60,
        "x": 12,
        "y": 17
    },
    {
        "name": "bourse_l",
        "n": 61,
        "x": 12,
        "y": 16
    },
    {
        "name": "bourse_l",
        "n": 62,
        "x": 11,
        "y": 16
    },
    {
        "name": "bourse_l",
        "n": 63,
        "x": 10,
        "y": 16
    },
    {
        "name": "bourse_l",
        "n": 64,
        "x": 9,
        "y": 16
    },
    {
        "name": "bourse_l",
        "n": 65,
        "x": 9,
        "y": 17
    },
    {
        "name": "bourse_l",
        "n": 66,
        "x": 9,
        "y": 18
    },
    {
        "name": "bourse_l",
        "n": 67,
        "x": 9,
        "y": 19
    },
    {
        "name": "bourse_l",
        "n": 68,
        "x": 10,
        "y": 19
    },
    {
        "name": "bourse_l",
        "n": 69,
        "x": 11,
        "y": 19
    },
    {
        "name": "bourse_l",
        "n": 70,
        "x": 11,
        "y": 20
    },
    {
        "name": "bourse_l",
        "n": 71,
        "x": 11,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 72,
        "x": 10,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 73,
        "x": 9,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 74,
        "x": 9,
        "y": 22
    },
    {
        "name": "bourse_l",
        "n": 75,
        "x": 9,
        "y": 23
    }
]

// 1 de décallage
const ile4_pc = [
    {
        "name": "bourse_l",
        "n": 77,
        "x": 9,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 78,
        "x": 9,
        "y": 26
    },
    {
        "name": "bourse_l",
        "n": 79,
        "x": 9,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 80,
        "x": 10,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 81,
        "x": 11,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 82,
        "x": 12,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 83,
        "x": 12,
        "y": 26
    },
    {
        "name": "bourse_l",
        "n": 84,
        "x": 12,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 85,
        "x": 13,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 86,
        "x": 14,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 87,
        "x": 14,
        "y": 26
    },
    {
        "name": "bourse_l",
        "n": 88,
        "x": 14,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 89,
        "x": 15,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 90,
        "x": 16,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 91,
        "x": 17,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 92,
        "x": 17,
        "y": 26
    },
    {
        "name": "bourse_l",
        "n": 93,
        "x": 17,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 94,
        "x": 18,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 95,
        "x": 19,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 96,
        "x": 20,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 97,
        "x": 21,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 98,
        "x": 22,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 99,
        "x": 23,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 100,
        "x": 15,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 101,
        "x": 16,
        "y": 25
    }
]

const ile5_pc = [
    {
        "name": "bourse_l",
        "n": 102,
        "x": 24,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 103,
        "x": 25,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 104,
        "x": 26,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 105,
        "x": 27,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 106,
        "x": 28,
        "y": 26
    },
    {
        "name": "bourse_l",
        "n": 107,
        "x": 28,
        "y": 27
    },
    {
        "name": "bourse_l",
        "n": 108,
        "x": 28,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 109,
        "x": 28,
        "y": 24
    },
    {
        "name": "bourse_l",
        "n": 110,
        "x": 27,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 111,
        "x": 28,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 112,
        "x": 26,
        "y": 22
    },
    {
        "name": "bourse_l",
        "n": 113,
        "x": 26,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 114,
        "x": 26,
        "y": 24
    },
    {
        "name": "bourse_l",
        "n": 115,
        "x": 26,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 116,
        "x": 25,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 117,
        "x": 24,
        "y": 25
    },
    {
        "name": "bourse_l",
        "n": 118,
        "x": 24,
        "y": 24
    },
    {
        "name": "bourse_l",
        "n": 119,
        "x": 24,
        "y": 23
    },
    {
        "name": "bourse_l",
        "n": 120,
        "x": 24,
        "y": 22
    },
    {
        "name": "bourse_l",
        "n": 121,
        "x": 24,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 122,
        "x": 25,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 123,
        "x": 26,
        "y": 21
    },
    {
        "name": "bourse_l",
        "n": 124,
        "x": 26,
        "y": 19
    },
    {
        "name": "bourse_l",
        "n": 125,
        "x": 27,
        "y": 19
    },
    {
        "name": "bourse_l",
        "n": 126,
        "x": 27,
        "y": 18
    },
    {
        "name": "bourse_l",
        "n": 127,
        "x": 26,
        "y": 18
    },
    {
        "name": "bourse_l",
        "n": 128,
        "x": 26,
        "y": 16
    },
    {
        "name": "bourse_l",
        "n": 129,
        "x": 27,
        "y": 16
    },
    {
        "name": "bourse_l",
        "n": 130,
        "x": 27,
        "y": 15
    },
    {
        "name": "bourse_l",
        "n": 131,
        "x": 26,
        "y": 15
    },
    {
        "name": "bourse_l",
        "n": 132,
        "x": 26,
        "y": 13
    },
    {
        "name": "bourse_l",
        "n": 133,
        "x": 27,
        "y": 13
    },
    {
        "name": "bourse_l",
        "n": 134,
        "x": 27,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 135,
        "x": 26,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 136,
        "x": 26,
        "y": 10
    },
    {
        "name": "bourse_l",
        "n": 137,
        "x": 27,
        "y": 10
    },
    {
        "name": "bourse_l",
        "n": 138,
        "x": 27,
        "y": 9
    },
    {
        "name": "bourse_l",
        "n": 139,
        "x": 26,
        "y": 9
    },
    {
        "name": "bourse_l",
        "n": 140,
        "x": 24,
        "y": 19
    },
    {
        "name": "bourse_l",
        "n": 141,
        "x": 24,
        "y": 18
    },
    {
        "name": "bourse_l",
        "n": 142,
        "x": 24,
        "y": 16
    },
    {
        "name": "bourse_l",
        "n": 143,
        "x": 24,
        "y": 15
    },
    {
        "name": "bourse_l",
        "n": 144,
        "x": 24,
        "y": 13
    },
    {
        "name": "bourse_l",
        "n": 145,
        "x": 24,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 146,
        "x": 24,
        "y": 10
    },
    {
        "name": "bourse_l",
        "n": 147,
        "x": 24,
        "y": 9
    }
]

const ile4 = [
    {
        "name": "base",
        "x": 9,
        "y": 25
    },
    {
        "name": "base",
        "x": 10,
        "y": 25
    },
    {
        "name": "base",
        "x": 8,
        "y": 25
    },
    {
        "name": "base",
        "x": 11,
        "y": 25
    },
    {
        "name": "base",
        "x": 12,
        "y": 25
    },
    {
        "name": "base",
        "x": 13,
        "y": 25
    },
    {
        "name": "base",
        "x": 14,
        "y": 25
    },
    {
        "name": "base",
        "x": 15,
        "y": 25
    },
    {
        "name": "base",
        "x": 16,
        "y": 25
    },
    {
        "name": "base",
        "x": 18,
        "y": 25
    },
    {
        "name": "base",
        "x": 17,
        "y": 25
    },
    {
        "name": "base",
        "x": 17,
        "y": 26
    },
    {
        "name": "base",
        "x": 18,
        "y": 26
    },
    {
        "name": "base",
        "x": 16,
        "y": 27
    },
    {
        "name": "base",
        "x": 16,
        "y": 26
    },
    {
        "name": "base",
        "x": 15,
        "y": 26
    },
    {
        "name": "base",
        "x": 15,
        "y": 27
    },
    {
        "name": "base",
        "x": 17,
        "y": 27
    },
    {
        "name": "base",
        "x": 18,
        "y": 27
    },
    {
        "name": "base",
        "x": 18,
        "y": 24
    },
    {
        "name": "base",
        "x": 17,
        "y": 24
    },
    {
        "name": "base",
        "x": 16,
        "y": 24
    },
    {
        "name": "base",
        "x": 15,
        "y": 24
    },
    {
        "name": "base",
        "x": 14,
        "y": 24
    },
    {
        "name": "base",
        "x": 13,
        "y": 24
    },
    {
        "name": "base",
        "x": 12,
        "y": 24
    },
    {
        "name": "base",
        "x": 11,
        "y": 24
    },
    {
        "name": "base",
        "x": 10,
        "y": 24
    },
    {
        "name": "base",
        "x": 9,
        "y": 24
    },
    {
        "name": "base",
        "x": 8,
        "y": 24
    },
    {
        "name": "base",
        "x": 8,
        "y": 26
    },
    {
        "name": "base",
        "x": 9,
        "y": 26
    },
    {
        "name": "base",
        "x": 10,
        "y": 26
    },
    {
        "name": "base",
        "x": 11,
        "y": 26
    },
    {
        "name": "base",
        "x": 12,
        "y": 26
    },
    {
        "name": "base",
        "x": 13,
        "y": 26
    },
    {
        "name": "base",
        "x": 14,
        "y": 26
    },
    {
        "name": "base",
        "x": 14,
        "y": 27
    },
    {
        "name": "base",
        "x": 13,
        "y": 27
    },
    {
        "name": "base",
        "x": 12,
        "y": 27
    },
    {
        "name": "base",
        "x": 11,
        "y": 27
    },
    {
        "name": "base",
        "x": 10,
        "y": 27
    },
    {
        "name": "base",
        "x": 9,
        "y": 27
    },
    {
        "name": "base",
        "x": 8,
        "y": 27
    },
    {
        "name": "base",
        "x": 19,
        "y": 27
    },
    {
        "name": "base",
        "x": 19,
        "y": 26
    },
    {
        "name": "base",
        "x": 20,
        "y": 26
    },
    {
        "name": "base",
        "x": 20,
        "y": 27
    },
    {
        "name": "base",
        "x": 21,
        "y": 27
    },
    {
        "name": "base",
        "x": 21,
        "y": 26
    },
    {
        "name": "base",
        "x": 22,
        "y": 26
    },
    {
        "name": "base",
        "x": 22,
        "y": 27
    },
    {
        "name": "base",
        "x": 23,
        "y": 27
    },
    {
        "name": "base",
        "x": 23,
        "y": 26
    },
    {
        "name": "base",
        "x": 23,
        "y": 28
    },
    {
        "name": "base",
        "x": 22,
        "y": 28
    },
    {
        "name": "base",
        "x": 21,
        "y": 28
    },
    {
        "name": "base",
        "x": 20,
        "y": 28
    },
    {
        "name": "base",
        "x": 19,
        "y": 28
    },
    {
        "name": "base",
        "x": 18,
        "y": 28
    },
    {
        "name": "base",
        "x": 17,
        "y": 28
    },
    {
        "name": "base",
        "x": 16,
        "y": 28
    },
    {
        "name": "base",
        "x": 15,
        "y": 28
    },
    {
        "name": "base",
        "x": 14,
        "y": 28
    },
    {
        "name": "base",
        "x": 13,
        "y": 28
    },
    {
        "name": "base",
        "x": 12,
        "y": 28
    },
    {
        "name": "base",
        "x": 11,
        "y": 28
    },
    {
        "name": "base",
        "x": 10,
        "y": 28
    },
    {
        "name": "base",
        "x": 9,
        "y": 28
    },
    {
        "name": "base",
        "x": 8,
        "y": 28
    },
    {
        "name": "way",
        "x": 8,
        "y": 24
    },
    {
        "name": "way",
        "x": 8,
        "y": 24
    },
    {
        "name": "way",
        "x": 9,
        "y": 24
    },
    {
        "name": "way",
        "x": 10,
        "y": 24
    },
    {
        "name": "way",
        "x": 11,
        "y": 24
    },
    {
        "name": "way",
        "x": 12,
        "y": 24
    },
    {
        "name": "way",
        "x": 13,
        "y": 24
    },
    {
        "name": "way",
        "x": 14,
        "y": 24
    },
    {
        "name": "way",
        "x": 15,
        "y": 24
    },
    {
        "name": "way",
        "x": 16,
        "y": 24
    },
    {
        "name": "way",
        "x": 17,
        "y": 24
    },
    {
        "name": "way",
        "x": 18,
        "y": 24
    },
    {
        "name": "way",
        "x": 18,
        "y": 25
    },
    {
        "name": "way",
        "x": 18,
        "y": 26
    },
    {
        "name": "way",
        "x": 19,
        "y": 26
    },
    {
        "name": "way",
        "x": 20,
        "y": 26
    },
    {
        "name": "way",
        "x": 21,
        "y": 26
    },
    {
        "name": "way",
        "x": 22,
        "y": 26
    },
    {
        "name": "way",
        "x": 23,
        "y": 26
    }
]

const ile5 = [
    {
        "name": "base",
        "x": 25,
        "y": 9
    },
    {
        "name": "base",
        "x": 25,
        "y": 8
    },
    {
        "name": "base",
        "x": 24,
        "y": 8
    },
    {
        "name": "base",
        "x": 24,
        "y": 9
    },
    {
        "name": "base",
        "x": 24,
        "y": 10
    },
    {
        "name": "base",
        "x": 25,
        "y": 10
    },
    {
        "name": "base",
        "x": 24,
        "y": 11
    },
    {
        "name": "base",
        "x": 25,
        "y": 11
    },
    {
        "name": "base",
        "x": 24,
        "y": 12
    },
    {
        "name": "base",
        "x": 25,
        "y": 12
    },
    {
        "name": "base",
        "x": 24,
        "y": 13
    },
    {
        "name": "base",
        "x": 25,
        "y": 13
    },
    {
        "name": "base",
        "x": 24,
        "y": 14
    },
    {
        "name": "base",
        "x": 25,
        "y": 14
    },
    {
        "name": "base",
        "x": 24,
        "y": 15
    },
    {
        "name": "base",
        "x": 25,
        "y": 15
    },
    {
        "name": "base",
        "x": 24,
        "y": 16
    },
    {
        "name": "base",
        "x": 25,
        "y": 16
    },
    {
        "name": "base",
        "x": 24,
        "y": 17
    },
    {
        "name": "base",
        "x": 25,
        "y": 17
    },
    {
        "name": "base",
        "x": 24,
        "y": 18
    },
    {
        "name": "base",
        "x": 25,
        "y": 18
    },
    {
        "name": "base",
        "x": 24,
        "y": 19
    },
    {
        "name": "base",
        "x": 25,
        "y": 20
    },
    {
        "name": "base",
        "x": 25,
        "y": 19
    },
    {
        "name": "base",
        "x": 24,
        "y": 20
    },
    {
        "name": "base",
        "x": 26,
        "y": 8
    },
    {
        "name": "base",
        "x": 26,
        "y": 9
    },
    {
        "name": "base",
        "x": 26,
        "y": 10
    },
    {
        "name": "base",
        "x": 26,
        "y": 11
    },
    {
        "name": "base",
        "x": 26,
        "y": 12
    },
    {
        "name": "base",
        "x": 26,
        "y": 13
    },
    {
        "name": "base",
        "x": 26,
        "y": 15
    },
    {
        "name": "base",
        "x": 26,
        "y": 14
    },
    {
        "name": "base",
        "x": 26,
        "y": 16
    },
    {
        "name": "base",
        "x": 26,
        "y": 17
    },
    {
        "name": "base",
        "x": 26,
        "y": 19
    },
    {
        "name": "base",
        "x": 26,
        "y": 20
    },
    {
        "name": "base",
        "x": 26,
        "y": 18
    },
    {
        "name": "base",
        "x": 27,
        "y": 20
    },
    {
        "name": "base",
        "x": 27,
        "y": 19
    },
    {
        "name": "base",
        "x": 27,
        "y": 18
    },
    {
        "name": "base",
        "x": 27,
        "y": 17
    },
    {
        "name": "base",
        "x": 27,
        "y": 16
    },
    {
        "name": "base",
        "x": 27,
        "y": 15
    },
    {
        "name": "base",
        "x": 27,
        "y": 14
    },
    {
        "name": "base",
        "x": 27,
        "y": 13
    },
    {
        "name": "base",
        "x": 27,
        "y": 12
    },
    {
        "name": "base",
        "x": 27,
        "y": 11
    },
    {
        "name": "base",
        "x": 27,
        "y": 10
    },
    {
        "name": "base",
        "x": 27,
        "y": 9
    },
    {
        "name": "base",
        "x": 27,
        "y": 8
    },
    {
        "name": "base",
        "x": 24,
        "y": 21
    },
    {
        "name": "base",
        "x": 25,
        "y": 21
    },
    {
        "name": "base",
        "x": 26,
        "y": 21
    },
    {
        "name": "base",
        "x": 27,
        "y": 21
    },
    {
        "name": "base",
        "x": 24,
        "y": 22
    },
    {
        "name": "base",
        "x": 25,
        "y": 22
    },
    {
        "name": "base",
        "x": 26,
        "y": 22
    },
    {
        "name": "base",
        "x": 27,
        "y": 22
    },
    {
        "name": "base",
        "x": 24,
        "y": 23
    },
    {
        "name": "base",
        "x": 25,
        "y": 23
    },
    {
        "name": "base",
        "x": 26,
        "y": 23
    },
    {
        "name": "base",
        "x": 27,
        "y": 23
    },
    {
        "name": "base",
        "x": 24,
        "y": 24
    },
    {
        "name": "base",
        "x": 25,
        "y": 24
    },
    {
        "name": "base",
        "x": 26,
        "y": 24
    },
    {
        "name": "base",
        "x": 27,
        "y": 24
    },
    {
        "name": "base",
        "x": 24,
        "y": 25
    },
    {
        "name": "base",
        "x": 25,
        "y": 25
    },
    {
        "name": "base",
        "x": 26,
        "y": 25
    },
    {
        "name": "base",
        "x": 27,
        "y": 25
    },
    {
        "name": "base",
        "x": 27,
        "y": 26
    },
    {
        "name": "base",
        "x": 26,
        "y": 26
    },
    {
        "name": "base",
        "x": 25,
        "y": 26
    },
    {
        "name": "base",
        "x": 24,
        "y": 26
    },
    {
        "name": "base",
        "x": 24,
        "y": 27
    },
    {
        "name": "base",
        "x": 24,
        "y": 28
    },
    {
        "name": "base",
        "x": 25,
        "y": 28
    },
    {
        "name": "base",
        "x": 25,
        "y": 27
    },
    {
        "name": "base",
        "x": 26,
        "y": 27
    },
    {
        "name": "base",
        "x": 27,
        "y": 27
    },
    {
        "name": "base",
        "x": 26,
        "y": 28
    },
    {
        "name": "base",
        "x": 27,
        "y": 28
    },
    {
        "name": "base",
        "x": 28,
        "y": 8
    },
    {
        "name": "base",
        "x": 28,
        "y": 9
    },
    {
        "name": "base",
        "x": 28,
        "y": 11
    },
    {
        "name": "base",
        "x": 28,
        "y": 10
    },
    {
        "name": "base",
        "x": 28,
        "y": 12
    },
    {
        "name": "base",
        "x": 28,
        "y": 13
    },
    {
        "name": "base",
        "x": 28,
        "y": 14
    },
    {
        "name": "base",
        "x": 28,
        "y": 15
    },
    {
        "name": "base",
        "x": 28,
        "y": 16
    },
    {
        "name": "base",
        "x": 28,
        "y": 17
    },
    {
        "name": "base",
        "x": 28,
        "y": 18
    },
    {
        "name": "base",
        "x": 28,
        "y": 19
    },
    {
        "name": "base",
        "x": 28,
        "y": 21
    },
    {
        "name": "base",
        "x": 28,
        "y": 20
    },
    {
        "name": "base",
        "x": 28,
        "y": 22
    },
    {
        "name": "base",
        "x": 28,
        "y": 23
    },
    {
        "name": "base",
        "x": 28,
        "y": 24
    },
    {
        "name": "base",
        "x": 28,
        "y": 25
    },
    {
        "name": "base",
        "x": 28,
        "y": 26
    },
    {
        "name": "base",
        "x": 28,
        "y": 27
    },
    {
        "name": "base",
        "x": 28,
        "y": 28
    }
]

const ile5_deco = [
    [
       
        {
            "name": "way",
            "x": 24,
            "y": 26
        },
        {
            "name": "way",
            "x": 25,
            "y": 26
        },
        {
            "name": "way",
            "x": 25,
            "y": 24
        },
        {
            "name": "way",
            "x": 25,
            "y": 23
        },
        {
            "name": "way",
            "x": 25,
            "y": 22
        },
        {
            "name": "way",
            "x": 25,
            "y": 20
        },
        {
            "name": "way",
            "x": 26,
            "y": 20
        },
        {
            "name": "way",
            "x": 27,
            "y": 20
        },
        {
            "name": "way",
            "x": 28,
            "y": 20
        }
    ]
]


const ile6 = [
    {
        "name": "base",
        "x": 32,
        "y": 29
    },
    {
        "name": "base",
        "x": 32,
        "y": 30
    },
    {
        "name": "base",
        "x": 32,
        "y": 31
    },
    {
        "name": "base",
        "x": 33,
        "y": 31
    },
    {
        "name": "base",
        "x": 33,
        "y": 30
    },
    {
        "name": "base",
        "x": 33,
        "y": 29
    },
    {
        "name": "base",
        "x": 34,
        "y": 31
    },
    {
        "name": "base",
        "x": 35,
        "y": 31
    },
    {
        "name": "base",
        "x": 35,
        "y": 30
    },
    {
        "name": "base",
        "x": 35,
        "y": 29
    },
    {
        "name": "base",
        "x": 36,
        "y": 29
    },
    {
        "name": "base",
        "x": 37,
        "y": 29
    },
    {
        "name": "base",
        "x": 38,
        "y": 30
    },
    {
        "name": "base",
        "x": 37,
        "y": 30
    },
    {
        "name": "base",
        "x": 37,
        "y": 31
    },
    {
        "name": "base",
        "x": 38,
        "y": 31
    },
    {
        "name": "base",
        "x": 39,
        "y": 31
    },
    {
        "name": "base",
        "x": 39,
        "y": 30
    },
    {
        "name": "base",
        "x": 39,
        "y": 29
    },
    {
        "name": "base",
        "x": 40,
        "y": 29
    },
    {
        "name": "base",
        "x": 40,
        "y": 30
    },
    {
        "name": "base",
        "x": 40,
        "y": 31
    },
    {
        "name": "empty",
        "x": 38,
        "y": 30
    },
    {
        "name": "base",
        "x": 32,
        "y": 32
    },
    {
        "name": "base",
        "x": 33,
        "y": 32
    },
    {
        "name": "base",
        "x": 32,
        "y": 33
    },
    {
        "name": "base",
        "x": 31,
        "y": 33
    },
    {
        "name": "base",
        "x": 30,
        "y": 33
    },
    {
        "name": "base",
        "x": 29,
        "y": 33
    },
    {
        "name": "base",
        "x": 28,
        "y": 33
    },
    {
        "name": "base",
        "x": 27,
        "y": 33
    },
    {
        "name": "base",
        "x": 26,
        "y": 33
    },
    {
        "name": "base",
        "x": 25,
        "y": 33
    },
    {
        "name": "base",
        "x": 24,
        "y": 33
    },
    {
        "name": "base",
        "x": 24,
        "y": 34
    },
    {
        "name": "base",
        "x": 24,
        "y": 36
    },
    {
        "name": "base",
        "x": 24,
        "y": 35
    },
    {
        "name": "base",
        "x": 24,
        "y": 37
    },
    {
        "name": "base",
        "x": 24,
        "y": 38
    },
    {
        "name": "base",
        "x": 24,
        "y": 39
    },
    {
        "name": "base",
        "x": 25,
        "y": 39
    },
    {
        "name": "base",
        "x": 26,
        "y": 39
    },
    {
        "name": "base",
        "x": 27,
        "y": 39
    },
    {
        "name": "base",
        "x": 28,
        "y": 39
    },
    {
        "name": "base",
        "x": 29,
        "y": 39
    },
    {
        "name": "base",
        "x": 30,
        "y": 39
    },
    {
        "name": "base",
        "x": 30,
        "y": 40
    },
    {
        "name": "base",
        "x": 30,
        "y": 41
    },
    {
        "name": "base",
        "x": 30,
        "y": 42
    },
    {
        "name": "base",
        "x": 30,
        "y": 43
    },
    {
        "name": "base",
        "x": 30,
        "y": 44
    },
    {
        "name": "base",
        "x": 30,
        "y": 45
    },
    {
        "name": "base",
        "x": 30,
        "y": 46
    },
    {
        "name": "base",
        "x": 31,
        "y": 46
    },
    {
        "name": "base",
        "x": 32,
        "y": 46
    },
    {
        "name": "base",
        "x": 33,
        "y": 47
    },
    {
        "name": "base",
        "x": 33,
        "y": 46
    },
    {
        "name": "base",
        "x": 34,
        "y": 46
    },
    {
        "name": "base",
        "x": 35,
        "y": 46
    },
    {
        "name": "base",
        "x": 36,
        "y": 46
    },
    {
        "name": "base",
        "x": 37,
        "y": 46
    },
    {
        "name": "base",
        "x": 37,
        "y": 45
    },
    {
        "name": "base",
        "x": 37,
        "y": 44
    },
    {
        "name": "base",
        "x": 37,
        "y": 43
    },
    {
        "name": "base",
        "x": 37,
        "y": 42
    },
    {
        "name": "base",
        "x": 37,
        "y": 41
    },
    {
        "name": "base",
        "x": 37,
        "y": 40
    },
    {
        "name": "base",
        "x": 37,
        "y": 38
    },
    {
        "name": "base",
        "x": 37,
        "y": 39
    },
    {
        "name": "base",
        "x": 37,
        "y": 37
    },
    {
        "name": "base",
        "x": 37,
        "y": 36
    },
    {
        "name": "base",
        "x": 38,
        "y": 36
    },
    {
        "name": "base",
        "x": 39,
        "y": 36
    },
    {
        "name": "base",
        "x": 40,
        "y": 36
    },
    {
        "name": "base",
        "x": 42,
        "y": 36
    },
    {
        "name": "base",
        "x": 41,
        "y": 36
    },
    {
        "name": "base",
        "x": 43,
        "y": 36
    },
    {
        "name": "base",
        "x": 44,
        "y": 36
    },
    {
        "name": "base",
        "x": 44,
        "y": 35
    },
    {
        "name": "base",
        "x": 44,
        "y": 33
    },
    {
        "name": "base",
        "x": 44,
        "y": 34
    },
    {
        "name": "base",
        "x": 44,
        "y": 32
    },
    {
        "name": "base",
        "x": 44,
        "y": 31
    },
    {
        "name": "base",
        "x": 44,
        "y": 30
    },
    {
        "name": "base",
        "x": 44,
        "y": 29
    },
    {
        "name": "base",
        "x": 43,
        "y": 29
    },
    {
        "name": "base",
        "x": 42,
        "y": 29
    },
    {
        "name": "base",
        "x": 41,
        "y": 29
    },
    {
        "name": "base",
        "x": 25,
        "y": 34
    },
    {
        "name": "base",
        "x": 26,
        "y": 34
    },
    {
        "name": "base",
        "x": 27,
        "y": 34
    },
    {
        "name": "base",
        "x": 28,
        "y": 34
    },
    {
        "name": "base",
        "x": 30,
        "y": 34
    },
    {
        "name": "base",
        "x": 29,
        "y": 34
    },
    {
        "name": "base",
        "x": 31,
        "y": 34
    },
    {
        "name": "base",
        "x": 33,
        "y": 34
    },
    {
        "name": "base",
        "x": 32,
        "y": 34
    },
    {
        "name": "base",
        "x": 33,
        "y": 33
    },
    {
        "name": "base",
        "x": 34,
        "y": 32
    },
    {
        "name": "base",
        "x": 34,
        "y": 33
    },
    {
        "name": "base",
        "x": 34,
        "y": 34
    },
    {
        "name": "base",
        "x": 35,
        "y": 34
    },
    {
        "name": "base",
        "x": 35,
        "y": 33
    },
    {
        "name": "base",
        "x": 35,
        "y": 32
    },
    {
        "name": "base",
        "x": 36,
        "y": 30
    },
    {
        "name": "base",
        "x": 36,
        "y": 31
    },
    {
        "name": "base",
        "x": 36,
        "y": 32
    },
    {
        "name": "base",
        "x": 36,
        "y": 34
    },
    {
        "name": "base",
        "x": 36,
        "y": 33
    },
    {
        "name": "base",
        "x": 37,
        "y": 32
    },
    {
        "name": "base",
        "x": 38,
        "y": 32
    },
    {
        "name": "base",
        "x": 40,
        "y": 32
    },
    {
        "name": "base",
        "x": 39,
        "y": 32
    },
    {
        "name": "base",
        "x": 37,
        "y": 34
    },
    {
        "name": "base",
        "x": 37,
        "y": 33
    },
    {
        "name": "base",
        "x": 38,
        "y": 34
    },
    {
        "name": "base",
        "x": 38,
        "y": 33
    },
    {
        "name": "base",
        "x": 39,
        "y": 34
    },
    {
        "name": "base",
        "x": 39,
        "y": 33
    },
    {
        "name": "base",
        "x": 40,
        "y": 33
    },
    {
        "name": "base",
        "x": 40,
        "y": 34
    },
    {
        "name": "base",
        "x": 41,
        "y": 31
    },
    {
        "name": "base",
        "x": 41,
        "y": 30
    },
    {
        "name": "base",
        "x": 43,
        "y": 30
    },
    {
        "name": "base",
        "x": 42,
        "y": 30
    },
    {
        "name": "base",
        "x": 42,
        "y": 31
    },
    {
        "name": "base",
        "x": 43,
        "y": 31
    },
    {
        "name": "base",
        "x": 43,
        "y": 32
    },
    {
        "name": "base",
        "x": 41,
        "y": 32
    },
    {
        "name": "base",
        "x": 42,
        "y": 32
    },
    {
        "name": "base",
        "x": 43,
        "y": 33
    },
    {
        "name": "base",
        "x": 42,
        "y": 33
    },
    {
        "name": "base",
        "x": 41,
        "y": 33
    },
    {
        "name": "base",
        "x": 41,
        "y": 34
    },
    {
        "name": "base",
        "x": 42,
        "y": 34
    },
    {
        "name": "base",
        "x": 43,
        "y": 34
    },
    {
        "name": "base",
        "x": 43,
        "y": 35
    },
    {
        "name": "base",
        "x": 42,
        "y": 35
    },
    {
        "name": "base",
        "x": 41,
        "y": 35
    },
    {
        "name": "base",
        "x": 40,
        "y": 35
    },
    {
        "name": "base",
        "x": 39,
        "y": 35
    },
    {
        "name": "base",
        "x": 37,
        "y": 35
    },
    {
        "name": "base",
        "x": 38,
        "y": 35
    },
    {
        "name": "base",
        "x": 26,
        "y": 38
    },
    {
        "name": "base",
        "x": 25,
        "y": 37
    },
    {
        "name": "base",
        "x": 25,
        "y": 36
    },
    {
        "name": "base",
        "x": 25,
        "y": 35
    },
    {
        "name": "base",
        "x": 26,
        "y": 35
    },
    {
        "name": "base",
        "x": 26,
        "y": 36
    },
    {
        "name": "base",
        "x": 26,
        "y": 37
    },
    {
        "name": "base",
        "x": 25,
        "y": 38
    },
    {
        "name": "base",
        "x": 28,
        "y": 38
    },
    {
        "name": "base",
        "x": 27,
        "y": 37
    },
    {
        "name": "base",
        "x": 28,
        "y": 36
    },
    {
        "name": "base",
        "x": 27,
        "y": 35
    },
    {
        "name": "base",
        "x": 27,
        "y": 36
    },
    {
        "name": "base",
        "x": 27,
        "y": 38
    },
    {
        "name": "base",
        "x": 28,
        "y": 37
    },
    {
        "name": "base",
        "x": 28,
        "y": 35
    },
    {
        "name": "base",
        "x": 30,
        "y": 35
    },
    {
        "name": "base",
        "x": 29,
        "y": 36
    },
    {
        "name": "base",
        "x": 29,
        "y": 35
    },
    {
        "name": "base",
        "x": 29,
        "y": 37
    },
    {
        "name": "base",
        "x": 29,
        "y": 38
    },
    {
        "name": "base",
        "x": 30,
        "y": 38
    },
    {
        "name": "base",
        "x": 30,
        "y": 37
    },
    {
        "name": "base",
        "x": 30,
        "y": 36
    },
    {
        "name": "base",
        "x": 31,
        "y": 35
    },
    {
        "name": "base",
        "x": 32,
        "y": 35
    },
    {
        "name": "base",
        "x": 33,
        "y": 35
    },
    {
        "name": "base",
        "x": 34,
        "y": 35
    },
    {
        "name": "base",
        "x": 35,
        "y": 35
    },
    {
        "name": "base",
        "x": 36,
        "y": 35
    },
    {
        "name": "base",
        "x": 36,
        "y": 36
    },
    {
        "name": "base",
        "x": 36,
        "y": 37
    },
    {
        "name": "base",
        "x": 36,
        "y": 38
    },
    {
        "name": "base",
        "x": 35,
        "y": 36
    },
    {
        "name": "base",
        "x": 34,
        "y": 36
    },
    {
        "name": "base",
        "x": 33,
        "y": 36
    },
    {
        "name": "base",
        "x": 31,
        "y": 36
    },
    {
        "name": "base",
        "x": 32,
        "y": 36
    },
    {
        "name": "base",
        "x": 35,
        "y": 37
    },
    {
        "name": "base",
        "x": 34,
        "y": 37
    },
    {
        "name": "base",
        "x": 33,
        "y": 37
    },
    {
        "name": "base",
        "x": 32,
        "y": 37
    },
    {
        "name": "base",
        "x": 31,
        "y": 37
    },
    {
        "name": "base",
        "x": 35,
        "y": 38
    },
    {
        "name": "base",
        "x": 34,
        "y": 38
    },
    {
        "name": "base",
        "x": 33,
        "y": 38
    },
    {
        "name": "base",
        "x": 32,
        "y": 38
    },
    {
        "name": "base",
        "x": 31,
        "y": 38
    },
    {
        "name": "base",
        "x": 36,
        "y": 39
    },
    {
        "name": "base",
        "x": 35,
        "y": 39
    },
    {
        "name": "base",
        "x": 34,
        "y": 39
    },
    {
        "name": "base",
        "x": 33,
        "y": 39
    },
    {
        "name": "base",
        "x": 32,
        "y": 39
    },
    {
        "name": "base",
        "x": 31,
        "y": 39
    },
    {
        "name": "base",
        "x": 36,
        "y": 40
    },
    {
        "name": "base",
        "x": 35,
        "y": 40
    },
    {
        "name": "base",
        "x": 34,
        "y": 40
    },
    {
        "name": "base",
        "x": 33,
        "y": 40
    },
    {
        "name": "base",
        "x": 32,
        "y": 40
    },
    {
        "name": "base",
        "x": 31,
        "y": 40
    },
    {
        "name": "base",
        "x": 31,
        "y": 41
    },
    {
        "name": "base",
        "x": 32,
        "y": 41
    },
    {
        "name": "base",
        "x": 33,
        "y": 41
    },
    {
        "name": "base",
        "x": 34,
        "y": 41
    },
    {
        "name": "base",
        "x": 35,
        "y": 41
    },
    {
        "name": "base",
        "x": 36,
        "y": 41
    },
    {
        "name": "base",
        "x": 36,
        "y": 42
    },
    {
        "name": "base",
        "x": 36,
        "y": 43
    },
    {
        "name": "base",
        "x": 36,
        "y": 44
    },
    {
        "name": "base",
        "x": 36,
        "y": 45
    },
    {
        "name": "base",
        "x": 35,
        "y": 45
    },
    {
        "name": "base",
        "x": 35,
        "y": 44
    },
    {
        "name": "base",
        "x": 35,
        "y": 43
    },
    {
        "name": "base",
        "x": 34,
        "y": 42
    },
    {
        "name": "base",
        "x": 35,
        "y": 42
    },
    {
        "name": "base",
        "x": 33,
        "y": 42
    },
    {
        "name": "base",
        "x": 32,
        "y": 42
    },
    {
        "name": "base",
        "x": 31,
        "y": 42
    },
    {
        "name": "base",
        "x": 31,
        "y": 43
    },
    {
        "name": "base",
        "x": 31,
        "y": 44
    },
    {
        "name": "base",
        "x": 31,
        "y": 45
    },
    {
        "name": "base",
        "x": 33,
        "y": 45
    },
    {
        "name": "base",
        "x": 32,
        "y": 45
    },
    {
        "name": "base",
        "x": 32,
        "y": 44
    },
    {
        "name": "base",
        "x": 33,
        "y": 44
    },
    {
        "name": "base",
        "x": 32,
        "y": 43
    },
    {
        "name": "base",
        "x": 33,
        "y": 43
    },
    {
        "name": "base",
        "x": 34,
        "y": 43
    },
    {
        "name": "base",
        "x": 34,
        "y": 45
    },
    {
        "name": "base",
        "x": 34,
        "y": 44
    },
    {
        "name": "base",
        "x": 34,
        "y": 30
    },
    {
        "name": "base",
        "x": 34,
        "y": 29
    },
    {
        "name": "base",
        "x": 38,
        "y": 29
    },
    {
        "name": "base",
        "x": 38,
        "y": 30
    },
    {
        "name": "base",
        "x": 34,
        "y": 47
    },
    {
        "name": "base",
        "x": 35,
        "y": 47
    },
    {
        "name": "base",
        "x": 36,
        "y": 47
    },
    {
        "name": "base",
        "x": 37,
        "y": 47
    },
    {
        "name": "base",
        "x": 32,
        "y": 47
    },
    {
        "name": "base",
        "x": 31,
        "y": 47
    },
    {
        "name": "base",
        "x": 30,
        "y": 47
    }
]

const ile7 = [
    {
        "name": "base",
        "x": 13,
        "y": 33
    },
    {
        "name": "base",
        "x": 14,
        "y": 33
    },
    {
        "name": "base",
        "x": 16,
        "y": 33
    },
    {
        "name": "base",
        "x": 15,
        "y": 34
    },
    {
        "name": "base",
        "x": 15,
        "y": 33
    },
    {
        "name": "base",
        "x": 16,
        "y": 34
    },
    {
        "name": "base",
        "x": 14,
        "y": 34
    },
    {
        "name": "base",
        "x": 14,
        "y": 35
    },
    {
        "name": "base",
        "x": 13,
        "y": 34
    },
    {
        "name": "base",
        "x": 13,
        "y": 35
    },
    {
        "name": "base",
        "x": 15,
        "y": 35
    },
    {
        "name": "base",
        "x": 16,
        "y": 35
    },
    {
        "name": "base",
        "x": 17,
        "y": 33
    },
    {
        "name": "base",
        "x": 18,
        "y": 33
    },
    {
        "name": "base",
        "x": 19,
        "y": 33
    },
    {
        "name": "base",
        "x": 20,
        "y": 34
    },
    {
        "name": "base",
        "x": 21,
        "y": 33
    },
    {
        "name": "base",
        "x": 20,
        "y": 33
    },
    {
        "name": "base",
        "x": 21,
        "y": 34
    },
    {
        "name": "base",
        "x": 22,
        "y": 33
    },
    {
        "name": "base",
        "x": 22,
        "y": 34
    },
    {
        "name": "base",
        "x": 22,
        "y": 35
    },
    {
        "name": "base",
        "x": 21,
        "y": 35
    },
    {
        "name": "base",
        "x": 20,
        "y": 35
    },
    {
        "name": "base",
        "x": 19,
        "y": 34
    },
    {
        "name": "base",
        "x": 19,
        "y": 35
    },
    {
        "name": "base",
        "x": 18,
        "y": 35
    },
    {
        "name": "base",
        "x": 18,
        "y": 34
    },
    {
        "name": "base",
        "x": 17,
        "y": 34
    },
    {
        "name": "base",
        "x": 17,
        "y": 35
    },
    {
        "name": "base",
        "x": 13,
        "y": 36
    },
    {
        "name": "base",
        "x": 14,
        "y": 36
    },
    {
        "name": "base",
        "x": 15,
        "y": 36
    },
    {
        "name": "base",
        "x": 16,
        "y": 36
    },
    {
        "name": "base",
        "x": 16,
        "y": 37
    },
    {
        "name": "base",
        "x": 15,
        "y": 37
    },
    {
        "name": "base",
        "x": 14,
        "y": 37
    },
    {
        "name": "base",
        "x": 13,
        "y": 37
    },
    {
        "name": "base",
        "x": 13,
        "y": 38
    },
    {
        "name": "base",
        "x": 14,
        "y": 38
    },
    {
        "name": "base",
        "x": 15,
        "y": 38
    },
    {
        "name": "base",
        "x": 16,
        "y": 38
    },
    {
        "name": "base",
        "x": 16,
        "y": 39
    },
    {
        "name": "base",
        "x": 15,
        "y": 39
    },
    {
        "name": "base",
        "x": 14,
        "y": 39
    },
    {
        "name": "base",
        "x": 13,
        "y": 39
    },
    {
        "name": "base",
        "x": 16,
        "y": 40
    },
    {
        "name": "base",
        "x": 15,
        "y": 40
    },
    {
        "name": "base",
        "x": 14,
        "y": 40
    },
    {
        "name": "base",
        "x": 13,
        "y": 40
    },
    {
        "name": "base",
        "x": 22,
        "y": 36
    },
    {
        "name": "base",
        "x": 21,
        "y": 36
    },
    {
        "name": "base",
        "x": 20,
        "y": 36
    },
    {
        "name": "base",
        "x": 21,
        "y": 37
    },
    {
        "name": "base",
        "x": 20,
        "y": 37
    },
    {
        "name": "base",
        "x": 22,
        "y": 37
    },
    {
        "name": "base",
        "x": 22,
        "y": 38
    },
    {
        "name": "base",
        "x": 21,
        "y": 38
    },
    {
        "name": "base",
        "x": 20,
        "y": 38
    },
    {
        "name": "base",
        "x": 20,
        "y": 39
    },
    {
        "name": "base",
        "x": 21,
        "y": 40
    },
    {
        "name": "base",
        "x": 21,
        "y": 39
    },
    {
        "name": "base",
        "x": 22,
        "y": 39
    },
    {
        "name": "base",
        "x": 22,
        "y": 40
    },
    {
        "name": "base",
        "x": 20,
        "y": 40
    },
    {
        "name": "base",
        "x": 20,
        "y": 41
    },
    {
        "name": "base",
        "x": 21,
        "y": 41
    },
    {
        "name": "base",
        "x": 23,
        "y": 41
    },
    {
        "name": "base",
        "x": 22,
        "y": 41
    },
    {
        "name": "base",
        "x": 20,
        "y": 42
    },
    {
        "name": "base",
        "x": 21,
        "y": 42
    },
    {
        "name": "base",
        "x": 22,
        "y": 42
    },
    {
        "name": "base",
        "x": 23,
        "y": 42
    },
    {
        "name": "base",
        "x": 24,
        "y": 41
    },
    {
        "name": "base",
        "x": 25,
        "y": 41
    },
    {
        "name": "base",
        "x": 26,
        "y": 41
    },
    {
        "name": "base",
        "x": 27,
        "y": 41
    },
    {
        "name": "base",
        "x": 28,
        "y": 41
    },
    {
        "name": "base",
        "x": 28,
        "y": 42
    },
    {
        "name": "base",
        "x": 27,
        "y": 42
    },
    {
        "name": "base",
        "x": 26,
        "y": 42
    },
    {
        "name": "base",
        "x": 25,
        "y": 42
    },
    {
        "name": "base",
        "x": 24,
        "y": 42
    },
    {
        "name": "base",
        "x": 28,
        "y": 43
    },
    {
        "name": "base",
        "x": 28,
        "y": 44
    },
    {
        "name": "base",
        "x": 28,
        "y": 45
    },
    {
        "name": "base",
        "x": 28,
        "y": 46
    },
    {
        "name": "base",
        "x": 28,
        "y": 47
    },
    {
        "name": "base",
        "x": 27,
        "y": 47
    },
    {
        "name": "base",
        "x": 27,
        "y": 46
    },
    {
        "name": "base",
        "x": 27,
        "y": 45
    },
    {
        "name": "base",
        "x": 27,
        "y": 44
    },
    {
        "name": "base",
        "x": 27,
        "y": 43
    },
    {
        "name": "base",
        "x": 26,
        "y": 43
    },
    {
        "name": "base",
        "x": 25,
        "y": 43
    },
    {
        "name": "base",
        "x": 24,
        "y": 43
    },
    {
        "name": "base",
        "x": 23,
        "y": 43
    },
    {
        "name": "base",
        "x": 22,
        "y": 43
    },
    {
        "name": "base",
        "x": 21,
        "y": 43
    },
    {
        "name": "base",
        "x": 20,
        "y": 43
    },
    {
        "name": "base",
        "x": 26,
        "y": 44
    },
    {
        "name": "base",
        "x": 26,
        "y": 45
    },
    {
        "name": "base",
        "x": 26,
        "y": 47
    },
    {
        "name": "base",
        "x": 26,
        "y": 46
    },
    {
        "name": "base",
        "x": 25,
        "y": 44
    },
    {
        "name": "base",
        "x": 25,
        "y": 45
    },
    {
        "name": "base",
        "x": 25,
        "y": 46
    },
    {
        "name": "base",
        "x": 25,
        "y": 47
    },
    {
        "name": "base",
        "x": 12,
        "y": 40
    },
    {
        "name": "base",
        "x": 12,
        "y": 39
    },
    {
        "name": "base",
        "x": 12,
        "y": 38
    },
    {
        "name": "base",
        "x": 11,
        "y": 38
    },
    {
        "name": "base",
        "x": 12,
        "y": 37
    },
    {
        "name": "base",
        "x": 11,
        "y": 39
    },
    {
        "name": "base",
        "x": 11,
        "y": 40
    },
    {
        "name": "base",
        "x": 11,
        "y": 37
    },
    {
        "name": "base",
        "x": 10,
        "y": 37
    },
    {
        "name": "base",
        "x": 10,
        "y": 38
    },
    {
        "name": "base",
        "x": 10,
        "y": 39
    },
    {
        "name": "base",
        "x": 10,
        "y": 40
    },
    {
        "name": "base",
        "x": 9,
        "y": 40
    },
    {
        "name": "base",
        "x": 8,
        "y": 40
    },
    {
        "name": "base",
        "x": 7,
        "y": 39
    },
    {
        "name": "base",
        "x": 8,
        "y": 39
    },
    {
        "name": "base",
        "x": 8,
        "y": 38
    },
    {
        "name": "base",
        "x": 8,
        "y": 37
    },
    {
        "name": "base",
        "x": 8,
        "y": 36
    },
    {
        "name": "base",
        "x": 8,
        "y": 35
    },
    {
        "name": "base",
        "x": 8,
        "y": 34
    },
    {
        "name": "base",
        "x": 8,
        "y": 33
    },
    {
        "name": "base",
        "x": 9,
        "y": 33
    },
    {
        "name": "base",
        "x": 9,
        "y": 34
    },
    {
        "name": "base",
        "x": 9,
        "y": 35
    },
    {
        "name": "base",
        "x": 9,
        "y": 36
    },
    {
        "name": "base",
        "x": 9,
        "y": 37
    },
    {
        "name": "base",
        "x": 9,
        "y": 38
    },
    {
        "name": "base",
        "x": 9,
        "y": 39
    },
    {
        "name": "base",
        "x": 10,
        "y": 36
    },
    {
        "name": "base",
        "x": 10,
        "y": 35
    },
    {
        "name": "base",
        "x": 10,
        "y": 34
    },
    {
        "name": "base",
        "x": 10,
        "y": 33
    },
    {
        "name": "base",
        "x": 25,
        "y": 48
    },
    {
        "name": "base",
        "x": 26,
        "y": 48
    },
    {
        "name": "base",
        "x": 27,
        "y": 48
    },
    {
        "name": "base",
        "x": 28,
        "y": 48
    },
    {
        "name": "base",
        "x": 25,
        "y": 49
    },
    {
        "name": "base",
        "x": 26,
        "y": 49
    },
    {
        "name": "base",
        "x": 27,
        "y": 49
    },
    {
        "name": "base",
        "x": 28,
        "y": 49
    },
    {
        "name": "base",
        "x": 30,
        "y": 49
    },
    {
        "name": "base",
        "x": 29,
        "y": 49
    },
    {
        "name": "base",
        "x": 26,
        "y": 50
    },
    {
        "name": "base",
        "x": 27,
        "y": 50
    },
    {
        "name": "base",
        "x": 28,
        "y": 50
    },
    {
        "name": "base",
        "x": 29,
        "y": 50
    },
    {
        "name": "base",
        "x": 31,
        "y": 50
    },
    {
        "name": "base",
        "x": 30,
        "y": 50
    },
    {
        "name": "base",
        "x": 31,
        "y": 49
    },
    {
        "name": "base",
        "x": 32,
        "y": 50
    },
    {
        "name": "base",
        "x": 32,
        "y": 49
    },
    {
        "name": "base",
        "x": 27,
        "y": 51
    },
    {
        "name": "base",
        "x": 28,
        "y": 51
    },
    {
        "name": "base",
        "x": 29,
        "y": 51
    },
    {
        "name": "base",
        "x": 30,
        "y": 51
    },
    {
        "name": "base",
        "x": 31,
        "y": 51
    },
    {
        "name": "base",
        "x": 32,
        "y": 51
    },
    {
        "name": "base",
        "x": 34,
        "y": 51
    },
    {
        "name": "base",
        "x": 33,
        "y": 50
    },
    {
        "name": "base",
        "x": 33,
        "y": 49
    },
    {
        "name": "base",
        "x": 34,
        "y": 49
    },
    {
        "name": "base",
        "x": 34,
        "y": 50
    },
    {
        "name": "base",
        "x": 33,
        "y": 51
    },
    {
        "name": "base",
        "x": 35,
        "y": 51
    },
    {
        "name": "base",
        "x": 35,
        "y": 50
    },
    {
        "name": "base",
        "x": 35,
        "y": 49
    },
    {
        "name": "base",
        "x": 37,
        "y": 49
    },
    {
        "name": "base",
        "x": 36,
        "y": 49
    },
    {
        "name": "base",
        "x": 36,
        "y": 50
    },
    {
        "name": "base",
        "x": 36,
        "y": 51
    },
    {
        "name": "base",
        "x": 37,
        "y": 51
    },
    {
        "name": "base",
        "x": 37,
        "y": 50
    },
    {
        "name": "base",
        "x": 26,
        "y": 51
    },
    {
        "name": "base",
        "x": 25,
        "y": 50
    },
    {
        "name": "base",
        "x": 25,
        "y": 51
    },
    {
        "name": "base",
        "x": 38,
        "y": 51
    },
    {
        "name": "base",
        "x": 38,
        "y": 50
    },
    {
        "name": "base",
        "x": 38,
        "y": 49
    },
    {
        "name": "base",
        "x": 39,
        "y": 52
    },
    {
        "name": "base",
        "x": 39,
        "y": 51
    },
    {
        "name": "base",
        "x": 39,
        "y": 50
    },
    {
        "name": "base",
        "x": 39,
        "y": 49
    },
    {
        "name": "base",
        "x": 39,
        "y": 48
    },
    {
        "name": "base",
        "x": 39,
        "y": 47
    },
    {
        "name": "base",
        "x": 39,
        "y": 46
    },
    {
        "name": "base",
        "x": 39,
        "y": 45
    },
    {
        "name": "base",
        "x": 39,
        "y": 44
    },
    {
        "name": "base",
        "x": 40,
        "y": 51
    },
    {
        "name": "base",
        "x": 40,
        "y": 50
    },
    {
        "name": "base",
        "x": 40,
        "y": 49
    },
    {
        "name": "base",
        "x": 40,
        "y": 48
    },
    {
        "name": "base",
        "x": 40,
        "y": 47
    },
    {
        "name": "base",
        "x": 40,
        "y": 46
    },
    {
        "name": "base",
        "x": 40,
        "y": 45
    },
    {
        "name": "base",
        "x": 40,
        "y": 44
    },
    {
        "name": "base",
        "x": 41,
        "y": 44
    },
    {
        "name": "base",
        "x": 41,
        "y": 45
    },
    {
        "name": "base",
        "x": 41,
        "y": 46
    },
    {
        "name": "base",
        "x": 41,
        "y": 47
    },
    {
        "name": "base",
        "x": 41,
        "y": 48
    },
    {
        "name": "base",
        "x": 41,
        "y": 49
    },
    {
        "name": "base",
        "x": 41,
        "y": 50
    },
    {
        "name": "base",
        "x": 41,
        "y": 51
    },
    {
        "name": "base",
        "x": 39,
        "y": 43
    },
    {
        "name": "base",
        "x": 39,
        "y": 42
    },
    {
        "name": "base",
        "x": 39,
        "y": 41
    },
    {
        "name": "base",
        "x": 39,
        "y": 40
    },
    {
        "name": "base",
        "x": 39,
        "y": 39
    },
    {
        "name": "base",
        "x": 39,
        "y": 38
    },
    {
        "name": "base",
        "x": 40,
        "y": 38
    },
    {
        "name": "base",
        "x": 41,
        "y": 38
    },
    {
        "name": "base",
        "x": 42,
        "y": 38
    },
    {
        "name": "base",
        "x": 43,
        "y": 38
    },
    {
        "name": "base",
        "x": 45,
        "y": 39
    },
    {
        "name": "base",
        "x": 44,
        "y": 38
    },
    {
        "name": "base",
        "x": 44,
        "y": 39
    },
    {
        "name": "base",
        "x": 43,
        "y": 39
    },
    {
        "name": "base",
        "x": 42,
        "y": 39
    },
    {
        "name": "base",
        "x": 41,
        "y": 39
    },
    {
        "name": "base",
        "x": 40,
        "y": 39
    },
    {
        "name": "base",
        "x": 40,
        "y": 40
    },
    {
        "name": "base",
        "x": 40,
        "y": 41
    },
    {
        "name": "base",
        "x": 40,
        "y": 43
    },
    {
        "name": "base",
        "x": 40,
        "y": 42
    },
    {
        "name": "base",
        "x": 41,
        "y": 43
    },
    {
        "name": "base",
        "x": 41,
        "y": 42
    },
    {
        "name": "base",
        "x": 41,
        "y": 41
    },
    {
        "name": "base",
        "x": 41,
        "y": 40
    }
]

const ile2_pc = [
    {
        "name": "bourse_l",
        "n": 11,
        "x": 16,
        "y": 9
    },
    {
        "name": "bourse_l",
        "n": 12,
        "x": 17,
        "y": 9
    },
    {
        "name": "bourse_l",
        "n": 13,
        "x": 18,
        "y": 9
    },
    {
        "name": "bourse_l",
        "n": 14,
        "x": 19,
        "y": 9
    },
    {
        "name": "bourse_l",
        "n": 15,
        "x": 20,
        "y": 9
    },
    {
        "name": "bourse_l",
        "n": 16,
        "x": 21,
        "y": 9
    },
    {
        "name": "bourse_l",
        "n": 17,
        "x": 21,
        "y": 10
    },
    {
        "name": "bourse_l",
        "n": 18,
        "x": 21,
        "y": 11
    },
    {
        "name": "bourse_l",
        "n": 19,
        "x": 21,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 20,
        "x": 20,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 21,
        "x": 19,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 22,
        "x": 18,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 23,
        "x": 17,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 24,
        "x": 17,
        "y": 13
    },
    {
        "name": "bourse_l",
        "n": 25,
        "x": 17,
        "y": 14
    },
    {
        "name": "bourse_l",
        "n": 26,
        "x": 17,
        "y": 15
    },
    {
        "name": "bourse_l",
        "n": 27,
        "x": 18,
        "y": 15
    },
    {
        "name": "bourse_l",
        "n": 28,
        "x": 19,
        "y": 15
    },
    {
        "name": "bourse_l",
        "n": 29,
        "x": 20,
        "y": 15
    },
    {
        "name": "bourse_l",
        "n": 30,
        "x": 20,
        "y": 16
    },
    {
        "name": "bourse_l",
        "n": 31,
        "x": 20,
        "y": 17
    },
    {
        "name": "bourse_l",
        "n": 32,
        "x": 19,
        "y": 17
    },
    {
        "name": "bourse_l",
        "n": 33,
        "x": 18,
        "y": 17
    },
    {
        "name": "bourse_l",
        "n": 34,
        "x": 18,
        "y": 18
    }
]
const ile1_pc = [
    {
        "name": "bourse_l",
        "n": 1,
        "x": 10,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 2,
        "x": 10,
        "y": 13
    },
    {
        "name": "bourse_l",
        "n": 3,
        "x": 11,
        "y": 13
    },
    {
        "name": "bourse_l",
        "n": 4,
        "x": 12,
        "y": 13
    },
    {
        "name": "bourse_l",
        "n": 5,
        "x": 13,
        "y": 13
    },
    {
        "name": "bourse_l",
        "n": 6,
        "x": 13,
        "y": 12
    },
    {
        "name": "bourse_l",
        "n": 7,
        "x": 13,
        "y": 11
    },
    {
        "name": "bourse_l",
        "n": 8,
        "x": 13,
        "y": 10
    },
    {
        "name": "bourse_l",
        "n": 9,
        "x": 13,
        "y": 9
    },
    {
        "name": "bourse_l",
        "n": 10,
        "x": 14,
        "y": 9
    }
]
const ile1 =[
    {
        "name": "base",
        "x": 8,
        "y": 9,
    },
    {
        "name": "base",
        "x": 9,
        "y": 9,
    },
    {
        "name": "base",
        "x": 8,
        "y": 10,
    },
    {
        "name": "base",
        "x": 9,
        "y": 10,
    },
    {
        "name": "base",
        "x": 9,
        "y": 12,
    },
    {
        "name": "base",
        "x": 10,
        "y": 10,
    },
    {
        "name": "base",
        "x": 9,
        "y": 8,
    },
    {
        "name": "base",
        "x": 8,
        "y": 8,
    },
    {
        "name": "base",
        "x": 10,
        "y": 8,
    },
    {
        "name": "base",
        "x": 10,
        "y": 9,
    },
    {
        "name": "base",
        "x": 8,
        "y": 12,
    },
    {
        "name": "base",
        "x": 12,
        "y": 12,
    },
    {
        "name": "base",
        "x": 12,
        "y": 10,
    },
    {
        "name": "base",
        "x": 12,
        "y": 9,
    },
    {
        "name": "base",
        "x": 12,
        "y": 8,
    },
    {
        "name": "base",
        "x": 8,
        "y": 13,
    },
    {
        "name": "base",
        "x": 9,
        "y": 13,
    },
    {
        "name": "base",
        "x": 10,
        "y": 13,
    },
    {
        "name": "base",
        "x": 12,
        "y": 14,
    },
    {
        "name": "base",
        "x": 11,
        "y": 13,
    },
    {
        "name": "base",
        "x": 12,
        "y": 13,
    },
    {
        "name": "base",
        "x": 13,
        "y": 14,
    },
    {
        "name": "base",
        "x": 13,
        "y": 13,
    },
    {
        "name": "base",
        "x": 13,
        "y": 12,
    },
    {
        "name": "base",
        "x": 13,
        "y": 11,
    },
    {
        "name": "base",
        "x": 13,
        "y": 10,
    },
    {
        "name": "base",
        "x": 13,
        "y": 9,
    },
    {
        "name": "base",
        "x": 13,
        "y": 8,
    },
    {
        "name": "base",
        "x": 11,
        "y": 14,
    },
    {
        "name": "base",
        "x": 10,
        "y": 14,
    },
    {
        "name": "base",
        "x": 9,
        "y": 14,
    },
    {
        "name": "base",
        "x": 8,
        "y": 14,
    },
    {
        "name": "base",
        "x": 14,
        "y": 8,
    },
    {
        "name": "base",
        "x": 14,
        "y": 9,
    },
    {
        "name": "base",
        "x": 14,
        "y": 11,
    },
    {
        "name": "base",
        "x": 14,
        "y": 12,
    },
    {
        "name": "base",
        "x": 14,
        "y": 10,
    },
    {
        "name": "base",
        "x": 14,
        "y": 13,
    },
    {
        "name": "base",
        "x": 14,
        "y": 14,
    },
    {
        "name": "house_0",
        "x": 10,
        "y": 10,
    },
    {
        "name": "rock",
        "x": 8,
        "y": 14,
    },
    {
        "name": "rock",
        "x": 12,
        "y": 12,
    },
    {
        "name": "rock2",
        "x": 8,
        "y": 12,
    },
    {
        "name": "rock",
        "x": 14,
        "y": 10,
    },
    {
        "name": "base",
        "x": 8,
        "y": 11,
    },
    {
        "name": "base",
        "x": 9,
        "y": 11,
    },
    {
        "name": "base",
        "x": 10,
        "y": 11,
    },
    {
        "name": "base",
        "x": 11,
        "y": 11,
    },
    {
        "name": "base",
        "x": 11,
        "y": 10,
    },
    {
        "name": "base",
        "x": 11,
        "y": 9,
    },
    {
        "name": "base",
        "x": 11,
        "y": 8,
    },
    {
        "name": "base",
        "x": 11,
        "y": 12,
    },
    {
        "name": "base",
        "x": 12,
        "y": 11,
    },
    {
        "name": "base",
        "x": 10,
        "y": 12,
    },
    {
        "name": "4hGyTyBi9ae0qpDW2Q2h",
        "x": 11,
        "y": 11,
        "avatar_id": "4hGyTyBi9ae0qpDW2Q2h",
        "chat": "hello",
        "rmvc": "2024-10-28T09:54:26.339Z"
    },
    {
        "name": "way",
        "x": 8,
        "y": 13
    },
    {
        "name": "way",
        "x": 9,
        "y": 13
    },
    {
        "name": "way",
        "x": 9,
        "y": 14
    }
]

const ile3 = [
    {
        "name": "sand2",
        "x": 31,
        "y": 14,
    },
    {
        "name": "sand2",
        "x": 31,
        "y": 13,
    },
    {
        "name": "sand2",
        "x": 32,
        "y": 14,
    },
    {
        "name": "sand2",
        "x": 32,
        "y": 13,
    },
    {
        "name": "base",
        "x": 14,
        "y": 16,
    },
    {
        "name": "base",
        "x": 14,
        "y": 17,
    },
    {
        "name": "base",
        "x": 14,
        "y": 19,
    },
    {
        "name": "base",
        "x": 14,
        "y": 18,
    },
    {
        "name": "base",
        "x": 13,
        "y": 16,
    },
    {
        "name": "base",
        "x": 13,
        "y": 17,
    },
    {
        "name": "base",
        "x": 13,
        "y": 18,
    },
    {
        "name": "base",
        "x": 13,
        "y": 19,
    },
    {
        "name": "base",
        "x": 8,
        "y": 16,
    },
    {
        "name": "base",
        "x": 9,
        "y": 16,
    },
    {
        "name": "base",
        "x": 10,
        "y": 16,
    },
    {
        "name": "base",
        "x": 11,
        "y": 16,
    },
    {
        "name": "base",
        "x": 12,
        "y": 16,
    },
    {
        "name": "base",
        "x": 12,
        "y": 17,
    },
    {
        "name": "base",
        "x": 12,
        "y": 18,
    },
    {
        "name": "base",
        "x": 11,
        "y": 17,
    },
    {
        "name": "base",
        "x": 10,
        "y": 17,
    },
    {
        "name": "base",
        "x": 9,
        "y": 17,
    },
    {
        "name": "base",
        "x": 8,
        "y": 17,
    },
    {
        "name": "base",
        "x": 8,
        "y": 18,
    },
    {
        "name": "base",
        "x": 10,
        "y": 18,
    },
    {
        "name": "base",
        "x": 9,
        "y": 18,
    },
    {
        "name": "base",
        "x": 11,
        "y": 18,
    },
    {
        "name": "base",
        "x": 12,
        "y": 19,
    },
    {
        "name": "base",
        "x": 11,
        "y": 19,
    },
    {
        "name": "base",
        "x": 10,
        "y": 19,
    },
    {
        "name": "base",
        "x": 9,
        "y": 19,
    },
    {
        "name": "base",
        "x": 8,
        "y": 19,
    },
    {
        "name": "base",
        "x": 15,
        "y": 20,
    },
    {
        "name": "base",
        "x": 14,
        "y": 20,
    },
    {
        "name": "base",
        "x": 13,
        "y": 20,
    },
    {
        "name": "base",
        "x": 12,
        "y": 20,
    },
    {
        "name": "base",
        "x": 11,
        "y": 20,
    },
    {
        "name": "base",
        "x": 10,
        "y": 20,
    },
    {
        "name": "base",
        "x": 9,
        "y": 20,
    },
    {
        "name": "base",
        "x": 8,
        "y": 20,
    },
    {
        "name": "base",
        "x": 8,
        "y": 21,
    },
    {
        "name": "base",
        "x": 9,
        "y": 21,
    },
    {
        "name": "base",
        "x": 10,
        "y": 21,
    },
    {
        "name": "base",
        "x": 12,
        "y": 21,
    },
    {
        "name": "base",
        "x": 11,
        "y": 21,
    },
    {
        "name": "base",
        "x": 13,
        "y": 21,
    },
    {
        "name": "base",
        "x": 14,
        "y": 21,
    },
    {
        "name": "base",
        "x": 16,
        "y": 20,
    },
    {
        "name": "base",
        "x": 8,
        "y": 22,
    },
    {
        "name": "base",
        "x": 10,
        "y": 22,
    },
    {
        "name": "base",
        "x": 9,
        "y": 22,
    },
    {
        "name": "base",
        "x": 11,
        "y": 22,
    },
    {
        "name": "base",
        "x": 12,
        "y": 22,
    },
    {
        "name": "base",
        "x": 13,
        "y": 22,
    },
    {
        "name": "base",
        "x": 14,
        "y": 22,
    },
    {
        "name": "base",
        "x": 15,
        "y": 22,
    },
    {
        "name": "base",
        "x": 16,
        "y": 22,
    },
    {
        "name": "base",
        "x": 17,
        "y": 20,
    },
    {
        "name": "base",
        "x": 18,
        "y": 20,
    },
    {
        "name": "base",
        "x": 19,
        "y": 20,
    },
    {
        "name": "base",
        "x": 20,
        "y": 20,
    },
    {
        "name": "base",
        "x": 22,
        "y": 20,
    },
    {
        "name": "base",
        "x": 21,
        "y": 20,
    },
    {
        "name": "base",
        "x": 23,
        "y": 20,
    },
    {
        "name": "base",
        "x": 22,
        "y": 21,
    },
    {
        "name": "base",
        "x": 21,
        "y": 21,
    },
    {
        "name": "base",
        "x": 20,
        "y": 21,
    },
    {
        "name": "base",
        "x": 19,
        "y": 21,
    },
    {
        "name": "base",
        "x": 18,
        "y": 21,
    },
    {
        "name": "base",
        "x": 17,
        "y": 22,
    },
    {
        "name": "base",
        "x": 18,
        "y": 22,
    },
    {
        "name": "base",
        "x": 19,
        "y": 22,
    },
    {
        "name": "base",
        "x": 20,
        "y": 22,
    },
    {
        "name": "base",
        "x": 21,
        "y": 22,
    },
    {
        "name": "base",
        "x": 22,
        "y": 22,
    },
    {
        "name": "base",
        "x": 23,
        "y": 22,
    },
    {
        "name": "rondin",
        "x": 10,
        "y": 20,
    },
    {
        "name": "rock",
        "x": 10,
        "y": 22,
    },
    {
        "name": "rock",
        "x": 8,
        "y": 18,
    },
    {
        "name": "rock",
        "x": 13,
        "y": 22,
    },
    {
        "name": "rock",
        "x": 11,
        "y": 17,
    },
    {
        "name": "rock",
        "x": 23,
        "y": 22,
    },
    {
        "name": "base",
        "x": 8,
        "y": 23
    },
    {
        "name": "base",
        "x": 9,
        "y": 23
    },
    {
        "name": "base",
        "x": 10,
        "y": 23
    },
    {
        "name": "base",
        "x": 11,
        "y": 23
    },
    {
        "name": "base",
        "x": 12,
        "y": 23
    },
    {
        "name": "base",
        "x": 13,
        "y": 23
    },
    {
        "name": "base",
        "x": 14,
        "y": 23
    },
    {
        "name": "base",
        "x": 15,
        "y": 23
    },
    {
        "name": "base",
        "x": 16,
        "y": 23
    },
    {
        "name": "base",
        "x": 17,
        "y": 23
    },
    {
        "name": "base",
        "x": 19,
        "y": 23
    },
    {
        "name": "base",
        "x": 18,
        "y": 23
    },
    {
        "name": "base",
        "x": 20,
        "y": 23
    },
    {
        "name": "base",
        "x": 21,
        "y": 23
    },
    {
        "name": "base",
        "x": 22,
        "y": 23
    },
    {
        "name": "base",
        "x": 23,
        "y": 23
    },
    {
        "name": "base",
        "x": 23,
        "y": 21
    },
    {
        "name": "base",
        "x": 23,
        "y": 24
    },
    {
        "name": "base",
        "x": 22,
        "y": 24
    },
    {
        "name": "base",
        "x": 21,
        "y": 24
    },
    {
        "name": "base",
        "x": 20,
        "y": 24
    },
    {
        "name": "base",
        "x": 20,
        "y": 25
    },
    {
        "name": "base",
        "x": 21,
        "y": 25
    },
    {
        "name": "base",
        "x": 22,
        "y": 25
    },
    {
        "name": "base",
        "x": 23,
        "y": 25
    },
    {
        "name": "base",
        "x": 15,
        "y": 21
    },
    {
        "name": "base",
        "x": 16,
        "y": 21
    },
    {
        "name": "base",
        "x": 17,
        "y": 21
    },
    {
        "name": "base",
        "x": 19,
        "y": 24
    },
    {
        "name": "base",
        "x": 19,
        "y": 25
    },
    {
        "name": "way",
        "x": 12,
        "y": 23
    },
    {
        "name": "way",
        "x": 12,
        "y": 22
    },
    {
        "name": "way",
        "x": 12,
        "y": 21
    },
    {
        "name": "way",
        "x": 12,
        "y": 20
    },
    {
        "name": "way",
        "x": 12,
        "y": 19
    },
    {
        "name": "way",
        "x": 12,
        "y": 18
    },
    {
        "name": "way",
        "x": 11,
        "y": 18
    },
    {
        "name": "way",
        "x": 10,
        "y": 18
    },
    {
        "name": "way",
        "x": 10,
        "y": 17
    },
    {
        "name": "way",
        "x": 8,
        "y": 17
    }, 
        {
            "name": "base",
            "x": 8,
            "y": 15
        },
        {
            "name": "base",
            "x": 9,
            "y": 15
        },
        {
            "name": "base",
            "x": 10,
            "y": 15
        },
        {
            "name": "base",
            "x": 11,
            "y": 15
        },
        {
            "name": "base",
            "x": 12,
            "y": 15
        },
        {
            "name": "base",
            "x": 13,
            "y": 15
        },
        {
            "name": "base",
            "x": 14,
            "y": 15
        },
        {
            "name": "way",
            "x": 8,
            "y": 15
        },
        {
            "name": "way",
            "x": 9,
            "y": 15
        },
        {
            "name": "way",
            "x": 10,
            "y": 15
        },
        {
            "name": "way",
            "x": 11,
            "y": 15
        },
        {
            "name": "way",
            "x": 12,
            "y": 15
        },
        {
            "name": "way",
            "x": 13,
            "y": 15
        },
        {
            "name": "way",
            "x": 14,
            "y": 15
        }
    
]

const ile2 = [
    {
        "name": "base",
        "x": 16,
        "y": 8,
    },
    {
        "name": "base",
        "x": 17,
        "y": 10,
    },
    {
        "name": "base",
        "x": 16,
        "y": 9,
    },
    {
        "name": "base",
        "x": 16,
        "y": 10,
    },
    {
        "name": "base",
        "x": 16,
        "y": 11,
    },
    {
        "name": "base",
        "x": 17,
        "y": 11,
    },
    {
        "name": "base",
        "x": 17,
        "y": 12,
    },
    {
        "name": "base",
        "x": 16,
        "y": 12,
    },
    {
        "name": "base",
        "x": 16,
        "y": 13,
    },
    {
        "name": "base",
        "x": 17,
        "y": 13,
    },
    {
        "name": "base",
        "x": 16,
        "y": 14,
    },
    {
        "name": "base",
        "x": 17,
        "y": 14,
    },
    {
        "name": "base",
        "x": 17,
        "y": 9,
    },
    {
        "name": "base",
        "x": 17,
        "y": 8,
    },
    {
        "name": "base",
        "x": 18,
        "y": 8,
    },
    {
        "name": "base",
        "x": 19,
        "y": 9,
    },
    {
        "name": "base",
        "x": 18,
        "y": 10,
    },
    {
        "name": "base",
        "x": 18,
        "y": 9,
    },
    {
        "name": "base",
        "x": 18,
        "y": 11,
    },
    {
        "name": "base",
        "x": 18,
        "y": 12,
    },
    {
        "name": "base",
        "x": 19,
        "y": 12,
    },
    {
        "name": "base",
        "x": 19,
        "y": 11,
    },
    {
        "name": "base",
        "x": 19,
        "y": 10,
    },
    {
        "name": "base",
        "x": 19,
        "y": 8,
    },
    {
        "name": "base",
        "x": 20,
        "y": 8,
    },
    {
        "name": "base",
        "x": 21,
        "y": 9,
    },
    {
        "name": "base",
        "x": 20,
        "y": 9,
    },
    {
        "name": "base",
        "x": 21,
        "y": 8,
    },
    {
        "name": "base",
        "x": 22,
        "y": 8,
    },
    {
        "name": "base",
        "x": 22,
        "y": 9,
    },
    {
        "name": "base",
        "x": 22,
        "y": 10,
    },
    {
        "name": "base",
        "x": 21,
        "y": 10,
    },
    {
        "name": "base",
        "x": 20,
        "y": 10,
    },
    {
        "name": "base",
        "x": 20,
        "y": 13,
    },
    {
        "name": "base",
        "x": 20,
        "y": 12,
    },
    {
        "name": "base",
        "x": 20,
        "y": 11,
    },
    {
        "name": "base",
        "x": 22,
        "y": 11,
    },
    {
        "name": "base",
        "x": 21,
        "y": 11,
    },
    {
        "name": "base",
        "x": 21,
        "y": 12,
    },
    {
        "name": "base",
        "x": 22,
        "y": 12,
    },
    {
        "name": "base",
        "x": 22,
        "y": 13,
    },
    {
        "name": "base",
        "x": 21,
        "y": 13,
    },
    {
        "name": "base",
        "x": 21,
        "y": 14,
    },
    {
        "name": "base",
        "x": 22,
        "y": 14,
    },
    {
        "name": "base",
        "x": 16,
        "y": 15,
    },
    {
        "name": "base",
        "x": 17,
        "y": 15,
    },
    {
        "name": "base",
        "x": 18,
        "y": 15,
    },
    {
        "name": "base",
        "x": 19,
        "y": 15,
    },
    {
        "name": "base",
        "x": 21,
        "y": 15,
    },
    {
        "name": "base",
        "x": 22,
        "y": 15,
    },
    {
        "name": "base",
        "x": 20,
        "y": 15,
    },
    {
        "name": "base",
        "x": 16,
        "y": 16,
    },
    {
        "name": "base",
        "x": 17,
        "y": 16,
    },
    {
        "name": "base",
        "x": 18,
        "y": 16,
    },
    {
        "name": "base",
        "x": 19,
        "y": 16,
    },
    {
        "name": "base",
        "x": 20,
        "y": 16,
    },
    {
        "name": "base",
        "x": 21,
        "y": 16,
    },
    {
        "name": "base",
        "x": 22,
        "y": 16,
    },
    {
        "name": "base",
        "x": 22,
        "y": 17,
    },
    {
        "name": "base",
        "x": 21,
        "y": 17,
    },
    {
        "name": "base",
        "x": 20,
        "y": 17,
    },
    {
        "name": "base",
        "x": 19,
        "y": 17,
    },
    {
        "name": "base",
        "x": 18,
        "y": 17,
    },
    {
        "name": "base",
        "x": 17,
        "y": 17,
    },
    {
        "name": "base",
        "x": 16,
        "y": 17,
    },
    {
        "name": "base",
        "x": 16,
        "y": 18,
    },
    {
        "name": "base",
        "x": 17,
        "y": 18,
    },
    {
        "name": "base",
        "x": 18,
        "y": 18,
    },
    {
        "name": "base",
        "x": 19,
        "y": 18,
    },
    {
        "name": "base",
        "x": 20,
        "y": 18,
    },
    {
        "name": "base",
        "x": 21,
        "y": 18,
    },
    {
        "name": "base",
        "x": 22,
        "y": 18,
    },
    {
        "name": "sand2",
        "x": 31,
        "y": 14,
    },
    {
        "name": "sand2",
        "x": 31,
        "y": 13,
    },
    {
        "name": "sand2",
        "x": 32,
        "y": 14,
    },
    {
        "name": "sand2",
        "x": 32,
        "y": 13,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 18,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 17,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 16,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 15,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 14,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 13,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 12,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 11,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 10,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 9,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 8,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 22,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 21,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 20,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 19,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 19,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 18,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 17,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 16,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 8,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 9,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 10,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 11,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 12,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 13,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 14,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 15,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 16,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 17,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 18,
    },
    {
        "name": "sand2",
        "x": 16,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 19,
    },
    {
        "name": "sand2",
        "x": 23,
        "y": 7,
    },
    {
        "name": "sand2",
        "x": 22,
        "y": 7,
    },
    {
        "name": "sand2",
        "x": 21,
        "y": 7,
    },
    {
        "name": "sand2",
        "x": 20,
        "y": 7,
    },
    {
        "name": "sand2",
        "x": 19,
        "y": 7,
    },
    {
        "name": "sand2",
        "x": 18,
        "y": 7,
    },
    {
        "name": "sand2",
        "x": 17,
        "y": 7,
    },
    {
        "name": "sand2",
        "x": 16,
        "y": 7,
    },
    {
        "name": "sand2",
        "x": 15,
        "y": 7,
    },
    {
        "name": "rock2",
        "x": 16,
        "y": 8,
    },
    {
        "name": "rock",
        "x": 19,
        "y": 10,
    },
    {
        "name": "rock",
        "x": 16,
        "y": 14,
    },
    {
        "name": "rock",
        "x": 21,
        "y": 13,
    },
    {
        "name": "rock",
        "x": 16,
        "y": 17,
    },
    {
        "name": "rock",
        "x": 22,
        "y": 17,
    },
    {
        "name": "rock",
        "x": 22,
        "y": 8,
    },
    {
        "name": "rondin",
        "x": 19,
        "y": 16,
    },
    {
        "name": "rondin",
        "x": 22,
        "y": 13,
    },
    {
        "name": "old_wood",
        "x": 17,
        "y": 10,
    },
    {
        "name": "old_wood",
        "x": 22,
        "y": 16,
    },
    {
        "name": "base",
        "x": 20,
        "y": 14,
    },
    {
        "name": "greeny",
        "x": 18,
        "y": 13,
    },
    {
        "name": "greeny",
        "x": 18,
        "y": 14,
    },
    {
        "name": "greeny",
        "x": 19,
        "y": 14,
    },
    {
        "name": "greeny",
        "x": 19,
        "y": 13,
    }
]



const islands = [ile1.concat(ile1_pc), ile2.concat(ile2_pc), ile3.concat(ile3_pc), ile4.concat(ile4_pc), ile5.concat(ile5_pc).concat(ile5_deco)]

const totemsExtension = [
    {
        "name": "totem_extension",
        "x": 18,
        "y": 11,
        "fixed": true,
        elixirs: 20,
        xp: 20,
        seeds: 30,
        house_lvl: 0
    }, {
        "name": "totem_extension",
        "x": 11,
        "y": 18,
        "fixed": true,
        elixirs: 60,
        seeds: 100,
        xp: 10,
        house_lvl: 1
    }
    , {
        "name": "totem_extension",
        "x": 12,
        "y": 27,
        "fixed": true,
        elixirs: 200,
        xp: 200,
        seeds: 150,
        house_lvl: 2
    }
    , {
        
        "name": "totem_extension",
        "x": 28,
        "y": 23,
        "fixed": true,
        elixirs: 666,
        xp: 10,
        seeds: 300,
        house_lvl: 3
        
    }
]


export {totemsExtension, islands}