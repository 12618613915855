import { useEffect, useState } from "react"
import { useStateContext } from "../StateProvider"
import { displayDuration, getDateValue, getEvents } from "../helperFunctions"
import { sbUpdate } from "../DbFunction"


const LetterBox = () => {



    const {events, setShowModal, setEvents, profile, setProfile, profileWorkspace, setProfileWorkspace} = useStateContext()


    const today = new Date()

    useEffect(() => {
        // profile?.id && getEvents(profile?.id, setEvents)
        setProfile(prev => ({...prev, events: {...prev?.events, checkMsg: new Date()}}))
        sbUpdate('profiles', profile.id, {events: {...profile?.events, checkMsg: new Date()}})
    }, [])




    return <div className="fixed z-[62]  top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-indigo-900 to-purple-900">
       <div className='max-w-[600px] fredoka mx-auto w-full '>
            <div className='fixed z-[99] top-2 right-3 text-white' onClick={() => setShowModal()}>

                         <div class="absolute  top-4 right-4 text-white z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></div>

            </div>
            <div className='text-white bg-black/20  flex pt-6 flex p-4 gap-4 items-center text-xl text-sm text-center'>
                <img src="/images/deco/mailbox.png" className='h-8' />
                <div>Boîte aux lettres</div>
            </div>

            <div className='p-0 overflow-scroll h-[94vh] pb-[100px] bg-indigo-900/50'>

            {!events?.length && <div className='text-white  h-[300px] flex justify-center items-center text-center'>Vous n'avez pas encore reçu de message</div>}

            {events.sort((a,b) => new Date(b.date) - new Date(a.date) )?.map(ev => {
                return <div className='p-3 z-0 bg-indigo-900/50 text-white relative   justify-between  '>
                    <div>
                        <div className='text-xs text-white/80  flex flex-wrap gap-2 items-center mr-[40px]'>
                            {/* {!ev.data.read && <div className='h-2 w-2 bg-red-400 rounded-full absolute top-1 right-1'></div>} */}
                            {ev.type == "gift" && <img src="images/deco/gift.png" className="w-5" />}
                            {ev.type == "steal" && <img src="images/deco/attack.png" className="w-5" />}
                            {ev.type == "msg" && <img src="images/deco/logo_letter.png" className="w-5" />}
                            <div className='font-semibold text-yellow-200/90 -mr-1'>{ev.data.name.split(' ')?.[0]} {ev.data.name.split(' ')?.[1]?.[0]}</div>
                            {ev.type == "gift" && <div>vous a offert un cadeau</div>}
                            {ev.type == "steal" && <div>a pillé votre forêt</div>}
                             </div>
                        <div className='text-purple-100'>{ev.text ? "“" + ev.text+ "“" : ""}</div>
                        <div className='flex justify-end w-full'>
                            {!ev.data.reward && ev.type == "gift" && <div onClick={() => {
                            
                                sbUpdate("events", ev?.id, {data: {...ev.data, reward: true, read: true}})
                                setEvents(prev => prev.map(e => e.id == ev.id ? {...e, data: {...ev.data, reward: true, read: true}} : e))
                                sbUpdate("profiles_workspaces", profileWorkspace?.id, {tt_elixirs: profileWorkspace?.tt_elixirs + ev.data.elixir})
                                setProfileWorkspace(prev => ({...prev, tt_elixirs: profileWorkspace?.tt_elixirs + ev.data.elixir}))

                            }} className="bg-white text-indigo-600 px-3 border-2 border-b-4 py-1 mt-2 rounded-xl flex fredoka gap-2" >Récuperer <span className="flex gap-1 bg-indigo-500/20 px-1 rounded-xl">+ {ev.data.elixir} <img className="h-6" src="/images/deco/elixir.png"/></span></div>
                            }
                    </div>
                    </div>
                   
                    

                    <div className='text-xs absolute  top-4 text-white/60 right-2'>{displayDuration(new Date(ev.date), today)}</div>
                    </div>
            })}
         
        </div>
        </div>
    </div>

 
}


export {LetterBox}