const ChallengeScreen = ({setShowModalForest}) => {
    return (
    <div  className={`fredoka text-center p-8 bg-gradient-to-b from-indigo-800  to-indigo-900 border-indigo-600 z-[40]  transition fixed bottom-[50px] top-0 left-0 right-0 `} >
        


        <div onClick={() => {setShowModalForest()}}   className="absolute top-4 right-4 text-white z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700">
       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
         <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
       </svg>
     </div>


        <div className="text-2xl pt-12">Quêtes & Challenge </div>
        
        <div className="description">
          <p>
            Fais des missions quotidiennes pour débloquer des récompenses et atteindre tes objectifs           
          </p> 
        </div>
  
        <div className="coming-soon mt-8">
          <img 
            src="/images/UX/time_logo.png" 
            alt="Time logo" 
            className="h-20 mx-auto" 
          />
          <p>Bientôt disponible</p>
        </div>
      </div>
    );
  };
  
  export default ChallengeScreen;