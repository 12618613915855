import { useEffect, useState } from "react";
import { useStateContext } from "../StateProvider"
import { capitalizeFirstLetter, getCardToLearn, getWeekNumberAndYear, toggleVersion } from "../helperFunctions"
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { addXpToProfile, getNextCards, getNextCardsByTheme, sbUpdate } from "../DbFunction";
import { goals, menu, toeicPaths } from "../helperData";
import { RosetaScreen } from "./Roseta";
import { SVG_close } from "../svgs";
import { supabase } from "../../V2/screens/testScreen";
import { ScenarioScreen } from "./ScenarioScreen";
import { Actuality } from "../../V2/screens/Actuality";
import { Grammar } from "./Grammar";
import { CultureG } from "./CultureG";
import { TrainingExam } from "./TrainingExam";












const layersPaths = [
    // {
    //     name: "Niveau 0",
    //     nb: 32   
    //  },
    {
       name: "Niveau 1",
       nb: 40,   
    },
    {
        name: "Niveau 2",
        nb: 96   
     },
     {
        name: "Niveau 3",
        nb: 152   
     },
     {
        name: "Niveau 4",
        nb: 300  
     },
     {
        name: "Niveau 5",
        nb: 300   
     },
     {
        name: "Niveau 6",
        nb: 300   
     },

].map((e, i, arr) => {
    // Calculer la somme cumulative de `nb` jusqu'à ce niveau
    const nb_sum = arr.slice(0, i ).reduce((sum, level) => sum + level.nb, 0);
    return { ...e, nb_sum };
});

console.log('layersPaths', layersPaths)

const BubbleCard = ({cards, state}) => {

    const {setCardsToDiscover, setCardsToRefresh, setShowModal, nextCards} = useStateContext()

    return <div className={` ${state == "locked" ? "grayscale-[0.8]" : ""} from-purple-500 to-indigo-500  p-3 px-4 bg-gradient-to-b  rounded-xl text-center max-w-[280px]`}>
    <div className="flex justify-center"><div className="px-3 text-sm bg-purple-600 -mt-4 rounded-full">Graines de vocabulaire</div></div>
    {/* prev cards = {prevCard?.map(e => <div className="px-2">{e.card.term}</div>)} */}
    {cards?.length != 4 && state != "locked" && <div>
        <div className="my-3 text-sm">Découvre {4 - cards?.length} cartes de vocabulaire</div>
            <div className="mb-[0px] "><div className="bg-white justify-center rounded-xl text-purple-500 flex gap-2 items-center px-3 py-1 border border-b-4" onClick={() => {
                setCardsToDiscover(nextCards?.slice(0,4 - cards?.length)) ;
                setCardsToRefresh(null);
                setShowModal('learnCards')
            }}>Nouvelle leçon <span className="bg-amber-100 text-amber-500 px-2 rounded-xl flex gap-1 items-center justify-center">+12<img src={"/images/icons/coin-xp.png"} className="h-4"/></span></div>
        </div>
    </div>}

    {state == "locked" && <div className="mt-2 text-sm text-white/80">Cette leçon n'est pas encore disponible</div>}

    {cards.length == 4 && <div>
        <div className="flex my-2 mt-4 gap-2 flex-wrap">{cards?.map(e => <div className="px-2 bg-white/10 rounded-xl">{e.card.term}</div>)}</div>
          
    </div>}
</div>
}


const RenderLayer = ({layer, learnedCards, setBubble, indexLayer}) => {

    


    return <div className="bg-black/20 rounded-xl">
    <div className="mt-6 mb-2 bg-black/20 py-1 rounded-t-xl text-sm">{layer.name}</div>
        <div className="p-4 rounded-xl flex gap-2 flex-wrap">
        {
        layer.state == "free" && [...Array(Math.round(layer.nb/4))].map((e, i) => {
            // const i = index + (Math.round(layer?.nb_sum/4))
            const cards = layer.cards?.slice(i*4, (i+1)*4) || []
            
            
            let state = "locked"
            if (cards?.length == 4) state = "mastered"
            if (cards.length < 4) {
                const prevCard = layer.cards?.slice((i-1)*4, (i)*4).length 
                if (prevCard == 4 && cards?.length ==0 || i ==0) {
                    state = "new"
                }
            }
            if (cards?.length > 0 && cards?.length < 4) state = "free"
           
        
        return <div key={"card_" + i} onClick={(e) => {setBubble({event: e, content: <BubbleCard cards={cards} state={state} />})}} className={`${state == "locked" ? "opacity-[0.6]" : "bg-indigo-500/20"} ${state == "new" && "animate-pulse !bg-[#5d1ce0] !text-amber-300"} ${state == "mastered" ? "text-emerald-500/70" : "text-white/60"} scale-y-[0.9] italic h-[30px] w-[30px] flex items-center justify-center rounded-full border-b-4 border border-black/60`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" strokeWidth={1.5} stroke="currentColor" className="h-4">
                <path fillRule="evenodd" d="M8 1.75a.75.75 0 0 1 .692.462l1.41 3.393 3.664.293a.75.75 0 0 1 .428 1.317l-2.791 2.39.853 3.575a.75.75 0 0 1-1.12.814L7.998 12.08l-3.135 1.915a.75.75 0 0 1-1.12-.814l.852-3.574-2.79-2.39a.75.75 0 0 1 .427-1.318l3.663-.293 1.41-3.393A.75.75 0 0 1 8 1.75Z" clipRule="evenodd" />
            </svg>
        </div>})
        }

        {
            layer.state == "locked" && <div className="text-white/60 text-sm text-center w-full">Ce contenu n'est pas encore disponible</div>
        }



    </div>
</div>
}


const Home = () => {
    const nav = useNavigate()
    const { user, setUser,nextCards, setAlert, daily, setShowModal, setLearnedCards, setNextCards, setShowCategorie, setProfileWorkspace, profileWorkspace, selectedDecks,  setBubble, setShowBottomBar, profile, setProfile, workspace, setWorkspace, learnedCards, setActiveScreen} = useStateContext()


    useEffect(() => {
        setActiveScreen("home")
        return () => { setActiveScreen("")}
    }, [])

    const today = new Date();
    const mastered_lc = learnedCards?.filter(e => !e.profile_card?.archived).filter(e => {
        const nextDate = new Date(e.profile_card.next_date).getTime();
        return nextDate > today;
    })
    const review_lc = learnedCards?.filter(e => !e.profile_card.archived).filter(e => {
        const nextDate = new Date(e.profile_card.next_date).getTime();
        return nextDate <= today;
    })
    const [selectedPath, setSelectedPath] = useState(menu.find(e => e.id == "voc"))


    const currentWeek = getWeekNumberAndYear(today);
    const filteredMenu = menu.filter(e => e.open_only_to.includes(profileWorkspace?.goal_id))

    const filteredLearnedCards = profileWorkspace?.goal_id== "TOEIC" ? learnedCards?.filter(e => e.card?.tags?.includes('TOEIC')) : learnedCards
    console.log('filteredLearnedCards', filteredLearnedCards)

    return <div className="min-h-screen text-white " style={{background: "linear-gradient(180deg, #5A3591 0%, #36206C 100%)"}}>
        
        {/* {profile && profile?.blocked && <div className="fixed top-0 text-white left-0 bottom-0 z-[10] bg-black/90 bg-blur right-0 flex items-center justify-center">
                
                <div className="text-center">
                    <div>Cette version n'est pas encore disponible</div>
                    <div className="text-white/70">Reviens dans quelques jours</div>
                    <div className="text-white/70 mt-[200px]" onClick={() => toggleVersion()}>Revenir en 1.9</div>
                </div>
            </div>} */}

           

       
       
        {profileWorkspace?.goal_id!= "roseta" && <>
            <div 
           
            style={{
                backgroundImage: "url('/images/seeds/bottom-store.png')",
                backgroundRepeat: "repeat-x",
                backgroundPositionY: "100%",
                filter: `hue-rotate(${true ? selectedPath?.hue || 0 : 0}deg)`
            }} className="h-[100px] transition-all duration-700	 p-4 top-0 left-0 right-0 bg-blue-500">
         <div 
        id="noHue" 
        style={{
            filter: `hue-rotate(-${true ? selectedPath?.hue || 0 : 0}deg)`
        }}
        className="flex transition-all duration-700 justify-center"
    >
                <div className="flex relative items-center justify-center  rounded-xl bg-white/10">
                    <div onClick={() => {setProfileWorkspace()}} className="flex gap-2 items-center"><img src={workspace?.img} className="h-[18px] border border-black/90 rounded-full ml-2" /></div><div className="px-2 flex items-center gap-2" onClick={() => setProfileWorkspace(prev => ({...prev, goal_id: null}))}>
                        {goals.find(e => e.goal_id == profileWorkspace?.goal_id)?.short || "Choisis un parcours"}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path></svg>

                        </div>

                </div>
            </div>

            {<div className="text-xl text-shadow text-center font-bold mt-1">{selectedPath?.name}</div>}
            </div>
        </>}





        {profileWorkspace?.goal_id != "roseta" && <div className="px-4 relative">
            <div className="mx-auto rounded-full -mt-4 border-b-4  border  border-black bg-[#411067] max-w-[400px] justify-around flex">
            {filteredMenu.map(m => <div  onClick={() => {  setSelectedPath(m) }}  className="relative">
            <img src={m.icon}  className={`  ${m.id == selectedPath?.id ? "scale-[2.1]" : "grayscale opacity-50 "}  transition h-[32px]  hover:rotate-[10deg] `}/>
            </div>)}
            </div>
        </div>}


        {profileWorkspace?.goal_id== "roseta" && <div className="font-sans z-10 relative">
            <RosetaScreen 
                setUser={setUser} 
                user={user} 
                hideMenuBar={setShowBottomBar} 
                setUserCards={[]} 
                userCards={[]} 
                workspace={workspace}
                />
        </div>}



        <div className="p-4 py-0 overflow-auto" style={{maxHeight: `calc(100vh - 220px)`}}>

       

        {/* <div className="rounded-xl relative bg-indigo-900/40 px-6 py-2  border-2 border-b-4 border-black/30 mt-4">
            <div>{learnedCards?.filter(e => !e.profile_card.archived)?.length} mots appris</div>
            <div className="text-xs text-white/30">{learnedCards?.filter(e => e.profile_card.archived)?.length} mots archivés</div>
        </div> */}



        {/* <div onClick={() => {console.log('daily', daily)}}>daily</div> */}
    

        {profileWorkspace?.goal_id== "perfect" &&  selectedPath?.id == "voc" && <div className="rounded-xl relative flex flex-col items-center mt-4">
           {/* <div className="text-xs text-white/30 mb-2">Vocabulaire à apprendre</div> */}
        
         
           
           <div onClick={() => setShowModal('houseOfCards')} className="flex justify-center gap-2 my-4">
            <div className="w-[90px] flex flex-col items-center bg-black/10 p-2 text-center rounded-xl text-indigo-300">
                <div className="flex justify-center gap-2 items-center">{review_lc?.length} <svg width="18" height="22" viewBox="0 0 97 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 7.5H17.8231L8.62591 89.8146C8.19091 93.7078 10.8256 97.28 14.6736 98.0144L56.5585 106.008L9.77342 104.76C5.16889 104.637 1.5 100.869 1.5 96.2631V14C1.5 10.4101 4.41015 7.5 8 7.5Z" stroke="currentColor" stroke-width="5"/>
                    <rect x="20.188" y="0.912542" width="77" height="98.7955" rx="8.5" transform="rotate(7.85215 20.188 0.912542)" stroke="currentColor" stroke-width="5"/>
                    </svg>
                </div>
            <div className="text-xs opacity-[0.8]">à rafraichir</div>
            </div>
            {/* <div className="w-[90px] bg-black/10 p-2 flex flex-col items-center text-center rounded-xl text-amber-300"> <div className="flex gap-2 items-center">{1200 - mastered_lc?.length} <svg width="18" height="22" viewBox="0 0 97 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 7.5H justify-center17.8231L8.62591 89.8146C8.19091 93.7078 10.8256 97.28 14.6736 98.0144L56.5585 106.008L9.77342 104.76C5.16889 104.637 1.5 100.869 1.5 96.2631V14C1.5 10.4101 4.41015 7.5 8 7.5Z" stroke="currentColor" stroke-width="5"/>
                    <rect x="20.188" y="0.912542" width="77" height="98.7955" rx="8.5" transform="rotate(7.85215 20.188 0.912542)" stroke="currentColor" stroke-width="5"/>
                    </svg>
                </div> <div className="text-xs opacity-[0.8]">Ajouté</div></div> */}
            <div className="w-[90px] bg-black/10 p-2 flex flex-col items-center text-center rounded-xl text-green-300"> <div className="flex gap-2 items-center">{mastered_lc?.length} <svg width="18" height="22" viewBox="0 0 97 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 7.5H justify-center17.8231L8.62591 89.8146C8.19091 93.7078 10.8256 97.28 14.6736 98.0144L56.5585 106.008L9.77342 104.76C5.16889 104.637 1.5 100.869 1.5 96.2631V14C1.5 10.4101 4.41015 7.5 8 7.5Z" stroke="currentColor" stroke-width="5"/>
                    <rect x="20.188" y="0.912542" width="77" height="98.7955" rx="8.5" transform="rotate(7.85215 20.188 0.912542)" stroke="currentColor" stroke-width="5"/>
                    </svg>
                </div> <div className="text-xs opacity-[0.8]">maitrisé</div></div>
           </div>

           <div onClick={() => {setShowCategorie(true)}} className="border border-b-2 flex gap-2 items-center border-black/50 bg-black/10 p-2 px-4  relative justify-center gap-2 rounded-xl">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 text-white/50">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122" />
            </svg>
            <div>

            <div className="text-white/60 text-xs">catégories selectionnées </div>
                
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 absolute top-2 right-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                </svg> */}

            <div className="flex gap-1 mt-0 italic text-white/90">{toeicPaths?.filter(e =>  profileWorkspace?.decks_ids?.includes(e.id)).slice(0, 2).map(e => <div className="text-xs rounded-xl">{e.name}</div>)} {profileWorkspace?.decks_ids?.length > 2 && <span className="text-white/70 text-xs">+{profileWorkspace?.decks_ids?.length - 2}</span>}</div>
           </div>
           <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 text-white" >
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
                </span>
           </div>
           
           </div>
           }


    
        {profileWorkspace?.goal_id== "learn" && selectedPath?.id == "voc" && <div className="rounded-xl relative flex flex-col items-center mt-4">
           <div className="text-xs text-white/30 ">Vocabulaire à apprendre</div>
           <div>{capitalizeFirstLetter(workspace?.name || "")} débutant</div>
           <div onClick={() => setShowModal('houseOfCards')} className="flex justify-center gap-2 my-4">
            <div className="w-[90px] flex flex-col items-center bg-black/10 p-2 text-center rounded-xl text-indigo-300">
                <div className="flex justify-center gap-2 items-center">{review_lc?.length} <svg width="18" height="22" viewBox="0 0 97 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 7.5H17.8231L8.62591 89.8146C8.19091 93.7078 10.8256 97.28 14.6736 98.0144L56.5585 106.008L9.77342 104.76C5.16889 104.637 1.5 100.869 1.5 96.2631V14C1.5 10.4101 4.41015 7.5 8 7.5Z" stroke="currentColor" stroke-width="5"/>
                    <rect x="20.188" y="0.912542" width="77" height="98.7955" rx="8.5" transform="rotate(7.85215 20.188 0.912542)" stroke="currentColor" stroke-width="5"/>
                    </svg>
                </div>
            <div className="text-xs opacity-[0.8]">à rafraichir</div>
            </div>
            {/* <div className="w-[90px] bg-black/10 p-2 flex flex-col items-center text-center rounded-xl text-amber-300"> <div className="flex gap-2 items-center">{80}% <svg width="18" height="22" viewBox="0 0 97 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 7.5H justify-center17.8231L8.62591 89.8146C8.19091 93.7078 10.8256 97.28 14.6736 98.0144L56.5585 106.008L9.77342 104.76C5.16889 104.637 1.5 100.869 1.5 96.2631V14C1.5 10.4101 4.41015 7.5 8 7.5Z" stroke="currentColor" stroke-width="5"/>
                    <rect x="20.188" y="0.912542" width="77" height="98.7955" rx="8.5" transform="rotate(7.85215 20.188 0.912542)" stroke="currentColor" stroke-width="5"/>
                    </svg>
                </div> <div className="text-xs opacity-[0.8]">Maitrise</div></div> */}
            <div className="w-[90px] bg-black/10 p-2 flex flex-col items-center text-center rounded-xl text-green-300"> <div className="flex gap-2 items-center">{mastered_lc?.length} <svg width="18" height="22" viewBox="0 0 97 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 7.5H justify-center17.8231L8.62591 89.8146C8.19091 93.7078 10.8256 97.28 14.6736 98.0144L56.5585 106.008L9.77342 104.76C5.16889 104.637 1.5 100.869 1.5 96.2631V14C1.5 10.4101 4.41015 7.5 8 7.5Z" stroke="currentColor" stroke-width="5"/>
                    <rect x="20.188" y="0.912542" width="77" height="98.7955" rx="8.5" transform="rotate(7.85215 20.188 0.912542)" stroke="currentColor" stroke-width="5"/>
                    </svg>
                </div> <div className="text-xs opacity-[0.8]">maitrisé</div></div>
           </div>


         
        </div>}

   
        {/* {profileWorkspace?.goal_id== "TOEIC" &&  selectedPath?.id == "voc" && <div className="text-center mt-40">
           <div className="text-2xl">Bientôt disponible</div>
           <div className="flex justify-center"><div className="py-1 border border-b-2 border-black/50 mt-4 rounded-xl cursor-pointer bg-black/10 px-4" onClick={() => toggleVersion()} >Revenir à la version 1.9 →</div></div>
        </div>} */}

        {/* {(profileWorkspace?.goal_id== "perfect" || profileWorkspace?.goal_id== "learn") &&  selectedPath?.id == "voc"  &&<div className="rounded-xl relative bg-black/10 px-6 py-2  border-2  border-black/30 mt-4">
           <div className="text-xs text-white/30">Mission du jour</div>
           <div className="flex gap-2 items-center"><div className="h-[16px] bg-black/10 border-white/60 rounded border-[1.6px] w-[16px]"></div> Faire 12xp</div>
           <div className="flex gap-2 items-center"><div className="h-[16px] bg-black/10 border-white/60 rounded border-[1.6px] w-[16px]"></div> Apprendre 4 cartes</div>
           <div className="flex gap-2 items-center"><div className="h-[16px] bg-black/10 border-white/60 rounded border-[1.6px] w-[16px]"></div> Rafraichir 8 cartes</div>


           
        </div>} */}




        {/* {<div className="p-4 rounded-xl bg-black/10 mt-4 border border-black/10">
            <div className="text-xs text-white/40 mb-2">Prochaines cartes</div>
            <div className="rounded-xl flex flex-wrap gap-2">{nextCards?.map(e => <div className="px-2 bg-white/10 rounded-xl">{e.term}</div>)}</div>
        </div> } */}

        {!selectedPath?.ready && !selectedPath?.beta && <div className={`p-4 rounded-xl mt-4 bg-black/30 mb-2 flex gap-2`}>
               <img src="/images/UX/time_logo.png" className="h-[80px]"/>
            
                <div className="flex-grow">
                  <div className="font-semibold fredoka">{selectedPath?.beta ? "Beta Test" : "Coming Soon"}</div>
                  <div className="text-sm">Cette fonctionnalité est en cours de développement et sera bientôt disponible</div>
                </div>
              </div>}

              {selectedPath?.beta && <div className={`p-4 rounded-xl mt-4 bg-black/30 mb-2 flex gap-2`}>
               <img src="/images/UX/time_logo.png" className="h-[80px]"/>
            
                <div className="flex-grow">
                  <div className="font-semibold fredoka">{selectedPath?.beta ? "Beta Test" : "Coming Soon"}</div>
                  <div className="text-sm">Cette fonctionnalité est en cours de développement et est disponible en bêta Test</div>
                </div>
              </div>}




              {selectedPath?.id == "train" && <TrainingExam />}
              {selectedPath?.id == "chatbot" && <ScenarioScreen />}
              {selectedPath?.id == "news" && <Actuality />}
              {selectedPath?.id == "grammar" && <Grammar />}
              {selectedPath?.id == "cultureG" && <CultureG />}


        



        {(profileWorkspace?.goal_id== "perfect" || profileWorkspace?.goal_id== "learn" || profileWorkspace?.goal_id == "TOEIC") && selectedPath?.id == "voc" && <div className="mt-3 text-center">
            {layersPaths
            .map((layer, i) => {
                const prevLayer = layersPaths?.[i-1]
                const cards = filteredLearnedCards?.filter(e => e.card.model == "lang").slice(layer.nb_sum, layer.nb_sum+layer.nb) || []
                const prevCards = filteredLearnedCards?.filter(e => e.card.model == "lang").slice(prevLayer?.nb_sum, prevLayer?.nb_sum+prevLayer?.nb) || []
                let state = "locked"
                // on test si les cartes du layers précédents sont terminées
                if (prevCards.length >= prevLayer?.nb) state = "free"
                if (i == 0)  state = "free"
                return {...layer, state, cards}
            })
            .map((layer) => { return <RenderLayer setBubble={setBubble} learnedCards={learnedCards} layer={layer} />})}
        </div>}

        {false && <div className="pt-8 flex justify-center"><div 
        onClick={async () => {
            try {
            const { error } = await supabase
                .from('profiles_cards')
                .delete()
                .match({ 
                profile_id: profile.id,
                workspace_id: workspace.id
                })

            if (error) {
                throw error
            }

            // Succès - vous pouvez ajouter une notification ou rafraîchir les données
            setLearnedCards([])
            setNextCards([])
            getNextCards(workspace.id, profile.id, setNextCards)

            
            } catch (error) {
            console.error('Erreur lors de la suppression:', error.message)

            }
        }} 
        className="px-4 mb-8 rounded-xl bg-black/10 text-red-300 cursor-pointer"
        >
        Reset ma progression
        </div>
        </div>}
    

        </div>

        {/* <div onClick={() => {getNextCards(workspace.id, profile?.id, setNextCards)}} className="rounded-xl bg-indigo-900/40 px-6 py-2  border-2 border-b-4 border-black/30 mt-4">
            <div> prochaine cartes : {nextCards?.map(e => <span>{e.term}</span>)}</div>
        </div> */}


        {/* <div className="bottom-[90px] fixed flex justify-center left-0 right-0">
            <div className="flex justify-center gap-2">
                <div onClick={() => {
                    setCardsToDiscover(nextCards.slice(0,4)) ;
                    setCardsToRefresh(null);
                    setShowModal('learnCards')}} className="px-4 border-black/80 text-shadow rounded-xl py-2 text-white border border-b-4 game-btn bg-cyan-500">Nouvelle leçon</div></div>

            <div onClick={() => {
                const _cardsToRefresh = getCardToLearn(learnedCards)?.slice(0,20)
                setCardsToRefresh(_cardsToRefresh);
                setCardsToDiscover(null)
                setShowModal('learnCards')
            } } className="px-4  border-black/80 rounded-xl py-2 text-white border-2 text-shadow border-b-4 game-btn bg-indigo-500">Rafraichir ma mémoire</div>
        </div> */}



    </div>
}

export {Home}