import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { pops } from "../../../utilities/helper/helper";
import { toggleVersion } from "../../../V3/helperFunctions";

const reload = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
  
        Promise.all(registrations.map(registration => registration.update())).then(() => {
          toggleVersion()
          // window.location.reload(true);
        });
      });
    }
  };


const changeSW = () => {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      // Attendre que tous les service workers soient désenregistrés
      Promise.all(registrations.map(registration => registration.unregister())).then(() => {
        // Suppression des caches
        caches.keys().then(cacheNames => {
          Promise.all(cacheNames.map(cacheName => caches.delete(cacheName))).then(() => {
            // Rechargement après la suppression des caches et la désinscription des SWs
            reload();
          });
        });
      });
    });
  };

  const getDataSW = () => {
    try {
      navigator.serviceWorker.controller.postMessage('getValeur');  
  } catch (error) {
    console.log(error)
  }
  }
  

const MainMenu = ({setShowMenu, user, setShowWorkspaces, setShowTutoBook, setShowBug, setShowInfo}) => {

    const [datasw, setdatasw] = useState(true)  

    

      useEffect(() => {
        getDataSW()
        navigator.serviceWorker?.addEventListener('message', function(event) {
            console.log('Valeur reçue du Service Worker :', event.data);
            setdatasw(event.data);
          });
        
          return () => {
            navigator.serviceWorker?.removeEventListener('message', null);
          };

      }, [])
   

      function toggleVersion() {
        // Récupérer la valeur actuelle (ou 0 si elle n'existe pas)
        let currentVersion = localStorage.getItem('new_version') || 'classic';
        
        // Toggle entre 0 et 1
        let newVersion = currentVersion === 'classic' ? 'v2' : 'classic';
        
        // Sauvegarder la nouvelle valeur
        localStorage.setItem('new_version', newVersion);
        
        // Optionnel : afficher la nouvelle valeur
        console.log('Version changée à:', newVersion);
        window.location.reload()
    }


    return <div id="backdrop" onClick={() => setShowMenu(false)} className='p-4 fixed top-0 bottom-0 left-0 right-0 z-[50]'>
                
                <div className='fredoka text-shadow text-white font-bold'>
                    <Link to={'/choose'}  className='bg-blue-600 border-b-4 border-2 border-blue-900 mb-2 px-4 py-2 rounded-xl inline-block'>Choisir un autre cours</Link>
                    <br/>
                    <div onClick={() => setShowTutoBook("all")}  className='bg-blue-600 border-b-4 border-2 border-blue-900 mb-2 px-4 py-2 rounded-xl inline-block'>Tutoriels</div>
                    <br/>                
                    <Link to={'/me'} className='bg-blue-600 border-b-4 border-2 border-blue-900 mb-2 px-4 py-2 rounded-xl inline-block'>Mon compte</Link>
                    <br/>
                    <Link to={'/notifications'} className='bg-blue-600 border-b-4 border-2 border-blue-900 mb-2 px-4 py-2 rounded-xl inline-block'>Mon coach</Link>
                    <br/>
                    <div  className='bg-blue-600 border-b-4 border-2 border-blue-900 mb-2 px-4 py-2 rounded-xl inline-block ' onClick={() => {
                      // setShowInfo(pops.popReportBug)
                      setShowBug(true)
    
                      }}><div className='flex gap-2 items-center'><svg className='h-4 w-4' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M19 15V11.9375C19 9.76288 17.2371 8 15.0625 8H8.9375C6.76288 8 5 9.76288 5 11.9375V15C5 18.866 8.13401 22 12 22C15.866 22 19 18.866 19 15Z" stroke="currentColor" strokeWidth="2"></path> <path d="M16.5 8.5V7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5V8.5" stroke="currentColor" strokeWidth="2"></path> <path d="M19 14H22" stroke="currentColor" strokeWidth="2" stroke-linecap="round"></path> <path d="M5 14H2" stroke="currentColor" strokeWidth="2" stroke-linecap="round"></path> <path d="M14.5 3.5L17 2" stroke="currentColor" strokeWidth="2" stroke-linecap="round"></path> <path d="M9.5 3.5L7 2" stroke="currentColor" strokeWidth="2" stroke-linecap="round"></path> <path d="M20.5 20.0002L18.5 19.2002" stroke="currentColor" strokeWidth="2" stroke-linecap="round"></path> <path d="M20.5 7.9998L18.5 8.7998" stroke="currentColor" strokeWidth="2" stroke-linecap="round"></path> <path d="M3.5 20.0002L5.5 19.2002" stroke="currentColor" strokeWidth="2" stroke-linecap="round"></path> <path d="M3.5 7.9998L5.5 8.7998" stroke="currentColor" strokeWidth="2" stroke-linecap="round"></path> <path d="M12 21.5V15" stroke="currentColor" strokeWidth="2" stroke-linecap="round"></path> </g></svg> 
                  Faire un retour</div>
                  </div><br/>   
                    {<div onClick={() => toggleVersion()}  className='bg-blue-600 border-b-4 border-2 border-blue-900 mb-2 px-4 py-2 rounded-xl inline-block'>Acceder à MindSeed 2.0</div>}
                    <br/>   



                {/* <div  className='bg-blue-600 border-b-4 border-2 border-blue-900 mb-2 px-4 py-2 rounded-xl inline-block ' onClick={() => {
                      // setShowInfo(pops.popReportBug)
                      setShowBug(true)
    
                    }}>
                      <div onClick={() => {
                        setShowInfo(pops.popReportBug)
                    }} className='flex gap-2 items-center'>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
  <path fillRule="evenodd" d="M8 1.75a.75.75 0 0 1 .692.462l1.41 3.393 3.664.293a.75.75 0 0 1 .428 1.317l-2.791 2.39.853 3.575a.75.75 0 0 1-1.12.814L7.998 12.08l-3.135 1.915a.75.75 0 0 1-1.12-.814l.852-3.574-2.79-2.39a.75.75 0 0 1 .427-1.318l3.663-.293 1.41-3.393A.75.75 0 0 1 8 1.75Z" clipRule="evenodd" />
</svg>
                Devenir ambassadeur</div>
                </div> */}
    
                
                    <br/>
     
                    <div className='flex my-2 justify-center items-center'>
                      <div  onClick={async(e) => {e.stopPropagation();await changeSW()}} className={'border-2  fixed bottom-10 border-b-4 px-3 py-1 rounded bg-purple-500 my-2 opacity-[10] text-amber-200 text-center   mb-2 px-4 py-2 rounded-xl '}>Charger la dernière version</div>
                    </div>
    
                    <div  className='opacity-[10] hover:scale-110 fixed text-center w-full  text-sm bottom-2 mb-2 px-4 py-2 rounded-xl '>enzo@mindseed.app</div>
                    {/* <Link to={'/test'} className='opacity-[10] hover:scale-110 fixed text-center w-full  text-sm bottom-[100px] mb-2 px-4 py-2 rounded-xl '>___</Link> */}
    
    
    
    
    
                <br/>
    
                </div>
                </div>
    }


export {MainMenu}