import { useEffect, useState } from "react"
import { sectionInfos } from "../../V2/components/quizzReader";
import { centToStars, getTotalFromTestId, quizList, quizList2 } from "../../utilities/helper/helper";
import { supabase } from "../../V2/screens/testScreen";
import { useStateContext } from "../StateProvider";


const TrainingExam = () => {

    const [trainingView, setTrainingView] = useState()
    const [tests, setTests ] = useState([]);
    const [showModalTraining, setShowModalTraining ] = useState();
    const [userTests, setUserTests ] = useState();

    const {setSelectedQuiz, profile, profilesTests, setProfilesTests} = useStateContext()

    const getTests = async(set) => {
        const { data, error } = await supabase
            .from('tests')
            .select(`*`)
    
        if (error) {
            console.error('Error fetching grammar decks:', error)
            return { data: null, error }
        }
    
        // Transformation des données pour n'avoir qu'un seul profile_card

        console.log('data', data)
      
        set(data)
        return { data, error }
        
    }

    const getProfileTests = async(set) => {
      const { data, error } = await supabase
          .from('profiles_tests')
          .select(`*`)
          .eq('profile_id', profile?.id)
  
      if (error) {
          console.error('Error fetching grammar decks:', error)
          return { data: null, error }
      }
  
      // Transformation des données pour n'avoir qu'un seul profile_card

      console.log('data', data)
    
      set(data)
      return { data, error }
      
  }

    useEffect(() => {
        getTests(setTests)
        getProfileTests(setProfilesTests)
    }, [])

    console.log('render traning')

    return (
    <div id="" className='flex fredoka text-white flex-col items-between justify-between' onClick={() => {setShowModalTraining(false)}}>
      
      {/* <div onClick={() => {console.log('profilesTests', profilesTests)}}>test= {profilesTests?.length}</div> */}
       <div onClick={(e) => e.stopPropagation()} className="">
         <div className="mb-4 my-2 ">
     
           <div onClick={() => setShowModalTraining(false)} className="absolute top-2 right-2 z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700" >
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
               <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
             </svg>
           </div>

           {/* <div onClick={() => console.log('tests', tests)}>test: {tests?.length}</div> */}
           {/* <img src="images/UX/dumbel.png" className="h-[63px] mt-2 mx-auto" /> */}
           {/* <h2 className="text-white text-center mb-4 -mt-2">
             Préparation TOEIC
        
             <div className='text-white/80 text-sm'>{typeof trainingView === 'number' && "Entrainement partie "+trainingView}</div>
             </h2> */}
     
             <div className='flex gap-2 justify-center mt-8'>
               <div onClick={() => {setTrainingView(null)}} className={`grow transition-all bg-black/10 rounded-md border border-b-2  text-center border-b border-black/40 ${trainingView != "white" ? "!bg-indgo-500/20 " : "scale-[0.95] opacity-[0.5]"}`}>Entrainement</div>
               <div onClick={() => {setTrainingView("white")}} className={`grow transition-all bg-black/10 rounded-md border border-b-2  text-center border-b border-black/40 ${trainingView == "white" ? "!bg-indgo-500/20 " : "scale-[0.95] opacity-[0.5]"}`}>Examens</div>
             </div>
          
         </div>
         {trainingView == null ? <div className="">
          
          {sectionInfos?.map((s, i) => <div onClick={() => {setTrainingView(i+1)}} className="rounded-[6px] flex gap-2 text-sm border-2 border-b-4 border-black/50 mt-1 px-3 py-2">
           <span className="flex gap-1"><img src="images/UX/dumbel.png" className='h-10 w-10 -mb-2 -mt-2 -ml-2 rotate-[344deg]' />
             Partie {i + 1}</span> <span className=" bg-white/10 px-2 rounded-full text-base">{s.title}</span>
           </div>)}
     
           <div onClick={() => {setTrainingView("mini")}} className="rounded-[6px] border-2 border-b-4 mt-4 text-sm border-black/50 flex items-center gap-1 mt-1 px-3 py-2">
           <img src="images/UX/dumbel.png" className='h-10 w-10 -mb-2 -mt-2 -ml-2 rotate-[344deg]' />
             Complet <span className="ml-2 bg-white/10 px-2 rounded-full text-base">Mini TOEIC</span>
             </div>
     
           
       </div> : <div>
         {trainingView != "white" && <div className='flex'><div className="mb-2 rounded-md px-2 bg-white/20 border border-white/50" onClick={() => setTrainingView(null)}>← retour</div></div>}
         {typeof trainingView === 'number' && <div>
           {quizList2.map(q => tests?.find(t => t.old_id == q.id)).map((t,i) => {
                 const profilesTest = profilesTests?.map(e => ({...e, valid: e.questions?.filter(q => q.valid)?.length})).sort((a,b) => b.valid - a.valid).find(ut => ut.train_id == `training_part_${trainingView}_n_${i}`)
                 const questions = profilesTest?.questions
                 const total = getTotalFromTestId(profilesTest?.test_id || profilesTest?.train_id) || 1
                 const cent = Math.round(questions?.filter(q => q.valid)?.length*100/total) || null
                
                 return <div className={`rounded-[6px] ${profilesTest ? "bg-black/10" : "opacity-50"} flex justify-between gap-2 text-sm border-2 border-b-4 border-black/50 mt-1 px-3 py-2`} onClick={() => {
                  
                   const questionToReview = questions?.filter(q => q.next_date && q.next_date < new Date())
                   setSelectedQuiz({id: t?.id, mode: `white`, fullVersion: true})
                //    {id: quizList[indexTOEIC]?.id, random: true, id_train: `training_part_${index_part%8}_n_${props.index}`, part: index_part%8, indexTOEIC: indexTOEIC}
                   setSelectedQuiz({id: t?.id, id_train: `training_part_${trainingView}_n_${i}`, mode: `${trainingView}`, fullVersion: null, filteredCards: []})
        
           }}>
             <div>
               <div> Entrainement {i+1}</div>
               <div className='flex gap-2 mt-1'> <div className='text-xs bg-white/20 rounded-full px-2 py-[2px]'>{sectionInfos[trainingView-1||0].title}</div>
               <div className={`${profilesTest ? "" : "text-white/80" } flex gap-1`}>{centToStars(cent/10)} {cent && <div>{cent}%</div>} </div>
              
               </div>
               {/* <div>id= {t?.id} {`training_part_${trainingView}_n_${i}`}</div> */}
             </div>
           
     
           </div>})}
           </div>}
     
         {trainingView == "white" && <div>{quizList.map(q => tests?.find(t => t.old_id == q.id)).map((t,i) => {
            const orderedProfileTests = profilesTests?.map(e => ({...e, valid: e.questions?.filter(q => q.valid)?.length})).sort((a,b) => b.valid - a.valid)

             const userTest = orderedProfileTests?.find(ut => ut.test_id == t.id)
             
             const cent = Math.round(userTest?.toeic_score*100/995) || null
             return <div onClick={() => {
                setSelectedQuiz({id: t.id, mode: `white`, fullVersion: true})
             }} className={`${userTest ? "bg-black/10" : "opacity-50"} rounded-[6px] flex gap-2 text-sm border-2 border-b-4 border-black/50 mt-1 px-3 py-2`}>
           
           <div>
             <div>TOEIC Blanc {i+1}</div>
             <div className='flex gap-1'>
             <div className={`${userTest ? "" : "text-white/80" } flex gap-1`}>{centToStars(cent/10)} </div>
             <div className='text-white/50 bg-white/10 rounded-full px-1'><span className='text-amber-400'>{userTest?.toeic_score || "--"}</span>/995</div>
            
             </div>
           </div>
           </div>}
           )}</div>}
         
         {trainingView == "mini" && <div onClick={() => console.log('coucou')}>{tests.filter(t => t.data?.mini).sort((a,b) => a.name - b.name).map(t => {
           const orderedProfileTests = profilesTests?.map(e => ({...e, valid: e.questions?.filter(q => q.valid)?.length})).sort((a,b) => b.valid - a.valid)
           const userTest = orderedProfileTests?.find(ut =>  ut.test_id == t.id)
           const total = getTotalFromTestId(userTest?.test_id)
           const cent = Math.round(userTest?.questions?.filter(q => q.valid)?.length*100/total) || null
     
           return <div onClick={() => {
            console.log('userTest', userTest)
            setSelectedQuiz({id: t?.id, mode: `all`, fullVersion: true, filteredCards: []})

            }} className={`${userTest ? "" : "opacity-50"} rounded-[6px] flex gap-2 text-sm border-2 border-b-4 border-black/50 mt-1 px-3 py-2`}>
             <div className=''>
               {t.name.slice(3)} - {userTest?.questions?.filter(q => q.valid)?.length}/{total}
               <div className={`${userTest ? "" : "text-white/80" } flex gap-1`}>{centToStars(cent/10)} {cent && <div>{cent}%</div>} </div>
               {/* <div className='flex mt-1'><div className='bg-white/20 rounded-full px-2 '>{questionsCorrect || "--"}/{total || '--'}  </div></div> */}
               </div>
             </div>})}</div>}
         </div>}
     </div>
     </div>
    )
}

export {TrainingExam} 