import { useEffect } from "react";

const Alert = ({alert, setAlert}) => {

  
    useEffect(() => {
      console.log('il y aune allerte !')

      const timeout = setTimeout(() => {
        setAlert(null)
      }, alert && alert.time || 1000);
  
      return () => clearTimeout(timeout);
    }, [alert]);
   
    
  
    return (
      <div className={`fredoka fixed bottom-0 z-[999] rounded-top-xl transition-all right-0 left-0`}>
      
          <div className=' bg-gradient-to-b from-purple-600 to-indigo-600 bg-blue-500 items-center text-white gap-4 flex p-4 pb-8'>
            {alert?.icon && <div>{alert?.icon}</div>}
            <div>
              <h4 className='text-xl font-semibold mb-2'>{alert?.title}</h4>
              <div>{alert?.content}</div>
            </div>
          </div>
      </div>
    );
  };
  
  export {Alert};