import { useEffect, useState } from "react"
import { QCM } from "../triggers/QCM"
import { SentenceBuilder, SentenceBuilderDiscover, clean, splitSentence } from "../triggers/Sentence"
import { FillSentence } from "../triggers/FillSentence"
import { brackSentence, extractFirstBracketContent, getTraduction, hasWordInBrackets, speak } from "../../helperFunctions"
import { BackCard } from "./flashcard"
import { AudioBtn } from "../triggers/AudioBtn"



const TriggerInput = ({card, validCard, workspace}) => {


    const brackedSentence = hasWordInBrackets(card.context) ? card.context : brackSentence({sentence: card.context, word: card.term})
    console.log('card', card)
    console.log('brackedSentence', brackedSentence)

    return <>
        <div className="h-[40%] mb-8 bg-center bg-cover text-slate-500 rounded-xl relative" style={{backgroundImage: `url('${card.img}')`}}>
    <div className="bg-gradient-to-b from-black/0 to-[#4a0382e6] p-2 pt-[20%] absolute bottom-0 left-0 right-0 rounded-b-xl">
        <div className="text-white/90 italic">{getTraduction(card)}</div>
    </div>
   
   
   
</div>      
    <FillSentence speak={() => {speak({text: card.term + "; . " + card.context, lang: workspace.lang})}} isNew={true} brackedSentence={brackedSentence}  validCard={validCard} />
    
    {/* <div className="h-[100px]  flex items-center justify-center  rounded-b-2xl absolute p-4 bottom-0 left-0 right-0">
      <div className="bg-white px-6 rounded-xl border-amber-600/50 border border-b-4 py-2 text-amber-700 ">Voir un indice</div>
    </div> */}
    </>
}


const TriggerQCM = ({card, randomCards, validCard, handleArchived}) => {

    const word = extractFirstBracketContent(card?.context) || card?.term

return <>
<div className="h-[40%] bg-center bg-cover text-slate-500 rounded-xl relative" style={{backgroundImage: `url('${card?.img}')`}}>
    {/* <div className="bg-gradient-to-b from-black/0 to-[#4a0382e6] p-2 pt-[20%] absolute bottom-0 left-0 right-0 rounded-b-xl">
        <div className="text-white/90 italic">{getTraduction(card)}</div>
    </div> */}
   
   
</div>        

<div 
  className="text-amber-900/50  italic text-sm mt-4" 
  dangerouslySetInnerHTML={{ __html: card.context_fr.replace(/\[([^\]]+)\]/g, '<span class="text-amber-500/80 font-semibold">$1</span>') }}
></div>
{/* <div className="flex flex-wrap gap-1 justify-center mt-2">{splitSentence(card.context, word).map((e,i) => <div  key={i} className={`${clean(e) == word ? "bg-amber-500/50 border-amber-500/50": "bg-amber-200/60 border-transparent"} border-2  max-h-[20px] text-transparent   px-2 rounded-lg `}>{e}</div>)}</div> */}

<div className="flex justify-center"></div>
<div className="h-[200px] absolute p-4 bg-[#ffeac6] rounded-2xl pattern-triangle bottom-0 left-0 right-0">
    <QCM className={'!bg-amber-500/90 !border-amber-600 !text-white'} elements={randomCards.slice(0,3)} field={"term"} goodElement={card} validCard={validCard} />

    <div className="flex justify-center">
        <div className="text-amber-700/80  bg-amber-500/20 mt-4 text-sm border-2 px-3 py-1 rounded-2xl border-amber-700/40 flex gap-1 items-center" onClick={() => {handleArchived(card)}}>Ne pas apprendre 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z" />
        </svg>
        </div>
    </div>
</div>
</>}




const TriggerSentence = ({card, validCard, handleArchived, workspace}) => {


return <div className="flex -mx-6 rounded-b-2xl flex-col h-full">
  
    <AudioBtn  card={card} lang={workspace.lang} speakText={card.context.replace(/\[([^\]]+)\]/g, '$1')}/>
   
   
 
    <div 
  className="text-amber-900/50 italic text-sm mt-2 mx-3" 
  dangerouslySetInnerHTML={{ __html: card.context_fr.replace(/\[([^\]]+)\]/g, '<span class="text-amber-500/80 font-semibold">$1</span>') }}
></div>

<div className="rounded-b-2xl mt-8 grow  overflow-hidden">
    <SentenceBuilder validCard={validCard} sentence={card.context?.trim()} word={extractFirstBracketContent(card.context) || card.term} />
    
</div>

</div>}

const DiscoverCard = ({card, profile_card, updateCard, setDiscoverPoints, lang, validCard, workspace, handleArchived, randomCards, setCurrentMode}) => {

    const [state, setState] = useState(1)
    useEffect(() => {
        setState(1)
    }, [card])


    const createUserProfile = () => {
        
    }

    const passWriting = workspace?.name == "Japonais" || workspace?.name == "Chinois" || workspace?.name == "Coréen"

    if (!card) return <div>Pas de carte ...</div>

    if (state == 4) return <BackCard updateCard={updateCard} workspace={workspace} randomCards={randomCards} card={card} profile_card={profile_card} validCard={validCard}/>

    return <div className="bg-[#FEF7EB] h-full p-6 relative rounded-2xl">
        <div className="flex justify-center mb-2 -mt-2"><div className="px-2  text-sm text-amber-600 bg-amber-500/20 rounded-xl" onClick={() => {validCard()}}>Nouvelle carte</div></div>
        {state == 1 && <TriggerQCM  card={card} randomCards={randomCards} validCard={() => {setState(2); setDiscoverPoints(prev => prev + 1)}} handleArchived={handleArchived} />}
        {state == 2 && <TriggerSentence  workspace={workspace} card={card} randomCards={randomCards} validCard={() => {setState(passWriting ? 4 : 3); setDiscoverPoints(prev => prev + 1)}} handleArchived={handleArchived} />}
        {state == 3 && <TriggerInput workspace={workspace} card={card} randomCards={randomCards} validCard={() => {
            console.log('__blink valid du TriggerInput' )
            setState(4);
            setDiscoverPoints(prev => prev + 1)
            speak({text: card.term + "; ." + card.context, lang: workspace.lang})
            }} handleArchived={handleArchived} />}

    </div>

}

export {DiscoverCard}