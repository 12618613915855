import { useEffect, useRef, useState } from "react";
import { scoreTableTOIEC } from "../../utilities/helper/helper";
import { SuperEditor } from "../../components/editor";
import { displayDuration } from "../helperFunctions";
import { useStateContext } from "../StateProvider";
import { supabase } from "../../V2/screens/testScreen";
import { sbCreate, sbUpdate } from "../DbFunction";

const sectionInfos = [{
    nb: 6, 
    nb_before: 0,
    title: "Photographies",
    nb_mini: 1,
    nb_before_mini: 0,
    consign: "Tu entendras quatre descriptions courtes pour chaque photographie. Choisis la description qui correspond le mieux à la photographie."
  }, {
    nb: 25,
    nb_before: 6,
    nb_mini: 5,
    nb_before_mini: 1,
    title: "Question-Réponse",
    consign: "Tu entendras une question ou une déclaration suivie de trois réponses. Choisis la meilleure réponse à la question ou la meilleure réaction à la déclaration."
  }, 
  {
    nb: 39,
    nb_before: 31,
    nb_mini: 3,
    nb_before_mini: 5,
    title: "Conversations Courtes",
    consign: "Tu entendras plusieurs dialogues courts. Chaque dialogue sera suivi de trois questions. Choisis la meilleure réponse pour chaque question."
  }, {
    nb: 30,
    nb_before: 70,
    nb_mini: 3,
    nb_before_mini: 8,
    title: "Courts Exposés",
    consign: "Tu entendras des courts exposés ou annonces. Chaque exposé ou annonce sera suivi de trois questions. Choisis la meilleure réponse pour chaque question."
  }, {
    nb: 30,
    nb_before: 100,
    nb_mini: 5,
    nb_before_mini: 12,
    title: "Phrases Incomplètes",
    consign: "Complète la phrase en choisissant la meilleure parmi les quatre options proposées."
  }, {
    nb: 16,
    nb_before: 130,
    nb_mini: 4,
    nb_before_mini: 16,
    title: "Textes à Compléter",
    consign: "Complète les textes avec les phrases proposées. Il y a généralement plusieurs phrases proposées pour chaque emplacement, et tu dois choisir la plus appropriée."
  }, {
    nb: 54,
    nb_mini: 6,
    nb_before: 146,
    nb_before_mini: 20,
    title: "Compréhension de Textes",
    consign: "Tu liras différents types de textes, tels que des extraits de brochures, des lettres, des articles de journaux, etc. Chaque texte est suivi de plusieurs questions. Choisis la meilleure réponse pour chaque question basée sur le texte."
  }]
  


  const getPages = async(test_id) => {
    const { data, error } = await supabase
    .from('pages')
    .select(`*`)
    .eq('test_id', test_id)

    console.log('page data', data)
    console.log('error', error)

  

  return data
  }

  const getPagesPart = async (test_id, index_section) => {

  
      const { data, error } = await supabase
      .from('pages')
      .select(`*`)
      .eq('index_section', index_section - 1 )
      .eq('test_id', test_id)


      console.log('page data', data)
      console.log('error', error)

    

    return data

}
  
  
  const TimeCounter = (props) => {
    // Temps total et temps passé en secondes
    const totalTime = props.totalTime; // Temps total en secondes
    const timePassed = props.timePassed; // Temps déjà écoulé en secondes
  
    // Initialiser l'état du compteur pour le temps restant
    const [counter, setCounter] = useState(totalTime - timePassed);
  
    // Mettre à jour le compteur chaque seconde pour le décompte
    useEffect(() => {
      console.log('counter')
      if (counter <= 0) {
        // Si le temps est écoulé, nettoyer l'intervalle
        return;
      }
  
      // Créer un intervalle qui décrémente le compteur chaque seconde
      const interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
  
      // Nettoyer l'intervalle quand le composant est démonté ou rechargé
      return () => clearInterval(interval);
    }, [counter]); // Ajouter counter comme dépendance pour arrêter l'intervalle quand il atteint 0
  
    // Calculer les heures et les minutes à partir du compteur pour le temps restant
    const hours = Math.floor(counter / 3600);
    const minutes = Math.floor((counter % 3600) / 60);
  
    // Formater l'affichage pour montrer les heures et les minutes restantes
    const formattedTime = `${hours}h${String(minutes).padStart(2, '0')}`;
  
    // Afficher "Terminé" si le temps est écoulé
    if (counter <= 0) {
      return (
        <div onClick={() => {props.setShowRecap(true)}} className="flex items-center gap-2 text-sm text-slate-500">
          Résulats →
        </div>
      );
    }
  
    return (
      <div className="flex items-center gap-2 text-sm text-slate-500">
        {formattedTime}
      </div>
    );
  };
  
  export default TimeCounter;
  
  
  
  
  
  const AudioReader = (props) => {
    const [audioProgress, setAudioProgress] = useState(0);
    const audioRef = useRef();
    const id = "_" + props.id
    
  
    useEffect(() => {
      const audio = audioRef.current;
  
      const updateProgress = () => {
        const progress = (audio.currentTime / audio.duration) * 100;
        setAudioProgress(progress.toFixed(0));
      };
    
  
      audio.addEventListener('timeupdate', updateProgress);
  
      return () => {
        audio.removeEventListener('timeupdate', updateProgress);
      };
    }, []);
  
    const play = () => {
      const audio = audioRef.current;
      const audioHTML = document.querySelector('#'+id)
      console.log('id', id)
      console.log('audio', audioHTML);
      audioHTML?.click()
      if (audio.paused) {
        audio.play();
    } else {
        audio.pause();
    }}
    
  
    return <div>
      <audio 
        id={id}
        ref={audioRef}
        className="my-1 w-fullh hidden bg-green-500 p-[1px] rounded-full" 
        src={props.src} 
        controls
      ></audio>
      <div className="flex gap-2 rounded-xl text-white bg-gradient-to-r to-blue-500 from-cyan-500 items-center mb-2 p-2 pr-4 " onClick={() => {play()}}>
        <div className="rounded-full p-2 bg-white/10">
        { audioRef?.current?.paused ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
          </svg>
          : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
          </svg>
          }
        </div>
        <div className="bg-white/10 rounded-full relative w-full h-[10px]">
          <div className="absolute top-0 left-0 bottom-0 rounded-full bg-white/70" style={{width: audioProgress+"%"}}></div>
        </div>
      </div>
    </div>
  }
  
  const validSound = new Audio('/assets/valid_sound.mp3');
  validSound.volume = 0.2;
  
  function objectsAreEqual(obj1, obj2) {
    if (!(obj1 && obj2)) return false
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) {
        return false;
    }
  
    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
  
    return true;
  }
  
  const RenderPage = (props) => {
    const page = props.page;



    const {profile, profilesTests, setProfilesTests} = useStateContext()

    const index = props.index;
    const mode = props.mode;
    const userTest = props.userTest;
    const userTestQuestion = userTest?.questions?.find(q => q.page_id == page?.id)
    // console.log('userTestQuestion 🔑🔑🔑', userTestQuestion)
  
    const noFeedBack = mode == "white"
    
    //const isRevision = (userTestQuestion?.next_date && userTestQuestion?.next_date < new Date())
    const isRevision = false
    const [selectedInput, setSelectedInput] = useState(userTestQuestion?.answer);
    const [audioProgress, setAudioProgress] = useState(0);
    const sectionInfo = sectionInfos[page?.index_section]
  
    useEffect(() => {
      const userTestQuestion = userTest?.questions?.find(q => q.page_id == page?.id)
      setSelectedInput(userTestQuestion?.answer)
      console.log('userTestQuestion 2 🔑', userTestQuestion)
    }, [props.page, userTest])
  
  
    const showFeedBack = props.showFeedBack || mode == "white" ? userTest?.endDate : isRevision ? selectedInput : selectedInput && userTestQuestion
  
    const handleInput = async (input, index_section) => {
      setSelectedInput(input)
      input.correct && showFeedBack && validSound.play();
     
      if (userTest && input) {
        let nextDate = new Date()
        // nextDate.setHours(nextDate.getHours() + 3); // Ajoute 3 heures à l'objet Date
        nextDate.setMinutes(nextDate.getMinutes() +1)
        if (mode == "white") nextDate = null
        const oldQuestion = userTest?.questions?.find(q => q.page_id == page?.id)
        const newQuestion = oldQuestion ? userTest?.questions.map(q => q.page_id == page?.id ? {...q, answer: input, valid: input.correct ? true : false, next_date: !input.correct ? nextDate : null } : q) 
        : [...userTest?.questions || [], {
          page_id: page?.id, 
          answer: input,
          valid: input.correct ? true : false, 
          part: index_section +1,
          next_date: !input.correct ? nextDate : null 
        } ]
  
        const newUserTest = {...userTest,  data: {...userTest.data, [`part_${index_section + 1}`]: (userTest.data?.[`part_${index_section + 1}`] || 0) + (input.correct ? 1:0)}, questions: newQuestion}
  
        

        const listenParts = (newUserTest.data.part_1 || 0) + (newUserTest.data.part_2 || 0) + (newUserTest.data.part_3 || 0) + (newUserTest.data.part_4 || 0)
        const listenScore = scoreTableTOIEC[listenParts].l || 0
        const readScore = scoreTableTOIEC[(newUserTest.data.part_5 || 0) + (newUserTest.data.part_6 || 0) + (newUserTest.data.part_7 || 0)].r || 0
  
        const _userTest = {...newUserTest, toeic_score: (listenScore|| 0)+(readScore||0), listenScore, readScore }
        const _userTests = profilesTests?.map(ut => ut.id == newUserTest.id ? _userTest : ut)
        await sbUpdate('profiles_tests', _userTest.id, _userTest)

        console.log('handle input', _userTest)
        setProfilesTests(_userTests)
  
      } else {
        console.log('pas de user tests 🔴🔴')
      }
  
      
    }
  
  
  
  
    // const showFeedBack = userTestQuestion
    const editable = props.editable != null ? props.editable  : !showFeedBack || (!userTestQuestion || (userTestQuestion.next_date && userTestQuestion.next_date < new Date()))
  
    // console.log('userTestQuestion', userTestQuestion)

    console.log('renderPage')
  
    const isAdmin = profile.email == "local@gmail.com"
    // console.log(' ct.user.mail',  ct.user)
    // console.log('isAdmin', isAdmin ? "oui " : "no")
  
  
    // console.log("userTestQuestion", userTestQuestion)
    //  console.log("selectedInput", selectedInput)
  
    return <div className={`transition-all bg-white/40 rounded-lg shadow border-black/30  mt-4 p-3`} onClick={() => console.log('pint', page)}>
      <div className="mb-2 items-center flex justify-between text-sm text-slate-400">
        <div className="flex items-center gap-2">
          {isAdmin && <div className="" ><svg 
          onClick={() => {
            const newPage = {...page, order: (parseInt(page.order||0)) + 1}
            props.setPages(p => p.map(pp => page.id == pp.id ? newPage : pp))
            // updateDoc(doc(db, 'pages', newPage.id), newPage);  
            }} 
          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
          </div>}
          <div>Question {props.selectedQuestion || index + 1 + (mode.length > 2 ? mode == "all" ? sectionInfo.nb_before_mini : sectionInfo.nb_before  : 0)} {isRevision ? "(revision)" : ""}</div>
        </div>
        <div className={`${page?.bug == "oui" ? "bg-red-200 text-red-500" : page?.bug == "non" ? "" : "text-gray-300" } rounded-full p-1 `} 
        onClick={async () => {
           const newPage = {...page, bug: page.bug != "oui" ? "oui" : "non"}
           props.setPages(p => p.map(pp => page.id == pp.id ? newPage : pp))
        //    updateDoc(doc(db, 'pages', newPage.id), newPage);  
        }}
          ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0 1 12 12.75Zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 0 1-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 0 0 2.248-2.354M12 12.75a2.25 2.25 0 0 1-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 0 0-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 0 1 .4-2.253M12 8.25a2.25 2.25 0 0 0-2.248 2.146M12 8.25a2.25 2.25 0 0 1 2.248 2.146M8.683 5a6.032 6.032 0 0 1-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0 1 15.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 0 0-.575-1.752M4.921 6a24.048 24.048 0 0 0-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 0 1-5.223 1.082" />
        </svg>
        
        </div>
        <div onClick={async() => {
           const { error,data } = await supabase
           .from('pages')
           .delete()
           .match({ 
           id: page.id
           })

           console.log('delete', { error,data })

        }}>Supprimer</div>
        {page.bug == "oui" && <textarea className="rounded-xl bg-red-200/30 p-1" onBlur={async (e) => {
           const newPage = {...page, bugInfo: e.currentTarget.value}
           props.setPages(p => p.map(pp => page.id == pp.id ? newPage : pp))
        //    updateDoc(doc(db, 'pages', newPage.id), newPage);  
        }} placeholder="Signaler un bug">{page.bugInfo}</textarea>}
        </div>
      <div>{page?.content}</div>
      {page?.img && page.index_section == 2 && <div class="image-container rounded-xl"><img src={page?.img} className="rounded-md " /></div>}
      {!page.group_id && <div>
        {page?.audio && <AudioReader id={index + 1 + sectionInfo.nb_before} src={page.audio} />}
        {page?.img && <div class="image-container rounded-xl"><img src={page?.img} className="rounded-md " /></div>}
       
      </div>}
      
      <div className="flex gap-2 mt-2 rounded-xl flex-wrap ">
    
        {page?.inputs.sort((a, b) => a.letter?.localeCompare(b.letter)).map((input, index_input) => 
          <div onClick={() => {editable && handleInput(input, page.index_section)}} className={`${input.answer ? " w-full" : ""} ${showFeedBack ? 
            objectsAreEqual(selectedInput,input) ? 
            selectedInput.correct ? "!bg-[#3cffdb] border-[#00a289] !text-[#00a289] w-full" : " !text-[#ec4899] w-full !bg-[#ffd4f8] !border-[#ffadf2]" 
            : 
            selectedInput ? 
              input.correct ? "!bg-[#3cffdb] !border-[#00a289] !text-[#00a289]" : "" : "" : ""} ${objectsAreEqual(selectedInput,input) && "bg-blue-500 text-white" }  ${input.answer ? "" : "text-center "} w-full text-blue-700 border-blue-400/50 flex gap-2 p-1 px-2 cursor-pointer transition   rounded-xl grow`}>
            <div className="flex gap-2">
              <div className={`border-[1.6px] border-b-[3px] border-slate-400 rounded-full max-h-[18px] max-w-[18px] min-h-[18px] min-w-[18px] mt-1 flex items-center justify-center ${objectsAreEqual(selectedInput,input) ? "border-purple-500" : ""}`}>{objectsAreEqual(selectedInput,input) && <div className="bg-purple-500 rounded-full h-2 w-2"></div>}</div>
              {input.answer || input.letter}
              </div>
            {(showFeedBack && page.index_section == 0) && <div className="grow text-left" > {input.reveal}</div>}
          </div>
        )}
      </div>
    </div>
  }
  
  
  function sortByStartDate(data) {
    return data?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  }
const QuizzReader = (props) => {


    

  

    const ct = props.ct


  

    const [userTest, setUserTest] = useState()
    
    const [pages, setPages] = useState([])
    const [groupIndex, setGroupIndex] = useState(0)
    const [showRecap, setShowRecap] = useState(false)
    const [showText, setShowText] = useState(false)
    const [showCorrection, setShowCorrection] = useState(false)
    const [selectedQuestion, setSelectedQuestion] = useState(0)
    const [correctionIndex, setCorrectionIndex] = useState(0)


    console.log('pages', pages)


    const {setSelectedQuiz, workspace, setProfilesTests, profilesTests, selectedQuiz, profile} = useStateContext()



   

    const mode =  props.test.mode
    const fullVersion = props.test.fullVersion


    const partNumber = parseInt(mode) - 1
    console.log('partNumber', partNumber)

   
    const [selectedSection, setSelectedSection] = useState(partNumber ?  partNumber : partNumber == 0 ? 0 : null)
    const [readedSection, setReadedSection] = useState([])   
    const sectionInfo = sectionInfos[selectedSection]

  

    useEffect(() => {
      if (!selectedQuestion) return
      let newSection = 0
      if (selectedQuestion > 0) { newSection = 0}
      if (selectedQuestion > 6) { newSection = 1}
      if (selectedQuestion > 31) { newSection = 2}
      if (selectedQuestion > 70) { newSection = 3}
      if (selectedQuestion > 100) { newSection = 4}
      if (selectedQuestion > 130) { newSection = 5}
      if (selectedQuestion > 146) { newSection = 6}
      console.log(`question: ${selectedQuestion} changement de section: ` + newSection)
      setSelectedSection(newSection)
      setShowText(false)
    }, [selectedQuestion])

    const chooseQuestionFromSection = (section) => {
      const nb = sectionInfos[section].nb_before || 0
      console.log('chooseQuestionFromSection 💡💡💡', nb)
       
      setSelectedQuestion(nb)
      setCorrectionIndex(nb-1)
    }

    useEffect(() => {
   
      console.log('props.test?.id', props.test?.id)
      console.log('props.test?.id_train', props.test?.id_train)
      props.test?.id_train && setUserTest(sortByStartDate(profilesTests)?.find(ut =>  ut.test_id == props.test?.id_train || ut.train_id == props.test?.id_train))
      !props.test?.id_train && setUserTest(sortByStartDate(profilesTests)?.find(ut => ut.test_id == props.test?.id || ut.train_id == props.test?.id))
      
    }, [profilesTests])
    // console.log('ct userTests', ct.userTests)
    // console.log('props.test?.id', props.test?.id)
    //  console.log('userTest', userTest)
    const index_section = parseInt(mode)



    const initPage =async (selectedTest_id, mode) => {
     
      if (mode == "white" || mode == "all") {
        console.log('récupération des pages all part', selectedTest_id)
        const _pages = await getPages(selectedTest_id)
        console.log('récupération des _pages', _pages)
        setPages(_pages)
      } else {
        const index_section = parseInt(mode)
        console.log('id', selectedTest_id)
        console.log('index_section', index_section)
        console.log('ct quiz', selectedQuiz)
      
        const userTest = sortByStartDate(profilesTests)?.find(ut => ut.test_id == props.test?.id || ut.test_id == props.test?.id_train || ut.train_id == props.test?.id_train)
        let _pages = await getPagesPart(selectedTest_id, index_section)

        const questions = userTest?.questions
        const questionToReview = questions?.filter(q => q.next_date && q.next_date < new Date())
        const questionToReviewIDS = questionToReview?.map(q => q.page_id)
        const sectionInfo = sectionInfos[parseInt(mode) - 1]
        // console.log('sectionInfo', sectionInfo)
        const isFinished = questions?.length == sectionInfo.nb
        

        // console.log(`questions: ${questions?.length} / pages: ${sectionInfo.nb}`, isFinished)
        // console.log("questionToReview", questionToReview)

        // console.log('récupération des _pages part par part', _pages)

        //  if (isFinished) _pages = _pages.filter(p => questionToReviewIDS?.includes(p.id))
       
       
        setPages(_pages)
      }
    }

    useEffect(() => {
      if (props.fetch) {
        initPage(props.test?.id, props.test.mode)
      } else {setPages(props.pages)}
    }, [props.test?.id])


    const indexSlice = props.test?.mode === "all" ? pages?.filter((page) => page.index_section == selectedSection).length : index_section ? (sectionInfo?.nb > 12 ? 12 : sectionInfo?.nb ) : sectionInfo?.nb
    const pagesOfSection = pages?.filter((page) => page.index_section == selectedSection && !page?.group_id).sort((a,b) => (a.order || 0) - (b.order||0)).slice(0, indexSlice)
    const allPages = pages?.sort((a,b) => (a.order || 0) - (b.order||0)).sort((a,b) => (a.group_id || 0) - (b.group_id||0)).sort((a,b) => (a.index_section || 0) - (b.index_section||0))
    
    console.log('allPages', allPages)
    // console.log('pages', pages)
    const groupedPages = pages?.filter(p => p.index_section === selectedSection)?.sort((a,b) => (a.order || 0) - (b.order||0))
    .filter(a => a.group_id)
    .sort((a, b) => (a.group_id - b.group_id))
    .slice(0, indexSlice)
    .reduce((r, item) => {
        const groupId = item.group_id || "alone"; // Utilisez "alone" comme clé par défaut si group_id n'existe pas
        r[groupId] = [...(r[groupId] || []), item];
        return r;
    }, {});
    
    console.log('groupedPages', groupedPages)
    console.log('groupedPages filter 1', pages?.filter(p => p.index_section === selectedSection))
    console.log('groupedPages filter 1', pages?.filter(a => a.group_id))
    console.log('selectedSection', selectedSection)
    console.log('pages', pages)
let currentPageNumber = 0; // Variable pour suivre le numéro de page actuel


const groupedPagesArray = Object.entries(groupedPages)
    .filter(([key, _]) => key !== "alone")
    .map(([_, value]) => value.map((page) => ({
        ...page,
        pageNumber: ++currentPageNumber // Incrémente et ajoute le numéro de la page
    })));


    

  


    const startHandler = async (test_id, newTry = false) => {
      console.log("startHandler pour 🟠", test_id)
      console.log('props', props)
      console.log('partNumber', partNumber)

     
      if (!userTest || newTry) {
        const newUserTest = {
          test_id: typeof test_id === "string" ? null : test_id, 
          profile_id: profile.id, 
          workspace_id: workspace?.id, 
          startDate: new Date(),
          train_id: typeof test_id === "string" ? test_id : null 
         
        }
        console.log("creation d'un user test !!! ✅ ✅ ✅", newUserTest)


        // BIEN FAIRE LES DATES AUSSI 
    //    const idTest = await ct.fire.createDb('user_tests', newUserTest)

       

       const newData = await sbCreate('profiles_tests', newUserTest)
       
       setProfilesTests(prev => [...prev || [], {...newUserTest, id: newData?.id}])
        
      }
    }


    // console.log('usersTests', profilesTests)
    // console.log('usersTest', userTest)

    

    const handleEnd = () => {
      const newUserTest = {...userTest, endDate: new Date()}
      console.log('update du userTest', newUserTest)
   
      sbUpdate('profiles_tests', newUserTest?.id, newUserTest)
      setProfilesTests(p => p.map(ut => ut.id == newUserTest.id ? newUserTest : ut))
      setShowRecap(true)
    }

    const replaceWithNb = (text, nb) => {
      let i = 0
      return text.replace(/__([0-9]+)__/g, (match, number) => {
          i++
          return nb ? `<u>${parseInt(nb) + i}</u>` : match; // Remplace ou laisse tel quel si non trouvé
      });
  };


   


  const totalQuestions = mode == "all" ? 26 : 200
  const validQuestions = userTest?.questions?.filter(q => q.valid)?.length

  function differenceInSeconds(date1, date2) {
    // Obtenir la différence en millisecondes
    const differenceInMilliseconds = date2 - date1;
  
    // Convertir la différence en secondes
    const differenceInSeconds = differenceInMilliseconds / 1000;
  
    return Math.abs(differenceInSeconds); // Retourne la valeur absolue pour s'assurer que la différence est toujours positive
  }
    

    const nbQuestionCurrentPart = props.test?.mode === "all" ? pages?.filter((page) => page.index_section == selectedSection).length : index_section ? (sectionInfo?.nb > 12 ? 12 : sectionInfo?.nb ) : sectionInfo?.nb
    const nbCurrentQuestions = userTest?.questions?.filter(q => q.part == selectedSection+1).length
    const progressPart = (nbCurrentQuestions/nbQuestionCurrentPart)*100

    console.log("groupedPagesArray", groupedPagesArray)
    console.log("groupIndex", groupIndex)
    console.log('pagesOfSection', pagesOfSection)
    const currentPageArray =  groupedPagesArray.slice(groupIndex,groupIndex+1) 
    console.log('groupedPagesArray', groupedPagesArray)
     console.log('selected quesiton', selectedQuestion)
    const separateRichText = currentPageArray[0] && currentPageArray[0][0]?.index_section > 4
    const questPerPartFinished = (groupedPagesArray.length && groupIndex+1 == groupedPagesArray.length) || (groupedPagesArray.length == 0 && pagesOfSection.length <= (groupIndex+1)*6)

  return (
    <div className={`fredoka h-screen overflow-scroll max-w-[500px] mx-auto`}>


        {selectedSection == null && <div id="firstScreen" className="bg-[#fef050] text-center z-20 flex flex-col justify-center p-8 h-screen">
          <div className="absolute top-4 left-4" onClick={() => setSelectedQuiz()}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg></div>
              <img src="https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/MindSeed%2Fmotivation.png?alt=media&token=390a7712-f6b6-4c22-bac9-bb2d5e703893" className="w-[160px] mx-auto mb-12"/>
              <div className="text-2xl">{props.test.mode == "all" ? "Mini TOEIC" : "TOEIC Blanc"}</div>
              <div>{props.test.mode == "all" ? "Entraîne-toi pour le TOEIC avec une version condensée qui te permet d'évaluer tes compétences dans toutes les sections." : "Passes le TOEIC dans des conditions réelles."}</div>
             {workspace?.id && <div onClick={() => {startHandler(props.test.id, true);  setSelectedSection(0);}} className="px-4 py-2 text-xl text-white bg-slate-900 rounded-xl mt-8 mb-6 mx-auto border-2 border-b-4 border-slate-700 cursor-pointer">{userTest ? 'Continuer' : 'Commencer'}</div>}
            </div> 
          }

          {selectedSection != null  && <div className="fixed bg-white shadow text-slate-500 top-0 z-[20] h-[76px] text-center left-0 right-0 bg-amber-50 p-6 border ">
          {/* { selectedSection != 0 ? <div onClick={() => setSelectedSection(prev => prev - 1)} className=""><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            </div> } */}
           <div className="flex justify-between gap-2 -mt-2">  
           <div>{showRecap ? "Résulats" : 
            <div className="flex gap-2 justify-center items-center">
                {fullVersion && userTest?.endDate && <div onClick={() => {selectedSection != 0 && setSelectedSection(prev => prev - 1); setGroupIndex(0)}} className=""><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 ${selectedSection != 0 ? "" : "opacity-50"}  rounded-full p-[1px] h-5`}> <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg></div>}
              {`Partie ${selectedSection + 1}`}
              {fullVersion && userTest?.endDate && <div onClick={() => {selectedSection < 6 && setSelectedSection(prev => prev + 1); setGroupIndex(0)}} className=""><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 ${selectedSection < 6 != 0 ? "" : "opacity-50"}  rounded-full p-[1px] h-5`}><path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg></div>}
              </div>
            }</div>

            <div className="flex gap-2 items-center">

{mode == "white" && !showRecap ? <div className="flex items-center gap-2 text-sm text-slate-500 "><TimeCounter setShowRecap={setShowRecap} totalTime={60*120} timePassed={differenceInSeconds(new Date(), userTest?.startDate || new Date())}/> 
              {!userTest?.endDate && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 text-slate-400 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>}
</div> : <div></div>}
            <div onClick={() => setSelectedQuiz()} ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 bg-gray-200/70 p-1 rounded-full">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
              </div>
             

            </div>

          
          
             
            {(mode == "white" || mode == "all") && <div className="h-[5px] -mb-[6px]  w-[150px] flex gap-[3px] rounded-xl overflow-hidden pr-[44px] -mb-2 ">
                {sectionInfos.map((e,i) => <div className={`${i == selectedSection ? "bg-purple-500 grow" : i < selectedSection ? "bg-[#7976f5]" : "bg-slate-200"}  w-full h-full rounded-full`}></div>)}
              </div>}
            {(selectedSection != null && progressPart) ? <div className="mt-3 ">
             
              <div className=" flex  w-full  z-10 left-0 right-0 text-center ">
             
              <div className="bg-slate-100 h-[10px] w-full rounded-xl overflow-hidden mr-[4px]">
              <div className={` ${progressPart == 100 ? "bg-cyan-500" : "bg-purple-500"} h-[10px] transition-all  rounded-r-full`} style={{width: progressPart + "%"}}></div>
              </div>
              {!showRecap && <div className="-mt-[3px] w-[40px] text-right text-xs">{nbCurrentQuestions}/{nbQuestionCurrentPart}</div>}
              </div> </div> : <div className="bg-slate-100 h-[10px] w-full rounded-xl mt-2"></div>}
  
            </div> }



            {separateRichText && !showRecap ? <div className={`flex fixed z-10 w-full ${showCorrection ? "top-[106px]" : "top-[76px]" }  bg-red-100 `}>
              <div onClick={() => setShowText(false)} className={`${!showText ? "border-purple-500 text-purple-500" : ""}  text-center grow py-2 px-4 bg-white border-b-4`}>Questions</div>
              <div onClick={() => setShowText(true)} className={`${showText ? "border-purple-500 text-purple-500" : ""}  text-center grow py-2 px-4 bg-white border-b-4`}>Texte</div>
            </div> : "" }

            {showCorrection && <div className=" fixed w-full text-white z-20 top-[0px] left-0 right-0 p-4 bg-purple-500">
              <div onClick={() => {setShowCorrection(false); setSelectedQuestion(null); setShowRecap(true)}} className="absolute text-white top-3 right-3 "><div className="p-1 rounded-full bg-white/20 flex gap-2"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
</div></div>
              <div className="flex gap-2 justify-center items-center border-b border-white/20 mb-2 pb-2">
                <div className={`${selectedSection > 0 ? "" : "opacity-50"}`} onClick={() => {selectedSection > 0 && setSelectedSection(prev => prev - 1);setGroupIndex(0);setCorrectionIndex(sectionInfos[selectedSection-1].nb_before); setSelectedQuestion(sectionInfos[selectedSection-1].nb_before+1)}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg></div>
                <div>{`${selectedSection + 1}`}. {sectionInfo?.title}</div>
                <div className={`${selectedSection < 6 ? "" : "opacity-50"}`} onClick={() => {selectedSection < 6 && setSelectedSection(prev => prev + 1);setGroupIndex(0);setCorrectionIndex(sectionInfos[selectedSection+1].nb_before); setSelectedQuestion(sectionInfos[selectedSection+1].nb_before+1)}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5"><path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg></div>
              </div>
              <div className="flex gap-2 justify-center items-center">
                <div className={`${correctionIndex > 1 ? "" : "opacity-50"}`} onClick={() => {correctionIndex >1 && setCorrectionIndex(n => n-7)}} ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg></div>
                <div className="flex gap-2 ">{[1,2,3,4,5,6,7].map(e => (e + correctionIndex < pages.length+1) && (e + correctionIndex > 0) && <div onClick={() => {setSelectedQuestion(e + correctionIndex); console.log('user test 🔑🔑🔑', userTest?.questions?.find(q => q.page_id == allPages[e + correctionIndex -1]?.id))}} className={`${userTest?.questions?.find(q => q.page_id == allPages[e + correctionIndex -1]?.id) ? (userTest?.questions?.find(q => q.page_id == allPages[e + correctionIndex -1]?.id)?.valid ? "border !bg-green-300/60 border-green-500" : "border !bg-red-300/60 border-red-500") :""} h-8 w-8 text-white rounded-full flex items-center justify-center transition-all ${(e + correctionIndex) == selectedQuestion ? "scale-[1.2] bg-white/40" : "bg-white/20"}`}>{e + correctionIndex}</div>)}</div>
                <div className={`${correctionIndex < (pages.length - 7) ? "" : "opacity-50"}`} onClick={() => {correctionIndex < (pages.length - 7) && setCorrectionIndex(n => n+7)}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg></div>
              </div>
              </div>}
           

          {/* page tuto */}
          {sectionInfo && !readedSection.includes(selectedSection)  && <div className="justify-center text-slate-500 text-center flex flex-col p-8 pt-12 h-screen">
             <div className="text-2xl text-blue-500">{sectionInfo.title}</div>
             <div>{nbQuestionCurrentPart} questions</div>
             <div className="mt-12">{sectionInfo.consign}</div>
             <div onClick={() => {setReadedSection(prev => [...prev, selectedSection]); props.test.id_train && startHandler(props.test.id_train, true); setShowText(false); setGroupIndex(0) }} className="px-4 py-2 text-xl text-white bg-blue-500 border-2 border-b-4 border-blue-700 rounded-xl mt-8 mb-6 mx-auto cursor-pointer">Commencer la partie {selectedSection + 1}</div>
            </div>}

            

            {sectionInfo && (readedSection.includes(selectedSection) || userTest?.endDate)  && <div className="pt-12 p-4">
           
              {!showText && currentPageArray.map((pageArr, indexArr) => <div className="border-t-2 pt-24 mb-12 border-dashed ">
                <div className=""> 
                    {!separateRichText && <img src={pageArr[0]?.img} />}
                    {pageArr[0]?.audio && <AudioReader id={indexArr} src={pageArr[0]?.audio} /> }
                    {!separateRichText && pageArr[0]?.richtText && <SuperEditor className="bg-yellow-50 rounded-xl my-2" content={ replaceWithNb(pageArr[0].richtText, pageArr[0]?.pageNumber +  (mode == "all" ? sectionInfo.nb_before_mini : sectionInfo.nb_before))}  />   }
                  </div>
                  <div>{ !selectedQuestion ? pageArr?.map((page, index) => <RenderPage ct={ct} userTest={userTest} setPages={setPages} page={page} mode={props.test?.mode} index={page.pageNumber-1} />) : ""}</div>
                 
                </div>
              )}



              {showText && currentPageArray.map((pageArr ) => <div className="border-t-2 pt-24 mb-12 border-dashed ">
                    <img src={pageArr[0]?.img} />
                    {pageArr[0]?.richtText && <SuperEditor className="bg-yellow-50 rounded-xl my-2" content={ replaceWithNb(pageArr[0].richtText, pageArr[0]?.pageNumber +  (mode == "all" ? sectionInfo.nb_before_mini : sectionInfo.nb_before))}  />   }
                </div>
             )}

              {selectedQuestion && (!showText) ? <RenderPage ct={ct} userTest={userTest} setPages={setPages} page={allPages[selectedQuestion-1]} mode={props.test?.mode} editable={false} selectedQuestion={selectedQuestion} index={0} /> 
              : pagesOfSection.slice(groupIndex*6,(groupIndex+1)*6).map((page, index) => <RenderPage ct={ct} userTest={userTest} setPages={setPages} page={page} mode={props.test?.mode} index={index+groupIndex*6} />)
              }
            
            


              <div className="flex justify-center">
               
                {!questPerPartFinished && !showCorrection && <div className="px-4 py-2 text-xl rounded-xl mt-8 mb-6 mx-auto bg-blue-500 bg-gradient-to-r from-blue-600 to-blue-500 cursor-pointer border-blue-600 border-b-4 text-white " onClick={() => setGroupIndex(prev => prev + 1)}>Suivant</div>}
                {fullVersion && questPerPartFinished && !showCorrection && <div onClick={() => {selectedSection != 6 ? setSelectedSection(prev => prev + 1) : handleEnd(true) }} className="px-4 py-2 text-xl rounded-xl mt-8 mb-6 mx-auto bg-blue-500 bg-gradient-to-r from-blue-600 to-blue-500 cursor-pointer border-blue-600 border-b-4 text-white "> {selectedSection != 6 ? "Partie suivante →" : "Terminer"}</div>}
                {!fullVersion && questPerPartFinished && !showCorrection && <div onClick={() => {handleEnd(true);  setShowRecap(true)}} className="px-4 py-2 text-xl rounded-xl mt-8 mb-6 mx-auto bg-blue-500 bg-gradient-to-r from-blue-600 to-blue-500 cursor-pointer border-blue-600 border-b-4 text-white ">Voir mes résulats</div>}

              </div>

              
            </div>}

            {selectedSection == 9 && <div className="mt-[200px]">Terminé</div>}


            {showRecap  && <div className="bg-amber-50 pt-12  max-h-screen pb-36 overflow-scroll justify-center fixed top-12 bottom-0 right-0 left-0  p-6  space-y-4">
            
            {/* <div className="relative h-[240px] mx-auto inline-block  flex items-center justify-center w-[240px]">
              <CircularProgressbar
              value={percentValid || 0}
              className={` circularProgress absolute top-0 !h-[240px] transition`}
              styles={buildStyles({
              strokeLinecap: 'butt',
              strokeWidth: 4,
              textSize: '0px',
              pathColor: "#3b82f6" ,
              trailColor: '#D9D9D9',
              textColor: '#f88',
              pathTransitionDuration: 0.5,
              strokeLinecap: 'round',
              transform: 'rotateX(30deg)',
              })}
              strokeWidth={10} 
              />
               
               

              </div> */}

              <div className="flex flex-col items-center justify-center  w-full rounded-xl border shadow bg-white p-6">
                <div onClick={() => console.log('userTest.toeic_score', userTest) } className=" text-sm rounded-xl text-center">
                  Votre score 
                  
                  {mode != "white" && mode != "all" && <div className="text-2xl mb-4 text-slate-600"> <span className="text-purple-500 text-center text-4xl font-semibold">{validQuestions}</span>/{indexSlice}</div>}
                  {mode == "white" && <div className="text-2xl mb-4 text-slate-600"> <span className="text-purple-500 text-center text-4xl font-semibold">{userTest.toeic_score}</span>/995</div>}
                  {mode == "all" && <div className="text-blue-500 text-center font-semibold text-4xl ">{validQuestions}<span className="text-slate-400 ml-1 font-base text-lg">/{totalQuestions}</span></div>}
                  </div>
                  {mode == "white" && <div>
                    Durée : <span className="font-semibold ">{displayDuration(userTest.startDate, userTest?.endDate)}</span>
                    </div>}
              </div> 
              
               

        

              {/* {mode == "white" && <p className="text-lg text-center text-slate-500">Temps passé : {displayDuration(userTest.startDate, userTest?.endDate)}</p>} */}
              
              {/* <p className="text-lg font-semibold">Score : 895</p> */}
              {/* <div className="flex justify-center mb-4">
                <div className="py-2 px-6 border border-slate-400 text-slate-700 border-b-2 inline-block mx-auto rounded-xl mt-12">Voir mes erreurs</div>
              </div> */}
    
               {(mode == "white" || mode == "all") && ['Listening', 'Reading'].map((a) => <div className="rounded-xl overflow-hidden bg-white shadow border">
                <div onClick={() => console.log('usertest', userTest)} className="text-xl flex bg-purple-500 text-white py-2 px-4 justify-between">{a}
                {mode == "white" &&<div className="">
                  {a == "Listening" ? userTest.listenScore : userTest.readScore}/495
                </div>}
                  </div>
                {sectionInfos.slice(a == "Listening" ? 0 : 4, a == "Listening" ? 4 : 7).map((e, i) => <div onClick={() => {setSelectedSection(i + (a == "Listening" ? 0 : 4)); setShowRecap(false); setShowCorrection(true); chooseQuestionFromSection(i + (a == "Listening" ? 0 : 4))}} className=" mt-1 border-b-2 px-4 pl-2 -mb-1 pb-2 flex justify-between py-1 max-w-[300px] mx-auto bg-white/20">
                <div className="text-sm" >{i + 1 + (a == "Listening" ? 0 : 4)} - {e?.title}
                {/* ({userTest.questions.filter(q => q.part == i+1 && q.valid).length}/{userTest.questions.filter(q => q.part == i+1).length}) */}
                </div>
                <div className="flex gap-2 text-slate-600">
                {userTest?.data?.['part_'+(i+1 + (a == "Listening" ? 0 : 4))]}/{mode == "white" ? e.nb : pages?.filter(page => page?.index_section == i + (a == "Listening" ? 0 : 4))?.length} 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 opacity-50">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                  </div>

                </div>)}
                </div>)}
              {/* <div onClick={() => console.log('usertest', userTest)}>Partie 1 : {userTest.part_1}/6 ({userTest.questions.filter(q => q.part == 1 && q.valid).length}/{userTest.questions.filter(q => q.part == 1).length})</div> */}
              <div className="  space-x-4 fixed bottom-4 left-0 right-0">
                
                {/* <div className="flex justify-center mb-4">
                  <div className="bg-[#0AAC6C] border border-b-4 border-[#048753] mx-auto text-white py-2 inline-block px-6 rounded-xl hover:bg-green-600 transition duration-300">
                  Fermer
                  </div>
                </div> */}
                {/* <div className="text-slate-400 text-center">
                  Réessayer dans 2 mins
                </div> */}
               
              </div>


           
            
              </div>
            }
       
    </div>
  );
};

export {QuizzReader}