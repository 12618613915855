import { createContext, useContext, useState } from "react";

const StateContext = createContext();

export function StateProvider({ children }) {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null)
  const [activeScreen, setActiveScreen] = useState(null)
  const [workspaces, setWorkspaces] = useState([])
  const [workspace, setWorkspace] = useState(null)
  const [mandatoryScreen, setMandatoryScreen] = useState(null)
  const [nextCards, setNextCards] = useState(null)
  const [learnedCards, setLearnedCards] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showBottomBar, setShowBottomBar] = useState(true)
  const [cardsToRefresh, setCardsToRefresh] = useState(null)
  const [cardsToDiscover, setCardsToDiscover] = useState(null)
  const [showTopBar, setShowTopBar] = useState(true)
  const [bubulle, setBubble] = useState()
  const [decks, setDecks] = useState([])
  const [daily, setDaily] = useState(null)
  const [profileDecoration, setProfileDecoration] = useState([])
  const [profileWorkspace, setProfileWorkspace] = useState()
  const [profileWorkspaces, setProfileWorkspaces] = useState([])
  const [showCategorie, setShowCategorie] = useState(false)
  const [alert, setAlert] = useState()
  const [tutoTriggers, setTutoTriggers] = useState([])
  const [showInfo, setShowInfo] = useState(false)
  const [scenarios, setScenarios] = useState([])
  const [groups, setGroups] = useState()
  const [magnifier, setMagnifier] = useState()
  const [showCard, setShowCard] = useState()
  const [text_50k, setText_50k] = useState([])
  const [int_50k, setInt_50k] = useState([])
  const [view, setView] = useState()
  const [events, setEvents] = useState([])
  const [visitingPW, setVisitingPW] = useState()
  const [blockAudio, setBlockAudio] = useState()
  const [selectedQuiz, setSelectedQuiz] = useState()
  const [profilesTests, setProfilesTests] = useState()


  const value = {
    user,setUser,
    profilesTests, setProfilesTests,
    visitingPW, setVisitingPW,
    view, setView,
    text_50k, setText_50k,
    int_50k, setInt_50k,
    magnifier, setMagnifier,
    setDaily, daily,
    scenarios, setScenarios,
    showCategorie, setShowCategorie,
    profile,setProfile,
    activeScreen, setActiveScreen,
    workspaces, setWorkspaces,
    workspace, setWorkspace,
    mandatoryScreen, setMandatoryScreen,
    nextCards, setNextCards,
    profileDecoration, setProfileDecoration,
    learnedCards, setLearnedCards,
    showModal, setShowModal,
    showBottomBar, setShowBottomBar,
    cardsToRefresh, setCardsToRefresh,
    cardsToDiscover, setCardsToDiscover,
    showTopBar, setShowTopBar,
    groups, setGroups, 
    selectedQuiz, setSelectedQuiz,
    showCard, setShowCard,
    bubulle, setBubble,
    tutoTriggers, setTutoTriggers,
    profileWorkspace, setProfileWorkspace,
    profileWorkspaces, setProfileWorkspaces,
    decks, setDecks,
    alert, setAlert,
    showInfo, setShowInfo,
    events, setEvents,
    blockAudio, setBlockAudio

    
  };

  return (
    <StateContext.Provider value={value}>
      {children}
    </StateContext.Provider>
  );
}

export function useStateContext() {
    return useContext(StateContext);
  }