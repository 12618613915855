import { useEffect, useMemo, useState } from "react"
import { supabase } from "../../V2/screens/testScreen"
import { useStateContext } from "../StateProvider"
import { SuperEditor } from "../../components/editor"
import { TipTapEditor } from "../TipTapEditor"
import { getStorage } from "firebase/storage"
import { app } from "../../config"
import { SuperText } from "../components/SuperText"


const darkMode = false

const ImageSlider = ({ src, cent, className, active }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const images = useMemo(() => src, [src]);
  
    useEffect(() => {
      if (active) {
        const newIndex = Math.floor((cent / 100) * images.length);
        setCurrentIndex(newIndex % images.length);
      }
    }, [cent, images.length, active]);
  
    return (
      <div className={`${className} image-slider`}>
        {/* <div>currentIndex: {currentIndex}</div> */}
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className={`image ${index === currentIndex ? 'active' : 'inactive'}`}
          />
        ))}
      </div>
    );
  };

const pauseAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
    }
  };

function findGroupByWordIndex(groups, wordIndex) {
    let currentIndex = 0;
  
    for (let i = 0; i < groups.length; i++) {
        let group = groups[i];
        
        if (wordIndex < currentIndex + group.length) {
            return group.map((word, idx) => ({
                word: word,
                // current: (currentIndex + idx == wordIndex)
            }));
        }
  
       
        
        currentIndex += group.length;
    }
    
    return null; // Si l'index est en dehors des limites
  }
function groupWordsByTime(words) {
    let groupedWords = [];
    let currentGroup = [];
    let currentTime = 0;
    let coloredTime = 0;
    let sep = 850
    let coloredSep = 1000
  
    words?.forEach(wordObj => {
        if (wordObj.offset / sep >= currentTime + 1) {
            if (currentGroup.length > 0) {
                groupedWords.push(currentGroup);
                currentGroup = [];
            }
            currentTime = Math.floor(wordObj.offset / sep);
        }
        if (wordObj.offset / coloredSep >= coloredTime + 1) {
          coloredTime = Math.floor(wordObj.offset / coloredSep);
          wordObj.current = true;
      }
        currentGroup.push(wordObj.word);
    });
  
    if (currentGroup.length > 0) {
        groupedWords.push(currentGroup);
    }
  
    return groupedWords;
  }

const voices = [
    "de-DE-FlorianMultilingualNeural",
    "de-DE-SeraphinaMultilingualNeural",
    "en-US-BrianMultilingualNeural",
    "fr-FR-VivienneMultilingualNeural"
  ] 

function getVoiceByIndex(voices, index) {
    return voices[index % voices.length];
  }

let currentAudio = null;
const synthesizeSpeech = async ({ text, voice = "fr-FR-VivienneMultilingualNeural", setWordIndex, setTsw, setPlaying, fact, lang,setProgressAudio, storage}) => {
 
  
    if (fact.data?.[`wt_${lang}`] && fact.data?.[`audio_${lang}`]) {
      const audioUrl = fact.data?.[`audio_${lang}`];
      const wordList = fact.data?.[`wt_${lang}`];
  
      // Play the audio and start word index updates
      setPlaying(true);
      setTsw(wordList);
  
      const audio = new Audio(audioUrl);
      currentAudio = audio; // Store the current audio object
  
      audio.addEventListener('play', () => {
        let startTime = Date.now();
      });
  
      audio.addEventListener('timeupdate', () => {
        let currentTime = audio.currentTime * 1000; // currentTime is in seconds, convert to milliseconds
        let duration = audio.duration * 1000; // duration is in seconds, convert to milliseconds
  
        // Calculate and set the progress percentage
        let progress = (currentTime / duration) * 100;
        setProgressAudio(progress);
    
  
        for (let i = 0; i < wordList.length; i++) {
          if (currentTime >= wordList[i].offset && currentTime < (wordList[i].offset + wordList[i].duration)) {
            setWordIndex(i);
            break;
          }
        }
  
        if (currentTime >= wordList[wordList.length - 1].offset + wordList[wordList.length - 1].duration) {
          setPlaying(false); // Set playing to false when the audio finishes
        }
      });
  
      audio.addEventListener('ended', () => {
        setPlaying(false); // Set playing to false when the audio finishes
      });
  
      audio.play();
      return;
  
  
    } 
    // else {
    // console.log('synthesizeSpeech', text)
    // const speechConfig = sdk.SpeechConfig.fromSubscription(key_1, "francecentral");
    // const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();
  
    // speechConfig.speechSynthesisOutputFormat = sdk.SpeechSynthesisOutputFormat.Audio48Khz192KBitRateMonoMp3;
    // speechConfig.requestWordLevelTimestamps();
  
    
  
    // let wordList = [];
    // let startTime = null;
    // const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
  
    // synthesizer.wordBoundary = (s, e) => {
    //     wordList.push({
    //         word: e.text,
    //         offset: e.audioOffset / 10000, // Convert to milliseconds
    //         duration: e.duration / 10000 // Convert to milliseconds
    //     });
    // };
  
    // setWordIndex(0);
    // const ssml = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xml:lang="fr-FR">
    //     <voice name="${voice}">
    //         <prosody rate="0%" pitch="0%">
    //             <mstts:express-as style="cheerful">
    //                 ${text}
    //             </mstts:express-as>
    //         </prosody>
    //     </voice>
    // </speak>`;
  
    // console.log('synthesizer', synthesizer)
  
    // synthesizer.speakSsmlAsync(
    //     ssml,
    //    async (result) => {
    //         if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
  
    //             console.log('Synthesis finished.', result);
              
    //             setProgressAudio(0);
    //             const audioBlob = new Blob([result.audioData], { type: 'audio/mp3' });
    //              //const audioUrl = URL.createObjectURL(audioBlob);
    //             const storageRef = ref(storage, `audio_facts/${lang}/${fact.id}.mp3`);
    //             await uploadBytes(storageRef, audioBlob);
    //             const audioUrl = await getDownloadURL(storageRef);
                
                
                
    //             if (wordList.length > 0) {
                  
    //                 console.log("wordList", wordList);
    //                 console.log('fact UPDATED ! 🟢🟢🟢🟢', fact)
    //                 updateDoc(doc(db, "facts", fact.id), {
    //                   [`wt_${lang}`]: wordList ,
    //                   [`audio_${lang}`]: audioUrl
    //                 })
    //                 setPlaying(true);
    //                 setTsw(wordList);
                    
    //                 startTime = Date.now(); // Initialize start time when synthesis is completed
  
    //                 const intervalId = setInterval(() => {
    //                     let elapsedTime = Date.now() - startTime;
    //                     let currentTime = elapsedTime;
  
    //                     for (let i = 0; i < wordList.length; i++) {
    //                         if (currentTime >= wordList[i].offset && currentTime < (wordList[i].offset + wordList[i].duration)) {
    //                             setWordIndex(i);
    //                             break;
    //                         }
    //                     }
  
    //                     if (currentTime >= wordList[wordList.length - 1].offset + wordList[wordList.length - 1].duration) {
    //                         clearInterval(intervalId);
    //                         setPlaying(false); // Set playing to false when the audio finishes
    //                     }
    //                 }, 10); // Check every 10 milliseconds
  
    //             } else {
    //                 console.warn('Word boundaries are not available.');
    //                 setPlaying(false); // Set playing to false if no word boundaries are available
    //             }
    //         } else {
    //             console.error('Speech synthesis canceled, ' + result.errorDetails);
    //             setPlaying(false); // Set playing to false in case of error
    //         }
            
    //     },
    //     (err) => {
    //         console.trace('err - ' + err);
    //         setPlaying(false); // Set playing to false in case of error
            
    //     }
    // );
    // }
  };

const splitWordsAndPunctuation = (sentence) => {
    return sentence.match(/[\wÀ-ÿ]+|[.,!?;:()]/g);
  };
const ElFacto = ({fact, lang, facts, setFact, indexFact, ct, reelMode, index}) => {

    const storage = getStorage(app);
  
    const [tradMode, setTradMode] = useState(false)
    const [colorText, setColorText] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [darkMode, setDarkMode] = useState(false)
    const [wordIndex, setWordIndex] = useState(false)
    const [expended, setExpended] = useState(true)
    const [tsw, setTsw] = useState()
    const sentence = fact.data?.[`text_${lang}`]?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '')
    const more = fact.data?.[`more_${lang}`]?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '')
    const splitSentence = splitWordsAndPunctuation( sentence + " " + more)
    const question = fact.data?.[`question_${lang}`] 
    const [progressAudio, setProgressAudio] = useState()
  
  
  
    const text = '<break time="1s"/>' + question + '<break time="0.7s"/>' + sentence + '<break time="0.7s"/>' + more 
    // const text = question
  
    const read = () => {
        console.log('fact', fact)
        synthesizeSpeech({voice: getVoiceByIndex(voices, index), text  , setWordIndex, setTsw, setPlaying, storage, fact, lang, setProgressAudio})
    }
    const groupWords = tsw && groupWordsByTime(tsw)
  
    const currentGroup = groupWords && findGroupByWordIndex(groupWords, wordIndex)
    
    let img = fact.img
  
    if (progressAudio > 20 && fact.img_1) {img = fact.img_1}
    if (progressAudio > 40 && fact.img_2) {img = fact.img_2}
    if (progressAudio > 60 && fact.img_3) {img = fact.img_3}
  
  
    // const currentGroup=  [{word: "Las", current: false}, {word: "abejas", current: false}, {word: "se", current: true}, {word: "comunican", current: false}]
  
    // split 1s 
   
  
    // const content = fact.data?.[`text_${lang}`]?.replace(/\[\d+\]/g, '').replace(/\|\|/g, "")
  
    return <div onClick={(e) => e.stopPropagation()} className='relative snap-center mb-8 text-slate-500 bg-white overflow-hidden rounded-xl   '>
      <div className={` ${expended ? 'overflow-hidden ': "overflow-auto pb-[30px]"}   h-[500px] `}>
      {fact.images?.[0] ? <div onClick={() => {
        if(!playing){pauseAudio(); read()}
        if(playing) {pauseAudio(); setPlaying(false)}
      }} className='relative'>
        <ImageSlider src={fact.images} cent={progressAudio} active={playing} className={`object-cover rounded-xl  w-full ${expended ? 'h-[500px]': "h-[200px]"}`} />
        {/* <img className={`object-cover rounded-xl  w-full ${expended ? 'h-[500px]': "h-[200px]"}  `} src={img} /> */}
        <div className={`absolute left-0 right-0 flex items-center justify-center bottom-2 top-0 ${(!playing && expended) ? "bg-gradient-to-b from-black/10 to-black/50" : "" } `}>
             {(!playing && expended) ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-16 w-16 text-white bg-white/20 p-2 rounded-full">
          <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
        </svg> : "" }
  
        </div>
        { playing && <div className='absolute bottom-[68px] left-0 right-0 h-[4px] bg-black/20'><div className='bg-indigo-500 h-[4px] rounded-xl transition-all' style={{width: (progressAudio)+"%"}}></div></div>}
        {!playing && expended&&  <div className='absolute p-4 bottom-[80px]'>
          <div className='bg-black/20 text-2xl font-semibold text-center backdrop-blur text-shadow text-white rounded-md p-1 px-3'>{fact.data?.[`question_${lang}`]}</div>
        </div>}
        <div onClick={(e) => {e.stopPropagation(); setExpended(!expended)}} className={`absolute z-[20] bottom-0 left-0 right-0 ${!expended ? "bg-white rounded-xl" : "bg-black/30 rounded-xl overflow-hidden backdrop-blur-xl text-white rounded-t-none" } pt-2 py-1  `}>
        <div className='flex justify-center'>
          {expended ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 scale-x-[1.6]">
    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
  </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6  scale-x-[1.6]">
    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
  </svg>
  }
  </div>
  {expended && <div className='text-center pb-2'>Lire la carte </div>}
        </div>
  
      <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
      <div className={`relative mt-4 px-4 ${!expended ? "" : "pt-[200px]"}  flex justify-center`}>
      <div className='text-3xl pop flex justify-center transition-all text-center flex-wrap text-white font-semibold text-shadow px-1 rounded '>
      {playing && currentGroup?.map((e,index) => <div key={"w-"+index} className={`${e.current ? "bg-[#875CFF] rounded-md text-yellow-100" : ""} transition-all backdrop-blur bg-[#6360ff] rounded-md -ml-2 px-2 `}>{e.word}</div>)}
  
      </div>
    </div>
        {/* <div className='text-xl text-white font-semibold text-shadow px-1 rounded bg-amber-500'>{splitSentence[wordIndex]?.replace('.', "")}</div> */}
        </div>
  </div> : <div className='h-[80px]'></div>}
  
  
  
      {/* {!expended && <div className={`bg-blue-500 flex gap-2 items-center text-white pt-5 px-2 py-1 rounded-xl`} onClick={() => {
        // const sentences = supprimeNumerotationEtDivise(fact.data?.[`more_${lang}`].replace(/\|/g, ''))
        // const sentencesFr = supprimeNumerotationEtDivise(fact.data?.[`more_fr`].replace(/\|/g, ''))
       
        // readSentences(sentences, ct.workspace.lang)
        // speak({ text: sentence, lang })
        read()
        
        console.log(sentence)
        // console.log(sentencesFr)
      }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
      <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
    </svg>
    <div className='rounded-xl bg-white/20 h-[10px] w-full'></div>
    </div>} */}
  
    {!expended ? <div className={`${!expended ? "bg-white" : "bg-black/20 backdrop-blur-xl" }   transition-all z-10 -mt-4 rounded-xl p-4 `}>
  
     {/* <div className='text-slate-500 mt-2 text-md'>{fact.data?.[`title_${lang}`]?.replace(/"/g, '')}</div> */}
     
      <div className=' text-purple-500 text-xl mb-4'>{fact.data?.[`question_${lang}`]?.replace(/"/g, '')}</div>
   
      
      <SuperText 
                          tradMode={tradMode} 
                          colorText={colorText}
                          text={fact.data?.[`text_${lang}`]} 
                          darkMode={darkMode}
                          imgContext={fact.img || fact.img_1}
                          trad={fact.data?.[`text_fr`]} 
                        //   decks={ct?.decks || []} 
                        //   cards={ct.cards}  
                        //   ct={ct}
                          />
      
      {fact.data?.[`more_${lang}`]?.length > 12 && <SuperText 
                          tradMode={tradMode} 
                          className={"text-sm text-slate-400 mt-4"}
                          colorText={colorText}
                          text={fact.data?.[`more_${lang}`]} 
                          darkMode={darkMode}
                          imgContext={fact.img || fact.img_1}
                          trad={fact.data?.[`more_fr`]} 
                        //   decks={ct?.decks || []} 
                        //   cards={ct.cards}  
                        //   ct={ct}
                          />}
  
  
      </div> : <div></div>}
      </div>
      
    
      {/* <div className='absolute bottom-0 right-0 left-0 flex justify-end p-2'>
        <div onClick={(e) => {
          e.stopPropagation()
          const indexFact = facts.indexOf(fact)
          setFacts(facts[indexFact + 1])
          console.log('indexFact', indexFact)
          console.log('facts[indexFact + 1]', facts[indexFact + 1])
        }} className='bg-purple-500 text-white border-2 border-b-4 rounded-xl px-3 py-1 border-purple-600'>Suivant</div>
      </div>
      <div onClick={() => {setColorText(!colorText)}} className={`absolute bottom-4 left-4 ${colorText ? "text-emerald-600" : "text-slate-400 "} `}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
      </svg>
      </div> */}
    </div>
  }

const CultureG = () => {




    const {workspace, decks, setDecks, learnedCards, setShowModal, setCardsToRefresh} = useStateContext()

 
    const [selectedFact, setSelectedFact] = useState()
    const [selectedDeck, setSelectedDeck] = useState()
    const [facts, setFacts] = useState()
    const [listFacts, setListFacts] = useState()
    const [reelMode, setReelMode] = useState(false)
    const [showComingSoon, setShowComingSoon] = useState()


    const lang = workspace?.lang?.split('-')?.[0]


    console.log('learnedCards', learnedCards)
    useEffect(() =>{
        workspace?.lang && getFacks("it", setFacts)
    }, [workspace?.lang])


    const getFacks = async(lang, set) => {
        const { data, error } = await supabase
            .from('facts')
            .select(`*`)
    
        if (error) {
            console.error('Error fetching grammar decks:', error)
            return { data: null, error }
        }
    
        // Transformation des données pour n'avoir qu'un seul profile_card

        console.log('data', data)
      
        set(data)
        return { data, error }
        
    }







    return <div className='fredoka'>

        {/* <div onClick={() => {
            getFacks("it", setFacts)
        }}>Charger</div> */}

        <div className="p-4 mt-2 mb-8 bg-indigo-500/30 border-2 flex gap-2 items-center justify-between border-b-4 cursor-pointer border-black/50 rounded-xl" onClick={() => setListFacts(facts)}>Voir les shorts</div>
       


        <div>
            {selectedFact && <div className="fixed bg-yellow-50 p-6 pt-[100px] top-0 left-0 right-0 z-[79] pb-[100px] max-h-screen overflow-scroll bottom-0">
                <div className='fixed z-[80] top-2 right-2 text-blue-900' onClick={() => setSelectedFact()}>
                    <div class="absolute top-4 right-4 text-white z-40   bg-red-500 p-1 rounded-xl border border-b-4 border-red-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></div>
                </div>

            </div>}

            {listFacts?.length > 0 && <> <div className='text-white  backdrop-blur-xl  z-[200] fixed left-0 top-8 right-0' onClick={() => setFacts()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                  </div><div onClick={() => setSelectedFact()} className={`absolute ${darkMode ? "bg-purple-700" : "backdrop-blur-[24px]"}  bg-black/50 p-2  top-0 left-0 right-0 z-[999] max-h-screen overflow-scroll bottom-0 `}>
                 
                  {/* <div className='text-white fixed top-8 left-8' onClick={(e) => {e.stopPropagation();setReelMode(!reelMode)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>

                  </div> */}
                  <div className='p-4 flex justify-between items-center'>
                   

                    <svg onClick={() => setListFacts()}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    <div>{selectedDeck?.name}</div>
                    <div className='w-6'></div>

                  </div>
                  <div className=' snap-y snap-mandatory overflow-y-scroll h-[88vh] rounded-xl flex-grow z-0 '>
                  {listFacts.filter(e => e.data?.[`title_`+lang] && e.images && e.data?.[`wt_`+lang])?.map((fact,i) => <ElFacto index={i} reelMode={reelMode} fact={fact} lang={workspace?.lang?.split('-')?.[0]} setFacts={setFacts} facts={facts} />)}

                  </div>
               
                </div></>}

            {/* {!selectedFact && facts?.map(fact => <div onClick={() => setSelectedFact(fact)} className="p-4 mt-2 border-2 flex gap-2 items-center justify-between border-b-4 cursor-pointer border-black/50 rounded-xl" key={fact.id}>
                 {fact?.data?.title_fr}
            </div>)} */}
        </div>

    </div>

}


export {CultureG}