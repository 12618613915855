import { useEffect, useState } from "react"
import { brackSentence, displayDuration, evolveProfileCard, getAverageLvlTrigger, getNextTrigger, getTraduction, hasWordInBrackets, speak, transformDates, transformDatesToJS } from "../../helperFunctions"
import { QCM } from "../triggers/QCM"
import { DiscoverCard } from "./discoverCard"
import { FillSentence } from "../triggers/FillSentence"
import { boosters, mp3_bad, mp3_valid } from "../../helperData"
import { HighlightText } from "../triggers/HighlightText"
import { AudioBtn } from "../triggers/AudioBtn"
import { WordDefinitions, getPromptImage, getTask, sendTaskMidJourney, splitImage, translateContext, updateAllCards, varyContext } from "../../pages/AdminCards"
import { sbCreate, sbUpdate } from "../../DbFunction"
import { useStateContext } from "../../StateProvider"
import { Hint } from "../../../V2/components/pop/Hint"
import { gpt } from "../../../utilities/helper/text"
import { SVG_IA } from "../../svgs"

import { quantum, waveform } from 'ldrs'
import { getStorage } from "firebase/storage"
import { app } from "../../../config"
quantum.register()



const loader = <l-quantum
size="18"
speed="3.75" 
color="#fff" 
></l-quantum>



// const WordDefinition = ({definitions}) => {

//     const [state, setState] = useState(0)

    
//     return <div className="text-left text-sm">{JSON.parse(definitions)?.definitions?.map(e => <div>
//         <div className="mt-4 mb-2">{e.trad_fr} - <span className="italic text-xs italic opacity-[0.8]">{e.categorie}</span></div>
//         {state == 1 && <div className="mb-2 text-indigo-700">{e.def}</div>}
//         {state == 1 && e.ex.map(ex => <div className="pl-4">
//         <div className="text-purple-500">{ex.text}</div>
//         <div className="text-xs italic opacity-[0.8]">{ex.trad_fr}</div>
//         </div>)}
//         {state == 0 && <div className="pl-4">
//         <div className="text-indigo-500">{e.ex[0]?.text}</div>
//         <div className="text-xs italic opacity-[0.8]">{e.ex[0]?.trad_fr}</div>
//         </div>}
//         <div className="pl-4 mt-2 flex gap-2 text-xs">{e.syn.map(syn => <div className="bg-indigo-400/10 rounded-xl text-indigo-400 px-1">{syn}</div>)}</div>
//         </div>)}    
//     </div>
// }





const ContextLang = ({card, className, text, trad, lang, setCurrentCard, updateCard}) => {

    const [audioPlaying, setAudioPlaying] = useState(false)
    const [showTrad, setShowTrad] = useState(false)
    const [showVariation, setShowVariation] = useState(false)
    const [loading, setLoading] = useState(false)


    const {profile} = useStateContext()


    const generateVariations = async (maxRetries = 3) => {
        let attempt = 1;
        
        while (attempt <= maxRetries) {
            setLoading(true);
            const text_request = `Donne juste la réponse sans markdown. Tu es un professeur ${lang}.
            Pour la phrase suivantes: ${text}. 
            Donne 3 variations de cette phrase en variant la formulation et les registre de langue.
            Donne la reponse dans le format json suivant :
            [{
               
                "registre": "", // courant, familier, soutenu
                "variation": "..",
                "trad_fr": ".."
                
            }]
            `;
            console.log('data variations text_request', text_request);
            
           
                const request = [{ "role": "user", "content": text_request}];
                const requestData = await gpt(request, true, null, {model: "gpt-4"});
                console.log('data variations', requestData.choices[0]?.message?.content);
                try {
                const parsedData = JSON.parse(requestData.choices[0]?.message?.content);
                if (parsedData) {
                    attempt = 3
                    setLoading(false);
                    sbUpdate('cards', card.id, {data: {...card.data, variants: parsedData}});
                    setCurrentCard({...card, data: {...card.data, variants: parsedData}});
                    updateCard && updateCard(card.id, {data: {...card.data, variants: parsedData}});
                    
                    return parsedData;
                } 
             
            } catch (error) {
                console.log(`Tentative ${attempt} échouée, nouvelle tentative...`, error);
                attempt++;
                
                if (attempt > maxRetries) {
                    console.error(`Échec après ${maxRetries} tentatives`);
                    setLoading(false);
                    return null;
                }
            }
        }
    };

    const handlePlay = async() => {
        setAudioPlaying(true)
        await speak({text: text, lang: lang})
        setAudioPlaying(false)
    }

    return <div className={className}>
                
               

    <div className="text-[#7459c3] font-semibold text-md">
        <span className="font-[200] tracking-wider font-mono">❝</span> 
        <span  dangerouslySetInnerHTML={{ __html: text.replace(/\[([^\]]+)\]/g, '<span class="text-indigo-500 font-semibold">$1</span>') }} ></span><span className="font-[200] tracking-wider font-mono">❞</span></div>
    {showTrad && <div className="text-xs text-purple-900/50 mb-2" dangerouslySetInnerHTML={{ __html: trad.replace(/\[([^\]]+)\]/g, '<span class="text-indigo-500/80 ">$1</span>') }} ></div>}
    <div className="text-xs text-purple-900/50 flex gap-2 items-center">
    {!audioPlaying ? <svg onClick={handlePlay} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
                <path d="M7.557 2.066A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.248.56L3.59 11H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.59l3.162-2.81a.75.75 0 0 1 .805-.124ZM12.95 3.05a.75.75 0 1 0-1.06 1.06 5.5 5.5 0 0 1 0 7.78.75.75 0 1 0 1.06 1.06 7 7 0 0 0 0-9.9Z" />
                <path d="M10.828 5.172a.75.75 0 1 0-1.06 1.06 2.5 2.5 0 0 1 0 3.536.75.75 0 1 0 1.06 1.06 4 4 0 0 0 0-5.656Z" />
    </svg> : <l-waveform
            
            size="16"
            speed="1.2" 
            color="#a17fc3" 
          ></l-waveform>}
    <div onClick={() => {setShowTrad(prev => !prev)}}>{showTrad ? "Cacher":'Voir'} la traduction</div>
    <div className="grow"></div>
    {<div className="flex gap-1 items-center" onClick={() => {!loading && !card.data?.variants && generateVariations(); setShowVariation(prev => !prev)}}>{showVariation ? "Cacher":'Voir'} les variations
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-3 mt-[2px]">
        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
    </svg>

    </div>}

    
    
        
    </div>
    {showVariation && <div className="text-xs text-purple-900/50">
        {loading && <div className="text-center py-2">Chargement ...</div>}
        {card?.data?.variants?.filter(e => e.variation != text.replace(/\[([^\]]+)\]/g, '') )?.map((e, i) => <div key={i} className="mt-2">
            <div className="mt-2 text-right"></div>
            <div className="text-base text-purple-500">{e.variation}</div>
            <div className="text-xs text-purple-900/50 ">({e.registre}) {e.trad||e.trad_fr}</div>
            </div>)}
        {(profile.role == "admin" || profile.role == "editor") && !loading &&  <div className="mt-2 text-center" onClick={() => {
            generateVariations()
        }}>Générer des variations</div>}
        </div>}


</div>
}




const EditableCard = ({currentCard, setCurrentCard, updateCard}) => {
    const storage = getStorage(app);

    const defaultPromt = `Donne moi une image qui peut représenter le terme '${currentCard.term}' dans le style illustration morderne, simple, clean lines avec un fond léger (pas trop chargé ou neutre). 
    Si tu ne trouve pas d'image pour le term tu peux t'aider de la phrase suivante '${currentCard.context}'. 
    Aucuns mots ni écriture, ni lettre ne doit ne doit apparaitre sur l'image !!`

    const defaultPromptWord = `Donne moi une image qui peut représenter le terme '${currentCard.term}' dans le style illustration morderne, simple, clean lines avec un fond léger (pas trop chargé ou neutre). Sans texte sur l'image`

    const [editCard, setEditCard] = useState(currentCard)




    const [loading, setLoading] = useState([])
    const {setView, workspace, profile} = useStateContext()
    

    const [promptImg, setPromptImg] = useState()

    const [task, setTask] = useState()
    
    
    const replaceImage = (url) => {
        setTask()
        setEditCard(prev => ({...prev, img: url}))
        updateCard(currentCard.id, {...currentCard, img: url});
        updateAllCards({img: url}, editCard.img);
    }

    useEffect(() => {
        console.log('task', task)
        if (!task?.task_id) return 
    
        let interval; // Déclarer l'interval en dehors du setInterval
    
        if (task?.output?.progress === 100 || task?.output?.image_url) {
            clearInterval(interval)
            return
        } 
        
        interval = setInterval(() => {
            getTask(task?.task_id, setTask)
            console.log('get task')
        }, task?.status == "processing" ? 2000 : 5000)
    
        // Cleanup function pour arrêter le polling quand le composant est démonté
        return () => clearInterval(interval)
    }, [task?.status, task?.task_id])

    const mustChoose = !editCard.imgs?.find(url => editCard.img == url) && editCard.imgs?.length> 0 
    const errorBracket = !(hasWordInBrackets(editCard.context) && hasWordInBrackets(editCard.context_fr))






    return <div onClick={() => setView()} className="fixed z-[999] top-0 bottom-0 left-0 right-0 backdrop-blur flex items-center jusitfy-center  bg-[#05043e99]">
        <div onClick={(e) => {e.stopPropagation()}} style={{width: "calc(100vw - 20px)"}} className="p-4  text-white bg-gradient-to-b from-[#2e0c85] to-[#25054db8] relative rounded-xl  pb-[80px] h-[82vh] min-w-[300px] mx-auto max-w-[500px]">
            {/* <div>Editer {editCard?.term}</div> */}
            {/* <div onClick={() => console.log('currentCArd', {currentCard, editCard})}>Get current</div> */}
            <div className="h-full overflow-scroll">

          
                <div  className="flex justify-between"><div className="text-xs text-white/50">Image</div> </div>

              
            <div className="flex gap-2">
                {!(!mustChoose && task?.output?.image_url) && !mustChoose && <div><img id="test-img" src={editCard?.img}  className="h-[100px] hover:scale-[2.0] transition-all rounded-xl"/></div>}

                <div>
                    {/* <div className="h-[100px] bg-white/10 rounded-xl flex flex-col items-center justify-center text-center w-[140px]">
                        <div className="border-b w-full grow flex items-center justify-center">DALLE-3</div>
                        <div className="grow w-full flex items-center justify-center">Midjourney</div>
                    </div> */}
                     {mustChoose&& <div>
                        <div className="text-xs">Choisis ton image préférée</div>
                        <div className="flex relative flex flex-wrap">
                            {editCard.imgs?.map((img, i) => <div className="relative">
                                <img key={i} src={img} id="split-img" className={`h-[90px] min-w-[120px] transition-all hover:scale-[2.5] choisistransition-all rounded-xl`} />
                                {editCard.img == img ? 
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" strokeWidth={1.5} stroke="#fff" className="h-8 hover:scale-[2.5] top-4 left-4 absolute text-yellow-500"> <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" /> </svg>
                                : 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#15141280" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 hover:scale-[2.5] top-4 left-4 absolute text-yellow-100" onClick={() => {replaceImage(img)}}><path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" /> </svg>}
                                </div>)
                            }
                    </div>
                        </div>}
                     {!mustChoose && <>{task?.output?.image_url ? <div className="relative">
                        <img src={task.output?.image_url} className="h-[200px] rounded-xl"/>
                        <div className="flex gap-2 justify-between text-center">
                            <div className="grow" onClick={() => {setTask()}}>Annuler</div>
                            <div onClick={() => !loading?.includes('upload-split') && splitImage(task.output?.image_url, setEditCard, storage, editCard, setLoading)} className="text-center bg-green-100 text-green-500 px-2 py-1 grow relative hover:scale-125 rounded-xl">Valider {loading?.includes('upload-split') && loader}  {loading?.includes('proxied') && "..."}</div>
                        </div>
                     </div>: <div className="h-[100px] bg-white/10 rounded-xl flex flex-col items-center justify-center text-center w-[140px]">
                        {!task?.task_id && <div onClick={() => { 
                            sbCreate('events', {profile_id: profile.id, workspace_id: workspace.id, type: "image-gen", date: new Date()})
                            sendTaskMidJourney(promptImg ||defaultPromt, setTask)
                            }} className="h-full bg-white/10 w-full flex flex-col items-center justify-center rounded-xl text-sm"><div className="text-xs text-white/80">Générer une image</div> <div>Midjourney</div></div>}
                        {task?.task_id && <div className="text-center" onClick={() => {getTask(task.task_id, setTask)}} >
                            <div>{loader}</div>
                            {task.status}...<div className="text-xs">{Math.min(task.output.progress * 3, 99)}%</div>
                            </div>}

                    </div>}</>}
                </div>
            
            </div>

            <div  className="flex justify-between mt-2"><div className="text-xs text-white/50">Description <span onClick={async() => {
                 setLoading(prev => [...prev, "prompt"])
                 const prompt = await getPromptImage(editCard?.term,editCard?.term, setPromptImg)
                 setLoading(prev => prev.filter(e => e != "prompt"))
            }}>image</span></div> <div className="text-pink-100/70 text-sm p-1" onClick={async() => {
                    setLoading(prev => [...prev, "prompt"])
                    const prompt = await getPromptImage(editCard?.context, editCard?.term, setPromptImg)
                    setLoading(prev => prev.filter(e => e != "prompt"))
                }} >Génération automatique {loading.includes('prompt') ? loader : <SVG_IA/>}</div></div>
            <textarea placeholder="décris l'image à générer" className="bg-white/10 w-full rounded-md p-1 text-white" value={promptImg} onChange={e => setPromptImg(e.target?.value)}></textarea>


            
            <div className="mt-8">
                <div  className="flex justify-between"><div className="text-xs text-white/50">Contexte</div> <div onClick={async() => {
                    setLoading(prev => [...prev, "context"])
                    const variation = await varyContext(editCard?.context, editCard?.term, workspace?.name)
                    setLoading(prev => prev.filter(e => e != "context"))
                    setEditCard(prev => ({...prev, context: variation}))
                }} >Variation {loading.includes('context') ? loader : <SVG_IA/>}</div></div>
                <textarea className="bg-white/10 w-full rounded-md p-1 text-white" value={editCard?.context} onChange={e => setEditCard(prev => ({...prev, context: e.target?.value}))}></textarea>
            </div>

            <div>
                <div  className="flex justify-between"><div className="text-xs text-white/50">Traduction</div> <div onClick={async() => {
                    setLoading(prev => [...prev, "trad"])
                    const trad = await translateContext(editCard?.context, editCard?.term, workspace?.name)
                    setLoading(prev => prev.filter(e => e != "trad"))
                    setEditCard(prev => ({...prev, context_fr: trad}))
                }} >Traduire {loading.includes('trad') ? loader : <SVG_IA/>}</div></div>
                <textarea className="bg-white/10 w-full rounded-md p-1 text-white" value={editCard?.context_fr} onChange={e => setEditCard(prev => ({...prev, context_fr: e.target?.value}))}></textarea>
            </div>

            {errorBracket && <div className="text-pink-200 mt-2 text-center">Attention le mot doit être entre []</div>}

            <div className="absolute p-4 flex items-center gap-8 enter text-center justify-between bottom-0 left-0 h-[80px] right-0">
                <div className="px-3 py-2 rounded-xl  text-white bg-white/10"  onClick={() => {setView()}}>Annuler</div>
                {editCard && hasWordInBrackets(editCard.context) && hasWordInBrackets(editCard.context_fr) && <div className="px-3 py-2 rounded-xl  grow text-[#9D2DE7] bg-white" 
                onClick={() => {
                    
                    // const text = document.querySelector('#input-hint')?.value
                    // updateProfileCard(profile_card.id, {hint: {...hint, text_fr: text}})
                    // updateCurrentCard(prev => ({...prev, hint: {...hint, text_fr: text}}))
                    setCurrentCard(editCard)
                    updateCard(currentCard.id, editCard)
                    setView()
                    }}>Modifier la carte</div>}
            </div>
          
        </div>
        </div>
    </div>
}


const createProfileCardLang =async (card, profile_card, setLearnedCards) => {

    const triggers = [
        {next_date: new Date(),lvl: 1},
        {next_date: new Date(),lvl: 1},
]

    const new_profile_card = {
            ...profile_card,
            lvl: 1,
            last_update: new Date(),
            card_id: card.id,
            collected: false,
            next_date: new Date(),
            next_trigger: "0",
            triggers: triggers
    }




    console.log('new_profile_card', new_profile_card)
    const  new_profile_card_with_id = await sbCreate('profiles_cards', new_profile_card)
    setLearnedCards(prev => [...prev, {card, profile_card: new_profile_card_with_id}])
} 



const BackCard = ({card, old_profile_card, updateCard, invalidCardsBack, profile_card, validCard, workspace, updateProfileCard, setNewProfileCard}) => {


    const {setLearnedCards, setShowCard, profile, setAlert, setView} = useStateContext()

    const [currentProfilCard, setCurrentProfilCard] = useState()
    const [currentCard, setCurrentCard] = useState(card)

    useEffect(() => {
        setCurrentCard(card)
    }, [card?.id])


    useEffect(() => {
        setCurrentProfilCard(profile_card)
    }, [profile_card?.id])

    const updateCurrentCard = (profile_card) => {
        setCurrentProfilCard(profile_card)
        setNewProfileCard && setNewProfileCard(profile_card)
    }

   

    console.log('profile_card', profile_card)
    console.log('currentProfilCard', currentProfilCard)

    const frequencyRate = (card.rank_occ/50000)*100
    let frequencyForce = 0 // C2
    if (card.rank_occ < 50000) frequencyForce = 1 // C1
    if (card.rank_occ < 15000) frequencyForce = 2 // B2
    if (card.rank_occ < 5000) frequencyForce = 3 // B1
    if (card.rank_occ < 1200) frequencyForce = 4 // A2
    if (card.rank_occ < 500) frequencyForce = 5 // A1
    if (card.rank_occ == 0) frequencyForce = 0 // C2

    const frequenceTexts = ["Rare", "Occasionnelle", "Souvent", "Souvent", "Très souvent", "Très souvent"];
    const frequenceScore = ["C2", "C1", "B1", "B2", "A2", "A1"]


    const cardHasHint = card.hint?.text_fr
    const profilHasHint = currentProfilCard?.hint?.text_fr
    const alertHint = cardHasHint && !profilHasHint



    
    console.log('_current currentCard', currentCard)


    const triggers = profile_card && profile_card?.triggers?.map(e => ({...e, displayDuration: displayDuration(new Date, new Date(e.next_date))}))

    return <div  className="bg-[#fff7ea] rounded-2xl relative h-full  ">
                {updateCard && (profile.role == "editor" || profile.role == "admin") && <div onClick={() => {setView(<EditableCard updateCard={updateCard} currentCard={currentCard} setCurrentCard={setCurrentCard} />)}} className="px-2 text-sm rounded-lg absolute z-[99] top-2 right-2 bg-black/50 text-white">Modifier <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-3 inline-block">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                </svg>
                </div>}
                <div className="overflow-auto h-full rounded-2xl  pb-[100px]">
                {card.img ? <img onClick={() => {console.log('card', card)}} src={card.img} className="max-h-[52%]  mx-auto w-full object-cover  "/> : <div className="bg-purple-500 h-[100px] w-full"></div>}

                <div className="p-4 text-amber-900/60">

                  
                

                <ContextLang card={currentCard} setCurrentCard={setCurrentCard} updateCard={updateCard} lang={workspace.lang} trad={currentCard?.context_fr} text={currentCard?.context} className={`px-3 ${workspace.lang == "ar-XA" ? "text-right" : ""} pl-3 py-[6px] text-left bg-[#e8e0ff]  -mt-8 relative  rounded-xl border border-[#eddfff]`}/>
                {/* <div className="text-green-500">{card.hint?.text_fr}</div> */}

                {currentProfilCard?.hint?.text_fr?.length > 0 && <div  onClick={() => {
                    setView(<Hint 
                        card={card}
                        updateCurrentCard={updateCurrentCard} 
                        profile_card={currentProfilCard} 
                        updateProfileCard={updateProfileCard} 
                        quit={() => setView()} 
                        />)
                }} className="text-white relative flex gap-2 items-center justify-between mt-2 bg-[#954bed] text-left rounded-xl">
                    <div className="p-2 pl-3 ">{currentProfilCard?.hint?.text_fr}</div>
                   
                    {currentProfilCard?.hint.img ? <img src={currentProfilCard.hint.img} className="h-[100px] w-[100px] rounded" /> : 
                     <div className="p-2 bg-white/10 rounded-xl "><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                    </svg></div>}


                    
  
                </div>}



                <div onClick={() => speak({text: card.term, lang: workspace.lang})} className="text-[#7459c3] mt-4 flex gap-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
                        <path d="M7.557 2.066A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.248.56L3.59 11H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.59l3.162-2.81a.75.75 0 0 1 .805-.124ZM12.95 3.05a.75.75 0 1 0-1.06 1.06 5.5 5.5 0 0 1 0 7.78.75.75 0 1 0 1.06 1.06 7 7 0 0 0 0-9.9Z" />
                        <path d="M10.828 5.172a.75.75 0 1 0-1.06 1.06 2.5 2.5 0 0 1 0 3.536.75.75 0 1 0 1.06 1.06 4 4 0 0 0 0-5.656Z" />
                    </svg>
                    {card.term}
                </div>

               

               
              
               

                <WordDefinitions definitions={card.definitions} />

              
                {profile_card?.context?.context && <div onClick={() => {console.log('info', {profile_card, triggers,  card} )}} className='bg-[#ffeccd] mt-4 text-left flex gap-2 rounded-xl items-center px-3 py-1 my-2 text-sm'>
                       
                       {/* <div>path: {path}</div> */}
                       <div>
                           <div className='text-amber-600 text-xs font-[500]'>{profile_card?.context?.context}</div>
                           {/* <div className='text-black/40 text-xs italic' >{tradPrint}</div> */}
                       </div>
                       <img  src={profile_card?.context?.img} className='h-16 w-16 object-cover -mr-2 rounded-xl' />
                   </div>}

                {card.rank_occ !== null && <div className="border-t p-2 flex gap-2 items-center border-amber-500/20 text-left text-xs bg-[#fbf3e5]/10  mt-4">
                    <div className="">Fréquence d'utilisation: </div>
                    <div className="flex grow gap-[2px] ">{[...Array(5)]?.map((e,i) => <div key={i} className={`h-[5px] ${ frequencyForce > i ? "bg-indigo-400": "bg-indigo-900/20"}  rounded w-full grow max-w-[18px]`}></div>)}</div>
                    <div>{frequenceTexts[frequencyForce]} <span className="px-2 rounded-xl bg-amber-500/10">{frequenceScore[frequencyForce]}</span></div>
                </div>}

                { !currentProfilCard?.hint?.text_fr?.length > 0 && profile_card?.id && <div className="flex justify-center"><div className="text-sm border border-dashed border-purple-500/50 flex gap-2 items-center mt-4 px-3 py-[2px] rounded-xl mt-2 relative text-purple-500/90" onClick={() => {
                    setView(<Hint 
                        card={card}
                        updateCurrentCard={updateCurrentCard} 
                        profile_card={currentProfilCard} 
                        updateProfileCard={updateProfileCard} 
                        quit={() => setView()} 
                        />)
                }}> Ajouter une astuce
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 inline-block">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                </svg>

                 {alertHint    && <div className="h-3 w-3 absolute rounded-full bg-purple-500/90 -top-[5px] -right-[5px]"></div>}</div></div>} 

                

                </div>
                </div>
                {profile_card?.id && <div onClick={() => {
                    console.log('profile_card', profile_card)
                    console.log('old', old_profile_card)
                    console.log('triggers', triggers)
                    }} className="absolute overflow-visible pb-8 pattern-wiggle bg-[#fdf0d9] rounded-2xl text-amber-800/90 bottom-0 left-0  right-0 p-4">
                    
                    <div className="flex gap-2 grow">
                        {/* <div className="h-[40px] rounded-full min-w-[40px] border-2"></div> */}
                    
                        <div className="grow">
                        <div className="flex gap-2 items-center text-xs mb-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 -mr-1">
                            <path d="M7.25 3.688a8.035 8.035 0 0 0-4.872-.523A.48.48 0 0 0 2 3.64v7.994c0 .345.342.588.679.512a6.02 6.02 0 0 1 4.571.81V3.688ZM8.75 12.956a6.02 6.02 0 0 1 4.571-.81c.337.075.679-.167.679-.512V3.64a.48.48 0 0 0-.378-.475 8.034 8.034 0 0 0-4.872.523v9.268Z" />
                        </svg>
                        <div className="w-[80px] text-left mr-2">
                            Compréhension
                        </div>
                        <div className="flex gap-1 grow">
                            {[...Array(triggers?.[0]?.lvl > 5 ? triggers?.[0]?.lvl : 5)]?.map((e,i) => <div key={i} className={` h-[5px] ${ triggers?.[0]?.lvl > i ? "bg-cyan-400": "bg-cyan-900/20"} rounded-full w-full grow max-w-[18px]`}></div>)}
                           
                        </div>
                        {triggers?.[0]?.displayDuration != "maintenant" && <div className="flex gap-1 items-center bg-amber-500/10 rounded-xl px-1 border border-black/10"    ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-3">
                            <path fillRule="evenodd" d="M8 3.5c-.771 0-1.537.022-2.297.066a1.124 1.124 0 0 0-1.058 1.028l-.018.214a.75.75 0 1 1-1.495-.12l.018-.221a2.624 2.624 0 0 1 2.467-2.399 41.628 41.628 0 0 1 4.766 0 2.624 2.624 0 0 1 2.467 2.399c.056.662.097 1.329.122 2l.748-.748a.75.75 0 1 1 1.06 1.06l-2 2.001a.75.75 0 0 1-1.061 0l-2-1.999a.75.75 0 0 1 1.061-1.06l.689.688a39.89 39.89 0 0 0-.114-1.815 1.124 1.124 0 0 0-1.058-1.028A40.138 40.138 0 0 0 8 3.5ZM3.22 7.22a.75.75 0 0 1 1.061 0l2 2a.75.75 0 1 1-1.06 1.06l-.69-.69c.025.61.062 1.214.114 1.816.048.56.496.996 1.058 1.028a40.112 40.112 0 0 0 4.594 0 1.124 1.124 0 0 0 1.058-1.028 39.2 39.2 0 0 0 .018-.219.75.75 0 1 1 1.495.12l-.018.226a2.624 2.624 0 0 1-2.467 2.399 41.648 41.648 0 0 1-4.766 0 2.624 2.624 0 0 1-2.467-2.399 41.395 41.395 0 0 1-.122-2l-.748.748A.75.75 0 1 1 1.22 9.22l2-2Z" clipRule="evenodd" />
                            </svg>
                        {triggers?.[0]?.displayDuration}</div>}

                        </div>

                        {triggers?.[1] && <div className="flex gap-2 items-center text-xs mb-2 ">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 -mr-1">
                                <path fillRule="evenodd" d="M11.013 2.513a1.75 1.75 0 0 1 2.475 2.474L6.226 12.25a2.751 2.751 0 0 1-.892.596l-2.047.848a.75.75 0 0 1-.98-.98l.848-2.047a2.75 2.75 0 0 1 .596-.892l7.262-7.261Z" clipRule="evenodd" />
                            </svg>

                            <div className="w-[80px] text-left mr-2">
                                Expression
                            </div>
                            <div className="flex gap-1 grow">
                                {[...Array(triggers?.[1]?.lvl > 5 ? triggers?.[1].lvl : 5)]?.map((e,i) => <div key={i} className={`h-[5px] ${ triggers?.[1].lvl > i ? "bg-indigo-400": "bg-indigo-900/20"}  rounded-full w-full grow max-w-[18px]`}></div>)}
                            
                            </div>
                            
                            {triggers?.[1]?.displayDuration != "maintenant" && <div className="flex gap-1 items-center bg-amber-500/10 rounded-xl px-1 border border-black/10"    ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-3">
                            <path fillRule="evenodd" d="M8 3.5c-.771 0-1.537.022-2.297.066a1.124 1.124 0 0 0-1.058 1.028l-.018.214a.75.75 0 1 1-1.495-.12l.018-.221a2.624 2.624 0 0 1 2.467-2.399 41.628 41.628 0 0 1 4.766 0 2.624 2.624 0 0 1 2.467 2.399c.056.662.097 1.329.122 2l.748-.748a.75.75 0 1 1 1.06 1.06l-2 2.001a.75.75 0 0 1-1.061 0l-2-1.999a.75.75 0 0 1 1.061-1.06l.689.688a39.89 39.89 0 0 0-.114-1.815 1.124 1.124 0 0 0-1.058-1.028A40.138 40.138 0 0 0 8 3.5ZM3.22 7.22a.75.75 0 0 1 1.061 0l2 2a.75.75 0 1 1-1.06 1.06l-.69-.69c.025.61.062 1.214.114 1.816.048.56.496.996 1.058 1.028a40.112 40.112 0 0 0 4.594 0 1.124 1.124 0 0 0 1.058-1.028 39.2 39.2 0 0 0 .018-.219.75.75 0 1 1 1.495.12l-.018.226a2.624 2.624 0 0 1-2.467 2.399 41.648 41.648 0 0 1-4.766 0 2.624 2.624 0 0 1-2.467-2.399 41.395 41.395 0 0 1-.122-2l-.748.748A.75.75 0 1 1 1.22 9.22l2-2Z" clipRule="evenodd" />
                            </svg>
                        {triggers?.[1]?.displayDuration}</div>}

                        </div> }
                        </div>
                    </div>
                    
                </div>}

                {/* Si on découvre le mot en contexte  */}
                {profile_card && !profile_card?.id ? <div className="flex gap-2 items-center justify-between p-4 absolute bg-white bottom-0 rounded-b-xl left-0 right-0" onClick={() => {console.log('card', {card, profile_card})}}>
                <div className=" py-2 px-4 text-slate-500  rounded-xl border-2 border-b-4 border-black/10 ">Ne pas apprendre </div>
                <div onClick={() => {
                    createProfileCardLang(card, profile_card, setLearnedCards)
                    setShowCard()
                    setAlert({content: `La carte “${card.term}“ a été ajoutée dans ton parcours Vocabulaire`, time: 3000, icon: <img src="/images/UX/i_voc.png" className=" rounded-xl object-cover h-[60px] " />})

                    

                }} className="bg-purple-500 py-2 px-4 grow text-white rounded-xl border-2 border-b-4 border-black/60 game-btn">Apprendre</div>
                </div> : 
                     validCard && <div className="flex justify-end absolute -bottom-4 left-0 right-4 ">
                        {invalidCardsBack && <div className="bg-pink-500 py-2 px-10 text-white rounded-full border-2 border-b-4 mr-2 border-black/60 game-btn" onClick={() => {invalidCardsBack({valid: false})}}>Revoir</div>}
                    {<div className="bg-purple-500 py-2 px-10 text-white rounded-full border-2 border-b-4 border-black/60 game-btn" onClick={() => {validCard({valid: true})}}>Suivant <span className="ml-2"> →</span></div>}
                    </div> }

                
              
                {/* <div className="flex gap-2 w-full mt-4 justify-between">
                    <div onClick={() => validCard({valid: false, bonus: 0})}>Faux</div>
                    <div onClick={() => validCard({valid: true, bonus: 0})}>Juste</div>
                </div> */}
    </div>
}



const WaiterOpen = ({setDifficulty, setState, mode}) => {

    return  <div className="flex gap-2 w-full mt-4 justify-between">
    <div onClick={() => {setDifficulty(0); setState(2)} } className={`button ${mode == "exp" ?  "bg-[#CDA0CC]" : "bg-[#1ba480]"} font-[400] px-1 pb-1 game-btn w-[100px] text-white  border-black/30 border-2 border-b-[4px] rounded-2xl grow`}>
    
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[25px] opacity-50 mx-auto ">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
            <div className='text-shadow-50 -mt-1  font-[400] text-[15px] mb-[6px]'>Je sais pas</div>
            <div className='text-[11px] px-1 bg-black/10 rounded-full py-[-1px] font-sans text-shadow-50  -mb-[2px]  text-white/80'>à revoir</div>
        </div>
        <div onClick={() => {setDifficulty(1); setState(2)} } className={`button ${mode == "exp" ?  "bg-[#71B6BF]" : "bg-[#1dbb88]"}  font-[400] px-1 pb-1 game-btn w-[100px] text-white  border-black/30 border-2 border-b-[4px] rounded-2xl grow`}>
    
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[30px] opacity-50  mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round"d="M4.5 12.75l6 6 9-13.5" />
          </svg>
            <div className='text-shadow-50 -mt-1  font-[400]'>Je sais </div>
            <div className='text-[11px] px-1 bg-black/10 rounded-full py-[-1px] font-sans text-shadow-50  -mb-[2px]  text-white/80'>revoir dans 4j</div>
        </div>
        <div onClick={() => {setDifficulty(2); setState(2)} } className={`button ${mode == "exp" ?  "bg-[#8E8CEF]" : "bg-[#18bdab]"}   font-[400] px-1 pb-1 game-btn w-[100px] text-white  border-black/30 border-2 border-b-[4px] rounded-2xl grow`}>
    
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[30px] opacity-50   mx-auto">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z" />
    </svg>
            <div className='text-shadow-50 -mt-1  font-[400]'>Trop facile</div>
            <div className='text-[11px] px-1 bg-black/10 rounded-full py-[-1px] font-sans text-shadow-50  -mb-[2px]  text-white/80'>revoir dans 6j</div>
        </div>
        {/* <div onClick={() => validCard({valid: false, bonus: 0})}>Faux</div>
        <div onClick={() => validCard({valid: true, bonus: 0})}>Juste</div> */}
    </div>
}








const TriggerExpression = ({card, profile_card, updateCard, randomCards,  validCard, workspace, updateProfileCard}) => {

    const [state, setState] = useState(0)
    const [old_profile_card, setOldProfileCard] = useState(profile_card)
    const [newProfileCard, setNewProfileCard] = useState()
    const [isValid, setIsValid] = useState(false)
    const [difficulty, setDifficulty] = useState(1)
    let nbRandom = 3
    if (difficulty == 0) nbRandom = 1
    if (difficulty == 2) nbRandom = 8
    const {profileWorkspace} = useStateContext() 
    const expressionMode = profileWorkspace?.options?.exp_mode || workspace?.options?.exp_mode ||  "fill"

    useEffect(() => {
        setState(0)
        setOldProfileCard(profile_card)
        setNewProfileCard(profile_card)
        setIsValid(false)
        setDifficulty()
    }, [card.id])

    const validCardBack = (valid = true) => {
        // on update les profiles cards dans l'app
        
        updateProfileCard(newProfileCard.id, newProfileCard)
            // on passe la carte dans todoCards
        const ratio = expressionMode == "free" ? 3 : 1
        validCard({valid: isValid, ratio: ratio})
      
    }

    const invalidCardsBack = () => {
        console.log('free mode newProfileCard', newProfileCard)
        const _newProfileCard = evolveProfileCard({profile_card, trigger: 1, valid: 0})
        updateProfileCard(_newProfileCard.id, _newProfileCard)
        console.log('free mode _newProfileCard 2', _newProfileCard)
        validCard({valid: false})
    }

    const validTrigger = (valid) => {
        // on met à jour le nouveau profil_card pour le back
        console.log('_blink validTrigger ')
        speak({text: card.term + " . " + card.context, lang: workspace.lang})

        const _newProfileCard = evolveProfileCard({profile_card, trigger: 1, valid: valid})
        // console.log("debug _newProfileCard", _newProfileCard)
        setNewProfileCard(_newProfileCard)
        setIsValid(valid > 0) 

        // si bonus on met à jour le profile card et on passe
        if (valid == 2) {
            updateProfileCard(newProfileCard.id, _newProfileCard)
            validCard({valid: true, bonus: 1})
        }
       

    }

   

    const brackedSentence = hasWordInBrackets(card.context) ? card.context : brackSentence({sentence: card.context, word: card.term})

    
    if (state == 1) return <BackCard updateCard={updateCard}  setNewProfileCard={setNewProfileCard} updateProfileCard={updateProfileCard} workspace={workspace} randomCards={randomCards} card={card} old_profile_card={old_profile_card}  invalidCardsBack={expressionMode == "free" && isValid && invalidCardsBack}  profile_card={newProfileCard} validCard={validCardBack}/>
    return <div style={{backgroundColor: "rgb(235, 216, 255)"}} className="p-4 text-purple-600 rounded-2xl overflow-auto relative h-full flex flex-col justify-between pb-[70px]">

            <div onClick={() => {
                 updateProfileCard(profile_card.id, evolveProfileCard({profile_card, trigger: 1, valid: 1}))
                 validCard({valid: true})
               
            }} className="flex justify-center ">
                <div className="flex gap-2 bg-purple-500/10 px-2 text-sm rounded-xl items-center mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-3"><path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"></path></svg>
                    <div>Expression</div>
                </div>
            </div>
            
            <div className="flex flex-col justify-center grow ">
                <div className="">
                    <div className="text-sm text-indigo-600/70 ">Trouve le terme en {workspace.name}</div>
                    <div className="h-[180px] w-[220px] mx-auto my-4 bg-center bg-cover text-slate-500 rounded-xl relative" style={{backgroundImage: `url('${card.img}')`}}>
                        <div className="bg-gradient-to-b from-[#4a0382e6]/20 rounded-xl via-[#4a0382e6]/40 to-[#4a0382e6] p-2 h-full flex flex-col justify-end absolute bottom-0 left-0 right-0 rounded-b-xl">
                            {/* <div className="text-white text-sm">{getTraduction(card)}</div> */}
                            
                            <div className="text-white/70 text-[12px] italic" dangerouslySetInnerHTML={{ __html: card.context_fr.replace(/\[([^\]]+)\]/g, '<span class="text-indigo-100 font-semibold  ">$1</span>') }} ></div>

                        </div>   
                    </div>      

                     {expressionMode == "free" && <div className="bg-white/20 p-2 pb-4 rounded absolute bottom-0 left-0 right-0 flex gap-2 items-center">
                            <div onClick={() => {validTrigger(0); setState(1); speak({text: card.term + " . " + card.context, lang: workspace.lang}) }} className="px-2 p-2 rounded-xl text-center border-pink-500 bg-pink-100 text-pink-500 border border-b-4 grow ">Je sais pas</div>
                            <div onClick={() => {validTrigger(1); setState(1); speak({text: card.term + " . " + card.context, lang: workspace.lang})}} className="px-2 p-2 rounded-xl text-center border-purple-500 bg-purple-100 text-purple-500 border border-b-4 grow">Je sais</div>
                        </div>}        
                    {difficulty == 3 && <div>
                        <div className="text-indigo-500 text-base " dangerouslySetInnerHTML={{ __html: card.context.replace(/\[([^\]]+)\]/g, '<span class="text-transparent font-semibold border-b w-[200px] border-indigo-500">hello</span>') }} ></div>
                        <div className="bg-white/20 p-2 pb-4 rounded absolute bottom-0 left-0 right-0">
                       

                        <QCM noXp={true} setState={() => setState(1)} elements={randomCards?.slice(0, nbRandom)} field={"term"} goodElement={card} validCard={() => validTrigger(0)} /></div></div>}
                        {difficulty == 2 && <FillSentence inputClassName={"!bg-purple-300/50"} brackedSentence={brackedSentence} setState={setState} validCard={validTrigger} />}           
                        {expressionMode == "fill" && difficulty !== 3 && <FillSentence setDifficulty={setDifficulty} inputClassName={"!bg-purple-300/50"} brackedSentence={brackedSentence} setState={setState} validCard={validTrigger} />}
                        {expressionMode == "qcm" && <div className="">
            
                        {difficulty !== 2 && <div className="text-indigo-500 text-base " dangerouslySetInnerHTML={{ __html: card.context.replace(/\[([^\]]+)\]/g, '<span class="text-transparent font-semibold border-b w-[200px] border-indigo-500">hello</span>') }} ></div>}

                        {state == 0 && <div className="bg-white/20 p-2 pb-4 rounded absolute bottom-0 left-0 right-0"><WaiterOpen mode={"exp"} setState={setState} setDifficulty={setDifficulty}/></div>}
                        {state == 2 && difficulty !== 2 && <div className="bg-white/20 p-2 pb-4 rounded absolute bottom-0 left-0 right-0">
                        
                            <QCM setState={() => setState(1)} elements={randomCards?.slice(0, nbRandom)} field={"term"} goodElement={card} validCard={validTrigger} />
                        </div>}

                        </div>}
                </div>
            </div>
           

           

    </div>
}

const TriggerComprehension = ({card, profile_card, randomCards, updateCard,  validCard, workspace, updateProfileCard }) => {

    const {setAlert, blockAudio, setBlockAudio} = useStateContext()
    const [state, setState] = useState(1)
    const [old_profile_card, setOldProfileCard] = useState(profile_card)
    const brackedSentence = hasWordInBrackets(card.context) ? card.context : brackSentence({sentence: card.context, word: card.term})
    const [difficulty, setDifficulty] = useState(1)
    const [audioMode, setAudioMode] = useState(false)
    const [newProfileCard, setNewProfileCard] = useState()
    const [isValid, setIsValid] = useState(false)


    useEffect(() => {
        if (!profile_card) return
        const triggers = transformDates(profile_card?.triggers)?.map(e => ({...e, displayDuration: displayDuration(new Date, e.next_date)}))

        if (triggers[0].lvl%2==1 && (new Date() > blockAudio || !blockAudio)) {
            setAudioMode(true)
            
        } else {
            setAudioMode(false)
        }
    }, [profile_card])

    

    useEffect(() => {
        setState(1)
        setOldProfileCard(profile_card)
        setNewProfileCard(profile_card)
        setIsValid(false)
        console.log('changement de profil card', profile_card)
    }, [card.id])

   

    let nbRandom = 3
    if (difficulty == 0) nbRandom = 1
    if (difficulty == 2) nbRandom = 8

    const handleSpeak = () => {
        !audioMode && speak({text: card.term + ";. " + card.context, lang: workspace.lang})
    }

    const validCardBack = () => {
        // on update les profiles cards dans l'app
        updateProfileCard(newProfileCard?.id, newProfileCard)

        // on passe la carte dans todoCards
        validCard({valid: isValid})
    }

    const validTrigger = (valid) => {
         // on met à jour le nouveau profil_card pour le back
        const _newProfileCard = evolveProfileCard({profile_card, trigger: 0, valid: valid})
        console.log('_newProfileCard', _newProfileCard)
        setNewProfileCard(_newProfileCard)
        setIsValid(valid > 0)

        // si bonus on met à jour le profile card et on passe
        if (valid == 2) {
            updateProfileCard(_newProfileCard?.id, _newProfileCard)
            validCard({valid: true, bonus: 1})
        }
       

    }
    
    if (state == 3) return <BackCard updateCard={updateCard} setNewProfileCard={setNewProfileCard} updateProfileCard={updateProfileCard} workspace={workspace} randomCards={randomCards} card={card} old_profile_card={old_profile_card} profile_card={newProfileCard} validCard={validCardBack}/>

    return <div style={{backgroundColor: "rgb(214, 255, 238)"}} className="text-green-600 rounded-2xl overflow-auto gap-0 relative h-full flex flex-col  justify-start gap-0">
            
            <div className="pt-4">
                
                <div className="flex justify-center">
                <div onClick={() => {
                     updateProfileCard(profile_card.id, evolveProfileCard({profile_card, trigger: 0, valid: 1}))
                     validCard({valid: true})
                }} className="flex px-2 rounded-xl bg-green-500/20 gap-2 text-sm rounded-xl items-center mx-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 text-green-500/70">
                        <path d="M7.25 3.688a8.035 8.035 0 0 0-4.872-.523A.48.48 0 0 0 2 3.64v7.994c0 .345.342.588.679.512a6.02 6.02 0 0 1 4.571.81V3.688ZM8.75 12.956a6.02 6.02 0 0 1 4.571-.81c.337.075.679-.167.679-.512V3.64a.48.48 0 0 0-.378-.475 8.034 8.034 0 0 0-4.872.523v9.268Z" />
                    </svg>
                    <div>Compréhension</div>
                </div>
                </div>
                <div className="mt-4">Te souviens-tu de ce mot ?</div>
            </div>
            {audioMode ? <div className="flex px-4 flex-col justify-center grow">
                
                <AudioBtn autoSpeak={true} speakText={card.term + "; . "+ card.context} className={"!border-emerald-900 h-[120px] w-[120px]"} color={"!from-emerald-500 !to-[#0c887a]"} card={card} lang={workspace.lang} />
                <div onClick={() => {setAudioMode(false); setBlockAudio(new Date(Date.now() + 5 * 60000)); setAlert({title: "Son désactivé pendant 5 mins", time: 3000})}} className="text-emerald-700/60 text-sm">Je ne peux pas écouter</div>
                
                </div> : <div className="flex px-4 flex-col justify-center grow ">
                <div className="">
                    
                    <div className="mb-4 bg-green-300 h-[80px] rounded-xl mx-auto w-[140px] text-center text-green-800 text-[50px]">
                       ?

                     
                    </div>
                    <HighlightText brackedSentence={brackedSentence} className={"text-lg"} classNameText={"underline underline-offset-4 font-[500]  decoration-dashed text-[#119f87]"} />
                </div>
            </div>}
            <div className={" bg-input2 bottom-0 bg-[#84e8c6] transition-all border-t bg-input2 transition-all  p-3 px-2 rounded-b-xl left-0 right-0  "}>
                {state == 1 && <WaiterOpen setState={setState} setDifficulty={setDifficulty}/>}
                {state == 2 && <QCM handleSpeak={handleSpeak} setState={setState} elements={randomCards.slice(0, nbRandom)} field={"term"} goodElement={card} validCard={validTrigger} mode={"lang-comp"}/>}
            </div>

           

    </div>
}



const FlashGrammar = ({card, profile_card, validCard, updateProfileCard, createProfileCard}) => {


    let props = [...new Set(card.data.options)]
   
    const parts = card.term.split("|")?.[0]?.split(/\[(.*?)\]/);
    const correct = card.data.correct
    const trad = card.data.trad

    !props.includes(correct) && props.push(correct)


    const [answer, setAnswer] = useState()
    const [errors, setErrors] = useState(0)
    const [showTrad, setShowTrad] = useState()

    useEffect(() => {
        setErrors(0)
        setShowTrad()
        setAnswer()
    }, [card?.id])

    const {decks, profile, workspace} = useStateContext()
    console.log('decks', decks)

    const deck = decks?.find(e => e.id == card?.deck_id)

   
    const new_profile_card  = {
        lvl: 2,
        last_update: new Date(),
        card_id: card.id,
        collected: false,
        next_date: new Date(),
        next_trigger: "0",
        profile_id: profile.id,
        workspace_id: workspace.id,
        triggers: [{next_date: new Date(), lvl: 2}],
        id: new Date(),
    }

    const new_profile_card_bad  = {
        lvl: 1,
        last_update: new Date(),
        card_id: card.id,
        collected: false,
        next_date: new Date(),
        next_trigger: "0",
        profile_id: profile.id,
        workspace_id: workspace.id,
        triggers: [{next_date: new Date(), lvl: 1}],
        id: new Date(),
    }

    const handleValid = (valid) => {
        console.log('-- grammar handleValid', profile_card)
        let _profile_card

        if (valid) {
            validCard({valid: true, bonus: 0})
            mp3_valid.play()
            if (profile_card?.id) {
                _profile_card= evolveProfileCard({profile_card, trigger: "0", valid: true})
                console.log('-- grammar evolveProfileCard', _profile_card)
                updateProfileCard(_profile_card.id, _profile_card)
               
            } else {
                console.log('-- grammar createProfileCard', _profile_card)
                createProfileCard(card.id, new_profile_card, card )
            }
        } else {
            validCard({valid: false, bonus: 0})
            mp3_bad.play()
            if (profile_card?.id) {
                _profile_card= evolveProfileCard({profile_card, trigger: "0", valid: false})
                console.log('-- grammar evolveProfileCard', _profile_card)
                updateProfileCard(_profile_card.id, _profile_card)
               
            } else {
                console.log('-- grammar createProfileCard', _profile_card)
                createProfileCard(card.id, new_profile_card_bad, card )
            }
        }
      
       
      
    }



    return <div onClick={() => console.log('card', card)} className="mx-2 overflow-hidden flex flex-col flashcard text-center rounded-2xl  bg-white text-slate-800 max-h-[500px] w-[300px] min-w-[300px] max-w-[500px]">
        
        {/* Grammar: {card.term} */}
        <div className="p-4 bg-amber-100 text-amber-500">{deck?.name}</div>

        <div className="p-4 grow flex bg-amber-100/50 flex-col justify-center items-center">
            {/* <div>Niv: {profile_card?.lvl || 0}</div> */}
        <div className="text-amber-800  ">
        {parts?.map((part, index) => {
                // Si l'index est impair, c'est le contenu entre crochets
                // donc on le remplace par un input
                if (index % 2 === 1) {
                    return <span>___</span>
                }
                // Sinon on affiche le texte tel quel
                return <span key={index}>{part}</span>;
            })}
        </div>
        {showTrad && <div onClick={() => setShowTrad(e => !e)}  className="text-xs mt-4 text-slate-600/50">{trad}</div>}
        {!showTrad && <div onClick={() => setShowTrad(e => !e)} className="text-xs mt-4 text-slate-600/50">Voir la traduction</div>}
        </div>

       
        <div className="grid p-4 bg-amber-100 grid-cols-2 gap-2 ">
            {props.map(e => <div onClick={() => {
                setAnswer(e)
               
                if (e == correct) {
                    setTimeout(() => {
                        handleValid(errors == 0)
                    }, [500])
                } {
                    setErrors(e => e+1)
                }
               
                }} className={` ${e == answer ? answer == correct ? "bg-green-500 text-white !border-green-600"  : "bg-pink-500 text-white !border-pink-600" : " bg-white text-amber-600"} border border-b-4 border-amber-500  px-2 py-2 rounded-xl`}>{e}</div>)}
        </div>


        <div></div>
    
    </div>
}


const Flashcard = ({card, profile_card, updateCard, setDiscoverPoints, validCard, mode, handleArchived, randomCards, workspace, updateProfileCard, createProfileCard}) => {

    console.log('random cards', randomCards)

    if (card?.model == "grammar") return <FlashGrammar updateProfileCard={updateProfileCard} profile_card={profile_card} validCard={validCard} createProfileCard={createProfileCard} card={card}/>
   
    return <div className="mx-2 flashcard text-center rounded-2xl  bg-white text-slate-800 max-h-[500px] w-[300px] min-w-[300px] max-w-[500px]">
                {mode == "learn" && profile_card?.next_trigger == 0 && <TriggerComprehension updateCard={updateCard} updateProfileCard={updateProfileCard} workspace={workspace} randomCards={randomCards} card={card} profile_card={profile_card} validCard={validCard}/>}
                {mode == "learn" && profile_card?.next_trigger == 1 && <TriggerExpression updateCard={updateCard} updateProfileCard={updateProfileCard} workspace={workspace} randomCards={randomCards} card={card} profile_card={profile_card} validCard={validCard}/>}
                {mode == "back" && <BackCard updateCard={updateCard}  updateProfileCard={updateProfileCard} workspace={workspace} randomCards={randomCards} card={card} profile_card={profile_card} validCard={validCard}/>}
                {mode == "discover" && <DiscoverCard updateCard={updateCard} setDiscoverPoints={setDiscoverPoints} workspace={workspace} randomCards={randomCards} handleArchived={handleArchived} card={card} profile_card={profile_card} validCard={validCard} />}
        </div>
}
export {Flashcard, BackCard}