import { useEffect, useState } from "react"
import { useStateContext } from "../../StateProvider"
import { displayDuration, formatDateDMY, getCardToLearn, shuffleArray } from "../../helperFunctions"
import { SVG_close } from "../../svgs"
import { Flashcard } from "../flashcard/flashcard"
import { addElements, addXpToProfile, getNextCards, sbAdd, sbCreate, sbUpdate } from "../../DbFunction"
import { DailyMission } from "./DailyStreak"
import { images } from "../../../utilities/helper/helper"




const Header = ({setShowModal, newCards, discoverPoints, discoverPointsTotal, todoCards, setState}) => {
        const todayDate = formatDateDMY(new Date())
        const {profile,workspace, setProfile, setProfileWorkspace, profileWorkspace, setTutoTriggers, daily} = useStateContext()
        
        const [showOption, setShowOptions] = useState(false)

        const options = [
                {
                    name: "Écriture",
                    id: "fill",
                    description: "Tu dois écrire le terme pour valider la carte.",
                    xp: 1
                },
                {
                    name: "QCM",
                    id: "qcm",
                    description: "Choisis parmi 4 réponses.",
                    xp: 0.5
                },
                {
                    name: "Libre",
                    id: "free",
                    description: "À toi de juger si tu te souviens ou pas.",
                    xp: 0.3
                },
            ];
            

        const optionExpression = profileWorkspace?.options?.exp_mode || workspace?.options?.exp_mode ||  "fill"

        return <div className={"p-2 flex gap-2 text-white justify-between items-center"}>
                {showOption && <div className="fixed z-[999] top-0 bottom-0 left-0 right-0 top-0 bg-indigo-900/90 h-screen backdrop-blur-xl overflow-scroll">
                <SVG_close onClick={() => setShowOptions(false)} className="absolute top-4 right-4" />
                <div className="p-8 pt-[70px] text-white">
                        <div className="text-sm text-center">Options d'apprentissage </div>
                        <div className="mt-6">Test d'expression</div>
                        {options?.map(option => <div 
                        onClick={() => {
                         sbUpdate('profiles_workspaces', profileWorkspace?.id, {options: {...profileWorkspace?.options, exp_mode: option.id}})
                         setProfileWorkspace(prev => ({...prev, options: {...prev.options, exp_mode: option.id}}))
                        }}
                        className={`${optionExpression == option?.id ?  "bg-indigo-700/90": "scale-[0.95] opacity-[0.8] bg-indigo-800/80"} transition-all p-2 mt-2 rounded-xl pr-4 border border-b-4 border-black/90 flex gap-2 items-center `}>
                                <div className="bg-black/40 border border-white/20 p-1 rounded-full"> <div className={`${optionExpression == option?.id ? "bg-white" : ""}  border p-1 rounded-full h-[12px] w-[12px]`}></div></div>
                                <div>
                                <div>{option?.name}</div>
                                <div className="text-xs">{option?.description}</div>
                                <div className="text-xs italic mt-1 opacity-[0.8]">+{option?.xp} xp par réponse juste</div>
                                </div>
                        </div>)}
                      
                </div>
                </div>}
                <SVG_close onClick={() => {
                       
                        if (daily.tt_xp > 11 && (profile?.s_date != todayDate)) {
                                console.log('__exit yes')
                                setState(2)
                        } else {
                                console.log('__exit no', {profile, todayDate})
                                setShowModal()
                                
                        }
         
                        if (!profile?.events?.tt_end_first_session) setTutoTriggers(prev => [...prev || [], "end_first_session" ])    
                        }}/>
                {newCards ? <div className="text-amber-300 mt-1 text-center grow">
                        <div className="h-2 rounded-xl w-full bg-black/20 overflow-hidden ">
                                <div className="h-full game-btn bg-amber-500 rounded-xl transition-all" style={{width: `${discoverPoints*100/discoverPointsTotal}%`}}></div>
                        </div>
                        {/* Nouvelle carte {discoverPoints}/ {discoverPointsTotal} */}
                        </div> 
                        : <>
                        <div onClick={() => console.log('todo', todoCards)} className="h-2 bg-white/20 transition-all grow rounded">
                              <div className="h-full game-btn bg-amber-500 duration-[700] rounded-xl transition-all" style={{width: `${todoCards?.filter(e => e.session.valid).length*100/todoCards?.length}%`}}></div>
                        </div>
                        {todoCards?.filter(e => e.session.valid).length} / {todoCards?.length}
                        </>}
                        {/* <div></div> */}
                {(profile?.role == "admin" || profile?.role == "editor") ? <svg onClick={() => {setShowOptions(true)}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4"><path fillRule="evenodd" d="M6.455 1.45A.5.5 0 0 1 6.952 1h2.096a.5.5 0 0 1 .497.45l.186 1.858a4.996 4.996 0 0 1 1.466.848l1.703-.769a.5.5 0 0 1 .639.206l1.047 1.814a.5.5 0 0 1-.14.656l-1.517 1.09a5.026 5.026 0 0 1 0 1.694l1.516 1.09a.5.5 0 0 1 .141.656l-1.047 1.814a.5.5 0 0 1-.639.206l-1.703-.768c-.433.36-.928.649-1.466.847l-.186 1.858a.5.5 0 0 1-.497.45H6.952a.5.5 0 0 1-.497-.45l-.186-1.858a4.993 4.993 0 0 1-1.466-.848l-1.703.769a.5.5 0 0 1-.639-.206l-1.047-1.814a.5.5 0 0 1 .14-.656l1.517-1.09a5.033 5.033 0 0 1 0-1.694l-1.516-1.09a.5.5 0 0 1-.141-.656L2.46 3.593a.5.5 0 0 1 .639-.206l1.703.769c.433-.36.928-.65 1.466-.848l.186-1.858Zm-.177 7.567-.022-.037a2 2 0 0 1 3.466-1.997l.022.037a2 2 0 0 1-3.466 1.997Z" clipRule="evenodd" /></svg> : <div></div> }
        </div>
}


// quand on apprends une carte dans le TOEIC on ajoute pas les 2 triggers juste 1

// trie les cartes par triggers et ajoute session
const setSession = (cardsToLearns) => {
        return cardsToLearns?.sort((a,b) => a.profile_card?.next_trigger?.localeCompare(b.profile_card?.next_trigger)).map((e,i) => {
                return {...e, session: {i: i, valid: false, count: 0}}
        })
}

//fais une fonction qui trouve l'index dans todoCards de la carte avec le plus petit count et valid false
const getIndex = (toDoCards) => {
        // Filtre d'abord les cartes avec valid false
        const invalidCards = toDoCards.filter(card => !card.session.valid);
        
        // Si aucune carte invalide, retourne -1
        if (invalidCards.length === 0) return -1;
        
        // Trouve la carte avec le plus petit count parmi les cartes invalides
        const minCount = Math.min(...invalidCards.map(card => card.session.count));
        
        // Retourne l'index original dans todoCards
        return toDoCards.findIndex(card => 
            !card.session.valid && card.session.count === minCount
        );
    }

    const updateCard = () => {
        // on update la carte dans todo
        // on update la carte dans learnedCards
        // on update la carte en db
}

// nouvelle cartes 
// si validation on créer 2 todoCartes
// libérer les graines que toutes les 4 plantes

const DiscoverCards = ({todoDiscover, addToDoCards, setTodoDiscover, setDiscoverPoints, randomCards, updateCard}) => {
        const { nextCards, setNextCards, workspace, profile, learnedCards, setLearnedCards } = useStateContext()

        // On stocke maintenant un tableau d'IDs au lieu d'un simple compteur
        const [validedCards, setValidedCards] = useState([])

        const passCard = async(card) => { 
                setNextCards(prev => prev.filter(c => c.id != card.id))
                console.log('nextCards', nextCards.map(e => e?.term))
                if (nextCards?.length < 5) {
                        const _nextCards = await getNextCards(workspace.id, profile.id)
                        setNextCards(_nextCards.filter(ee => !learnedCards.find(e => e.card.id == ee.id)))
                }
        }
        
        const handleValidCard = async(card) => {
                // On vérifie si la carte n'a pas déjà été validée
                passCard(card)
                if (validedCards.includes(card.id)) return;
               
                addToDoCards(card);
                setValidedCards(prev => [...prev, card.id]); // On ajoute l'ID de la carte
              
                
                // On compare maintenant la longueur du tableau
                if (validedCards.length === todoDiscover.length - 1) {
                        setTodoDiscover()
                        console.log('Terminé !!')
                }
        }

        const handleArchived = async(card) => {
                // On vérifie aussi pour l'archivage si la carte n'a pas déjà été traitée
              

                const profile_card = {
                        workspace_id: workspace.id,
                        profile_id: profile.id,
                        card_id: card.id,
                        archived: true,
                }
                const id = await sbAdd("profiles_cards", profile_card)
                
                setLearnedCards(prev => [...prev, {card: card, profile_card: {...profile_card, id: id}}])
                passCard(card)    
        }

        return <div className="flex justify-center ">
                <Flashcard 
                    setDiscoverPoints={setDiscoverPoints} 
                    workspace={workspace} 
                    randomCards={randomCards?.filter(e => e.id != nextCards[0]?.id)} 
                    handleArchived={handleArchived} 
                    validCard={() => handleValidCard(nextCards[0])} 
                    card={nextCards[0]} 
                    mode={"discover"}
                />
        </div>
}

export default DiscoverCards;


const LearnCards = () => {
        const {  setShowModal,setNextCards, profile, setTutoTriggers, profileWorkspace, daily, setDaily, setProfileWorkspace, setProfile,setShowBottomBar, learnedCards, setLearnedCards, cardsToRefresh, cardsToDiscover ,selectedNewCards, nextCards, workspace, bonusPoints, setBonusPoints } = useStateContext()
        const [todoCards, setTodoCards] = useState([])
        const cardsToLearns = setSession(cardsToRefresh)
        const [todoDiscover, setTodoDiscover] = useState([])
        const [index, setIndex] = useState(0)
        const currentCard = todoCards?.[index]
        const [randomCards, setRandomCards] = useState([])
        const [beforeCards, setBeforeCards] = useState()
        const [state, setState] = useState(0)
        const [gainElixirs, setGainElixirs] = useState(0)
        const [gainXp, setGainXp] = useState(0)
        const todayDate = formatDateDMY(new Date())


        useEffect(() => {
                console.log('--cardsToDiscover', cardsToDiscover)
                console.log('--cardsToLearns', cardsToLearns)
                const all = [...cardsToLearns || [], ...cardsToDiscover?.map(e => ({card: e, profile_card: null})) || []].filter(e => e.card?.model == "lang")
                console.log("all", all)
                setBeforeCards(all)
                setState(0)
        }, [])
        
        
        const updateProfileCard = (profile_card_id, data) => {
                if (data){                
                        
                        setTodoCards(prev => prev.map(c => c.profile_card?.id === profile_card_id ? {...c, profile_card: {...c.profile_card, ...data}} : c))
                        setLearnedCards(prev => prev.map(c => c.profile_card?.id === profile_card_id ? {...c, profile_card: {...c.profile_card, ...data}} : c))
                        sbUpdate("profiles_cards", profile_card_id, data)
                }
        }

        const createProfileCard = async(card_id, data, card) => {
                // pour les cartes grammar
                if (data){                
                        const _new_profile_card = await sbCreate("profiles_cards", data)
                        setTodoCards(prev => prev.map(c => c.card?.id === card_id ? {...c, profile_card: _new_profile_card} : c))
                        setLearnedCards(prev => [...prev, {card: card, profile_card: _new_profile_card}])
                        // sbUpdate("profiles_cards", profile_card_id, data)
                }
        }

        const updateCard = (card_id, data) => {
                setNextCards(prev => prev?.map(c => c.id === card_id ? {...c, ...data} : c))
                setTodoCards(prev => prev?.map(c => c.card.id === card_id ? {...c, card: {...c.card, ...data}} : c))
                setLearnedCards(prev => prev?.map(c => c.card.id === card_id ? {...c, card: {...c.card, ...data}} : c))
                sbUpdate("cards", card_id, data)
        }

        useEffect(() => {
                // if (!currentCard) return
                const cards = learnedCards?.length > 7 ? learnedCards?.map(e => e.card)?.filter(e => e.id != currentCard?.card.id && e.model == "lang") || [] : learnedCards?.map(e => e.card)?.concat(nextCards).filter(e => e.id != currentCard?.card.id && e.model == "lang") || []
                let filteredCards = cards
                if (currentCard?.card?.img) {
 
                        filteredCards = cards.filter(e => e.img != null && e.id != currentCard?.card.id) || []
                } 

                console.log('filteredCards', filteredCards)
                console.log('currentCards', currentCard)
               

                const suffledCards = shuffleArray(filteredCards)?.slice(0,8)

                setRandomCards(suffledCards)
        }, [currentCard, learnedCards])
         
        useEffect(() => {
                setTodoCards(cardsToLearns) // liste de tout les exercices
                setTodoDiscover(cardsToDiscover)
                setShowBottomBar(false)
                return () => {
                        setShowBottomBar(true)
                }
        }, [])

        
        const validCard = ({valid, bonus, grammar, ratio= 1}) => {      
                
                
                const _toDoCards = [...todoCards.map(e => e.session.i == currentCard.session.i ? {...e, session: {...e.session, count: e.session.count + 1, valid: valid}} : e)]
                setTodoCards(prev => prev.map(e => e.session.i == currentCard.session.i ? {...e, session: {...e.session, count: e.session.count + 1, valid: valid}} : e))
                const newIndex = getIndex(_toDoCards)
                if (index == newIndex) {
                        setState(1)
                        if (daily.tt_xp > 11 && (profile?.s_date != todayDate)) setState(2)
                        if (!profile?.events?.tt_end_first_session) setTutoTriggers(prev => [...prev || [], "end_first_session" ])

                }

                if (newIndex == -1) {
                        setState(1)
                        if (daily.tt_xp > 11 && (profile?.s_date != todayDate)) setState(2)
                        if (!profile?.events?.tt_end_first_session) setTutoTriggers(prev => [...prev || [], "end_first_session" ])

                }
                console.log('validCard index', newIndex)
                setIndex(newIndex)
                // updateCard(valid, bonus)      
                console.log('profileWorkspace', profileWorkspace)
                const expressionMode = profileWorkspace?.options?.exp_mode || workspace?.options?.exp_mode ||  "fill"
               

                const elixirsToAdd =  (valid ? Math.round((Math.min((currentCard.profile_card?.lvl || 1) + 1, 3) / ratio) * 10) /10 : 0) || 0
                console.log('__debug elixirsToAdd', elixirsToAdd)
                console.log('__debug currentCard', currentCard)
                const xpToAdd = Math.round((((valid? 1:0) + (bonus||0)) / ratio) * 10) / 10 || 0
                console.log('xpToAdd', {xpToAdd, elixirsToAdd, ratio})
                sbUpdate('profiles_workspaces', profileWorkspace?.id, {tt_elixirs: (profileWorkspace.tt_elixirs || 0) + elixirsToAdd, tt_xp: (profileWorkspace.tt_xp || 0) +xpToAdd})
                setProfileWorkspace(prev => ({...prev, tt_elixirs: (profileWorkspace.tt_elixirs || 0) + elixirsToAdd, tt_xp: (profileWorkspace.tt_xp || 0) +xpToAdd}))
                addXpToProfile(profile, valid ? 1 : 0, bonus == 1 ? 1 : 0, setProfile)
                console.log('daily', daily)
                sbUpdate('profiles_dailies', daily.id, {tt_xp: (daily.tt_xp || 0) + xpToAdd})
                setDaily(prev=> ({...prev, tt_xp: (daily.tt_xp || 0) + xpToAdd}))
                // console.log(`gain: valid: ${valid} bonus: ${bonus}`)
                setGainElixirs(prev => prev + elixirsToAdd)
                setGainXp(prev => prev + xpToAdd)
                sbUpdate("profiles", profile?.id, {last_connexion: new Date()})
        }



        const addToDoCards = async (card, opt) => {
                console.log('card', card)

                // créer profile_card
                let triggers = [
                        {next_date: new Date(),lvl: 1},
                        {next_date: new Date(),lvl: 1},
                ]

                if (profileWorkspace?.goal_id == "TOEIC") {
                        triggers =  [{next_date: new Date(),lvl: 1}]
                }

                const profile_card = {
                        lvl: 1,
                        last_update: new Date(),
                        card_id: card.id,
                        collected: false,
                        next_date: new Date(),
                        next_trigger: "0",
                        profile_id: profile.id,
                        workspace_id: workspace.id,
                        triggers: triggers
                }

                console.log('new profile_card', profile_card)

                // ajouter en db
                const id = await sbAdd("profiles_cards", profile_card)

                let newToDoCards = [{card: card, profile_card: {...profile_card, id }, session: {i: todoCards?.length || 0, valid: false, count: 0, trigger: 0}},
                {card: card, profile_card: {...profile_card, id, next_trigger: "1"}, session: {i: todoCards?.length + 1 || 1, valid: false, count: 0, trigger: 1}}]
            
                if (profileWorkspace?.goal_id == "TOEIC") {
                        newToDoCards = [{card: card, profile_card: {...profile_card, id }, session: {i: todoCards?.length || 0, valid: false, count: 0, trigger: 0}}]
                }

                 // ajouter dans todo
                setTodoCards(prev => [...prev || [], ...newToDoCards].sort((a, b) => a.session?.trigger - b.session?.trigger))



                // ajouter dans learnCard
                setLearnedCards(prev => [...prev, {card: card, profile_card: {...profile_card, id: id}}])
                console.log('   ', daily)
                addXpToProfile(profile, 2, 0, setProfile)
                sbUpdate("profiles", profile?.id, {last_connexion: new Date()})
                sbUpdate('profiles_workspaces', profileWorkspace?.id, {tt_xp: (profileWorkspace.tt_xp || 0) + 2})
                setProfileWorkspace(prev => ({...prev, tt_xp: (profileWorkspace.tt_xp || 0) + 2}))
                sbUpdate('profiles_dailies', daily.id, {tt_xp: (daily.tt_xp || 0) + 2, new_card: (daily.new_card || 0) + 1})
                setDaily(prev=> ({...prev, tt_xp: (daily.tt_xp || 0) + 2, new_card: (daily.new_card || 0) + 1}))
                setGainXp(prev => prev + 2) 
        }


        const [discoverPoints, setDiscoverPoints] = useState(0)

        
       

    return <div onClick={(e) => {
        e.stopPropagation()
        setShowModal(); 
        
        }} className="bg-indigo-900/80 backdrop-blur-[4px] z-[80] fixed top-0 left-0 right-0 bottom-0">
                {state == 2 && <div>
                        <DailyMission quit={() => setState(1)}/>
                        </div>}
                
                {state == 1 && <div className="p-8 h-full bg-black/20">
                        
                        <div className="text-white mt-8 text-center ">Mise à jour des graines</div>
                        <div className="flex justify-center gap-1 mt-4">
                                <div className="bg-black/30 items-center rounded-xl flex gap-2 px-3 text-amber-200"> + {Math.round(gainXp)} <img className='h-4 w-4' src={'/images/icons/coin-xp.png'}/></div>
                                <div className="bg-black/30 items-center rounded-xl flex gap-2 px-3 text-indigo-200"> + {Math.round(gainElixirs)} <img className='h-4 w-4' src={'/images/deco/elixir.png'}/></div>
                                {beforeCards?.filter(e => !e?.profile_card)?.length > 0 && <div className="bg-black/30 items-center rounded-xl flex gap-2 px-3 text-indigo-200"> + {beforeCards?.filter(e => !e?.profile_card)?.length} <img className='h-4 w-4' src={'/images/seeds/flower.png'}/></div>}

                        </div>
                       

                        <div style={{maxHeight: "calc(100vh - 200px)"}} className={`mx-auto ${beforeCards?.length < 5 ? "max-w-[240px]" : ""}  pt-8 overflow-scroll`}>
                        <div  className={` ${beforeCards?.length < 5 ? "grid-cols-2 grid" : "flex flex-wrap justify-center"}   gap-2 gap-y-8`}>{beforeCards?.map(e => {
                        const lastVersion = learnedCards.find(ee => ee.card.id == e.card.id)
                        return <div className={`bg-[#502082] ${beforeCards?.length < 5 ? "" :"w-[70px]  max-w-[70px]"}  grow border-2 border-[#502082] text-white relative rounded-xl`}>
                                {e?.profile_card ? lastVersion?.profile_card?.lvl >= e?.profile_card?.lvl &&  <div className={`${beforeCards?.length < 5 ? "px-3  -top-4" : "px-1 pr-2 text-[11px] -top-2"} items-center absolute rounded-xl flex gap-1 bg-indigo-500 text-white`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
                                </svg> Niv +1
                                {/* {e?.profile_card?.lvl || 0} → {lastVersion?.profile_card?.lvl || 0} */}
                                
                                </div>
                                : <div className="absolute -top-2 text-xs px-2 rounded-xl bg-[#b93ba9] text-white">Nouveau</div> }
                                <div className={`  ${beforeCards?.length < 5 ? "h-[100px]":"h-[50px]" } rounded-t-xl  bg-[#7532b5] w-full`}>
                                         <img src={e.card?.img || images.find(i => i.name == "seed#1_green_2_mastered").src} className="w-full rounded-t-xl h-full object-cover" />
                                       
                                        </div>
                                <div className={` truncate ${beforeCards?.length < 5 ? "text-sm" : "text-[12px] max-w-[65px]"} py-1 pb-0 px-1 text-center fredoka`}>
                                {e?.card?.term}
                                <div className="text-purple-100/80 -mt-[1px] pb-[2px] text-[10px]">{displayDuration(new Date(), lastVersion?.profile_card.next_date) == "maintenant" ? "à revoir" : displayDuration(new Date(), lastVersion?.profile_card.next_date) }</div>
                                </div></div>})
                        }</div>
                        </div>
                        <div className="fixed left-0 right-0 bottom-2 p-4 text-center text-white">
                                
                                Continuer →
                        </div>
                </div>}
                {state == 0 && <div onClick={(e) => {e.stopPropagation()}} className="flex h-full flex-col">
                        {/* --/{todoDiscover?.length}<div className="flex gap-1 flex-wrap">{todoCards?.map(e => <div className={`${e.session.valid ? "bg-green-500" : "bg-red-500"} text-[8px]`}>#{e.session.i}{e.card.term.slice(0, 3)} - {e.profile_card.next_trigger}</div>)}</div> */}
                        <Header setState={setState} todoCards={todoCards} discoverPoints={discoverPoints} discoverPointsTotal={12} newCards={todoDiscover?.length} setShowModal={setShowModal} />
                        <div className=" grow h-full flex flex-col justify-center items-center">
                                {todoDiscover?.length && <DiscoverCards updateCard={updateCard} setDiscoverPoints={setDiscoverPoints} workspace={workspace} randomCards={randomCards} setTodoDiscover={setTodoDiscover}  addToDoCards={addToDoCards} todoDiscover={todoDiscover}/> }
                                {!todoDiscover?.length && currentCard && <Flashcard createProfileCard={createProfileCard} updateCard={updateCard} updateProfileCard={updateProfileCard} workspace={workspace} randomCards={randomCards}  mode={"learn"} validCard={validCard} profile_card={currentCard?.profile_card} card={currentCard?.card}/>}
                        </div>
                        {/* <div className="flex text-xs gap-1 flex-wrap" onClick={() => {
                                // console.log('toDo', learnedCards.map(e => displayDuration(e.profile_card.last_update)))
                                 console.log('toDo', todoCards)

                        }}>{todoCards?.map(e => <div className={`bg-white/80 ${e.profile_card?.next_trigger == 0 && " !bg-green-200 "} ${e.profile_card?.next_trigger == 1 && " !bg-purple-200 "} relative`}>
                                <div>{e.card.term}</div>
                                <div className="text-[8px]">t{e.profile_card?.next_trigger} - lvl{e.profile_card?.lvl} - s{e.session.valid ? "ui": "nn"}</div>
                                </div>)}</div> */}
                    
                </div> }

        </div>}


export {LearnCards}